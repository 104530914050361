<template>
    <PageWrapper>
        <template #headline>
            <fa icon="fa-person" />
            {{ $t('profile_information_natural') }}:
            {{ profileNatural.first_name }}
            {{ profileNatural.last_name }}
        </template>

        <template #actions>
            <BackButton
                v-if="$router.options.history.state.back"
                @click="router.go(-1)"
            />
            <BackButton
                v-if="
                    profileNatural.natural_profile_uid &&
                    $router.options.history.state.back !==
                        `/naturalProfile/${profileNatural.natural_profile_uid}`
                "
                icon="fa-eye"
                title="account"
                @click="goToDetails"
            />
            <BackButton
                v-if="
                    !$router.options.history.state.back
                        ?.toString()
                        .includes('/accounts')
                "
                icon="fa-list"
                title="accounts"
                @click="toAccounts"
            />
            <SaveButton @click="saveNaturalProfile" />
        </template>

        <AccordionBasic
            title="account_profile_details"
            class="mb-2"
            :open="true"
        >
            <ToggleSwitch
                v-model="profileNatural.active"
                name="natural_profile_active"
                label="natural_profile_active"
            />
            <TextField
                v-model="profileNatural.first_name"
                name="first_name"
                label="account_first_name"
                help="help_account_first_name"
                :required="true"
            />
            <TextField
                v-model="profileNatural.last_name"
                name="last_name"
                label="account_last_name"
                help="help_account_last_name"
                :required="true"
            />
            <TextField
                v-model="profileNatural.email"
                name="email"
                label="account_email"
                help="help_account_email"
                :required="true"
            />
            <SelectField
                v-model="profileNatural.nationality"
                name="nationality"
                label="account_nationality"
                help="help_account_nationality"
                :options="countries"
            />
            <SelectField
                v-model="profileNatural.default_language"
                name="language"
                label="account_default_language"
                help="help_default_language"
                :options="languages"
            />
            <Datepicker
                v-model="profileNatural.date_of_birth"
                label="account_date_of_birth"
                name="date_of_birth"
                placeholder="account_date_of_birth"
                help="help_date_of_birth"
            />
            <SelectField
                v-model="profileNatural.gender"
                name="gender"
                label="account_gender"
                help="help_account_gender"
                :options="mapEnumToArray(NaturalProfileGenderType)"
                :reduce="reduceEnumValue"
                :required="true"
            />
            <TextField
                v-model="profileNatural.custom_id"
                name="custom_id"
                label="account_custom_id"
                help="help_account_custom_id"
                :required="false"
            />
            <ToggleSwitch
                v-model="profileNatural.pep"
                name="pep"
                label="account_pep"
            />
            <template v-if="profileNatural.pep">
                <TextField
                    v-model="profileNatural.pep_position"
                    name="pep_position"
                    label="account_pep_position"
                    help="help_account_pep_position"
                    :required="false"
                />
                <ToggleSwitch
                    v-model="profileNatural.pep_past"
                    name="pep_past"
                    label="account_pep_past"
                />
                <template v-if="profileNatural.pep_past">
                    <TextField
                        v-model="profileNatural.pep_past_position"
                        name="pep_past_position"
                        label="account_pep_past_position"
                        help="help_account_pep_past_position"
                        :required="false"
                    />
                </template>
            </template>
            <ToggleSwitch
                v-model="profileNatural.newsletter_consent"
                name="newsletter_consent"
                label="account_nl_subscribed"
                :disabled="true"
            />
        </AccordionBasic>
        <AccordionBasic
            title="account_address_contact_details"
            class="mb-2"
            :open="false"
        >
            <h4 class="font-bold">{{ $t('billing_address') }}</h4>

            <TextField
                v-model="profileNatural.address"
                name="address"
                label="account_address_required_for_wallet"
                help="help_account_address"
            />
            <TextField
                v-model="profileNatural.postal_code"
                name="postal_code"
                label="account_zip_required_for_wallet"
                help="help_account_zip"
            />
            <TextField
                v-model="profileNatural.city"
                name="city"
                label="account_city_required_for_wallet"
                help="help_account_city"
            />

            <SelectField
                v-model="profileNatural.country"
                name="country"
                label="account_country_required_for_wallet"
                help="help_account_country"
                :options="countries"
            />
            <TextField
                v-model="profileNatural.state"
                name="state"
                label="account_state"
                help="help_account_state"
            />

            <h4 class="block pt-4 font-bold">{{ $t('postal_address') }}</h4>

            <ToggleSwitch
                v-model="isPostalAddressSameAsBillingAddress"
                name="is_postal_address_same_as_billing_address"
                label="same_as_billing_address"
                @toggle="handleTogglePostalAddress"
            />
            <div v-if="!isPostalAddressSameAsBillingAddress">
                <TextField
                    v-model="profileNatural.postal_address"
                    name="address"
                    label="account_address"
                    help="help_account_address"
                />
                <TextField
                    v-model="profileNatural.postal_address_postal_code"
                    name="postal_code"
                    label="account_zip"
                    help="help_account_zip"
                />
                <TextField
                    v-model="profileNatural.postal_address_city"
                    name="city"
                    label="account_city"
                    help="help_account_city"
                />
                <SelectField
                    v-model="profileNatural.postal_address_country"
                    name="country"
                    label="account_country"
                    help="help_account_country"
                    :options="countries"
                />
                <TextField
                    v-model="profileNatural.postal_address_state"
                    name="state"
                    label="account_state"
                    help="help_account_state"
                />
            </div>

            <h4 class="block pt-4 font-bold">{{ $t('contact_details') }}</h4>

            <TextField
                v-model="profileNatural.phone_number"
                name="phone_number"
                label="account_phone"
                help="help_account_phone"
            />
            <TextField
                v-model="profileNatural.mobile_number"
                name="mobile_number"
                label="account_mobile"
                help="help_account_mobile"
            />
            <TextField
                v-model="profileNatural.email_pending"
                name="email_pending"
                label="account_email_pending"
                help="help_account_email_pending"
                :disabled="true"
            />
        </AccordionBasic>

        <AccordionBasic
            title="bank_details"
            class="mb-2"
            :open="false"
        >
            <TextField
                v-model="profileNatural.iban"
                :mask="ibanMask"
                name="iban"
                label="iban"
                help="help_account_iban"
            />

            <TextField
                v-model="profileNatural.bic"
                name="bic"
                label="bic"
                help="help_account_bic"
            />
        </AccordionBasic>

        <AccordionBasic
            title="tax_details"
            class="mb-2"
            :open="false"
        >
            <TextField
                v-model="profileNatural.tax_number"
                name="tax_number"
                label="tax_number"
                help="help_account_tax_number"
            />

            <TextField
                v-model="profileNatural.social_security_number"
                name="social_security_number"
                label="social_security_number"
                help="help_account_social_security_number"
            />
        </AccordionBasic>
    </PageWrapper>
</template>
<script setup lang="ts">
import {
    AccordionBasic,
    Datepicker,
    SelectField,
    TextField,
    ToggleSwitch,
} from '@src/components'

import BackButton from '@src/partials/actions/BackButton.vue'
import SaveButton from '@src/partials/actions/SaveButton.vue'
import PageWrapper from '@src/partials/pages/PageWrapper.vue'
import { KYCDocument, NaturalProfile } from '@src/types'
import { NaturalProfileGenderType } from '@src/enums'
import { useCountryStore } from '@src/utils/stores/countries'
import {
    getKYCDocuments,
    getNaturalProfile,
    updateNaturalProfile,
} from '@utils/api/accounts'
import { onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { useEnum, useMask } from '@composables/common'
import { useToastStore } from '@stores/toast'
import { useI18n } from 'vue-i18n'
import { useLanguageStore } from '@stores/languages'

const profileNatural = ref<NaturalProfile>(new NaturalProfile())

const router = useRouter()
const route = useRoute()
const toast = useToastStore()
const i18n = useI18n()
const { ibanMask } = useMask()
const languageStore = useLanguageStore()

const { mapEnumToArray, reduceEnumValue } = useEnum()
const countries = ref<{ label: string; value: string | null }[]>([])
const languages = ref<{ label: string; value: string | null }[]>([])
const isPostalAddressSameAsBillingAddress = ref(true)
const countryStore = useCountryStore()

function goToDetails(): void {
    router.push({
        name: 'naturalProfile',
        params: { id: profileNatural.value.natural_profile_uid },
    })
}

function toAccounts() {
    router.push({ name: 'accounts' })
}

const KYCDocuments = ref<KYCDocument[]>([])
const addressKYCDocuments = ref<KYCDocument[]>([])

function initPostalAddress() {
    if (
        profileNatural.value.postal_address &&
        profileNatural.value.postal_address_postal_code &&
        profileNatural.value.postal_address_city &&
        profileNatural.value.postal_address_country &&
        profileNatural.value.postal_address_state
    ) {
        isPostalAddressSameAsBillingAddress.value = false
    }
}

function handleTogglePostalAddress() {
    if (!isPostalAddressSameAsBillingAddress.value) {
        profileNatural.value.postal_address = profileNatural.value.address
        profileNatural.value.postal_address_postal_code =
            profileNatural.value.postal_code
        profileNatural.value.postal_address_city = profileNatural.value.city
        profileNatural.value.postal_address_country =
            profileNatural.value.country
        profileNatural.value.postal_address_state = profileNatural.value.state
    } else {
        profileNatural.value.postal_address = null
        profileNatural.value.postal_address_postal_code = null
        profileNatural.value.postal_address_city = null
        profileNatural.value.postal_address_country = null
        profileNatural.value.postal_address_state = null
    }
}

onMounted(async () => {
    if (route.params.id) {
        const naturalProfileRequest = await getNaturalProfile(
            route.params.id as string
        )

        profileNatural.value = naturalProfileRequest.data.natural_profile

        initPostalAddress()

        loadData()

        KYCDocuments.value = await getKYCs(
            naturalProfileRequest.data.natural_profile.account_uid as string
        )

        addressKYCDocuments.value = KYCDocuments.value.filter(
            (document) => document.verification_type === 'kyc_address'
        )
    }
})

async function loadData() {
    countries.value = await countryStore.loadAndSortCountriesOptionsAsync()
    languages.value = await languageStore.loadAndSortLanguagesOptionsAsync()
}

async function saveNaturalProfile() {
    try {
        await updateNaturalProfile(profileNatural.value)
        toast.success(
            i18n.t('toast_success_natural_profile_updated', {
                profileNatural: profileNatural.value.natural_profile_uid,
            })
        )
    } catch (error) {
        console.error(error) // You can log the error for debugging
        toast.error(
            i18n.t('toast_error_natural_profile_update_failed')
            // Here, you should have a defined message for the update failure in your i18n messages
        )
    }
}

async function getKYCs(accountID: string): Promise<KYCDocument[]> {
    const KYCDocumentsRequest = await getKYCDocuments(accountID)
    return KYCDocumentsRequest.data
}

watch(i18n.locale, async () => {
    loadData()
})
</script>
