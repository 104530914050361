<template>
    <div
        :id="id"
        class="flex"
    >
        <div>
            <button
                v-if="checkCondition(actions[0].condition)"
                class="btn-sm bg-primary-additional-500 hover:bg-primary-additional-600 w-48 rounded-r-none text-white disabled:bg-gray-300"
                :title="getTitle(actions[0].title)"
                :disabled="checkDisabled(actions[0].disabled)"
                :class="[
                    {
                        'rounded-b-none': open,
                    },
                    actions[0].class,
                ]"
                @click.prevent="actions[0].action"
            >
                <fa
                    v-if="actions[0].icon"
                    :icon="actions[0].icon"
                    class="mr-2"
                />
                {{ $t(actions[0].label) }}
            </button>
            <div class="absolute">
                <ul
                    v-show="open"
                    ref="dropdown"
                >
                    <template
                        v-for="(action, index) in actions.slice(
                            1,
                            actions.length
                        )"
                    >
                        <li
                            v-if="checkCondition(action.condition)"
                            :key="`action-${index}`"
                        >
                            <button
                                class="btn-sm border-t-primary-additional-700 bg-primary-additional-500 hover:bg-primary-additional-600 w-48 rounded-t-none text-white disabled:bg-gray-300"
                                :title="getTitle(actions[0].title)"
                                :disabled="checkDisabled(action.disabled)"
                                :class="[
                                    {
                                        'rounded-b-none':
                                            index < actions.length - 2,
                                        'border-t-gray-400': checkDisabled(
                                            action.disabled
                                        ),
                                    },
                                    action.class,
                                ]"
                                @click.prevent="action.action"
                            >
                                <fa
                                    v-if="action.icon"
                                    :icon="action.icon"
                                    class="mr-2"
                                />
                                {{ $t(action.label) }}
                            </button>
                        </li>
                    </template>
                </ul>
            </div>
        </div>
        <button
            ref="trigger"
            class="btn-sm border-l-primary-additional-700 bg-primary-additional-500 hover:bg-primary-additional-600 rounded-l-none text-white"
            :class="{
                'border-l-gray-400 bg-gray-300 hover:bg-gray-300':
                    areActionsDisabled,
            }"
            aria-haspopup="true"
            :aria-expanded="open"
            :title="$t('actions')"
            @click.prevent="
                () => {
                    open = !open
                }
            "
        >
            <span class="sr-only">{{ $t('actions') }}</span>
            <fa icon="fa-solid fa-chevron-down" />
        </button>
    </div>
</template>
<script setup lang="ts">
import { MultiActionButtonAction } from '@src/composables/common'
import { computed, onMounted, onUnmounted, ref, toRefs } from 'vue'
import { useI18n } from 'vue-i18n'

const props = defineProps<{
    id: string
    actions: MultiActionButtonAction[]
}>()

const { actions } = toRefs(props)

const i18n = useI18n()

const open = ref(false)
const trigger = ref<HTMLElement | null>(null)
const dropdown = ref<HTMLElement | null>(null)

const areActionsDisabled = computed(() => {
    return (
        actions.value.filter(
            (action) => checkDisabled(action.disabled) === true
        ).length > 0
    )
})

function getTitle(title: string | { (): string | null } | undefined) {
    if (!title) {
        return
    }
    if (typeof title === 'string') {
        return i18n.t(title)
    }
    if (typeof title() === 'string') {
        return i18n.t(title() as string)
    }
}

function checkCondition(condition: boolean | { (): boolean } | undefined) {
    if (condition === undefined) {
        return true
    }
    if (typeof condition === 'boolean') {
        return condition
    }
    return condition()
}

function checkDisabled(disabled: boolean | { (): boolean } | undefined) {
    if (disabled === undefined) {
        return false
    }
    if (typeof disabled === 'boolean') {
        return disabled
    }
    return disabled()
}

// close on click outside
const clickHandler = (event: MouseEvent) => {
    const target: EventTarget | null = event.target
    if (
        target &&
        (!open.value ||
            dropdown.value?.contains(target as Node) ||
            trigger.value?.contains(target as Node))
    ) {
        return
    }
    open.value = false
}

// close if the esc key is pressed
const keyHandler = (event: KeyboardEvent) => {
    if (!open.value || event.code !== 'Escape') return
    open.value = false
}

onMounted(() => {
    document.addEventListener('click', clickHandler)
    document.addEventListener('keydown', keyHandler)
})

onUnmounted(() => {
    document.removeEventListener('click', clickHandler)
    document.removeEventListener('keydown', keyHandler)
})
</script>
