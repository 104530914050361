export enum TaskType {
    GENERIC = 'generic',
    INTEREST_PAYMENT_CALCULATION = 'interest-payment-calculation',
    INTEREST_PAYMENT_PAYOUT = 'interest-payment-payout',
    PAYMENT_ACCEPTANCE = 'payment-acceptance',
    EXPORT_CSV = 'export-csv',
}

export enum TaskStatus {
    REGISTERED = 'registered',
    STARTED = 'started',
    FINISHED = 'finished',
}

export enum TaskFinishedType {
    SUCCESS = 'success',
    WARNING = 'warning',
    HAS_ERRORS = 'has-errors',
    ERROR = 'error',
    ERROR_WHILE_GENERATING = 'error_while_generating',
    PENDING = 'pending',
}

export enum TaskTaskableType {
    INTEREST_PAYMENT = 'InterestPayment',
    ASSET = 'Asset',
}
