<template>
    <div
        :class="{
            'hidden': selected.length < 1,
        }"
    >
        <div class="flex items-center">
            <div class="mr-2 hidden whitespace-nowrap text-sm italic xl:block">
                <span>{{ selected.length }}</span>
                {{ $t('items_selected') }}
            </div>
            <button
                class="btn text-danger-500 border-gray-200 hover:border-gray-300"
            >
                <svg
                    class="h-4 w-4 shrink-0 fill-current"
                    viewBox="0 0 16 16"
                >
                    <path
                        d="M5 7h2v6H5V7zm4 0h2v6H9V7zm3-6v2h4v2h-1v10c0 .6-.4 1-1 1H2c-.6 0-1-.4-1-1V5H0V3h4V1c0-.6.4-1 1-1h6c.6 0 1 .4 1 1zM6 2v1h4V2H6zm7 3H3v9h10V5z"
                    ></path>
                </svg>
                <span class="ml-2">{{ $t('delete') }}</span>
            </button>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useVModels } from '@vueuse/core'

const props = defineProps<{
    selected: string[]
}>()

const emit = defineEmits(['update:selected'])

const { selected } = useVModels(props, emit)
</script>
