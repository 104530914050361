<template>
    <td
        class="whitespace-nowrap px-2 py-3 first:left-0 first:pl-5 last:sticky last:right-0 last:pr-5"
        :colspan="colspan"
    >
        <div
            class="flex items-center"
            :class="{ 'justify-center': center === true }"
        >
            <a
                v-if="url && targetBlank"
                :href="url"
                class="flex cursor-pointer items-center text-blue-500"
            >
                <slot />
            </a>
            <router-link
                v-if="url && !targetBlank"
                class="flex cursor-pointer items-center text-blue-500"
                :to="url"
            >
                <slot />
            </router-link>
            <div
                v-if="!url"
                class="flex items-center"
            >
                <slot />
            </div>
            <CopyButton
                v-if="copy && copyText"
                :value="copyText"
            />
        </div>
    </td>
</template>
<script setup lang="ts">
import { useVModels } from '@vueuse/core'
import CopyButton from '@src/partials/actions/CopyButton.vue'

const props = defineProps<{
    url?: string | null
    targetBlank?: boolean
    colspan?: number
    center?: boolean
    copy?: boolean
    copyText?: unknown
}>()

const { url, targetBlank } = useVModels(props)
</script>
<style scoped>
tr:hover td {
    @apply bg-gray-100;
}
</style>
