<template>
    <h4 class="mb-3 text-xl font-bold">{{ modelValue.meta_title }}</h4>
    <div v-save-html="modelValue.meta_value"></div>
</template>

<script setup lang="ts">
import { AssetsMeta } from '@src/types'
import { toRefs } from 'vue'

const props = defineProps<{
    modelValue: AssetsMeta
}>()

const { modelValue } = toRefs(props)
</script>

<style lang="scss" scoped></style>
