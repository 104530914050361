import { ModalButtonStyles, OpenModal, useTable } from '@composables/common'
import { NameAndId } from '@src/components'
import OrderAttachmentEditForm from '@src/partials/orders/OrderAttachmentEditForm.vue'
import { OrderAttachment } from '@src/types'
import { useUserStore } from '@src/utils/stores/user'
import { Ref, computed, h, ref } from 'vue'
import { useI18n } from 'vue-i18n'

export function useOrderAttachmentsTable(
    attachments: Ref<OrderAttachment[]>,
    update: (successMessage?: string | null) => Promise<void>,
    openModal: OpenModal,
    isModalOpen: Ref<boolean>
) {
    const userStore = useUserStore()
    const i18n = useI18n()

    const total = computed(() => {
        return attachments.value.length
    })

    const {
        TableWrapper: OrderAttachmentsTable,
        selected: selectedOrderAttachmentsIds,
    } = useTable({
        tableProps: {
            id: 'order_attachments',
            label: 'order_attachments',
            help: 'help_order_attachments_table',
            data: attachments,
            total: total,
            columns: [
                {
                    label: 'id',
                    key: 'id',
                    select: true,
                },
                {
                    label: 'id',
                    key: 'id',
                    condition: userStore.can('see_id_columns'),
                    copy: true,
                },
                {
                    label: 'name',
                    key: 'name',
                    copy: true,
                },
            ],
            actions: [
                {
                    action: handleViewOrderAttachment,
                    icon: 'fa-eye',
                    title: 'view',
                },
                {
                    action: handleUpdateOrCreateOrderAttachment,
                    icon: 'fa-solid fa-pen',
                    title: 'edit',
                    condition: userStore.can('update_order'),
                },
                {
                    action: handleDeleteOrderAttachment,
                    icon: 'fas fa-trash-alt',
                    title: 'delete',
                    color: 'text-danger-500',
                    condition: userStore.can('update_order'),
                },
            ],
        },
    })

    function handleViewOrderAttachment(attachment: OrderAttachment) {
        const url = attachment.url
        if (url) {
            window.open(url)
        }
    }

    function handleDeleteOrderAttachment(attachment: OrderAttachment) {
        isModalOpen.value = true
        openModal(
            {
                id: 'delete_order_attachment',
                title: 'modal_title_delete_order_attachment',
                open: isModalOpen,
                onCancel: () => (isModalOpen.value = false),
                onSubmit: () => deleteAttachment(attachment),
            },
            () =>
                h(NameAndId, {
                    idKey: 'id',
                    nameKey: 'name',
                    modelValue: [attachment],
                }),
            {
                submitButtonText: 'delete',
                styles: {
                    submitButton: ModalButtonStyles['RED'],
                },
            }
        )
    }

    function handleDeleteOrderAttachments() {
        isModalOpen.value = true
        openModal(
            {
                id: 'delete_order_attachments',
                title: 'modal_title_delete_order_attachments',
                open: isModalOpen,
                onCancel: () => (isModalOpen.value = false),
                onSubmit: () => deleteAttachments(selectedAttachments.value),
            },
            () =>
                h(NameAndId, {
                    idKey: 'id',
                    nameKey: 'name',
                    modelValue: selectedAttachments.value,
                }),
            {
                submitButtonText: 'delete',
                styles: {
                    submitButton: ModalButtonStyles['RED'],
                },
            }
        )
    }

    function deleteAttachment(
        attachmentToDelete: OrderAttachment,
        submit = true
    ) {
        const attachmentsTemp = [...attachments.value]
        attachments.value.splice(
            attachments.value.findIndex(
                (attachment) => attachmentToDelete.id == attachment.id
            ),
            1
        )
        if (submit) {
            update(
                i18n.t('toast_success_order_attachment_deleted', {
                    attachment: attachmentToDelete.name,
                })
            )
                .catch((error) => {
                    attachments.value = attachmentsTemp
                    throw error
                })
                .then(() => {
                    isModalOpen.value = false
                })
        }
    }

    function deleteAttachments(attachmentsToDelete: OrderAttachment[]) {
        const attachmentsTemp = [...attachments.value]
        attachmentsToDelete.forEach((attachment) => {
            deleteAttachment(attachment, false)
        })
        update(
            i18n.t('toast_success_order_attachments_deleted', {
                attachments: attachmentsToDelete
                    .map((attachment) => attachment.name)
                    .join(', '),
            })
        )
            .catch((error) => {
                attachments.value = attachmentsTemp
                throw error
            })
            .then(() => {
                selectedOrderAttachmentsIds.value = []
                isModalOpen.value = false
            })
    }

    function handleUpdateOrCreateOrderAttachment(
        attachment = {} as OrderAttachment
    ) {
        const editedOrderAttachment = ref({} as OrderAttachment)
        editedOrderAttachment.value = { ...attachment }
        isModalOpen.value = true

        const disableCreate = computed(() => {
            return !(
                editedOrderAttachment.value.name &&
                editedOrderAttachment.value.description &&
                editedOrderAttachment.value.url
            )
        })

        openModal(
            {
                id: 'edit_order_attachment',
                title: editedOrderAttachment.value.id
                    ? 'modal_title_edit_order_attachment'
                    : 'modal_title_create_order_attachment',
                open: isModalOpen,
                onCancel: () => (isModalOpen.value = false),
                onSubmit: () =>
                    saveOrderAttachment(editedOrderAttachment.value),
            },
            () =>
                h(OrderAttachmentEditForm, {
                    modelValue: editedOrderAttachment,
                }),
            {
                submitButtonText: editedOrderAttachment.value.id
                    ? 'update'
                    : 'create',
                styles: {
                    submitButton: ModalButtonStyles['BLUE'],
                },
                submitButtonDisabled: disableCreate,
            }
        )
    }

    function saveOrderAttachment(orderAttachment: OrderAttachment) {
        const attachmentsTemp = [...attachments.value]
        let toast = 'toast_success_order_attachment_updated'
        if (!orderAttachment.id) {
            toast = 'toast_success_order_attachment_created'
            delete orderAttachment.id
            attachments.value.push(orderAttachment)
        } else {
            attachments.value[
                attachments.value.findIndex(
                    (item) => item.id === orderAttachment.id
                )
            ] = orderAttachment
        }
        update(
            i18n.t(toast, {
                attachment: orderAttachment.name,
            })
        )
            .catch((error) => {
                attachments.value = attachmentsTemp
                throw error
            })
            .then(() => {
                isModalOpen.value = false
            })
    }

    const selectedAttachments = computed(() => {
        return attachments.value.filter(
            (item: OrderAttachment) =>
                item.id && selectedOrderAttachmentsIds.value.includes(item.id)
        )
    })

    return {
        OrderAttachmentsTable,
        selectedOrderAttachmentsIds,
        handleDeleteOrderAttachments,
        handleUpdateOrCreateOrderAttachment,
    }
}
