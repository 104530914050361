import { TableAction, useTable } from '@composables/common'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { TableWrapper as TableComponent } from '@src/components'
import { OrderStatus } from '@src/enums'
import router from '@src/router'
import {
    ContractOrder,
    InterestPaymentTransaction,
    TableSorting,
} from '@src/types'
import { formatNumericValues } from '@src/utils/helpers'
import { useUserStore } from '@src/utils/stores/user'
import moment from 'moment'
import { Ref, h, ref } from 'vue'
import { useI18n } from 'vue-i18n'

export function useContractOrdersTable(
    orders: Ref<ContractOrder[]>,
    ordersCount: Ref<number>,
    currency: Ref<string | null>
) {
    const i18n = useI18n()
    const userStore = useUserStore()

    const ordersTableColumns = ref([
        {
            label: 'id',
            key: 'order_uid',
            condition: userStore.can('see_id_columns'),
            copy: true,
        },
        {
            label: 'account',
            key: 'account_title',
            cellRenderer: (props: { rowData: ContractOrder }) => {
                const { warningsTexts } = getOrderWarningsTexts(props.rowData)
                if (warningsTexts.length > 0) {
                    return h('span', [
                        h(FontAwesomeIcon, {
                            icon: 'fa-solid fa-triangle-exclamation',
                            title: warningsTexts.join(', '),
                            class: 'text-danger-500 mr-2',
                        }),
                        props.rowData.account_title,
                    ])
                } else {
                    return props.rowData.account_title
                }
            },
            copy: true,
        },
        {
            label: 'created_on',
            key: 'created_on',
            sorting: true,
            cellRenderer: (props: { rowData: ContractOrder }) => {
                return moment(props.rowData.created_on).format('DD.MM.yyyy')
            },
        },
        {
            label: 'paid_at',
            key: 'paid_date',
            cellRenderer: (props: { rowData: ContractOrder }) => {
                return moment(props.rowData.paid_date).format('DD.MM.yyyy')
            },
        },
        {
            label: 'accepted_at',
            key: 'investment_acceptance_date',
            cellRenderer: (props: { rowData: ContractOrder }) => {
                return props.rowData.investment_acceptance_date
                    ? moment(props.rowData.investment_acceptance_date).format(
                          'DD.MM.yyyy'
                      )
                    : 'N/A'
            },
        },
        {
            label: 'status',
            key: 'status',
            cellRenderer: (props: { rowData: ContractOrder }) => {
                return i18n.t(OrderStatus[props.rowData.status])
            },
        },
        {
            label: 'total',
            key: 'total_amount',
            cellRenderer: (props: { rowData: ContractOrder }) => {
                return formatNumericValues(
                    props.rowData.total_amount as number,
                    currency.value as string
                )
            },
        },
    ])

    const ordersTableActions = ref<TableAction[]>([
        {
            action: 'expand',
            icon: 'fa-solid fa-money-bill',
            title: 'interest_payment_transactions',
            condition: (order: ContractOrder) => {
                if (!order.interest_payment_transactions) {
                    return false
                }
                return order.interest_payment_transactions.length > 0
            },
            disabled: (order: ContractOrder) => {
                return order.status !== OrderStatus['ACCEPTED']
            },
        },
        {
            action: handleViewOrder,
            icon: 'fa-eye',
            title: 'view',
        },
        {
            action: handleEditOrder,
            icon: 'fa-solid fa-pen',
            title: 'edit',
            condition: userStore.can('update_order'),
        },
    ])

    function getOrderWarningsTexts(contract: ContractOrder) {
        const warnings = contract.interest_payment_warnings
        const warningsTexts = []

        if (warnings?.failed_transactions_count) {
            warningsTexts.push(
                i18n.t('warning_order_failed_transactions_count', {
                    count: warnings.failed_transactions_count,
                })
            )
        }

        if (warnings?.missing_iban) {
            warningsTexts.push(i18n.t('warning_order_missing_iban'))
        }

        if (warnings?.missing_bic) {
            warningsTexts.push(i18n.t('warning_order_missing_bic'))
        }

        return {
            warningsTexts,
        }
    }

    function handleViewOrder(rowData: ContractOrder) {
        router.push({
            name: 'order',
            params: { id: rowData.order_uid },
        })
    }

    function handleEditOrder(rowData: ContractOrder) {
        router.push({
            name: 'order.edit',
            params: { id: rowData.order_uid },
        })
    }

    const ordersTableExpand = (props: { rowData: ContractOrder }) => {
        if (
            props.rowData.interest_payment_transactions &&
            props.rowData.interest_payment_transactions.length > 0
        ) {
            return h(
                'td',
                {
                    colspan: ordersTableColumns.value.length + 1,
                },
                h(
                    'div',
                    h(TableComponent, {
                        data: props.rowData.interest_payment_transactions,
                        total: props.rowData.interest_payment_transactions
                            .length,
                        hideHeaderActions: true,
                        columns: [
                            {
                                label: 'date',
                                key: 'date',
                                cellRenderer: (props: {
                                    rowData: InterestPaymentTransaction
                                }) => {
                                    return h(
                                        'span',
                                        moment(props.rowData.date).format(
                                            'DD.MM.yyyy'
                                        )
                                    )
                                },
                            },
                            {
                                label: 'loan',
                                key: 'current_loan_amount',
                                cellRenderer: (props: {
                                    rowData: InterestPaymentTransaction
                                }) => {
                                    return h(
                                        'span',
                                        `€${props.rowData.current_loan_amount}`
                                    )
                                },
                            },
                            {
                                label: 'paid_total_gross',
                                key: 'paid_total_gross',
                                cellRenderer: (props: {
                                    rowData: InterestPaymentTransaction
                                }) => {
                                    return h(
                                        'span',
                                        `€${props.rowData.paid_total_gross}`
                                    )
                                },
                            },
                            {
                                label: 'paid_total_net',
                                key: 'paid_total_net',
                                cellRenderer: (props: {
                                    rowData: InterestPaymentTransaction
                                }) => {
                                    return h(
                                        'span',
                                        `€${props.rowData.paid_total_net}`
                                    )
                                },
                            },
                            {
                                label: 'clearance',
                                key: 'paid_clearance',
                                cellRenderer: (props: {
                                    rowData: InterestPaymentTransaction
                                }) => {
                                    return h(
                                        'span',
                                        `€${props.rowData.paid_clearance}`
                                    )
                                },
                            },
                            {
                                label: 'interest',
                                key: 'paid_interest',
                                cellRenderer: (props: {
                                    rowData: InterestPaymentTransaction
                                }) => {
                                    return h(
                                        'span',
                                        `€${props.rowData.paid_interest}`
                                    )
                                },
                            },
                            {
                                label: 'rest',
                                key: 'paid_rest',
                                cellRenderer: (props: {
                                    rowData: InterestPaymentTransaction
                                }) => {
                                    return h(
                                        'span',
                                        `€${props.rowData.paid_rest}`
                                    )
                                },
                            },
                            {
                                label: 'bonus',
                                key: 'paid_bonus',
                                cellRenderer: (props: {
                                    rowData: InterestPaymentTransaction
                                }) => {
                                    return h(
                                        'span',
                                        `€${props.rowData.paid_bonus}`
                                    )
                                },
                            },
                            {
                                label: 'total_tax',
                                key: 'total_tax',
                                cellRenderer: (props: {
                                    rowData: InterestPaymentTransaction
                                }) => {
                                    return h(
                                        'span',
                                        `€${props.rowData.total_tax}`
                                    )
                                },
                            },
                            {
                                label: 'church_tax',
                                key: 'church_tax',
                                cellRenderer: (props: {
                                    rowData: InterestPaymentTransaction
                                }) => {
                                    return h(
                                        'span',
                                        `€${props.rowData.church_tax}`
                                    )
                                },
                            },
                            {
                                label: 'soli',
                                key: 'soli',
                                cellRenderer: (props: {
                                    rowData: InterestPaymentTransaction
                                }) => {
                                    return h('span', `€${props.rowData.soli}`)
                                },
                            },
                            {
                                label: 'tax',
                                key: 'tax',
                                cellRenderer: (props: {
                                    rowData: InterestPaymentTransaction
                                }) => {
                                    return h('span', `€${props.rowData.tax}`)
                                },
                            },
                        ],
                        class: '-mt-2 px-2',
                    })
                )
            )
        }
    }

    const { TableWrapper: ContractOrdersTable } = useTable({
        tableProps: {
            id: 'orders-table',
            label: 'orders',
            noEntriesInfo: 'no_orders',
            total: ordersCount,
            data: orders,
            sorting: ref<TableSorting[]>([
                {
                    field: 'created_on',
                    direction: 'desc',
                },
            ]),
            columns: ordersTableColumns.value,
            actions: ordersTableActions.value,
            expand: ordersTableExpand,
        },
    })

    return {
        ContractOrdersTable,
    }
}
