import { useSummary } from '@composables/common'
import { Platform } from '@src/types'
import { SummaryItem } from '@src/types/Summary'
import { Ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'

export function usePlatformSummary(platform: Ref<Platform>) {
    const i18n = useI18n()

    const items = computed<SummaryItem[]>(() => {
        return [
            {
                label: i18n.t('id'),
                value: platform.value.platform_uid,
                special: 'link',
                linkURL: `/platforms/${platform.value.platform_name}`,
                copy: true,
            },
            {
                label: i18n.t('platform'),
                value: platform.value.platform_name,
                special: 'link',
                linkURL: `/platforms/${platform.value.platform_name}`,
                copy: true,
            },
            {
                label: i18n.t('clearing_systems'),
                value: platform.value.clearing_systems
                    .map((clearing_system) => clearing_system.clearing_system)
                    .join(', '),
            },
        ]
    })

    const { Summary: PlatformSummary } = useSummary(items, {
        title: 'platform',
    })

    return {
        PlatformSummary,
    }
}
