export enum OrderAttachmentType {
    INVESTMENT_DOCUMENT = 'investment_document',
    OTHER = 'other',
}

export enum OrderStatus {
    CANCELED = -1,
    PENDING = 0,
    PAID = 1,
    ACCEPTED = 2,
}

export enum OpenBasketStep {
    STEP_1_INSTALMENT = 'step-1-instalment',
    STEP_1 = 'step-1',
    STEP_2 = 'step-2',
    STEP_3 = 'step-3',
    STEP_4 = 'step-4',
}
