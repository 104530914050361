import { useEnum, useMask, useSummary } from '@composables/common'
import { WalletStatus } from '@src/enums'
import { Wallet } from '@src/types'
import { formatNumericValues } from '@src/utils/helpers'
import { Ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'

export function useWalletSummary(wallet: Ref<Wallet>) {
    const i18n = useI18n()
    const { getEnumLabel } = useEnum()
    const { everyFourSpace } = useMask()

    const items = computed(() => {
        return [
            {
                label: i18n.t('id'),
                value: wallet.value.id,
                copy: true,
            },
            {
                label: i18n.t('external_wallet_id'),
                value: wallet.value.external_id,
                condition: !!wallet.value.external_id,
                copy: true,
            },
            {
                label: i18n.t('wallet_status'),
                value: wallet.value.status
                    ? getEnumLabel(WalletStatus, wallet.value.status)
                    : '-',
            },
            {
                label: i18n.t('wallet_balance'),
                value:
                    wallet.value.currency_symbol + ' ' + wallet.value.balance,
            },
            {
                label: i18n.t('wallet_retrievable_balance'),
                value:
                    wallet.value.currency_symbol +
                    ' ' +
                    wallet.value.retrievable_balance,
            },
            {
                label: i18n.t('wallet_blocked_balance_from_paid_orders'),
                value:
                    wallet.value.currency_symbol +
                    ' ' +
                    wallet.value?.blocked_balance_from_paid_orders,
                condition: !!wallet.value?.blocked_balance_from_paid_orders,
            },
            {
                label: i18n.t('blocked_balance_from_orders'),
                value: formatNumericValues(
                    wallet.value.blocked_balance_from_orders,
                    wallet.value?.currency as string
                ),
            },
            {
                label: i18n.t(
                    'wallet_blocked_balance_from_pending_withdrawals'
                ),
                value:
                    wallet.value.currency_symbol +
                    ' ' +
                    wallet.value?.blocked_balance_from_pending_withdrawals,
                condition:
                    !!wallet.value?.blocked_balance_from_pending_withdrawals,
            },
            {
                label: i18n.t('wallet_currency'),
                value: wallet.value?.currency,
            },
            {
                label: i18n.t('wallet_deposit_account_holder'),
                value: wallet.value?.deposit_account_holder,
                condition: !!wallet.value?.deposit_account_holder,
            },
            {
                label: i18n.t('wallet_deposit_iban'),
                value:
                    wallet.value?.deposit_iban &&
                    everyFourSpace(wallet.value?.deposit_iban),
                condition: !!wallet.value?.deposit_iban,
            },
            {
                label: i18n.t('wallet_deposit_bic'),
                value: wallet.value?.deposit_bic,
                condition: !!wallet.value?.deposit_bic,
            },
            {
                label: i18n.t('wallet_deposit_payment_reference'),
                value: wallet.value?.deposit_payment_reference,
                condition: !!wallet.value?.deposit_payment_reference,
            },
        ]
    })

    const { Summary: WalletSummary } = useSummary(items, {
        title: 'wallet',
    })

    return {
        WalletSummary,
    }
}
