<template>
    <div
        class="my-4 mb-2 grid grid-flow-col justify-start gap-2 sm:auto-cols-max sm:justify-end"
    ></div>
    <InterestPaymentTransactionsTable />
</template>

<script setup lang="ts">
import { useOrderInterestPaymentTransactionsTable } from '@composables/orders/useOrderInterestPaymentTransactionsTable'
import { InterestPaymentTransaction } from '@src/types'
import { getOrderInterestPaymentTransactions } from '@src/utils/api/orders'
import { onMounted, ref, toRefs } from 'vue'

const props = defineProps<{
    orderId: string | null
    hideNewCalculation?: boolean
}>()

const emit = defineEmits(['getCount', 'update'])
const { orderId } = toRefs(props)
const interestPaymentTransactions = ref<InterestPaymentTransaction[]>([])
const interestPaymentTransactionsCount = ref<number>(0)

const { InterestPaymentTransactionsTable } =
    useOrderInterestPaymentTransactionsTable(
        interestPaymentTransactions,
        interestPaymentTransactionsCount
    )

async function loadInterestPayments() {
    const interestPaymentTransactionsRequest =
        await getOrderInterestPaymentTransactions(orderId.value as string)
    interestPaymentTransactions.value =
        interestPaymentTransactionsRequest.data.interest_payment_transactions
    interestPaymentTransactionsCount.value =
        interestPaymentTransactionsRequest.data.interest_payment_transactions_count
    emit('getCount', interestPaymentTransactionsCount.value)
}

onMounted(async () => {
    await loadInterestPayments()
})
</script>

<style scoped></style>
