<template>
    <div>
        <SelectField
            v-model="modelValue"
            v-model:options="campaignOptions"
            name="campaign"
            label="campaign"
            help="help_bulk_change_order_campaign"
            :on-search="loadCampaignOptions"
            :required="true"
        />
    </div>
    <h3>{{ $t('orders') }}</h3>
    <ul class="list-disc pl-8">
        <li
            v-for="order in orders"
            :key="(order.order_uid as string)"
        >
            {{ order.order_uid }}
        </li>
    </ul>
</template>

<script setup lang="ts">
import { SelectField } from '@src/components'
import { Campaign, Order } from '@src/types'
import { getCampaigns } from '@src/utils/api/campaigns'
import { Ref, ref, toRefs } from 'vue'

const props = defineProps<{
    modelValue: Ref<string | null>
    orders: Ref<Order[]>
}>()
defineEmits(['update:modelValue', 'update:orders'])
const { orders, modelValue } = toRefs(props)

const campaignOptions = ref<
    { label: string | null; value: number | string | null }[]
>([])

async function loadCampaignOptions(search: string) {
    const response = await getCampaigns({
        'filter[asset_title]': search,
    })
    campaignOptions.value = response.data.asset_variations.map(
        (campaign: Campaign) => {
            return {
                label: campaign.asset_title as string,
                value: campaign.asset_var_uid as string,
            }
        }
    )
}
</script>
