import {
    ModalButtonStyles,
    OpenModal,
    useEnum,
    useTable,
} from '@composables/common'
import UpdateKYCDocumentForm from '@partials/accounts/UpdateKYCDocumentForm.vue'
import { KYCDocumentType, KycDocumentStatus } from '@src/enums'
import { BankKYCDocument } from '@src/types'
import { updateKYCDocument } from '@src/utils/api/accounts'
import { useToastStore } from '@src/utils/stores/toast'
import { useUserStore } from '@src/utils/stores/user'
import moment from 'moment'
import { Ref, computed, h, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { getEnumLabel } = useEnum()

export function useKYCBankDocumentsTable(
    kycDocuments: Ref<BankKYCDocument[]>,
    openModal: OpenModal,
    isModalOpen: Ref<boolean>,
    assignAndFilterKYCDocuments: (
        accountID?: string | undefined
    ) => Promise<void>,
    label: string,
    help?: string
) {
    const userStore = useUserStore()
    const i18n = useI18n()
    const toast = useToastStore()
    const total = computed(() => {
        return kycDocuments.value.length
    })
    const { TableWrapper: KYCBankTable } = useTable({
        tableProps: {
            data: kycDocuments,
            total: total,
            label: label,
            id: label,
            help: help,
            columns: [
                {
                    label: 'document_type',
                    key: 'type',
                    sorting: false,
                    cellRenderer: (props: { rowData: BankKYCDocument }) => {
                        return getEnumLabel(KYCDocumentType, props.rowData.type)
                    },
                },
                {
                    label: 'valid_to',
                    key: 'valid_to',
                    sorting: false,
                    cellRenderer: (props: { rowData: BankKYCDocument }) => {
                        return props.rowData.valid_to
                            ? moment(props.rowData.valid_to).format(
                                  'DD.MM.yyyy'
                              )
                            : '-'
                    },
                },
                {
                    label: 'kyc_verification_status',
                    key: 'verification_status',
                    sorting: false,
                    cellRenderer: (props: { rowData: BankKYCDocument }) => {
                        return getEnumLabel(
                            KycDocumentStatus,
                            props.rowData.verification_status
                        )
                    },
                },
                {
                    label: 'verification_date',
                    key: 'verification_date',
                    sorting: false,
                    cellRenderer: (props: { rowData: BankKYCDocument }) => {
                        return props.rowData.verification_date
                            ? moment(props.rowData.verification_date).format(
                                  'DD.MM.yyyy'
                              )
                            : '-'
                    },
                },
                {
                    label: 'external_kyc',
                    key: 'is_external',
                    sorting: false,
                    cellRenderer: (props: { rowData: BankKYCDocument }) => {
                        return props.rowData.is_external
                            ? i18n.t('yes')
                            : i18n.t('no')
                    },
                },
                {
                    label: 'manual_kyc',
                    key: 'is_manual',
                    sorting: false,
                    cellRenderer: (props: { rowData: BankKYCDocument }) => {
                        return props.rowData.is_manual
                            ? i18n.t('yes')
                            : i18n.t('no')
                    },
                },
            ],
            actions: [
                {
                    action: handleViewDocument,
                    icon: 'fa-eye',
                    title: 'help_open_file_in_new_tab',
                },
                {
                    action: handleUpdateKYCDocument,
                    icon: 'fa-solid fa-pen',
                    title: 'edit',
                    condition: (kycDocument: BankKYCDocument) => {
                        return (
                            userStore.can('update_account') &&
                            !kycDocument.is_external
                        )
                    },
                },
            ],
        },
    })

    function handleViewDocument(rowData: BankKYCDocument) {
        const url = rowData.url
        isModalOpen.value = true
        openModal(
            {
                id: 'view_kyc_bank_document',
                title: 'modal_title_view_kyc_bank_document',
                open: isModalOpen,
                onCancel: () => (isModalOpen.value = false),
            },
            () =>
                h('iframe', {
                    src: `${url}#zoom=100`,
                    class: 'full-size-iframe',
                })
        )
    }

    function handleUpdateKYCDocument(
        kycDocument: BankKYCDocument = new BankKYCDocument()
    ) {
        const editedKYCDocument = ref(new BankKYCDocument())
        editedKYCDocument.value = { ...kycDocument }
        isModalOpen.value = true

        const disableCreate = computed(() => {
            const dayAfterTomorrow = new Date()
            dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 1)

            const isValidToInFuture = editedKYCDocument.value.valid_to
                ? new Date(editedKYCDocument.value.valid_to) > dayAfterTomorrow
                : false
            const isVerificationStatusSet =
                !!editedKYCDocument.value.verification_status
            return !(isValidToInFuture && isVerificationStatusSet)
        })

        openModal(
            {
                id: 'edit_kyc_document',
                title: editedKYCDocument.value.id
                    ? 'modal_title_update_kyc_document'
                    : 'modal_title_create_kyc_document',
                open: isModalOpen,
                onCancel: () => (isModalOpen.value = false),
                onSubmit: () => saveKYCDocument(editedKYCDocument.value),
            },
            () =>
                h(UpdateKYCDocumentForm, {
                    modelValue: editedKYCDocument,
                }),
            {
                submitButtonText: 'update',
                styles: {
                    submitButton: ModalButtonStyles['BLUE'],
                },
                submitButtonDisabled: disableCreate,
            }
        )
    }

    async function saveKYCDocument(document: BankKYCDocument) {
        try {
            await updateKYCDocument(document)
            await assignAndFilterKYCDocuments()
            toast.success(i18n.t('toast_success_kyc_document_updated'))
        } catch (error) {
            toast.error(i18n.t('toast_error_kyc_document_updated'))
            throw error
        }

        isModalOpen.value = false
    }

    return {
        KYCBankTable,
    }
}
