export enum PlatformPolicyType {
    TERMS_OF_SERVICE = 'terms_of_service',
    COOKIE_POLICY = 'cookie_policy',
}

export enum PlatformWebhookAction {
    NATURAL_PROFILE_CREATED = 'natural-profile.created',
    NATURAL_PROFILE_UPDATED = 'natural-profile.updated',
    NATURAL_PROFILE_DELETED = 'natural-profile.deleted',
    NATURAL_PROFILE_FORCE_DELETED = 'natural-profile.force-deleted',

    ORDER_CREATED = 'order.created',
    ORDER_UPDATED = 'order.updated',
    ORDER_DELETED = 'order.deleted',
    ORDER_FORCE_DELETED = 'order.force-deleted',

    BASKET_CREATED = 'basket.created',
    BASKET_UPDATED = 'basket.updated',
    BASKET_DELETED = 'basket.deleted',
    BASKET_FORCE_DELETED = 'basket.force-deleted',
}

export enum KycProvider {
    SIGNTEQ = 'signteq',
    IDNOW = 'idnow',
}

export enum PlatformMembershipType {
    MANUAL = 'manual',
    SPROOF = 'sproof',
}

export const PlatformEmailTemplates: Array<string> = [
    'mail_active_welcome',
    'mail_active_acceptance_notice',
    'mail_active_bank_account_information_reminder',
    'mail_active_basket_reminder',
    'mail_active_consumer_information',
    'mail_active_external_kyc_status_pending',
    'mail_active_external_kyc_status_success',
    'mail_active_external_kyc_process_aborted',
    'mail_active_kyc_expire_reminder',
    'mail_active_interest_payment_announcement',
    'mail_active_interest_payment_payout_received',
    'mail_active_kyc_reminder',
    'mail_active_kyc_status',
    'mail_active_no_iban_reminder',
    'mail_active_payment_confirmation',
    'mail_active_payment_details',
    'mail_active_payment_reminder',
    'mail_active_pre_consumer_information',
    'mail_active_signature_reminder',
    'mail_active_signature_successful',
    'mail_active_signatures_regenerated',
    'mail_active_voucher_received',
    'mail_active_feedback_review',
    'mail_active_wallet_balance',
    'mail_active_voucher_balance',
    'mail_active_membership_document_reminder',
]

export enum PlatformEmailTemplateType {
    CONSUMER_INFORMATION = 'consumer_information',
    PAYMENT_DETAILS = 'payment_details',
    SAVINGS_PLAN_PAYMENT_REMINDER = 'savings_plan_payment_reminder',
    PAYMENT_REMINDER = 'payment_reminder',
    WELCOME = 'welcome',
    MEMBERSHIP_DOCUMENT_ACCEPTED = 'membership_document_accepted',
    PAYMENT_CONFIRMATION = 'payment_confirmation',
    ACCEPTANCE_NOTICE = 'acceptance_notice',
    KYC_REMINDER = 'kyc_reminder',
    KYC_STATUS = 'kyc_status',
    KYC_EXPIRE_REMINDER = 'kyc_expire_reminder',
    BASKET_REMINDER_ONE = 'basket_reminder_one',
    BASKET_REMINDER_TWO = 'basket_reminder_two',
    SIGNATURE_REMINDER = 'signature_reminder',
    SIGNATURES_REGENERATED = 'signatures_regenerated',
    SIGNATURE_SUCCESSFUL = 'signature_successful',
    BANK_ACCOUNT_INFORMATION_REMINDER = 'bank_account_information_reminder',
    PRE_CONSUMER_INFORMATION = 'pre_consumer_information',
    EXTERNAL_KYC_STATUS_PENDING = 'external_kyc_status_pending',
    EXTERNAL_KYC_STATUS_SUCCESS = 'external_kyc_status_success',
    NO_IBAN_REMINDER = 'no_iban_reminder',
    MONEY_WITHDRAWAL_VERIFICATION = 'money_withdrawal_verification',
    VOUCHER_RECEIVED = 'voucher_received',
    FEEDBACK_REVIEW = 'feedback_review',
}

export enum PlatformComplianceProfile {
    ESAC = 'esac',
    ECSP = 'ecsp',
}

export enum FundingTotalStatus {
    PENDING = 0,
    PAID = 1,
    ACCEPTED = 2,
}
