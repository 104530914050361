<template>
    <div>
        <div v-if="modelValue.length === 1">
            <span class="font-bold">{{ modelValue[0][nameKey] }}</span> ({{
                modelValue[0][idKey]
            }})
        </div>
        <ul
            v-if="modelValue.length > 1"
            class="list-disc pl-8"
        >
            <li
                v-for="item in modelValue"
                :key="item[idKey] as string"
                class="py-2"
            >
                <span class="font-bold">{{ item[nameKey] }}</span> ({{
                    item[idKey]
                }})
            </li>
        </ul>
    </div>
</template>

<script setup lang="ts">
import { useVModels } from '@vueuse/core'

const props = withDefaults(
    defineProps<{
        idKey?: string
        nameKey?: string
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        modelValue: Record<string, any>[]
    }>(),
    {
        idKey: 'id',
        nameKey: 'name',
    }
)

const { modelValue } = useVModels(props)
</script>

<style scoped></style>
