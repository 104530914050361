import { useEnum, useTable } from '@composables/common'
import { SignatureDocumentType, SignatureType } from '@src/enums'
import { EmissionSignature } from '@src/types'
import moment from 'moment'
import { Ref, computed } from 'vue'

const { getEnumLabel } = useEnum()

export function useSignaturesTable(signatures: Ref<EmissionSignature[]>) {
    const total = computed(() => {
        return signatures.value.length
    })

    const { TableWrapper: SignaturesTable } = useTable({
        tableProps: {
            id: 'signatures-table',
            label: 'signatures',
            help: 'help_signatures_table',
            total: total,
            data: signatures,
            columns: [
                {
                    label: 'document_type',
                    key: 'document_type',
                    sorting: false,
                    cellRenderer: (props: { rowData: EmissionSignature }) => {
                        return getEnumLabel(
                            SignatureDocumentType,
                            props.rowData.document_type
                        )
                    },
                },
                {
                    label: 'signature_type',
                    key: 'type',
                    sorting: false,
                    cellRenderer: (props: { rowData: EmissionSignature }) => {
                        return getEnumLabel(SignatureType, props.rowData.type)
                    },
                },
                {
                    label: 'signature_date',
                    key: 'signature_date',
                    sorting: false,
                    cellRenderer: (props: { rowData: EmissionSignature }) => {
                        if (props.rowData.signed_at) {
                            return moment(props.rowData.signed_at).format(
                                'DD.MM.yyyy HH:mm'
                            )
                        }
                    },
                },
                {
                    label: 'signature_cancellation_date',
                    key: 'signature_cancellation_date',
                    sorting: false,
                    cellRenderer: (props: { rowData: EmissionSignature }) => {
                        if (props.rowData.cancelled_at) {
                            return moment(props.rowData.cancelled_at).format(
                                'DD.MM.yyyy HH:mm'
                            )
                        }
                        return '-'
                    },
                },
            ],
            actions: [
                {
                    action: handleShowSignature,
                    icon: 'fa-eye',
                    title: 'view_document',
                    condition: (signature: EmissionSignature) => {
                        return !!(
                            signature.link || signature.unsigned_document_link
                        )
                    },
                },
            ],
        },
    })

    function handleShowSignature(signature: EmissionSignature) {
        let link = signature.unsigned_document_link || signature.link
        if (signature.signed_at && signature.link) {
            link = signature.link
        }
        window.open(link)
    }

    return {
        SignaturesTable,
    }
}
