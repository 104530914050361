<template>
    <PageWrapper context="dashboard">
        <template #headline>
            <fa icon="fa-house" />&nbsp;{{ $t('dashboard') }}
        </template>
        <p
            v-if="platformsWithAccess"
            class="py-2 text-base"
        >
            You have access to the following platforms:
            {{ platformsWithAccess }}
        </p>
        <Banner type="info">
            <div class="mb-1 font-medium text-gray-800">Welcome!</div>
            <p>
                This is the place where we will build a useful dashboard for
                your platforms, assets and campaigns.
            </p>
            <p>
                Please note that this is a work in progress version currently.
            </p>
        </Banner>
    </PageWrapper>
</template>
<script setup lang="ts">
import { Banner } from '@src/components'
import PageWrapper from '@src/partials/pages/PageWrapper.vue'
import { PlatformsResponse, getPlatforms } from '@src/utils/api/platforms'
import { computed, onMounted, ref } from 'vue'

const platforms = ref<PlatformsResponse>()

onMounted(async () => {
    const platformRequest = await getPlatforms()
    platforms.value = platformRequest.data
})

const platformsWithAccess = computed(() => {
    if (!platforms.value) return
    return platforms.value.platforms
        .map((platform) => platform.platform_name)
        .join(', ')
})
</script>
