<template>
    <div>
        <p>{{ $t('modal_text_end_contract') }} {{ assetTitle }}</p>
        <SelectField
            v-model="modelValue"
            name="contract_end_cause"
            label="contract_end_cause"
            help="help_contract_end_cause"
            :options="mapEnumToArray(ContractEndCause, false)"
            :reduce="reduceEnumValue"
            :required="true"
        />
    </div>
</template>

<script setup lang="ts">
import { SelectField } from '@src/components'
import { useEnum } from '@src/composables/common'
import { ContractEndCause } from '@src/enums'
import { Ref, toRefs, watch } from 'vue'

const props = defineProps<{
    modelValue: Ref<ContractEndCause | null>
    assetTitle: string
}>()

const { modelValue, assetTitle } = toRefs(props)

const emit = defineEmits(['update:modelValue'])

const { mapEnumToArray, reduceEnumValue } = useEnum()

watch(modelValue, (value) => {
    emit('update:modelValue', value)
})
</script>

<style scoped></style>
