<template>
    <div>
        <div>
            <label
                class="mb-1 block text-sm font-medium"
                for="name"
                >{{ $t('file') }} <span class="text-danger-500">*</span></label
            >
        </div>
        <div class="mb-2 flex flex-row gap-2">
            <button
                class="btn bg-primary-additional-500 hover:bg-primary-additional-600 text-white"
                @click.prevent="openFileDialog"
            >
                {{ $t('choose_file') }}
            </button>
            <button
                class="btn border-gray-200 text-gray-600 hover:border-gray-300"
                @click="resetForm"
            >
                {{ $t('reset') }}
            </button>
        </div>
        <div>
            <input
                v-if="fileName"
                v-model="fileName"
                class="form-input w-full bg-gray-200"
                type="text"
                disabled
            />
        </div>
    </div>
    <div>
        <TextField
            v-model="modelValue.name"
            name="name"
            label="name"
            help="help_file_name"
            :required="true"
        />
    </div>
    <div>
        <SelectField
            v-model="modelValue.type"
            name="type"
            label="type"
            help="help_file_type"
            :options="[
                {
                    label: $t('terms'),
                    value: 'terms',
                },
                {
                    label: $t('information_letter'),
                    value: 'information_letter',
                },
            ]"
            :required="true"
        />
    </div>
</template>
<script setup lang="ts">
import { SelectField, TextField } from '@src/components'
import { PlatformAttachment } from '@src/types'
import { useFileDialog } from '@vueuse/core'
import { Ref, ref, toRefs, watch } from 'vue'

const props = defineProps<{
    modelValue: Ref<PlatformAttachment>
}>()

const { modelValue } = toRefs(props)
const emit = defineEmits(['update:modelValue'])

const {
    files,
    open: openFileDialogReal,
    reset,
} = useFileDialog({ multiple: false, accept: '.pdf' })
const fileName = ref<string | null>(null)

function openFileDialog(): void {
    openFileDialogReal()
}

function resetForm(): void {
    reset()
    modelValue.value = new PlatformAttachment()
}

watch(files, (files) => {
    fileName.value = files?.item(0)?.name ?? null
    modelValue.value.name = files?.item(0)?.name ?? null
})

watch(modelValue.value, (value) => {
    emit('update:modelValue', value, files)
})
</script>
