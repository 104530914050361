<template>
    <Modal
        id="create-account-modal"
        ref="create-account-modal"
        v-model:open="open"
        title="modal_title_create_nautral_account"
        width="xl"
        @submit="submit"
        @cancel="cancel"
    >
        <TextField
            v-model.trim="modelValue.first_name"
            label="account_first_name"
            name="first_name"
            :required="true"
            :disabled="disabledFields?.includes('first_name')"
            help="help_account_first_name"
        />

        <TextField
            v-model.trim="modelValue.last_name"
            label="account_last_name"
            name="last_name"
            :required="true"
            :disabled="disabledFields?.includes('last_name')"
            help="help_account_last_name"
        />

        <TextField
            v-model.trim="modelValue.email"
            label="account_email"
            type="email"
            name="email"
            :required="true"
            :disabled="disabledFields?.includes('email')"
            help="help_account_email"
        />
        <SelectField
            v-model="modelValue.platform_name"
            v-model:options="platformOptions"
            name="platform_name"
            label="platform_name"
            help="help_platform_name"
            :required="true"
            :disabled="disabledFields?.includes('platform_name')"
            :on-search="loadPlatformsSelectOptions"
        />

        <template #actions>
            <MultiActionButton />
        </template>
    </Modal>
</template>
<script setup lang="ts">
import { useMultiActionButton } from '@composables/common'
import { Modal, SelectField, TextField } from '@src/components'
import { NaturalProfile, Platform } from '@src/types'
import { getPlatforms } from '@src/utils/api/platforms'
import { useVModels } from '@vueuse/core'
import { computed, onMounted, ref } from 'vue'

const props = defineProps<{
    open: boolean
    modelValue: NaturalProfile
    platforms: Platform[]
    disabledFields?: string[] | null
}>()

const emit = defineEmits([
    'update:modelValue',
    'update:open',
    'cancel',
    'submit',
])

const { modelValue, open } = useVModels(props, emit)
const platformOptions = ref<string[]>([])

const { MultiActionButton } = useMultiActionButton({
    id: 'create-asset-button',
    actions: [
        {
            label: 'create',
            action: submit,
            disabled: () => disableCreate.value,
        },
        {
            label: 'create_and_view',
            action: submitAndView,
            disabled: () => disableCreate.value,
        },
        {
            label: 'create_and_edit',
            action: submitAndEdit,
            disabled: () => disableCreate.value,
        },
    ],
})

async function loadPlatformsSelectOptions(search: string) {
    const initialPlatformsRequest = await getPlatforms({
        'filter[platform_name]': search,
    })
    platformOptions.value = initialPlatformsRequest.data.platforms.map(
        (platform: Platform) => {
            return platform.platform_name as string
        }
    )
}

function cancel() {
    emit('cancel')
}

function submit() {
    emit('submit')
}

function submitAndView() {
    emit('submit', 'view')
}

function submitAndEdit() {
    emit('submit', 'edit')
}

const disableCreate = computed(() => {
    return (
        !modelValue.value.first_name ||
        !modelValue.value.last_name ||
        !modelValue.value.email ||
        !modelValue.value.platform_name
    )
})

onMounted(async () => {
    if (modelValue.value.platform_name) {
        await loadPlatformsSelectOptions(modelValue.value.platform_name)
    }
})
</script>
