<template>
    <div :class="selected.length < 1 && 'hidden'">
        <div class="flex items-center">
            <div class="mr-2 hidden whitespace-nowrap text-sm italic xl:block">
                <span>{{ selected.length }}</span> {{ $t('items_selected') }}
            </div>
            <button
                class="btn text-danger-500 hover:text-danger-600 border-gray-200 bg-white hover:border-gray-300"
            >
                {{ $t('change_order_status') }}
            </button>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useVModels } from '@vueuse/core'

const props = defineProps<{
    selected: string[]
}>()

const emit = defineEmits(['update:selected'])

const { selected } = useVModels(props, emit)
</script>
