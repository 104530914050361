<template>
    <SelectField
        v-model="modelValue.template_name"
        name="type"
        label="type"
        help="help_platform_email_template_type"
        :options="emailTemplateSelectOptions"
        :required="true"
    />

    <SelectField
        v-model="modelValue.language"
        name="language"
        label="language"
        help="help_platform_email_template_language"
        :options="languageSelectOptions"
        :required="true"
    />

    <TextField
        v-model="modelValue.subject"
        name="subject"
        label="subject"
        help="help_platform_email_template_subject"
        :required="true"
    />

    <EditorField
        v-if="modelValue.template_name"
        v-model="modelValue.template_content"
        v-model:placeholders="placeholders"
        name="email_template_content"
        :required="true"
        label="content"
        help="help_platform_email_template_content"
    />
</template>
<script setup lang="ts">
import { EditorField, SelectField, TextField } from '@src/components'
import {
    Platform,
    PlatformEmailTemplate,
    PlatformEmailTemplateOption,
} from '@src/types'
import { getEmailTemplatesList } from '@src/utils/api/platforms'
import { useLanguageStore } from '@src/utils/stores/languages'
import { Ref, computed, onMounted, ref, toRefs, watch } from 'vue'
import { useI18n } from 'vue-i18n'

const i18n = useI18n()

const props = defineProps<{
    modelValue: Ref<PlatformEmailTemplate>
    platform: Ref<Platform>
}>()

const { modelValue, platform } = toRefs(props)
const emit = defineEmits(['update:modelValue', 'update:platform'])

const languageStore = useLanguageStore()
const languages = ref<{ label: string; value: string | null }[]>([])
const emailTemplates = ref<PlatformEmailTemplateOption[]>([])

const availableEmailTemplates = computed(() => {
    return emailTemplates.value
        .filter(
            (template) =>
                (template.for_realm && platform.value.is_realm) ||
                (template.for_platform && platform.value.is_platform)
        )
        .map((template) => {
            return template.value
        })
})

const emailTemplateSelectOptions = computed(() => {
    return availableEmailTemplates.value.map((templateName: string) => {
        return {
            label: i18n.t('EMAIL_TEMPLATE_' + templateName.toUpperCase()),
            value: templateName,
        }
    })
})

const languageSelectOptions = computed(() => {
    const langs = languages.value.filter((lang) => {
        if (!modelValue.value.template_name) return false
        return !platform.value.email_templates.some((template) => {
            return (
                template.template_name === modelValue.value.template_name &&
                template.language === lang.value
            )
        })
    })
    if (!langs.length)
        return [
            {
                label: i18n.t('no_languages_available_for_this_email_template'),
                value: false,
            },
        ]
    return langs
})

const placeholders = computed(() => {
    if (!modelValue.value.template_name) return []

    if (
        [
            'basket_reminder_one',
            'basket_reminder_two',
            'pre_consumer_information',
        ].includes(modelValue.value.template_name)
    ) {
        return [
            {
                key: 'first_name',
                value: '{{first_name}}',
            },
            {
                key: 'last_name',
                value: '{{last_name}}',
            },
            {
                key: 'campaign_title',
                value: '{{campaign_title}}',
            },
            {
                key: 'asset_title',
                value: '{{asset_title}}',
            },
            {
                key: 'company_name',
                value: '{{company_name}}',
            },
            {
                key: 'full_name_or_company_name',
                value: '{{full_name_or_company_name}}',
            },
        ]
    }

    if (
        [
            'external_kyc_status_pending',
            'external_kyc_status_success',
            'kyc_status',
        ].includes(modelValue.value.template_name)
    ) {
        return [
            {
                key: 'status',
                value: '{{status}}',
            },
            {
                key: 'first_name',
                value: '{{first_name}}',
            },
            {
                key: 'last_name',
                value: '{{last_name}}',
            },
            {
                key: 'email',
                value: '{{email}}',
            },
        ]
    }

    if (
        ['money_withdrawal_verification'].includes(
            modelValue.value.template_name
        )
    ) {
        return [
            {
                key: 'first_name',
                value: '{{first_name}}',
            },
            {
                key: 'last_name',
                value: '{{last_name}}',
            },
            {
                key: 'email',
                value: '{{email}}',
            },
            {
                key: 'amount_in_cent',
                value: '{{amount_in_cent}}',
            },
            {
                key: 'amount',
                value: '{{amount}}',
            },
            {
                key: 'is_company',
                value: '{{is_company}}',
            },
            {
                key: 'verification_link',
                value: '{{verification_link}}',
            },
        ]
    }

    if (['voucher_received'].includes(modelValue.value.template_name)) {
        return [
            {
                key: 'first_name',
                value: '{{first_name}}',
            },
            {
                key: 'last_name',
                value: '{{last_name}}',
            },
            {
                key: 'email',
                value: '{{email}}',
            },
            {
                key: 'voucher_amount',
                value: '{{voucher_amount}}',
            },
            {
                key: 'voucher_balance',
                value: '{{voucher_balance}}',
            },
        ]
    }

    if (['welcome'].includes(modelValue.value.template_name)) {
        return [
            {
                key: 'first_name',
                value: '{{first_name}}',
            },
            {
                key: 'last_name',
                value: '{{last_name}}',
            },
            {
                key: 'email',
                value: '{{email}}',
            },
        ]
    }

    if (
        ['check_requested_membership_documents'].includes(
            modelValue.value.template_name
        )
    ) {
        return [
            {
                key: 'email',
                value: '{{email}}',
            },
            {
                key: 'download_url',
                value: '{{download_url}}',
            },
        ]
    }

    if (
        ['membership_document_accepted'].includes(
            modelValue.value.template_name
        )
    ) {
        return [
            {
                key: 'investor',
                value: '{{investor}}',
            },
        ]
    }

    if (
        ['membership_document_reminder'].includes(
            modelValue.value.template_name
        )
    ) {
        return [
            {
                key: 'investor',
                value: '{{investor_name}}',
            },
            {
                key: 'days',
                value: '{{days}}',
            },
        ]
    }

    if (
        ['savings_plan_payment_reminder'].includes(
            modelValue.value.template_name
        )
    ) {
        return [
            {
                key: 'balance',
                value: '{{balance}}',
            },
            {
                key: 'investment_amount',
                value: '{{investment_amount}}',
            },
            {
                key: 'difference_missing',
                value: '{{difference_missing}}',
            },
        ]
    }

    if (
        [
            'acceptance_notice',
            'bank_account_information_reminder',
            'consumer_information',
            'kyc_reminder',
            'no_iban_reminder',
            'payment_confirmation',
            'payment_details',
            'payment_reminder',
            'signature_reminder',
            'signatures_regenerated',
            'signature_successful',
        ].includes(modelValue.value.template_name)
    ) {
        return [
            {
                key: 'investment',
                value: '{{investment}}',
            },
            {
                key: 'first_name',
                value: '{{first_name}}',
            },
            {
                key: 'last_name',
                value: '{{last_name}}',
            },
            {
                key: 'email',
                value: '{{email}}',
            },
            {
                key: 'investor_name',
                value: '{{investor_name}}',
            },
            {
                key: 'company_name',
                value: '{{company_name}}',
            },
            {
                key: 'investment_iban',
                value: '{{investment_iban}}',
            },
            {
                key: 'investment_bic',
                value: '{{investment_bic}}',
            },
            {
                key: 'order_id',
                value: '{{order_id}}',
            },
            {
                key: 'reference_number',
                value: '{{reference_number}}',
            },
            {
                key: 'investment_amount',
                value: '{{investment_amount}}',
            },
            {
                key: 'voucher_amount',
                value: '{{voucher_amount}}',
            },
            {
                key: 'wallet_amount',
                value: '{{wallet_amount}}',
            },
            {
                key: 'total_amount_to_pay',
                value: '{{total_amount_to_pay}}',
            },
            {
                key: 'contract_type',
                value: '{{contract_type}}',
            },
            {
                key: 'interest_rate',
                value: '{{interest_rate}}',
            },
            {
                key: 'full_name_or_company_name',
                value: '{{full_name_or_company_name}}',
            },
            {
                key: 'initial_payment',
                value: '{{initial_payment}}',
            },
            {
                key: 'installment_rates',
                value: '{{installment_rates}}',
            },
            {
                key: 'installment_value',
                value: '{{installment_value}}',
            },
            {
                key: 'installment_type',
                value: '{{installment_type}}',
            },
            {
                key: 'voucher',
                value: '{{voucher}}',
            },
            {
                key: 'agio',
                value: '{{agio}}',
            },
            {
                key: 'signature_sproof_link',
                value: '{{signature_sproof_link}}',
            },
            {
                key: 'banking_app_link',
                value: '{{banking_app_link}}',
            },
            {
                key: 'epc_qr_code',
                value: '{{{epc_qr_code}}}',
            },
            {
                key: 'total_amount',
                value: '{{total_amount}}',
            },
            {
                key: 'pre_contract_reflection_date',
                value: '{{pre_contract_reflection_date}}',
            },
        ]
    }

    if ('interest_payment_payout_received' === modelValue.value.template_name) {
        return [
            {
                key: 'email',
                value: '{{email}}',
            },
            {
                key: 'full_name_or_company_name',
                value: '{{full_name_or_company_name}}',
            },
            {
                key: 'paid_total',
                value: '{{paid_total}}',
            },
            {
                key: 'paid_interest',
                value: '{{paid_interest}}',
            },
            {
                key: 'paid_clearance',
                value: '{{paid_clearance}}',
            },
            {
                key: 'paid_rest',
                value: '{{paid_rest}}',
            },
            {
                key: 'paid_rest_clearance',
                value: '{{paid_rest_clearance}}',
            },
            {
                key: 'paid_bonus',
                value: '{{paid_bonus}}',
            },
            {
                key: 'interest_rate',
                value: '{{interest_rate}}',
            },
            {
                key: 'interest_bonus',
                value: '{{interest_bonus}}',
            },
            {
                key: 'paid_interest_total',
                value: '{{paid_interest_total}}',
            },
            {
                key: 'paid_bonus_total',
                value: '{{paid_bonus_total}}',
            },
            {
                key: 'previous_interest_date',
                value: '{{previous_interest_date}}',
            },
            {
                key: 'current_interest_date',
                value: '{{current_interest_date}}',
            },
            {
                key: 'paid_clearance_total',
                value: '{{paid_clearance_total}}',
            },
        ]
    }

    if ('nyala_registry_file' === modelValue.value.template_name) {
        return [
            {
                key: 'nyala_registry_file_name',
                value: '{{nyala_registry_file_name}}',
            },
            {
                key: 'asset_title',
                value: '{{asset_title}}',
            },
        ]
    }

    if ('newsletter_consent' === modelValue.value.template_name) {
        return [
            {
                key: 'first_name',
                value: '{{first_name}}',
            },
            {
                key: 'last_name',
                value: '{{last_name}}',
            },
            {
                key: 'email',
                value: '{{email}}',
            },
            {
                key: 'verification_link',
                value: '{{verification_link}}',
            },
        ]
    }

    if ('kyc_accepted' === modelValue.value.template_name) {
        return [
            {
                key: 'first_name',
                value: '{{first_name}}',
            },
            {
                key: 'last_name',
                value: '{{last_name}}',
            },
            {
                key: 'email',
                value: '{{email}}',
            },
        ]
    }

    return []
})

watch(modelValue.value, (value) => {
    emit('update:modelValue', value)
})

onMounted(async () => {
    languages.value = languageStore.languages.map((lang) => {
        return {
            label: i18n.t(lang.value) || lang.value,
            value: lang.value,
        }
    })
    const emailTemplatesResponse = await getEmailTemplatesList()
    emailTemplates.value = emailTemplatesResponse.data.email_templates
})
</script>
