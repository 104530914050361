export function useMask() {
    const ibanMask = '**## **** #### #### #### #### #### ####'

    const everyFourSpace = (text: string) => {
        if (!text) {
            return
        }
        return text
            .replace(/[^\dA-Z]/gi, '')
            .replace(/(.{4})/g, '$1 ')
            .trim()
    }

    return {
        ibanMask,
        everyFourSpace,
    }
}
