import { useTable } from '@composables/common'
import router from '@src/router'
import { NaturalProfile } from '@src/types'
import { Ref } from 'vue'
import { useI18n } from 'vue-i18n'

export function useNaturalProfilesTable(
    profilesNatural: Ref<NaturalProfile[]>,
    profilesNaturalNum: Ref<number>
) {
    const i18n = useI18n()
    const handleViewNaturalProfile = (rowData: NaturalProfile) => {
        router.push({
            name: 'naturalProfile',
            params: { id: rowData.natural_profile_uid },
        })
    }

    const { TableWrapper: NaturalProfilesTable } = useTable({
        tableProps: {
            id: 'natural-profiles-table',
            label: 'natural_profiles',
            help: 'help_natural_profiles',
            total: profilesNaturalNum,
            data: profilesNatural,
            columns: [
                {
                    label: 'first_name',
                    key: 'first_name',
                    sorting: false,
                    copy: true,
                },
                {
                    label: 'last_name',
                    key: 'last_name',
                    sorting: false,
                    copy: true,
                },
                {
                    label: 'account_owner',
                    key: 'owner',
                    sorting: false,
                    cellRenderer: (props: { rowData: NaturalProfile }) => {
                        return props.rowData.owner
                            ? i18n.t('yes')
                            : i18n.t('no')
                    },
                    copy: true,
                },
            ],
            actions: [
                {
                    action: handleViewNaturalProfile,
                    icon: 'fa-eye',
                    title: 'help_view_natural_profile',
                },
            ],
        },
    })

    return {
        NaturalProfilesTable,
    }
}
