export enum ContractPeriodType {
    FIXED = 'fixed',
    CONTINUOUS = 'continuous',
}

export enum ContractPeriodStart {
    CURRENT = 'current',
    NEXT = 'next',
}

export enum InterestRateCalculationMethod {
    IR_ACT_360 = 'IR_ACT_360',
    IR_ACT_365 = 'IR_ACT_365',
    IR_30_360 = 'IR_30_360',
}

export enum CalculationStartDate {
    ACCEPTANCE_DATE = 'acceptance_date',
    PAID_DATE = 'paid_date',
}

export enum PayoutTaskFinishedType {
    SUCCESS = 'success',
    WARNING = 'warning',
    HAS_ERRORS = 'has-errors',
    ERROR = 'error',
}

export enum ContractType {
    CASH = 'cash',
    VOUCHER = 'voucher',
}

export enum ContractEndCause {
    EXIT = 'EXIT',
    INSOLVENCY = 'INSOLVENCY',
    OTHER = 'OTHER',
}

export enum KistaRequestType {
    KISTAMA = 'KISTAMA', // Anfragetyp 2: KiStAM
    KISTAKOM = 'KISTAKOM', // Anfragetyp 3: IdNr und KiStAM
}

export enum KistaServiceType {
    BOP = 'BOP', // BOP = csv for uploading via online portal
    ELMA = 'ELMA', // ELMA = csv for uploading via sftp server
}
