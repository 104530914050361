<template>
    <div class="relative">
        <button
            ref="trigger"
            class="btn h-[34px] w-full border-gray-200 bg-white text-gray-600 hover:border-gray-300"
            :class="{ 'bg-gray-100 text-gray-500': open }"
            aria-haspopup="true"
            :aria-expanded="open"
            :title="$t('menu')"
            @click.prevent="
                () => {
                    open = !open
                }
            "
        >
            <span class="sr-only">{{ $t('menu') }}</span>
            <fa icon="ellipsis" />
        </button>
        <div
            :id="id"
            class="relative w-full"
        >
            <transition
                enter-active-class="transition ease-out duration-200 transform"
                enter-from-class="opacity-0 -translate-y-2"
                enter-to-class="opacity-100 translate-y-0"
                leave-active-class="transition ease-out duration-200"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
            >
                <div
                    v-show="open"
                    class="min-w-80 absolute top-full z-10 mt-1 origin-top-right overflow-hidden rounded border border-gray-200 bg-white py-1.5 shadow-lg"
                    :class="align === 'right' ? 'right-0' : 'left-0'"
                >
                    <ul
                        ref="dropdown"
                        @focusin="
                            () => {
                                open = true
                            }
                        "
                        @focusout="
                            () => {
                                open = false
                            }
                        "
                    >
                        <slot />
                    </ul>
                </div>
            </transition>
        </div>
    </div>
</template>
<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue'

defineProps<{
    id: string
    align: 'left' | 'right'
}>()

const open = ref(false)
const trigger = ref<HTMLElement | null>(null)
const dropdown = ref<HTMLElement | null>(null)

// close on click outside
const clickHandler = (event: MouseEvent) => {
    const target: EventTarget | null = event.target
    if (
        target &&
        (!open.value ||
            dropdown.value?.contains(target as Node) ||
            trigger.value?.contains(target as Node))
    ) {
        return
    }
    open.value = false
}

// close if the esc key is pressed
const keyHandler = (event: KeyboardEvent) => {
    if (!open.value || event.code !== 'Escape') return
    open.value = false
}

onMounted(() => {
    document.addEventListener('click', clickHandler)
    document.addEventListener('keydown', keyHandler)
})

onUnmounted(() => {
    document.removeEventListener('click', clickHandler)
    document.removeEventListener('keydown', keyHandler)
})
</script>
