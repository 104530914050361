export class PlatformLogo {
    id: string | null = null
    name: string | null = null
    url: string | null = null
    upload_key: string | null = null
    delete? = false
}

export class CompanyLogo {
    id: string | null = null
    name: string | null = null
    thumb_image_url: string | null = null
    thumb_image_srcset: string | null = null
    upload_key: string | null = null
    delete? = false
}

export class CampaignImage {
    id: string | null = null
    name: string | null = null
    thumb_image_url: string | null = null
    upload_key: string | null = null
    delete? = false
}

export class ContentImage {
    uuid: string | null = null
    name: string | null = null
    file_name: string | null = null
    thumb_image_url: string | null = null
    url: string | null = null
    upload_key: string | null = null
}
export class stdImage {
    id: string | null = null
    name: string | null = null
    file_name: string | null = null
    url: string | null = null
    delete? = false
}

export interface ImageInterface
    extends PlatformLogo,
        CompanyLogo,
        CampaignImage,
        ContentImage,
        stdImage {}
