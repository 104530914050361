<template>
    <div>
        <Modal
            id="new_interest_payment_calculation"
            ref="new_interest_payment_calculation"
            v-model:open="open"
            title="modal_title_new_interest_payment_calculation"
            width="2xl"
            @cancel="cancel"
        >
            <div>
                <div
                    v-if="
                        warnings.failed_transactions_count ||
                        warnings.missing_iban?.length > 0 ||
                        warnings.missing_bic?.length > 0
                    "
                    class="mb-6"
                >
                    <Banner
                        v-if="warnings.failed_transactions_count > 0"
                        type="warning"
                        class="mb-2"
                    >
                        {{
                            $t('warning_failed_transactions_count', {
                                count: warnings.failed_transactions_count,
                            })
                        }}
                    </Banner>
                    <Banner
                        v-if="warnings.missing_iban?.length > 0"
                        type="warning"
                        class="mb-2"
                    >
                        {{
                            $t('warning_missing_iban', {
                                count: warnings.missing_iban?.length,
                            })
                        }}
                    </Banner>
                    <Banner
                        v-if="warnings.missing_bic?.length > 0"
                        type="info"
                        class="mb-2"
                    >
                        {{
                            $t('warning_missing_bic', {
                                count: warnings.missing_bic.length,
                            })
                        }}
                    </Banner>
                </div>
                <div class="font-bold">{{ $t('interest_payment_date') }}</div>
                <div>
                    {{ moment(interestPayment.date).format('DD.MM.yyyy') }}
                    <Badge
                        v-if="isLastPayment"
                        type="info"
                        >{{ $t('last_payout') }}</Badge
                    >
                </div>
                <InterestPaymentsTable class="mt-0 mb-6" />

                <ToggleSwitch
                    v-model="modelValue.pay_interest"
                    name="pay_interest"
                    label="pay_interest"
                />
                <ToggleSwitch
                    v-model="modelValue.pay_clearance"
                    name="pay_clearance"
                    label="pay_clearance"
                />
                <NumberField
                    v-model="modelValue.pay_percent_of_rest"
                    label="pay_percent_of_rest"
                    name="pay_percent_of_rest"
                    unit="%"
                    :required="true"
                    help="help_pay_percent_of_rest"
                    :min="0"
                    :max="100"
                    @input="() => (isRecalculateRequired = true)"
                />
                <NumberField
                    v-if="modelValue.pay_interest"
                    v-model="modelValue.interest_bonus"
                    label="interest_bonus"
                    name="interest_bonus"
                    unit="%"
                    :required="true"
                    help="help_interest_bonus"
                    :min="0"
                    :max="100"
                    @input="() => (isRecalculateRequired = true)"
                />
            </div>
            <template #actions>
                <ExecuteButton />
                <RecalculateButton />
            </template>
        </Modal>
        <ModalWrapper v-if="isConfirmModalOpen" />
    </div>
</template>

<script setup lang="ts">
import {
    ModalButtonStyles,
    useModal,
    useMultiActionButton,
    useTable,
} from '@composables/common'
import {
    Badge,
    Banner,
    Modal,
    NumberField,
    ToggleSwitch,
} from '@src/components'
import {
    InterestPayment,
    InterestPaymentCalculation,
    InterestPaymentCalculationWarnings,
} from '@src/types'
import { formatNumericValues } from '@src/utils/helpers'
import { useVModels } from '@vueuse/core'
import moment from 'moment'
import { h, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const props = defineProps<{
    open: boolean
    modelValue: InterestPaymentCalculation
    currency: string | null
    interestPayment: InterestPayment
    warnings: InterestPaymentCalculationWarnings
    isLastPayment: boolean
}>()

const emit = defineEmits([
    'update:modelValue',
    'update:open',
    'cancel',
    'submit',
])

const { modelValue, currency, interestPayment, warnings, open } = useVModels(
    props,
    emit
)

const i18n = useI18n()

const { ModalWrapper, openModal } = useModal()

const isConfirmModalOpen = ref(false)

const isRecalculateRequired = ref(false)

const interestPaymentsTableColumns = [
    {
        label: 'interest',
        key: 'sum_paid_interest',
        cellRenderer: (props: { rowData: InterestPayment }) => {
            return formatNumericValues(
                props.rowData.sum_paid_interest as number,
                currency.value as string
            )
        },
    },
    {
        label: 'sum_paid_clearance',
        key: 'sum_paid_clearance',
        cellRenderer: (props: { rowData: InterestPayment }) => {
            return formatNumericValues(
                props.rowData.sum_paid_clearance as number,
                currency.value as string
            )
        },
    },
    {
        label: 'sum_paid_rest',
        key: 'sum_paid_rest ',
        cellRenderer: (props: { rowData: InterestPayment }) => {
            return formatNumericValues(
                props.rowData.sum_paid_rest as number,
                currency.value as string
            )
        },
    },
    {
        label: 'total_amount',
        key: 'sum_paid_total',
        cellRenderer: (props: { rowData: InterestPayment }) => {
            return h(
                'span',
                {
                    class: 'font-bold',
                },
                formatNumericValues(
                    props.rowData.sum_paid_total as number,
                    currency.value as string
                )
            )
        },
    },
]

const { TableWrapper: InterestPaymentsTable } = useTable({
    tableProps: {
        id: 'interest-payments-modal-table',
        total: 1,
        data: ref([interestPayment.value]),
        columns: interestPaymentsTableColumns,
        hideHeaderActions: true,
    },
})

const { MultiActionButton: ExecuteButton } = useMultiActionButton({
    id: 'execute-interest-payment-button',
    actions: [
        {
            label: 'execute_interest_payment',
            title: () =>
                isRecalculateRequired.value
                    ? 'please_recalculate_interest_first'
                    : null,
            action: () => submit({ confirm: true }),
            disabled: () => isRecalculateRequired.value,
        },
        {
            label: 'execute_interest_payment_including_sepa_export',
            title: () =>
                isRecalculateRequired.value
                    ? 'please_recalculate_interest_first'
                    : null,
            action: () => submit({ confirm: true, export: 'sepa' }),
            disabled: () => isRecalculateRequired.value,
        },
    ],
})

const { MultiActionButton: RecalculateButton } = useMultiActionButton({
    id: 'recalculate-interest-payment-button',
    actions: [
        {
            label: 'recalculate',
            action: () => submit(),
        },
        {
            label: 'recalculate_and_export_excel',
            action: () => submit({ export: 'excel' }),
        },
        {
            label: 'recalculate_and_export_sepa_csv',
            action: () => submit({ export: 'sepa' }),
        },
    ],
})

function cancel() {
    emit('cancel')
}

function submit(options?: { confirm?: boolean; export?: 'excel' | 'sepa' }) {
    if (options?.export) {
        modelValue.value.export = options.export
    }
    if (options?.confirm) {
        modelValue.value.persist = true
        isConfirmModalOpen.value = true
        openModal(
            {
                id: 'execute_interest_payment_modal',
                title: 'modal_title_execute_interest_payment',
                open: isConfirmModalOpen,
                onCancel: () => {
                    isConfirmModalOpen.value = false
                },
                onSubmit: async () => {
                    isConfirmModalOpen.value = false
                    emit('submit')
                },
            },
            () => h('p', i18n.t('modal_text_execute_interest_payment')),
            {
                submitButtonText: 'execute_interest_payment',
                styles: {
                    submitButton: ModalButtonStyles['BLUE'],
                },
            }
        )
    } else {
        isRecalculateRequired.value = false
        emit('submit')
    }
}
</script>

<style scoped></style>
