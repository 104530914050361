<template>
    <TransactionsTable>
        <template #filters>
            <DropdownFilter
                :pin="pinFilters"
                @apply="applyFilters"
                @clear="clearFilters"
                @toggle-pin="togglePinFilters"
            >
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <TextField
                            v-model="filtersFields.from"
                            name="from"
                            :placeholder="$t('sender')"
                            :disabled="disableFilter('from')"
                        />
                    </label>
                </DropdownFilterItem>
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <TextField
                            v-model="filtersFields.to"
                            name="to"
                            :placeholder="$t('receiver')"
                            :disabled="disableFilter('to')"
                        />
                    </label>
                </DropdownFilterItem>
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <TextField
                            v-model="filtersFields.amount_real"
                            name="amount_real"
                            :placeholder="$t('amount')"
                            :disabled="disableFilter('amount')"
                        />
                    </label>
                </DropdownFilterItem>
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <SelectField
                            v-model="filtersFields.type"
                            name="type"
                            :options="mapEnumToArray(TransactionType)"
                            :reduce="reduceEnumValue"
                            :placeholder="$t('transaction_type')"
                            :disabled="disableFilter('type')"
                            @keydown.enter.prevent="handleEnterPress"
                        />
                    </label>
                </DropdownFilterItem>

                <DropdownFilterItem>
                    <label class="flex items-center">
                        <Datepicker
                            v-model="filtersFields.date_from"
                            placeholder="date_from"
                            name="filter_date"
                            :disabled="disableFilter('date_from')"
                        />
                    </label>
                </DropdownFilterItem>
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <Datepicker
                            v-model="filtersFields.date_to"
                            placeholder="date_to"
                            name="filter_date"
                            :disabled="disableFilter('date_to')"
                        />
                    </label>
                </DropdownFilterItem>
            </DropdownFilter>
        </template>
    </TransactionsTable>
</template>

<script setup lang="ts">
import { useEnum, useTable } from '@composables/common'
import {
    Datepicker,
    DropdownFilter,
    DropdownFilterItem,
    SelectField,
    TextField,
} from '@src/components'
import { PaymentMethod, TransactionStatus, TransactionType } from '@src/enums'
import { TableSorting, Transaction } from '@src/types'
import { getTransactions } from '@src/utils/api/accounts'
import { handleEnterPress } from '@src/utils/helpers'
import { i18n } from '@src/utils/lang'
import { useUserStore } from '@src/utils/stores/user'
import moment from 'moment'
import { onMounted, ref, toRefs } from 'vue'
import { useRouter } from 'vue-router'

const props = withDefaults(
    defineProps<{
        initialFilters?: {
            id?: string | null
            from?: string | null
            to?: string | null
            method?: string | null
            payment_message?: string | null
            type?: string | null
            date?: string | null
            amount_real?: string | null
            natural_profile_id?: string | null
            legal_profile_id?: string | null
        } | null
    }>(),
    {
        initialFilters: null,
    }
)

const emit = defineEmits(['getCount'])

const { locale } = i18n.global

const { initialFilters } = toRefs(props)

const { mapEnumToArray, reduceEnumValue, getEnumLabel } = useEnum()

const router = useRouter()
const transactions = ref<Transaction[]>([])
const transactionsCount = ref<number>(0)
const userStore = useUserStore()

const disableFilter = (filter: string) => {
    if (initialFilters.value) {
        return (
            Object.keys(initialFilters.value).filter((item) => {
                return item === filter
            }).length > 0
        )
    }
    return false
}

const {
    TableWrapper: TransactionsTable,
    limit,
    sorting,
    page,
    filtersFields,
    filters,
    pinFilters,
    togglePinFilters,
    applyFilters,
    clearFilters,
    setFilters,
} = useTable({
    loadData: loadTransactions,
    filters: {
        id: null,
        from: null,
        to: null,
        method: null,
        payment_message: null,
        type: null,
        date: null,
        amount_real: null,
        natural_profile_id: null,
        legal_profile_id: null,
    },
    disableFilters: initialFilters.value
        ? Object.keys(initialFilters.value)
        : [],
    tableProps: {
        id: 'transactions-table',
        label: 'transactions',
        help: 'help_transactions_table',
        pagination: true,
        data: transactions,
        total: transactionsCount,
        sorting: ref<TableSorting[]>([
            {
                field: 'date',
                direction: 'desc',
            },
        ]),
        columns: [
            {
                label: 'id',
                key: 'id',
                sorting: true,
                condition: userStore.can('see_id_columns'),
                copy: true,
            },
            {
                label: 'status',
                key: 'status',
                sorting: true,
                cellRenderer: (props: { rowData: Transaction }) => {
                    return getEnumLabel(TransactionStatus, props.rowData.status)
                },
            },
            {
                label: 'sender',
                key: 'from',
                sorting: true,
                copy: true,
            },
            {
                label: 'receiver',
                key: 'to',
                sorting: true,
                copy: true,
            },
            {
                label: 'amount',
                key: 'amount_real',
                sorting: true,
                cellRenderer: (props: { rowData: Transaction }) => {
                    return props.rowData.amount.toLocaleString(locale.value)
                },
            },
            {
                label: 'comment',
                key: 'payment_message',
                sorting: true,
            },
            {
                label: 'transaction_method',
                key: 'method',
                sorting: true,
                cellRenderer: (props: { rowData: Transaction }) => {
                    return getEnumLabel(PaymentMethod, props.rowData.method)
                },
            },
            {
                label: 'transaction_type',
                key: 'type',
                sorting: true,
                cellRenderer: (props: { rowData: Transaction }) => {
                    return getEnumLabel(TransactionType, props.rowData.type)
                },
            },
            {
                label: 'created_on',
                key: 'date',
                sorting: true,
                cellRenderer: (props: { rowData: Transaction }) => {
                    return moment(props.rowData.date).format('DD.MM.yyyy HH:mm')
                },
            },
        ],
        actions: [
            {
                action: handleViewOrder,
                icon: 'fa-shop',
                title: 'view_linked_order',
                condition: (transaction: Transaction) => {
                    return (
                        userStore.can('view_orders') && !!transaction.order_id
                    )
                },
            },
            {
                action: handleViewNaturalProfile,
                icon: 'fa-person',
                title: 'view_sender_natural_profile',
                condition: (transaction: Transaction) => {
                    return (
                        userStore.can('view_accounts') &&
                        !!transaction.sender_natural_profile
                    )
                },
            },
            {
                action: handleViewNaturalProfile,
                icon: 'fa-person',
                title: 'view_receiver_natural_profile',
                condition: (transaction: Transaction) => {
                    return (
                        userStore.can('view_accounts') &&
                        !!transaction.receiver_natural_profile
                    )
                },
            },
            {
                action: handleViewLegalProfile,
                icon: 'fa-building-columns',
                title: 'view_sender_legal_profile',
                condition: (transaction: Transaction) => {
                    return (
                        userStore.can('view_accounts') &&
                        !!transaction.sender_legal_profile
                    )
                },
            },
            {
                action: handleViewLegalProfile,
                icon: 'fa-building-columns',
                title: 'view_receiver_legal_profile',
                condition: (transaction: Transaction) => {
                    return (
                        userStore.can('view_accounts') &&
                        !!transaction.receiver_legal_profile
                    )
                },
            },
        ],
    },
})

function handleViewOrder(transaction: Transaction): void {
    router.push({
        name: 'order',
        params: { id: transaction.order_id },
    })
}

function handleViewNaturalProfile(transaction: Transaction): void {
    let naturalProfileId

    if (transaction.sender_natural_profile) {
        naturalProfileId =
            transaction.sender_natural_profile.natural_profile_uid
    } else if (transaction.receiver_natural_profile) {
        naturalProfileId =
            transaction.receiver_natural_profile.natural_profile_uid
    }

    if (naturalProfileId) {
        router.push({
            name: 'naturalProfile',
            params: { id: naturalProfileId },
        })
    }
}

function handleViewLegalProfile(transaction: Transaction): void {
    let legalProfileId

    if (transaction.sender_legal_profile) {
        legalProfileId = transaction.sender_legal_profile.legal_profile_uid
    } else if (transaction.receiver_legal_profile) {
        legalProfileId = transaction.receiver_legal_profile.legal_profile_uid
    }

    if (legalProfileId) {
        router.push({
            name: 'legalProfile',
            params: { id: legalProfileId },
        })
    }
}

async function loadTransactions() {
    const transactionsRequest = await getTransactions(
        filters.value,
        sorting.value,
        page.value,
        limit.value
    )
    transactions.value = transactionsRequest.data.transactions
    transactionsCount.value = transactionsRequest.data.transactions_count
    emit('getCount', transactionsCount.value)
}

onMounted(async () => {
    if (initialFilters.value) {
        filtersFields.value = {
            ...filtersFields.value,
            ...initialFilters.value,
        }

        setFilters()
    }
    loadTransactions()
})
</script>
