<template>
    <div class="flex flex-1 justify-center px-5 py-4">
        <div class="mr-auto">
            <select
                v-model.number="limit"
                class="rounded-xl border-none bg-gray-100 text-sm !outline-none !ring-0"
            >
                <option :value="5">5</option>
                <option :value="10">10</option>
                <option :value="25">25</option>
                <option :value="50">50</option>
                <option :value="100">100</option>
                <option :value="200">200</option>
            </select>
        </div>
        <div class="flex justify-center">
            <nav
                class="flex"
                role="navigation"
                aria-label="Navigation"
            >
                <div
                    class="mr-2"
                    :class="{
                        'cursor-pointer': active > 1,
                    }"
                    @click="firstPage"
                >
                    <span
                        class="inline-flex items-center justify-center rounded border border-gray-200 bg-white px-2.5 py-2 leading-5"
                        :class="{
                            'hover:bg-primary-additional-500': active > 1,
                            'hover:text-white': active > 1,
                            'text-gray-600': active > pagesLength,
                            'text-gray-300': active <= 1,
                        }"
                    >
                        <span class="sr-only">First</span><wbr />
                        <fa icon="fa-solid fa-angles-left" />
                    </span>
                </div>
                <div
                    class="mr-2"
                    :class="{
                        'cursor-pointer': active > 1,
                    }"
                    @click="previousPage"
                >
                    <span
                        class="inline-flex items-center justify-center rounded border border-gray-200 bg-white px-2.5 py-2 leading-5"
                        :class="{
                            'hover:bg-primary-additional-500': active > 1,
                            'hover:text-white': active > 1,
                            'text-gray-600': active > pagesLength,
                            'text-gray-300': active <= 1,
                        }"
                    >
                        <span class="sr-only">Previous</span><wbr />
                        <fa icon="fa-solid fa-angle-left" />
                    </span>
                </div>
                <ul
                    class="inline-flex -space-x-px text-sm font-medium shadow-sm"
                >
                    <li
                        v-for="page in pagesToShow"
                        :key="page"
                        @click="toPage(page)"
                    >
                        <span
                            class="text-primary-additional-500 inline-flex items-center justify-center border border-gray-200 bg-white px-3.5 py-2 leading-5"
                            :class="{
                                'rounded-l': page === 1,
                                'hover:bg-primary-additional-500':
                                    page !== active,
                                'hover:text-white': page !== active,
                                'cursor-pointer': page !== active,
                                'rounded-r': page === pagesLength,
                                'text-gray-300': page === active,
                            }"
                            >{{ page }}</span
                        >
                    </li>
                    <li v-if="false">
                        <span
                            class="inline-flex items-center justify-center border border-gray-200 bg-white px-3.5 py-2 leading-5 text-gray-400"
                            >…</span
                        >
                    </li>
                </ul>
                <div
                    class="ml-2"
                    :class="{
                        'cursor-pointer': active < pagesLength,
                    }"
                    @click="nextPage"
                >
                    <span
                        class="inline-flex items-center justify-center rounded border border-gray-200 bg-white px-2.5 py-2 leading-5 shadow-sm"
                        :class="{
                            'hover:bg-primary-additional-500':
                                active < pagesLength,
                            'hover:text-white': active < pagesLength,
                            'text-gray-600': active < pagesLength,
                            'text-gray-300': active === pagesLength,
                        }"
                    >
                        <span class="sr-only">Next</span><wbr />
                        <fa icon="fa-solid fa-angle-right" />
                    </span>
                </div>
                <div
                    class="ml-2"
                    :class="{
                        'cursor-pointer': active < pagesLength,
                    }"
                    @click="lastPage"
                >
                    <span
                        class="inline-flex items-center justify-center rounded border border-gray-200 bg-white px-2.5 py-2 leading-5 shadow-sm"
                        :class="{
                            'hover:bg-primary-additional-500':
                                active < pagesLength,
                            'hover:text-white': active < pagesLength,
                            'text-gray-600': active < pagesLength,
                            'text-gray-300': active === pagesLength,
                        }"
                    >
                        <span class="sr-only">Last</span><wbr />
                        <fa icon="fa-solid fa-angles-right" />
                    </span>
                </div>
            </nav>
        </div>
        <div class="ml-auto"></div>
    </div>
</template>

<script setup lang="ts">
import { useVModels } from '@vueuse/core'
import { computed } from 'vue'

const props = defineProps<{
    total: number
    active: number
    limit: number
}>()

const emit = defineEmits(['update:active', 'update:limit'])

const { active, limit } = useVModels(props, emit)

const pagesLength = computed(() => {
    return Math.ceil(props.total / limit.value)
})

const pagesToShow = computed(() => {
    const pages = []
    if (active.value > 2) {
        pages.push(active.value - 2)
    }
    if (active.value > 1) {
        pages.push(active.value - 1)
    }
    pages.push(active.value)
    if (active.value + 1 <= pagesLength.value) {
        pages.push(active.value + 1)
    }
    if (active.value + 2 <= pagesLength.value) {
        pages.push(active.value + 2)
    }

    return pages
})

function firstPage(): void {
    if (active.value > 1) {
        active.value = 1
    }
}

function previousPage(): void {
    if (active.value > 1) {
        --active.value
    }
}

function nextPage(): void {
    if (active.value < pagesLength.value) {
        active.value += 1
    }
}

function lastPage(): void {
    if (active.value < pagesLength.value) {
        active.value = pagesLength.value
    }
}

function toPage(page: number) {
    if (page !== active.value && page > 0 && page <= pagesLength.value) {
        active.value = page
    }
}
</script>
