<template>
    <header class="sticky top-0 z-50 border-b border-gray-200 bg-white">
        <div class="px-4 sm:px-6 lg:px-8">
            <div class="-mb-px flex h-16 items-center justify-between">
                <!-- Header: Left side -->
                <div class="flex">
                    <!-- Hamburger button -->
                    <button
                        class="text-gray-500 hover:text-gray-600 lg:hidden"
                        aria-controls="sidebar"
                        :aria-expanded="sidebarOpen"
                        @click.stop="$emit('toggle-sidebar')"
                    >
                        <span class="sr-only">Open sidebar</span>
                        <svg
                            class="h-6 w-6 fill-current"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <rect
                                x="4"
                                y="5"
                                width="16"
                                height="2"
                            />
                            <rect
                                x="4"
                                y="11"
                                width="16"
                                height="2"
                            />
                            <rect
                                x="4"
                                y="17"
                                width="16"
                                height="2"
                            />
                        </svg>
                    </button>
                    <button
                        class="ml-3 flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 transition duration-150 hover:bg-gray-200 lg:hidden"
                        @click.stop="toggleMobileSearch"
                        @toggle-mobile-search="toggleMobileSearch"
                    >
                        <fa icon="fa-magnifying-glass" />
                    </button>
                </div>
                <Search
                    v-if="searchEnabled"
                    :mobile-search-open="mobileSearchOpen"
                    @toggle-mobile-search="toggleMobileSearch"
                />
                <!-- Header: Right side -->
                <div class="flex items-center space-x-3">
                    <Help align="right" />
                    <UserMenu align="right" />
                    <hr class="h-6 w-px bg-gray-200" />
                    <LanguageSwitch />
                </div>
            </div>
        </div>
    </header>
</template>

<script setup lang="ts">
/* TODO: This will come back once we have the individual functionality implemented in the API
import Notifications from '@src/components/DropdownNotifications.vue'
*/
import { Help, DropdownProfile as UserMenu } from '@src/components'
import LanguageSwitch from '@src/partials/header/LangSwitch.vue'
import Search from '@src/partials/header/Search.vue'
import { ref } from 'vue'

const searchEnabled = !!parseInt(import.meta.env.VITE_GLOBAL_SEARCH_ENABLED)

withDefaults(
    defineProps<{
        sidebarOpen?: boolean
    }>(),
    {
        sidebarOpen: false,
    }
)

defineEmits(['toggle-sidebar'])

const mobileSearchOpen = ref<boolean>(false)
function toggleMobileSearch() {
    mobileSearchOpen.value = !mobileSearchOpen.value
}
</script>
<style>
@tailwind base;
@tailwind components;
@tailwind utilities;
</style>
