<template>
    <LoadingScreen v-if="getLoading()" />
    <ToastNotifications />
    <router-view />
</template>

<script setup lang="ts">
import '@src/charts/ChartjsConfig'
import { LoadingScreen, ToastNotifications } from '@src/components'
import { useUserStore } from '@stores//user'
import { useCountryStore } from '@stores/countries'
import { useLanguageStore } from '@stores/languages'
import { useLoadingStore } from '@stores/loading'
import { computed, onMounted } from 'vue'

const { getLoading } = useLoadingStore()
const languageStore = useLanguageStore()
const countryStore = useCountryStore()
const userStore = useUserStore()

const user = computed(() => {
    return userStore.user
})

onMounted(async () => {
    // await userStore.loadUser()
    languageStore.loadLanguages()
    countryStore.loadCountries()

    window.onUsersnapLoad = function (api) {
        api.init({
            user: {
                email: user.value?.email,
                userId: user.value?.userId,
            },
            custom: {
                name: user.value?.name,
                role: user.value?.primaryRole,
            },
        })
        window.Usersnap = api
    }
    const script = document.createElement('script')
    script.defer = true
    script.src =
        'https://widget.usersnap.com/global/load/ce89d064-e781-4f18-8769-563767ea8cef?onload=onUsersnapLoad'
    document.getElementsByTagName('head')[0].appendChild(script)
})
</script>
