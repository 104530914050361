<template>
    <div>
        <LoadingSpinner class="text-3xl text-white" />
    </div>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router'
import { downloadDocument } from '@utils/api/membershipDocuments'
import { onBeforeMount } from 'vue'
import { LoadingSpinner } from '@src/components'

const route = useRoute()

onBeforeMount(async () => {
    if (route.params.membershipDocumentId) {
        const apiRes = await downloadDocument(route.params.membershipDocumentId)
        window.location.replace(apiRes.data.link)
    } else {
        console.error('Invalid doc ID!')
    }
})
</script>

<style scoped></style>
