import { SummaryOptions, useSummary } from '@composables/common'
import { LegalProfile, NaturalProfile } from '@src/types'
import { SummaryItem } from '@src/types/Summary'
import { useCountryStore } from '@src/utils/stores/countries'
import { useUserStore } from '@src/utils/stores/user'
import { computedAsync } from '@vueuse/core'
import moment from 'moment'
import { Ref } from 'vue'
import { useI18n } from 'vue-i18n'

export function useAccountSummary(
    naturalProfile: Ref<NaturalProfile>,
    legalProfile?: Ref<LegalProfile>,
    options?: SummaryOptions
) {
    const i18n = useI18n()
    const userStore = useUserStore()
    const countryStore = useCountryStore()

    const items = computedAsync<SummaryItem[]>(async () => {
        let items: SummaryItem[] = [
            {
                label: i18n.t('id'),
                value: naturalProfile.value.natural_profile_uid,
                special: 'link',
                linkURL: `/naturalProfile/${naturalProfile.value.natural_profile_uid}`,
                copy: true,
            },
            {
                label: i18n.t('account'),
                value:
                    naturalProfile.value.first_name +
                    ' ' +
                    naturalProfile.value.last_name,
                special: 'link',
                linkURL: `/naturalProfile/${naturalProfile.value.natural_profile_uid}`,
                copy: true,
            },
            {
                label: i18n.t('email'),
                value: naturalProfile.value.email,
                copy: true,
            },
            {
                label: i18n.t('date_of_birth'),
                value: naturalProfile.value.date_of_birth
                    ? moment(naturalProfile.value.date_of_birth).format(
                          'DD.MM.yyyy'
                      )
                    : '',
                copy: true,
            },
            {
                label: i18n.t('country_of_residence'),
                value: await countryStore.translate(
                    naturalProfile.value.country as string
                ),
                copy: true,
            },
        ]

        if (legalProfile?.value.legal_profile_uid !== undefined) {
            items = [
                {
                    label: i18n.t('legal_profile_uid'),
                    value: legalProfile.value.legal_profile_uid,
                    condition:
                        userStore.can('see_id_columns') &&
                        legalProfile.value.legal_profile_uid,
                    copy: true,
                } as SummaryItem,
                {
                    label: i18n.t('company_name'),
                    value: legalProfile.value.company_name,
                    special: 'link',
                    linkURL: `/legalProfile/${legalProfile.value.legal_profile_uid}`,
                    copy: true,
                } as SummaryItem,
                ...items,
            ]
        }

        return items
    }, [])

    const { Summary: AccountSummary } = useSummary(items, {
        ...{
            title: 'account',
        },
        ...options,
    })

    return {
        AccountSummary,
    }
}
