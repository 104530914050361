import { ModalButtonStyles, OpenModal, useTable } from '@composables/common'
import UpdateOrCreatePlatformEmailTemplateForm from '@partials/platforms/UpdateOrCreatePlatformEmailTemplateForm.vue'
import ViewPlatformEmailTemplate from '@partials/platforms/ViewPlatformEmailTemplate.vue'
import LanguageColumnCellRenderer from '@partials/tables/LanguageColumnCellRenderer.vue'
import { NameAndId } from '@src/components'
import { Platform, PlatformEmailTemplate } from '@src/types'
import { sendEmailTemplateTest } from '@src/utils/api/platforms'
import { useToastStore } from '@src/utils/stores/toast'
import { useUserStore } from '@src/utils/stores/user'
import { Ref, computed, h, ref, shallowRef } from 'vue'
import { useI18n } from 'vue-i18n'

export function usePlatformEmailTemplatesTable(
    emailTemplates: Ref<PlatformEmailTemplate[]>,
    platform: Ref<Platform>,
    update: (successMessage?: string | null) => Promise<void>,
    openModal: OpenModal,
    isModalOpen: Ref<boolean>
) {
    const userStore = useUserStore()
    const i18n = useI18n()
    const toast = useToastStore()

    const total = computed(() => {
        return emailTemplates.value.length
    })

    const {
        TableWrapper: PlatformEmailTemplatesTable,
        selected: selectedPlatformEmailTemplatesIds,
    } = useTable({
        tableProps: {
            id: 'platform_email_templates',
            label: 'platform_email_templates',
            help: 'help_platform_email_templates_table',
            data: emailTemplates,
            total: total,
            columns: [
                {
                    label: 'id',
                    key: 'id',
                    select: true,
                },
                {
                    label: 'id',
                    key: 'id',
                    condition: userStore.can('see_id_columns'),
                    copy: true,
                },
                {
                    label: 'type',
                    key: 'template_name',
                    cellRenderer: (props: {
                        rowData: PlatformEmailTemplate
                    }) => {
                        if (props.rowData.template_name) {
                            return props.rowData.template_name
                                ? i18n.t(
                                      'EMAIL_TEMPLATE_' +
                                          (props.rowData.template_name.toUpperCase() ??
                                              '')
                                  )
                                : props.rowData.template_name
                        }
                    },
                },
                {
                    label: 'language',
                    key: 'language',
                    cellRenderer: shallowRef(LanguageColumnCellRenderer),
                },
            ],
            actions: [
                {
                    action: handleViewPlatformEmailTemplate,
                    icon: 'fa-eye',
                    title: 'view',
                },
                {
                    action: handleEditOrCreatePlatformEmailTemplate,
                    icon: 'fa-solid fa-pen',
                    title: 'edit',
                    condition: userStore.can('update_platform'),
                },
                {
                    action: handleDeletePlatformEmailTemplate,
                    icon: 'fas fa-trash-alt',
                    title: 'delete',
                    color: 'text-danger-500',
                    condition: userStore.can('update_platform'),
                },
                {
                    action: handleSendEmailTemplate,
                    icon: 'fa-solid fa-paper-plane',
                    title: 'send',
                    color: 'text-gray-500',
                    condition: userStore.can('update_platform'),
                },
            ],
        },
    })

    function handleViewPlatformEmailTemplate(
        platformEmailTemplate: PlatformEmailTemplate
    ) {
        isModalOpen.value = true
        openModal(
            {
                id: 'view_platform_email_template',
                title: platformEmailTemplate.id
                    ? 'modal_title_update_platform_email_template'
                    : 'modal_title_create_platform_email_template',
                open: isModalOpen,
                width: '2xl',
                onCancel: () => (isModalOpen.value = false),
            },
            () =>
                h(ViewPlatformEmailTemplate, {
                    modelValue: platformEmailTemplate,
                })
        )
    }

    function handleDeletePlatformEmailTemplate(
        platformEmailTemplate: PlatformEmailTemplate
    ) {
        isModalOpen.value = true
        openModal(
            {
                id: 'delete_platform_email_template',
                title: 'modal_title_delete_platform_email_template',
                open: isModalOpen,
                width: '2xl',
                onCancel: () => (isModalOpen.value = false),
                onSubmit: () =>
                    deletePlatformEmailTemplate(platformEmailTemplate),
            },
            () =>
                h(NameAndId, {
                    idKey: 'id',
                    nameKey: 'name',
                    modelValue: [platformEmailTemplate],
                }),
            {
                submitButtonText: 'delete',
                styles: {
                    submitButton: ModalButtonStyles['RED'],
                },
            }
        )
    }

    function handleDeletePlatformEmailTemplates() {
        isModalOpen.value = true
        openModal(
            {
                id: 'delete_platform_email_templates',
                title: 'modal_title_delete_platform_email_templates',
                open: isModalOpen,
                width: '2xl',
                onCancel: () => (isModalOpen.value = false),
                onSubmit: () => deletePlatformEmailTemplates(),
            },
            () =>
                h(NameAndId, {
                    idKey: 'id',
                    nameKey: 'name',
                    modelValue: selectedPlatformEmailTemplates.value,
                }),
            {
                submitButtonText: 'delete',
                styles: {
                    submitButton: ModalButtonStyles['RED'],
                },
            }
        )
    }

    function deletePlatformEmailTemplate(
        platformEmailTemplateToDelete: PlatformEmailTemplate,
        submit = true
    ) {
        const emailTemplatesTemp = [...emailTemplates.value]
        emailTemplates.value.splice(
            emailTemplates.value.findIndex(
                (platformEmailTemplate) =>
                    platformEmailTemplateToDelete.id == platformEmailTemplate.id
            ),
            1
        )
        if (submit) {
            update(
                i18n.t('toast_success_platform_email_template_deleted', {
                    template:
                        i18n.t(
                            'EMAIL_TEMPLATE_' +
                                platformEmailTemplateToDelete.template_name?.toUpperCase()
                        ) ?? platformEmailTemplateToDelete.template_name,
                })
            )
                .catch((error) => {
                    emailTemplates.value = emailTemplatesTemp
                    throw error
                })
                .then(() => {
                    isModalOpen.value = false
                })
        }
    }

    function deletePlatformEmailTemplates() {
        const emailTemplatesTemp = [...emailTemplates.value]

        selectedPlatformEmailTemplates.value.forEach(
            (platformEmailTemplate) => {
                deletePlatformEmailTemplate(platformEmailTemplate, false)
            }
        )

        update(
            i18n.t('toast_success_platform_email_templates_deleted', {
                templates: selectedPlatformEmailTemplates.value
                    .map(
                        (platformEmailTemplate) =>
                            platformEmailTemplate.template_name
                    )
                    .join(', '),
            })
        )
            .catch((error) => {
                emailTemplates.value = emailTemplatesTemp
                throw error
            })
            .then(() => {
                selectedPlatformEmailTemplatesIds.value = []
                isModalOpen.value = false
            })
    }

    function handleEditOrCreatePlatformEmailTemplate(
        emailTemplate: PlatformEmailTemplate = new PlatformEmailTemplate()
    ) {
        const editedEmailTemplate = ref(new PlatformEmailTemplate())
        editedEmailTemplate.value = { ...emailTemplate }
        isModalOpen.value = true

        const disableCreate = computed(() => {
            return !(
                editedEmailTemplate.value.template_name &&
                editedEmailTemplate.value.language &&
                editedEmailTemplate.value.subject &&
                editedEmailTemplate.value.template_content
            )
        })

        openModal(
            {
                id: 'edit_platform_email_template',
                title: editedEmailTemplate.value.id
                    ? 'modal_title_update_platform_email_template'
                    : 'modal_title_create_platform_email_template',
                open: isModalOpen,
                width: '2xl',
                onCancel: () => (isModalOpen.value = false),
                onSubmit: () => saveEmailTemplate(editedEmailTemplate.value),
            },
            () =>
                h(UpdateOrCreatePlatformEmailTemplateForm, {
                    modelValue: editedEmailTemplate,
                    platform: platform,
                }),
            {
                submitButtonText: editedEmailTemplate.value.id
                    ? 'update'
                    : 'create',
                styles: {
                    submitButton: ModalButtonStyles['BLUE'],
                },
                submitButtonDisabled: disableCreate,
            }
        )
    }

    function saveEmailTemplate(platformEmailTemplate: PlatformEmailTemplate) {
        const emailTemplatesTemp = [...emailTemplates.value]

        if (platformEmailTemplate.id) {
            emailTemplates.value[
                emailTemplates.value.findIndex(
                    (emailTemplate: PlatformEmailTemplate) =>
                        platformEmailTemplate.id == emailTemplate.id
                )
            ] = platformEmailTemplate
        } else {
            emailTemplates.value.push(platformEmailTemplate)
        }

        let toast = 'toast_success_platform_email_template_updated'
        if (!platformEmailTemplate.id) {
            toast = 'toast_success_platform_email_template_created'
        }
        update(
            i18n.t(toast, {
                template:
                    i18n.t(
                        'EMAIL_TEMPLATE_' +
                            platformEmailTemplate.template_name?.toUpperCase()
                    ) ?? platformEmailTemplate.template_name,
            })
        )
            .catch((error) => {
                emailTemplates.value = emailTemplatesTemp
                throw error
            })
            .then(() => {
                isModalOpen.value = false
            })
    }

    async function handleSendEmailTemplate(
        emailTemplate: PlatformEmailTemplate
    ) {
        if (!platform.value.platform_name || !emailTemplate.template_name)
            return

        await sendEmailTemplateTest(
            platform.value.platform_name,
            emailTemplate.template_name
        )

        toast.success(
            i18n.t('toast_success_platform_email_template_sent', {
                template:
                    i18n.t(
                        'EMAIL_TEMPLATE_' +
                            emailTemplate.template_name?.toUpperCase()
                    ) ?? emailTemplate.template_name,
            })
        )
    }

    const selectedPlatformEmailTemplates = computed(() => {
        return emailTemplates.value.filter(
            (emailTemplate) =>
                emailTemplate.id &&
                selectedPlatformEmailTemplatesIds.value.includes(
                    emailTemplate.id
                )
        )
    })

    return {
        PlatformEmailTemplatesTable,
        selectedPlatformEmailTemplatesIds,
        handleEditOrCreatePlatformEmailTemplate,
        handleDeletePlatformEmailTemplates,
    }
}
