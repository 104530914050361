import { createRouter, createWebHistory } from 'vue-router'
// admin-ui pages
// - dashboard
import Dashboard from '@src/pages/Dashboard.vue'
// - platforms
import Platform from '@src/pages/platforms/Platform.vue'
import PlatformForm from '@src/pages/platforms/PlatformForm.vue'
import Platforms from '@src/pages/platforms/Platforms.vue'
// - assets
import Asset from '@src/pages/assets/Asset.vue'
import AssetForm from '@src/pages/assets/AssetForm.vue'
import Assets from '@src/pages/assets/Assets.vue'
// - campaigns
import Campaign from '@src/pages/campaigns/Campaign.vue'
import CampaignForm from '@src/pages/campaigns/CampaignForm.vue'
import Campaigns from '@src/pages/campaigns/Campaigns.vue'
// - orders
import OpenBaskets from '@src/pages/orders/OpenBaskets.vue'
import Order from '@src/pages/orders/Order.vue'
import OrderForm from '@src/pages/orders/OrderForm.vue'
import Orders from '@src/pages/orders/Orders.vue'
// - accounts
import Accounts from '@src/pages/accounts/Accounts.vue'
import LegalProfile from '@src/pages/accounts/LegalProfile.vue'
import LegalProfileForm from '@src/pages/accounts/LegalProfileForm.vue'
import NaturalProfile from '@src/pages/accounts/NaturalProfile.vue'
import NaturalProfileForm from '@src/pages/accounts/NaturalProfileForm.vue'

// - contracts
import Contract from '@src/pages/contracts/Contract.vue'
import ContractForm from '@src/pages/contracts/ContractForm.vue'
import Contracts from '@src/pages/contracts/Contracts.vue'

// - emails
import Emails from '@src/pages/emails/Emails.vue'
// - transactions
import Transactions from '@src/pages/transactions/Transactions.vue'

// - tasks
import Tasks from '@src/pages/tasks/Tasks.vue'

import Issuer from '@src/pages/issuers/Issuer.vue'
import IssuerForm from '@src/pages/issuers/IssuerForm.vue'
import Issuers from '@src/pages/issuers/Issuers.vue'
import { useUserStore } from '@stores/user'
import { i18n } from './utils/lang'
import { useToastStore } from './utils/stores/toast'
import DownloadMembershipDocument from '@src/pages/membershipDocument/downloadMembershipDocument.vue'

const routerHistory = createWebHistory()

const router = createRouter({
    history: routerHistory,
    routes: [
        // admin-ui
        {
            name: 'dashboard',
            path: '/',
            component: Dashboard,
        },

        // platforms
        {
            name: 'platforms',
            path: '/platforms',
            component: Platforms,
        },
        {
            name: 'platform',
            path: '/platforms/:slug',
            component: Platform,
        },
        {
            name: 'platform.create',
            path: '/platforms/create',
            component: PlatformForm,
        },
        {
            name: 'platform.edit',
            path: '/platforms/edit/:slug',
            component: PlatformForm,
        },

        // assets
        {
            name: 'assets',
            path: '/assets',
            component: Assets,
        },
        {
            name: 'asset.edit',
            path: '/assets/edit/:id',
            component: AssetForm,
        },
        {
            name: 'asset',
            path: '/assets/:id',
            component: Asset,
        },

        // issuers
        {
            name: 'issuers',
            path: '/issuers',
            component: Issuers,
        },
        {
            name: 'issuer.edit',
            path: '/issuers/edit/:id',
            component: IssuerForm,
        },
        {
            name: 'issuer',
            path: '/issuers/:id',
            component: Issuer,
        },

        // orders
        {
            name: 'orders',
            path: '/orders',
            component: Orders,
        },
        {
            name: 'order',
            path: '/orders/:id',
            component: Order,
        },
        {
            name: 'order.edit',
            path: '/orders/edit/:id',
            component: OrderForm,
        },
        {
            name: 'open-baskets',
            path: '/open-baskets',
            component: OpenBaskets,
        },
        // accounts
        {
            name: 'accounts',
            path: '/accounts',
            component: Accounts,
        },
        {
            name: 'naturalProfile',
            path: '/naturalProfile/:id',
            component: NaturalProfile,
        },
        {
            name: 'naturalProfile.edit',
            path: '/naturalProfile/edit/:id',
            component: NaturalProfileForm,
        },
        {
            name: 'legalProfile',
            path: '/legalProfile/:id',
            component: LegalProfile,
        },
        {
            name: 'legalProfile.edit',
            path: '/legalProfile/edit/:id',
            component: LegalProfileForm,
        },

        // campaigns
        {
            name: 'campaign',
            path: '/campaigns/:id',
            component: Campaign,
        },
        {
            name: 'campaigns',
            path: '/campaigns',
            component: Campaigns,
        },
        {
            name: 'campaign.edit',
            path: '/campaigns/edit/:id',
            component: CampaignForm,
        },

        // contracts
        {
            name: 'contracts',
            path: '/contracts',
            component: Contracts,
        },
        {
            name: 'contract',
            path: '/contracts/:id',
            component: Contract,
        },
        {
            name: 'contract.edit',
            path: '/contracts/edit/:id',
            component: ContractForm,
        },
        {
            name: 'contract.create_for_asset',
            path: '/contracts/create/:assetUid',
            component: ContractForm,
        },
        {
            name: 'contract.create',
            path: '/contracts/create',
            component: ContractForm,
        },
        {
            name: 'contract.copy',
            path: '/contracts/copy/:copyUid',
            component: ContractForm,
        },

        // transactions
        {
            name: 'transactions',
            path: '/transactions',
            component: Transactions,
        },
        // emails
        {
            name: 'emails',
            path: '/emails',
            component: Emails,
        },
        {
            name: 'email',
            path: '/emails/:id',
            component: Emails,
        },
        // tasks
        {
            name: 'tasks',
            path: '/tasks',
            component: Tasks,
        },
        // download membership document
        {
            name: 'membership_documents.download',
            path: '/membership_documents/download/:membershipDocumentId',
            component: DownloadMembershipDocument,
        },
    ],
})

router.beforeEach(async (to, from, next) => {
    const userStore = useUserStore()
    const toast = useToastStore()
    const { t } = i18n.global

    if (!userStore.user) {
        await userStore.loadUser()
        if (to.fullPath.indexOf('#') !== -1) {
            to.fullPath = to.fullPath.slice(0, to.fullPath.indexOf('#'))
        }
    }

    if (
        (to.name === 'dashboard' && !userStore.can('view_dashboard')) ||
        (to.name === 'platforms' && !userStore.can('view_platforms')) ||
        (to.name === 'platform' && !userStore.can('view_platforms')) ||
        (to.name === 'membership_documents' &&
            !userStore.can('view_platforms')) ||
        (to.name === 'platform.create' && !userStore.can('create_platform')) ||
        (to.name === 'platform.edit' && !userStore.can('update_platform')) ||
        (to.name === 'assets' && !userStore.can('view_assets')) ||
        (to.name === 'asset' && !userStore.can('view_assets')) ||
        (to.name === 'asset.edit' && !userStore.can('update_asset')) ||
        (to.name === 'orders' && !userStore.can('view_orders')) ||
        (to.name === 'order' && !userStore.can('view_orders')) ||
        (to.name === 'order.edit' && !userStore.can('update_order')) ||
        (to.name === 'accounts' && !userStore.can('view_accounts')) ||
        (to.name === 'accountnatural' && !userStore.can('view_accounts')) ||
        (to.name === 'campaign' && !userStore.can('view_campaigns')) ||
        (to.name === 'campaigns' && !userStore.can('view_campaigns')) ||
        (to.name === 'campaign.edit' && !userStore.can('update_campaign')) ||
        (to.name === 'contracts' && !userStore.can('view_contracts')) ||
        (to.name === 'contract' && !userStore.can('view_contracts')) ||
        (to.name === 'contract.edit' && !userStore.can('update_contract')) ||
        (to.name === 'transactions' && !userStore.can('view_transactions')) ||
        (to.name === 'emails' && !userStore.can('view_email_audits')) ||
        (to.name === 'tasks' && !userStore.can('view_tasks'))
    ) {
        next(from)
        toast.error(
            t('toast_error_access_denied', {
                path: to.path,
            })
        )
    } else {
        next()
    }
})

export default router
