<template>
    <OpenBasketTable>
        <template #filters>
            <DropdownFilter
                :pin="pinFilters"
                @apply="applyFilters"
                @clear="clearFilters"
                @toggle-pin="togglePinFilters"
            >
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <SelectField
                            v-model="filtersFields.platform_name_exact"
                            v-model:options="filterPlatformOptions"
                            name="platform_name"
                            class="w-full"
                            :placeholder="$t('platform')"
                            :on-search="loadPlatformNameOptions"
                            :disabled="disableFilter('platform_name_exact')"
                        />
                    </label>
                </DropdownFilterItem>
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <TextField
                            v-model="filtersFields.asset_title"
                            name="asset_title"
                            :placeholder="$t('campaign')"
                            :disabled="disableFilter('asset_title')"
                        />
                    </label>
                </DropdownFilterItem>
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <SelectField
                            v-model="filtersFields.stage"
                            name="stage"
                            :options="mapEnumToArray(OpenBasketStep)"
                            :reduce="reduceEnumValue"
                            :placeholder="$t('basket_stage')"
                            :disabled="disableFilter('stage')"
                            @keydown.enter.prevent="handleEnterPress"
                        />
                    </label>
                </DropdownFilterItem>
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <Datepicker
                            v-model="filtersFields.created_at"
                            v-model:dateFrom="filtersFields.date_from"
                            v-model:dateTo="filtersFields.date_to"
                            placeholder="created_at"
                            name="filter_created_at"
                            datemode="range"
                            :disabled="disableFilter('created_at')"
                        />
                    </label>
                </DropdownFilterItem>
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <Datepicker
                            v-model="filtersFields.cancelled_at"
                            v-model:dateFrom="filtersFields.date_cancelled_from"
                            v-model:dateTo="filtersFields.date_cancelled_to"
                            placeholder="cancelled_at"
                            name="filter_cancelled_at"
                            datemode="range"
                            :disabled="disableFilter('cancelled_at')"
                        />
                    </label>
                </DropdownFilterItem>
            </DropdownFilter>
        </template>
        <template #menu>
            <ActionsMenu />
        </template>
    </OpenBasketTable>

    <ModalWrapper v-if="isModalOpen" />
</template>

<script setup lang="ts">
import {
    useActionsMenu,
    useEnum,
    useModal,
    useTable,
} from '@composables/common'
import PlatformNameCellRenderer from '@partials/platforms/PlatformNameCellRenderer.vue'
import {
    Datepicker,
    DropdownFilter,
    DropdownFilterItem,
    SelectField,
    TextField,
} from '@src/components'
import { OpenBasketStep } from '@src/enums'
import { OpenBasket, Order, Platform } from '@src/types'
import { exportOpenBaskets, getOpenBasket } from '@src/utils/api/orders'
import { getPlatforms } from '@src/utils/api/platforms'
import { handleEnterPress } from '@src/utils/helpers'
import { useToastStore } from '@src/utils/stores/toast'
import { useUserStore } from '@src/utils/stores/user'
import moment from 'moment'
import { onMounted, ref, shallowRef, toRefs } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

const props = withDefaults(
    defineProps<{
        initialFilters?: {
            platform_name_exact?: string | null
            asset_title?: string | null
            stage?: OpenBasketStep | null
            created_at?: string | null
        } | null
        createDisabledFields?: string[] | null
    }>(),
    {
        initialFilters: null,
        createInitialValues: null,
        createDisabledFields: null,
    }
)

const { initialFilters } = toRefs(props)

const { mapEnumToArray, reduceEnumValue } = useEnum()

const { ModalWrapper } = useModal()

const router = useRouter()
const toast = useToastStore()
const i18n = useI18n()
const userStore = useUserStore()
const { getEnumLabel } = useEnum()

const { ActionsMenu } = useActionsMenu(
    {
        id: 'orders-actions-menu',
        align: 'right',
    },
    [
        {
            label: 'export_orders_csv',
            icon: 'fa-cart-shopping',
            color: 'text-primary-accent-600',
            action: exportOpenBasketsCsv,
        },
    ]
)

const baskets = ref<OpenBasket[]>([])
const basketsCount = ref<number>(0)

const isModalOpen = ref(false)

const filterPlatformOptions = ref<string[]>([])

const disableFilter = (filter: string) => {
    if (initialFilters.value) {
        return (
            Object.keys(initialFilters.value).filter((item) => {
                return item === filter
            }).length > 0
        )
    }
    return false
}

const handleViewNaturalProfile = (rowData: OpenBasket) => {
    router.push({
        name: 'naturalProfile',
        params: { id: rowData.natural_profile_id },
    })
}

const {
    TableWrapper: OpenBasketTable,
    limit,
    sorting,
    page,
    filtersFields,
    filters,
    pinFilters,
    togglePinFilters,
    setFilters,
    applyFilters,
    clearFilters,
} = useTable({
    loadData: loadOpenBaskets,
    filters: {
        platform_name_exact: null,
        asset_title: null,
        stage: null,
        created_at: null,
        date_from: null,
        date_to: null,
        cancelled_at: null,
        date_cancelled_from: null,
        date_cancelled_to: null,
    },
    disableFilters: initialFilters.value
        ? Object.keys(initialFilters.value)
        : [],
    tableProps: {
        id: 'open-basket-table',
        label: 'open_baskets',
        help: 'help_open_basket_table',
        pagination: true,
        data: baskets,
        total: basketsCount,
        columns: [
            {
                label: 'platform_name',
                key: 'platform_name',
                cellRenderer: shallowRef(PlatformNameCellRenderer),
                sorting: true,
            },
            {
                label: 'campaign',
                key: 'asset_title',
                url: campaignUrl,
                sorting: true,
                cellRenderer: (props: { rowData: OpenBasket }) => {
                    return props.rowData.campaign?.asset_title as string
                },
            },
            {
                label: 'basket_stage',
                key: 'stage',
                sorting: true,
                cellRenderer: (props: { rowData: OpenBasket }) => {
                    return getEnumLabel(OpenBasketStep, props.rowData.stage)
                },
            },
            {
                label: 'created_at',
                key: 'created_at',
                sorting: true,
                cellRenderer: (props: { rowData: Order }) => {
                    return moment(props.rowData.created_at).format(
                        'DD.MM.yyyy HH:mm'
                    )
                },
            },
            {
                label: 'cancelled_at',
                key: 'cancelled_at',
                sorting: true,
                cellRenderer: (props: { rowData: OpenBasket }) => {
                    return props.rowData.cancelled_at
                        ? moment(props.rowData.cancelled_at).format(
                              'DD.MM.yyyy HH:mm'
                          )
                        : '-'
                },
            },
        ],
        actions: [
            {
                action: handleViewNaturalProfile,
                icon: 'fa-user',
                title: 'help_view_natural_profile',
                condition: (openBasket: OpenBasket) => {
                    return (
                        userStore.can('view_accounts') &&
                        !!openBasket.natural_profile_id
                    )
                },
            },
        ],
    },
})

async function loadPlatformNameOptions(search: string) {
    const initialPlatformsRequest = await getPlatforms({
        'filter[platform_name]': search,
    })
    filterPlatformOptions.value = initialPlatformsRequest.data.platforms.map(
        (platform: Platform) => {
            return platform.platform_name as string
        }
    )
}

onMounted(async () => {
    if (initialFilters.value) {
        filtersFields.value = {
            ...filtersFields.value,
            ...initialFilters.value,
        }
        setFilters()
    }
    loadOpenBaskets()
})

function campaignUrl(openBasket: OpenBasket) {
    return '/campaigns/' + openBasket.campaign?.asset_var_uid
}

async function loadOpenBaskets() {
    const openBasketRequest = await getOpenBasket(
        filters.value,
        sorting.value,
        page.value,
        limit.value
    )
    baskets.value = openBasketRequest.data.baskets
    basketsCount.value = openBasketRequest.data.baskets_count

    // const platformsRequest = await getPlatforms()
    // platforms.value = platformsRequest.data.platforms
}

async function exportOpenBasketsCsv() {
    await exportOpenBaskets(filters.value, sorting.value)
        .catch((error) => {
            throw error
        })
        .then(() => {
            toast.success(i18n.t('toast_success_export_csv'))
        })
}
</script>
