import { ProfileType } from '@src/enums'
import {
    Account,
    BankKYCDocument,
    CreateMoneyWithdrawal,
    ECSPDocument,
    KYCDocument,
    LegalProfile,
    MembershipDocument,
    NaturalProfile,
    TableSorting,
    Task,
    Transaction,
} from '@src/types'
import { api, ApiRes } from '@src/utils/api/client'
import { AxiosRequestConfig } from 'axios'

export interface AccountsResponse {
    success: string
    accounts: Account[]
    accounts_count: number
}

export interface AccountsResponseSingle {
    success: string
    account: Account
}

export interface NaturalProfilesResponse {
    success: string
    natural_profiles: NaturalProfile[]
    natural_profiles_count: number
}

export interface NaturalProfileResponse {
    success: string
    natural_profile: NaturalProfile
}

export interface LegalProfileResponse {
    success: string
    legal_profile: LegalProfile
}

export type LegalProfileResponseRework = LegalProfile[]

export interface AccountsDownloadResponse {
    message: string
    tasks: Task[]
}

// TODO remove?
export interface KYCDocumentsResponseOLD {
    kyc_documents: KYCDocument[]
}

type KYCDocumentsResponse = Array<KYCDocument>

type ECSPDocumentsResponse = Array<ECSPDocument>

type MembershipDocumentsResponse = Array<MembershipDocument>

export interface TransactionsResponse {
    success: string
    transactions: Transaction[]
    transactions_count: number
}

export const getAccounts = (
    filters?: object,
    page?: number,
    limit?: number,
    sorting?: TableSorting[]
): ApiRes<AccountsResponse> => {
    const config: AxiosRequestConfig = {}

    config.params = {}

    if (filters && Object.keys(filters).length) {
        config.params = filters
    }

    if (typeof page === 'number' && typeof limit === 'number') {
        config.params['range'] = page * limit - limit + '-' + page * limit
    }

    if (sorting && sorting.length) {
        config.params['sort'] = sorting
            .map((sort) => {
                return (sort.direction === 'desc' ? '-' : '') + sort.field
            })
            .join(',')
    }

    return api.get('v2/manage/accounts', config)
}

export const searchAccounts = (params: string): ApiRes<AccountsResponse> =>
    api.get('manage/accounts/' + params)

export const getAccount = (id: string): ApiRes<AccountsResponseSingle> =>
    api.get('manage/accounts/' + id)

export const createAccount = (account: Account): ApiRes<AccountResponse> =>
    api.post('manage/accounts', account)

export const updateAccount = (account: Account): ApiRes<AccountResponse> =>
    api.patch('manage/accounts/' + account.platform_name, account)

export const deleteAccounts = (accounts: string[]): ApiRes<string> =>
    api.post('manage/accounts', { accounts: accounts })

export const deleteAccount = (id: string): ApiRes<string> =>
    api.delete('manage/accounts/' + id)

export const deleteAccountNaturalGDPR = (slug: string): ApiRes<string> =>
    api.delete(`manage/natural-profiles/${slug}?full_gdpr_delete=1`)

export const deleteAccountLegal = (slug: string): ApiRes<string> =>
    api.delete('manage/legal-profiles/' + slug)

export const getKYCDocuments = (
    accountID: string
): Promise<ApiRes<KYCDocumentsResponse>> =>
    api.get('manage/kyc_documents/?account_id=' + accountID)

export const createKYCDocument = (
    kycDocument: KYCDocument | BankKYCDocument
): ApiRes<KYCDocumentsResponse> => api.post('manage/kyc_documents', kycDocument)

export const updateKYCDocument = (
    kycDocument: KYCDocument | BankKYCDocument
): ApiRes<KYCDocumentsResponse> => {
    const url = 'manage/kyc_documents/' + kycDocument.id
    return api.put(url, kycDocument)
}

export const getECSPDocuments = (
    accountID: string
): Promise<ApiRes<ECSPDocumentsResponse>> =>
    api.get('manage/ecsp_documents/?account_id=' + accountID)

export const createECSPDocument = (
    ecspDocument: ECSPDocument
): ApiRes<ECSPDocumentsResponse> =>
    api.post('manage/ecsp_documents', ecspDocument)

export const createMembershipDocument = (
    membershipDocument: MembershipDocument
): ApiRes<MembershipDocumentsResponse> =>
    api.post('manage/membership_documents', membershipDocument)

export const updateECSPDocument = (
    ecspDocument: ECSPDocument
): ApiRes<ECSPDocumentsResponse> => {
    const url = 'manage/ecsp_documents/' + ecspDocument.id
    return api.put(url, ecspDocument)
}

export const updateMembershipDocument = (
    membershipDocument: MembershipDocument
): ApiRes<MembershipDocumentsResponse> => {
    const url = 'manage/membership_documents/' + membershipDocument.id
    return api.put(url, membershipDocument)
}

export const updateComplianceProfile = (
    profile: NaturalProfile | LegalProfile,
    profileType: ProfileType
): ApiRes<ECSPDocumentsResponse> => {
    const url = `accounts/profiles/${profileType}/${
        profileType === ProfileType.NATURAL
            ? profile.natural_profile_uid
            : profile.legal_profile_uid
    }`
    console.log('PUT', profile)
    return api.put(String(url), profile)
}

export const aprroveMembershipDocument = (
    membershipDocumentId: string
): ApiRes<MembershipDocumentsResponse> => {
    const url = 'manage/approve_membership_documents/' + membershipDocumentId
    return api.get(url)
}

export const getTransactions = (
    filters?: object,
    sorting?: TableSorting[],
    page?: number,
    limit?: number
): ApiRes<TransactionsResponse> => {
    const config: AxiosRequestConfig = {}

    config.params = {}

    if (filters && Object.keys(filters).length) {
        config.params = filters
    }

    if (typeof page === 'number' && typeof limit === 'number') {
        config.params['range'] = page * limit - limit + '-' + page * limit
    }

    if (sorting && sorting.length) {
        config.params['sort'] = sorting
            .map((sort) => {
                return (sort.direction === 'desc' ? '-' : '') + sort.field
            })
            .join(',')
    }

    return api.get('v2/manage/transactions/', config)
}

export const createAccountForNaturalProfile = (
    naturalProfile: NaturalProfile
): ApiRes<NaturalProfileResponse> =>
    api.post('manage/natural-profiles', naturalProfile)

export const createLegalProfile = (
    legalProfile: LegalProfile
): ApiRes<LegalProfileResponse> =>
    api.post('manage/legal-profiles', legalProfile)

export const getNaturalProfiles = (
    id: string
): ApiRes<NaturalProfilesResponse> =>
    api.get('manage/accounts/' + id + '/natural_profiles')

export const getNaturalProfile = (id: string): ApiRes<NaturalProfileResponse> =>
    api.get('manage/natural-profiles/' + id)

export const getLegalProfile = (id: string): ApiRes<LegalProfileResponse> =>
    api.get('manage/legal-profiles/' + id)

export interface AccountResponse {
    success: string
    account: Account
}

export const getLegalProfilesFromNaturalProfile = (
    id: string
): ApiRes<LegalProfileResponseRework> =>
    api.get('manage/natural-profiles/' + id + '/legal-profiles')

export interface AccountResponse {
    success: string
    account: Account
}

export const updateNaturalProfile = (
    naturalProfile: NaturalProfile
): ApiRes<NaturalProfileResponse> =>
    api.patch(
        'manage/natural-profiles/' + naturalProfile.natural_profile_uid,
        naturalProfile
    )

export const updateLegalProfile = (
    legalProfile: LegalProfile
): ApiRes<LegalProfileResponse> =>
    api.patch(
        'manage/legal-profiles/' + legalProfile.legal_profile_uid,
        legalProfile
    )

export const createMoneyWithdrawal = (
    moneyWithdrawal: CreateMoneyWithdrawal
): ApiRes<AccountResponse> =>
    api.post('manage/money-withdrawals', moneyWithdrawal)

export const syncWallet = (walletId: string): ApiRes<string> =>
    api.post(`manage/wallets/${walletId}/sync`, {})

export const apiDownloadAccountsCsv = (
    filters: object
): ApiRes<AccountsDownloadResponse> => {
    const config: AxiosRequestConfig = {}

    config.params = {}

    if (filters && Object.keys(filters).length) {
        config.params = filters
    }

    config.params['async'] = 1

    return api.get('v2/manage/accounts/csv', config)
}

export const getNaturalProfilesOfLegalProfile = (
    legal_profile_uid: string
): ApiRes<NaturalProfilesResponse> => {
    return api.get(
        `manage/legal-profiles/${legal_profile_uid}/natural-profiles`
    )
}

export const resetExternalKYCProcess = (
    natural_profile_uid: string
): ApiRes<NaturalProfileResponse> => {
    return api.post(
        `v2/manage/natural-profile/${natural_profile_uid}/reset-kyc-process`
    )
}
