import { useTable } from '@composables/common'
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { InterestPaymentTransaction, TableSorting } from '@src/types'
import { formatNumericValues } from '@src/utils/helpers'
import moment from 'moment'
import { Ref, h, ref } from 'vue'

export function useOrderInterestPaymentTransactionsTable(
    interestPayments: Ref<InterestPaymentTransaction[]>,
    interestPaymentsCount: Ref<number>
) {
    const { TableWrapper: InterestPaymentTransactionsTable } = useTable({
        tableProps: {
            id: 'order_interest_payments_transactions',
            label: 'interest_payments',
            total: interestPaymentsCount,
            data: interestPayments,
            sorting: ref<TableSorting[]>([
                {
                    field: 'created_at',
                    direction: 'desc',
                },
            ]),
            columns: [
                {
                    label: 'date',
                    key: 'date',
                    cellRenderer: (props: {
                        rowData: InterestPaymentTransaction
                    }) => {
                        return h(
                            'span',
                            moment(props.rowData.date).format('DD.MM.yyyy')
                        )
                    },
                },
                {
                    label: 'current_loan_amount',
                    key: 'current_loan_amount',
                    cellRenderer: (props: {
                        rowData: InterestPaymentTransaction
                    }) => {
                        return formatNumericValues(
                            props.rowData.current_loan_amount as number
                        )
                    },
                },
                {
                    label: 'paid_total',
                    key: 'paid_total',
                    cellRenderer: (props: {
                        rowData: InterestPaymentTransaction
                    }) => {
                        return formatNumericValues(
                            props.rowData.paid_total as number
                        )
                    },
                },
                {
                    label: 'paid_interest',
                    key: 'paid_interest',
                    cellRenderer: (props: {
                        rowData: InterestPaymentTransaction
                    }) => {
                        return formatNumericValues(
                            props.rowData.paid_interest as number
                        )
                    },
                },
                {
                    label: 'paid_clearance',
                    key: 'paid_clearance',
                    cellRenderer: (props: {
                        rowData: InterestPaymentTransaction
                    }) => {
                        return formatNumericValues(
                            props.rowData.paid_clearance as number
                        )
                    },
                },
                {
                    label: 'paid_rest',
                    key: 'paid_rest',
                    cellRenderer: (props: {
                        rowData: InterestPaymentTransaction
                    }) => {
                        return formatNumericValues(
                            props.rowData.paid_rest as number
                        )
                    },
                },
                {
                    label: 'paid_bonus',
                    key: 'paid_bonus',
                    cellRenderer: (props: {
                        rowData: InterestPaymentTransaction
                    }) => {
                        return formatNumericValues(
                            props.rowData.paid_bonus as number
                        )
                    },
                },
                {
                    label: 'interest',
                    key: 'interest',
                    cellRenderer: (props: {
                        rowData: InterestPaymentTransaction
                    }) => {
                        return formatNumericValues(
                            props.rowData.interest as number
                        )
                    },
                },
                {
                    label: 'clearance',
                    key: 'clearance',
                    cellRenderer: (props: {
                        rowData: InterestPaymentTransaction
                    }) => {
                        return formatNumericValues(
                            props.rowData.clearance as number
                        )
                    },
                },
                {
                    label: 'new_basis',
                    key: 'new_basis',
                    cellRenderer: (props: {
                        rowData: InterestPaymentTransaction
                    }) => {
                        return formatNumericValues(
                            props.rowData.new_basis as number
                        )
                    },
                },
                {
                    label: 'cumulative_rest',
                    key: 'cumulative_rest',
                    cellRenderer: (props: {
                        rowData: InterestPaymentTransaction
                    }) => {
                        return formatNumericValues(
                            props.rowData.cumulative_rest as number
                        )
                    },
                },
                {
                    label: 'current_rest',
                    key: 'current_rest',
                    cellRenderer: (props: {
                        rowData: InterestPaymentTransaction
                    }) => {
                        return formatNumericValues(
                            props.rowData.current_rest as number
                        )
                    },
                },
                {
                    label: 'status',
                    key: 'status',
                    cellRenderer: (props: {
                        rowData: InterestPaymentTransaction
                    }) => {
                        if (props.rowData.transaction_failed === true) {
                            return h(FontAwesomeIcon, {
                                icon: faCircleCheck,
                                faCircleXmark,
                                class: 'text-danger-400 text-xl',
                            })
                        } else {
                            return h(FontAwesomeIcon, {
                                icon: faCircleCheck,
                                class: 'text-success-400 text-xl',
                            })
                        }
                    },
                },
            ],
        },
    })

    return {
        InterestPaymentTransactionsTable,
    }
}
