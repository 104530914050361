/* eslint-disable quote-props */
import { ModalButtonStyles, OpenModal, useTable } from '@composables/common'
import UpdateOrCreateAssetDocumentForm from '@partials/assets/UpdateOrCreateAssetDocumentForm.vue'
import { NameAndId } from '@src/components'
import { AssetDocumentType } from '@src/enums'
import { AssetDocument } from '@src/types'
import { uploadPublic } from '@src/utils/api/s3'
import { useUserStore } from '@src/utils/stores/user'
import { downloadFile } from '@utils/helpers'
import { Ref, computed, h, ref } from 'vue'
import { useI18n } from 'vue-i18n'

export function useAssetDocumentsTable(
    documents: Ref<AssetDocument[]>,
    type: AssetDocumentType,
    update: (successMessage?: string | null) => Promise<void>,
    openModal: OpenModal,
    isModalOpen: Ref<boolean>
) {
    const userStore = useUserStore()
    const i18n = useI18n()

    const total = computed(() => {
        return documents.value.length
    })

    const {
        TableWrapper: AssetDocumentsTableNew,
        selected: selectedAssetDocumentsIds,
    } = useTable({
        tableProps: {
            id: 'asset_documents',
            label: `asset_documents_${type}`,
            help: `help_asset_documents_table_${type}`,
            data: documents,
            total: total,
            columns: [
                {
                    label: 'id',
                    key: 'id',
                    select: true,
                },
                {
                    label: 'id',
                    key: 'id',
                    url: assetDocumentsTableUrl,
                    condition: userStore.can('see_id_columns'),
                    copy: true,
                },
                {
                    label: 'name',
                    key: 'name',
                    copy: true,
                },
                {
                    label: 'description',
                    key: 'description',
                    cellRenderer: (props: { rowData: AssetDocument }) => {
                        return h(
                            'p',
                            {
                                class: 'max-w-sm truncate',
                            },
                            [props.rowData.description]
                        )
                    },
                },
            ],
            actions: [
                {
                    action: handleDownloadDocument,
                    icon: 'fa-solid fa-download',
                    title: 'download',
                    condition: Boolean(
                        parseInt(import.meta.env.VITE_IS_DOWNLOAD_FILE_ENABLED)
                    ),
                },
                {
                    action: handleViewAssetDocument,
                    icon: 'fa-eye',
                    title: 'view',
                },
                {
                    action: handleUpdateOrCreateAssetDocument,
                    icon: 'fa-solid fa-pen',
                    title: 'edit',
                    condition: userStore.can('update_asset'),
                },
                {
                    action: handleDeleteAssetDocument,
                    icon: 'fas fa-trash-alt',
                    title: 'delete',
                    color: 'text-danger-500',
                    condition: userStore.can('update_asset'),
                },
            ],
        },
    })

    function assetDocumentsTableUrl(assetDocument: AssetDocument) {
        return assetDocument.url as string
    }

    function handleDownloadDocument(assetDocument: AssetDocument) {
        const url = assetDocument.url
        if (url) {
            downloadFile(url)
        }
    }

    function handleViewAssetDocument(assetDocument: AssetDocument) {
        const url = assetDocument.url
        if (url) {
            window.open(url)
        }
    }

    function handleUpdateOrCreateAssetDocument(
        assetDocument: AssetDocument = new AssetDocument()
    ) {
        const editedAssetDocument = ref(new AssetDocument())
        editedAssetDocument.value = { ...assetDocument }
        editedAssetDocument.value.type = type
        const file = ref<File | null>(null)
        isModalOpen.value = true

        const disableCreate = computed(() => {
            if (editedAssetDocument.value.id) {
                return !(
                    editedAssetDocument.value.name &&
                    typeof editedAssetDocument.value.type === 'number'
                )
            }
            return !(
                file.value &&
                editedAssetDocument.value.name &&
                typeof editedAssetDocument.value.type === 'number'
            )
        })
        openModal(
            {
                id: 'edit_asset_document',
                title: editedAssetDocument.value.id
                    ? 'modal_title_update_asset_document'
                    : 'modal_title_create_asset_document',
                open: isModalOpen,
                onCancel: () => (isModalOpen.value = false),
                onSubmit: () =>
                    saveAssetDocument(
                        editedAssetDocument.value,
                        file.value as File
                    ),
            },
            () =>
                h(UpdateOrCreateAssetDocumentForm, {
                    modelValue: editedAssetDocument,
                    type: type,
                    'onUpdate:modelValue': (
                        value: unknown,
                        files: Ref<FileList>
                    ) => {
                        file.value = files.value?.item(0)
                    },
                }),
            {
                submitButtonText: editedAssetDocument.value.id
                    ? 'update'
                    : 'create',
                styles: {
                    submitButton: ModalButtonStyles['BLUE'],
                },
                submitButtonDisabled: disableCreate,
            }
        )
    }

    function handleDeleteAssetDocument(assetDocument: AssetDocument) {
        isModalOpen.value = true
        openModal(
            {
                id: 'delete_asset_document',
                title: 'modal_title_delete_asset_document',
                open: isModalOpen,
                onCancel: () => (isModalOpen.value = false),
                onSubmit: () => deleteAssetDocument(assetDocument),
            },
            () =>
                h(NameAndId, {
                    idKey: 'id',
                    nameKey: 'name',
                    modelValue: [assetDocument],
                }),
            {
                submitButtonText: 'delete',
                styles: {
                    submitButton: ModalButtonStyles['RED'],
                },
            }
        )
    }

    function handleDeleteAssetDocuments() {
        isModalOpen.value = true
        openModal(
            {
                id: 'delete_asset_documents',
                title: 'modal_title_delete_asset_documents',
                open: isModalOpen,
                onCancel: () => (isModalOpen.value = false),
                onSubmit: () =>
                    deleteAssetDocuments(selectedAssetDocuments.value),
            },
            () =>
                h(NameAndId, {
                    idKey: 'id',
                    nameKey: 'name',
                    modelValue: selectedAssetDocuments.value,
                }),
            {
                submitButtonText: 'delete',
                styles: {
                    submitButton: ModalButtonStyles['RED'],
                },
            }
        )
    }

    function deleteAssetDocument(
        assetDocumentToDelete: AssetDocument,
        submit = true
    ) {
        const assetDocumentsTemp = [...documents.value]
        documents.value.splice(
            documents.value.findIndex(
                (assetDocument) => assetDocumentToDelete.id == assetDocument.id
            ),
            1
        )
        if (submit) {
            update(
                i18n.t('toast_success_asset_document_deleted', {
                    assetDocument: assetDocumentToDelete.name,
                })
            )
                .catch((error) => {
                    documents.value = assetDocumentsTemp
                    throw error
                })
                .then(() => {
                    isModalOpen.value = false
                })
        }
    }

    function deleteAssetDocuments(documentsToDelete: AssetDocument[]) {
        const assetDocumentsTemp = [...documents.value]

        documentsToDelete.forEach((assetDocument) => {
            deleteAssetDocument(assetDocument, false)
        })

        update(
            i18n.t('toast_success_asset_documents_deleted', {
                documents: documentsToDelete
                    .map((assetDocument) => assetDocument.name)
                    .join(', '),
            })
        )
            .catch((error) => {
                documents.value = assetDocumentsTemp
                throw error
            })
            .then(() => {
                selectedAssetDocumentsIds.value = []
                isModalOpen.value = false
            })
    }

    async function saveAssetDocument(assetDocument: AssetDocument, file: File) {
        const assetDocumentsTemp = [...documents.value]

        if (file) {
            const uploadPublicResponse = await uploadPublic(file)
            assetDocument.upload_key = uploadPublicResponse.data.key
            assetDocument.url = uploadPublicResponse.data.temp_storage_url
        }

        if (assetDocument.id) {
            documents.value[
                documents.value.findIndex(
                    (document: AssetDocument) => assetDocument.id == document.id
                )
            ] = assetDocument
        } else {
            documents.value.push(assetDocument)
        }

        let toast = 'toast_success_asset_document_updated'
        if (!assetDocument.id) {
            toast = 'toast_success_asset_document_created'
        }
        update(
            i18n.t(toast, {
                document: assetDocument.name,
            })
        )
            .catch((error) => {
                documents.value = assetDocumentsTemp
                throw error
            })
            .then(() => {
                isModalOpen.value = false
            })
    }

    const selectedAssetDocuments = computed(() => {
        return documents.value.filter(
            (item: AssetDocument) =>
                item.id && selectedAssetDocumentsIds.value.includes(item.id)
        )
    })

    return {
        AssetDocumentsTableNew,
        selectedAssetDocumentsIds,
        handleUpdateOrCreateAssetDocument,
        handleDeleteAssetDocuments,
    }
}
