<template>
    <div>
        <SelectField
            v-model="status"
            name="status"
            label="status"
            help="help_bulk_change_order_status"
            :options="mapEnumToArray(OrderStatus)"
            :reduce="reduceEnumValue"
        />
    </div>
    <h3>{{ $t('orders') }}</h3>
    <ul class="list-disc pl-8">
        <li
            v-for="order in orders"
            :key="(order.order_uid as string)"
        >
            {{ order.order_uid }}
        </li>
    </ul>
</template>

<script setup lang="ts">
import { useEnum } from '@composables/common'
import { SelectField } from '@src/components'
import { OrderStatus } from '@src/enums'
import { Order } from '@src/types'
import { computed, Ref, toRefs } from 'vue'

const props = defineProps<{
    modelValue: Ref<number>
    orders: Ref<Order[]>
}>()

const emit = defineEmits(['update:modelValue'])
const { orders, modelValue } = toRefs(props)

const { mapEnumToArray, reduceEnumValue } = useEnum()

const status = computed({
    get() {
        return modelValue.value
    },
    set(value: number) {
        modelValue.value = value
        emit('update:modelValue')
    },
})
</script>

<style scoped></style>
