<template>
    <div>
        <TextField
            v-model="modelValue.meta_title"
            name="campaign_content_block_title"
            label="campaign_content_block_title"
            help="help_campaign_content_block_title"
            :required="true"
        />
        <EditorField
            v-model="modelValue.meta_value"
            v-model:placeholders="placeholders"
            name="campaign_content_block_content"
            label="campaign_content_block_content"
            help="help_campaign_content_block_content"
            :required="true"
        />
    </div>
</template>

<script setup lang="ts">
import { AssetsMeta, Campaign, CampaignImage, Placeholder } from '@src/types'

import { EditorField, TextField } from '@src/components'
import { Ref, computed, toRefs, watch } from 'vue'

const props = defineProps<{
    modelValue: Ref<AssetsMeta>
    campaign: Ref<Campaign>
}>()

const { modelValue, campaign } = toRefs(props)

const emit = defineEmits(['update:modelValue'])

const placeholders = computed((): Placeholder[] => {
    return campaign.value.content_images.map((image: CampaignImage) => {
        return {
            key: image.name,
            value: `<img src="${image.thumb_image_url}" alt="${image.name}" />`,
        } as Placeholder
    })
})

watch(modelValue, (value) => {
    emit('update:modelValue', value)
})
</script>

<style lang="scss" scoped></style>
