<template>
    <div>
        <FileUploadButton
            title="button_id_membership_document_upload"
            accept=".pdf"
            mode="MembershipDocument"
            :deletable="false"
            @submit="onUpload"
        />
        <div v-if="modelValue.key">
            <SelectField
                v-model="modelValue.status"
                name="membership_document_status"
                label="membership_document_status"
                help="help_membership_document_status"
                :options="mapEnumToArray(MembershipDocumentStatusType)"
                :reduce="reduceEnumValue"
                :required="true"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import { useEnum } from '@composables/common'
import FileUploadButton from '@partials/actions/FileUploadButton.vue'
import { SelectField } from '@src/components'
import { MembershipDocumentStatusType } from '@src/enums'
import { MembershipDocument } from '@src/types'
import { UploadFileResponse } from '@src/utils/api/s3'
import { Ref, toRefs, watch } from 'vue'

const props = defineProps<{
    modelValue: Ref<MembershipDocument>
}>()

const { modelValue } = toRefs(props)

const emit = defineEmits(['update:modelValue'])

const { mapEnumToArray, reduceEnumValue } = useEnum()

watch(
    modelValue,
    (value) => {
        emit('update:modelValue', value)
    },
    { deep: true }
)

function onUpload(response: UploadFileResponse) {
    modelValue.value = response
    modelValue.value.status = MembershipDocumentStatusType.PENDING
    modelValue.value.upload_key = response.key
}
</script>

<style lang="scss" scoped></style>
