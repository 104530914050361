<template>
    <div>
        <p class="font-bold">{{ $t('type') }}:</p>
        <p>{{ $t(templateName) }}</p>
        <p class="mt-2 font-bold">{{ $t('language') }}</p>
        <p>{{ language }}</p>
        <p class="mt-2 font-bold">{{ $t('subject') }}</p>
        <p>{{ modelValue.subject }}</p>
        <p class="mt-2 font-bold">{{ $t('content') }}:</p>
        <div v-html-safe="modelValue.template_content" />
    </div>
</template>
<script setup lang="ts">
import { PlatformEmailTemplate, PlatformEmailTemplateOption } from '@src/types'
import { getEmailTemplatesList } from '@src/utils/api/platforms'
import { ref, computed, onMounted, toRefs } from 'vue'
import { useLanguageStore } from '@src/utils/stores/languages'
import { useI18n } from 'vue-i18n'

const i18n = useI18n()
const props = defineProps<{
    modelValue: PlatformEmailTemplate
}>()

const { modelValue } = toRefs(props)

const languageStore = useLanguageStore()
const languages = ref<{ label: string; value: string | null }[]>([])
const emailTemplates = ref<PlatformEmailTemplateOption[]>([])

const templateName = computed(() => {
    return (
        emailTemplates.value.find(
            (item) => item.value === modelValue.value.template_name
        )?.name ?? ''
    )
})

const language = computed(() => {
    return languages.value.find(
        (item) => item.value === modelValue.value.language
    )?.label
})

onMounted(async () => {
    languages.value = languageStore.languages.map((lang) => {
        return {
            label: i18n.t(lang.value) || lang.value,
            value: lang.value,
        }
    })

    const emailTemplatesResponse = await getEmailTemplatesList()
    emailTemplates.value = emailTemplatesResponse.data.email_templates
})
</script>
