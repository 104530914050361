<!-- GridContainer.vue -->
<template>
    <div
        :class="{
            'grid grid-cols-1 gap-x-8 md:grid-cols-2':
                slots.default && slots.sidebar,
        }"
    >
        <div>
            <slot />
        </div>
        <div v-if="slots.default && slots.sidebar">
            <slot name="sidebar" />
        </div>
    </div>
</template>

<script setup lang="ts">
import { useSlots } from 'vue'

const slots = useSlots()
</script>
