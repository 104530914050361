import { useTable } from '@composables/common'
import { ReportingDocument } from '@src/types'
import { useUserStore } from '@src/utils/stores/user'
import { Ref, computed } from 'vue'

export function useOrderReportingDocumentsTable(
    orderReportingDocuments: Ref<ReportingDocument[]>
) {
    const userStore = useUserStore()

    const total = computed(() => {
        return orderReportingDocuments.value.length
    })
    const { TableWrapper: OrderReportingDocumentsTable } = useTable({
        tableProps: {
            data: orderReportingDocuments,
            total: total,
            label: 'reporting_documents',
            id: 'reporting_documents_table',
            help: 'help_order_reporting_documents_table',
            columns: [
                {
                    label: 'id',
                    key: 'id',
                    url: documentURL,
                    targetBlank: true,
                    sorting: false,
                    condition: userStore.can('see_id_columns'),
                    copy: true,
                },
                {
                    label: 'name',
                    key: 'name',
                    url: documentURL,
                    targetBlank: true,
                    sorting: false,
                    copy: true,
                },
            ],
            actions: [
                {
                    action: handleOpenInNewTab,
                    icon: 'fa-eye',
                    title: 'view_document',
                },
            ],
        },
    })

    function documentURL(document: ReportingDocument) {
        return document.url
    }

    function handleOpenInNewTab(rowData: ReportingDocument) {
        const url = rowData.url
        window.open(url, '_blank')
    }

    return {
        OrderReportingDocumentsTable,
    }
}
