<template>
    <div>
        <div class="mb-2 flex items-start justify-between">
            <span class="font-bold">{{ $t('document_id') }}:</span>
            <span>{{ modelValue.id }}</span>
        </div>
        <div class="mb-2 flex items-start justify-between">
            <span class="font-bold">{{ $t('document_type') }}:</span>
            <span>{{ getEnumLabel(KYCDocumentType, modelValue.type) }}</span>
        </div>
        <div class="mb-2 flex items-start justify-between">
            <span class="font-bold">{{ $t('manual_kyc') }}:</span>
            <span>{{ modelValue.is_manual ? $t('yes') : $t('no') }}</span>
        </div>
        <div class="flex items-start justify-between">
            <span class="font-bold">{{ $t('file') }}:</span>
            <a
                :href="(modelValue.url as string)"
                target="_blank"
                class="text-blue-500"
            >
                {{ $t('open_doc_in_new_tab') }}
            </a>
        </div>
    </div>

    <div v-if="modelValue.bankAccount">
        <hr class="my-4" />
        <div class="mb-2 font-bold">{{ $t('account_bank_account') }}</div>

        <div class="mb-2 flex items-start justify-between">
            <span class="font-bold">{{ $t('id') }}:</span>
            <span>{{ modelValue.bankAccount.id }}</span>
        </div>
        <div class="mb-2 flex items-start justify-between">
            <span class="font-bold"
                >{{ $t('help_kyc_document_account_holder') }}:</span
            >
            <span>{{ modelValue.bankAccount.holder }}</span>
        </div>
        <div class="mb-2 flex items-start justify-between">
            <span class="font-bold">{{ $t('iban') }}:</span>
            <span>{{
                modelValue.bankAccount.iban &&
                everyFourSpace(modelValue.bankAccount.iban)
            }}</span>
        </div>
        <div class="mb-2 flex items-start justify-between">
            <span class="font-bold">{{ $t('status') }}:</span>
            <span>{{ modelValue.bankAccount.status }}</span>
        </div>
        <div class="mb-2 flex items-start justify-between">
            <span class="font-bold">{{ $t('is_main_bank_account') }}:</span>
            <span>{{
                modelValue.bankAccount.is_main ? $t('yes') : $t('no')
            }}</span>
        </div>

        <hr class="my-4" />
    </div>

    <div>
        <Datepicker
            v-model="modelValue.valid_to"
            label="valid_to"
            name="valid_to"
            placeholder="valid_to"
            help="help_valid_to"
            :required="true"
        />
    </div>
    <div>
        <SelectField
            v-model="modelValue.verification_status"
            name="verification_status"
            label="verification_status"
            help="help_verification_status"
            :options="mapEnumToArray(KycDocumentStatus)"
            :reduce="reduceEnumValue"
            :required="true"
        />
    </div>
    <div>
        <TextField
            v-model="modelValue.verification_comment"
            label="comment"
            name="verification_comment"
            :rows="4"
            help="help_comment"
        />
    </div>
</template>
<script setup lang="ts">
import { useEnum, useMask } from '@composables/common'
import { Datepicker, SelectField, TextField } from '@src/components'
import { KYCDocumentType, KycDocumentStatus } from '@src/enums'
import { BankKYCDocument, KYCDocument } from '@src/types'
import { Ref, toRefs } from 'vue'

const props = defineProps<{ modelValue: Ref<KYCDocument | BankKYCDocument> }>()

const { modelValue } = toRefs(props)
const { mapEnumToArray, reduceEnumValue, getEnumLabel } = useEnum()
const { everyFourSpace } = useMask()
</script>
