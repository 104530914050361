import { CampaignStatus } from '@src/enums'
import { Asset, ImageInterface, PaymentMethods } from '@src/types'

export class Campaign {
    asset_var_uid: string | null = null
    asset_uid: string | null = null
    platform_name: string | null = null
    language: string | null = null
    country: string | null = null
    funding_start: string | number | Date | null = null
    funding_end: string | number | Date | null = null
    asset_title: string | null = null
    asset_asset_title: string | null = null
    asset_subtitle: string | null = null
    asset_summary: string | null = null
    asset_excerpt: string | null = null
    asset_description: string | null = null
    asset_needs_contract_selection = false
    investment_summary: string | null = null
    thumb_image: ImageInterface | null = null
    hero_image: ImageInterface | null = null
    content_images: ImageInterface[] = []
    asset_video: string | null = null
    status: CampaignStatus = CampaignStatus.DRAFT
    broker: Broker | null = null
    funding_stats_visible = false
    regulatory_requirements: number | null = 0
    badge: number | null = null
    asset_var_password: string | null = null
    agio_disagio = 0
    agio_disagio_rate: number | null = null
    agio_disagio_type = 0
    agio_disagio_title: string | null = null
    agio_disagio_disclaimer: string | null = null
    agio_disagio_info_text: string | null = null
    savings_plan = false
    savings_plan_description: string | null = null
    payment_methods: PaymentMethods = new PaymentMethods()
    voucher_code: string | null = null
    voucher_rate: number | null = null
    additional_fields: CampaignAdditionalFields | null = null
    additional_fields_enabled = false
    external_project_url: string | null = null
    show_amount_slider = false
    assets_incentives: AssetsIncentive[] = []
    assets_meta: AssetsMeta[] = []
    team_members: TeamMember[] = []
    created_on: string | number | Date | null = null
    updated_at: string | number | Date | null = null
    asset: Asset | null = null
}

export class DuplicatedCampaign {
    asset_title: string | null = null
    platform_name: string | null = null
}

export class Broker {
    broker_status = false
    broker_disclaimer: string | null = null
    broker_field_required = false
}

export class AssetsIncentive {
    asset_inc_uid?: string | null = null
    incentives_amount: number | null = null
    incentives_limit = 0
    incentives_title: string | null = null
    incentives_description: string | null = null
    position = 0
    deleted = false
    migrated = false
}

export class AssetsMeta {
    asset_meta_uid?: string | null = null
    meta_title: string | null = null
    meta_value: string | null = null
    placing = 0
}

export class TeamMember {
    id?: string | null = null
    name: string | null = null
    title: string | null = null
    picture: ImageInterface | null = null
    bio: string | null = null
    position = 0
}

export class CampaignAdditionalFields {
    depot_owner: { required: boolean } = { required: false }
    depot_number: { required: boolean } = { required: false }
    depot_bank: { required: boolean } = { required: false }
    depot_bic: { required: boolean } = { required: false }
}
