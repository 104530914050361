export enum ToastNotificationType {
    SUCCESS = 'SUCCESS',
    WARNING = 'WARNING',
    ERROR = 'ERROR',
}

export interface ToastNotification {
    type: ToastNotificationType
    message: string
}
