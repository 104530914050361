import { useEnum } from '@composables/common'
import { userPermissions } from '@src/const'
import { PrimaryUserRoles } from '@src/enums'
import { User } from '@src/types'
import { getAccountUrl, getKeycloakUser, logOut } from '@src/utils/auth'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useUserStore = defineStore('user', () => {
    const user = ref<User | null>(null)

    const { mapEnumToArray } = useEnum()

    async function loadUser() {
        const keycloakUser = await getKeycloakUser()
        if (keycloakUser) {
            user.value = {
                userId: keycloakUser.sub as string,
                name: keycloakUser.name,
                email: keycloakUser.email,
                roles: [
                    ...(keycloakUser.realm_access?.roles as string[]),
                    'read_only',
                ],
                primaryRole: getUserPrimaryUserRole(
                    keycloakUser.realm_access?.roles as string[]
                ),
                accountUrl: getAccountUrl(),
            }
        }
    }

    function getUserPrimaryUserRole(roles: string[]): string {
        return mapEnumToArray(PrimaryUserRoles)
            .map((item) => item.value as string)
            .filter((role: string) => roles.includes(role))[0]
    }

    function logOutUser() {
        logOut()
    }

    function can(permission: string): boolean {
        return (
            userPermissions[permission].filter((allowedRole: string) =>
                user.value?.roles?.includes(allowedRole)
            ).length > 0
        )
    }

    function is(role: string): boolean {
        return !!(user.value?.roles && user.value.roles.includes(role))
    }

    return {
        user,
        loadUser,
        logOutUser,
        can,
        is,
    }
})
