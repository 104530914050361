<template>
    <li v-if="showEntry">
        <a
            class="flex px-3 py-1 text-sm font-medium"
            href="#0"
            @click.prevent.stop="action()"
            ><div>
                <fa
                    :icon="icon"
                    class="mr-2"
                    :class="color"
                />
                <span class="text-gray-600 hover:text-gray-800">{{
                    $t(label)
                }}</span>
            </div></a
        >
    </li>
</template>
<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps<{
    label: string
    icon: string
    color: string
    action: () => void
    condition?: () => boolean
}>()

const showEntry = computed(() => {
    if (typeof props.condition !== 'function') {
        return true
    }
    return props.condition()
})
</script>
