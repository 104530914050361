<template>
    <div
        v-if="!pin"
        class="relative inline-flex"
    >
        <button
            ref="trigger"
            class="btn border-gray-200 bg-white text-gray-500 hover:border-gray-300 hover:text-gray-600"
            aria-haspopup="true"
            :aria-expanded="dropdownOpen"
            @click.prevent="openDropdown"
        >
            <span class="sr-only">{{ $t('filters') }}</span>
            <svg
                class="h-4 w-4 fill-current"
                viewBox="0 0 16 16"
            >
                <path
                    d="M9 15H7a1 1 0 010-2h2a1 1 0 010 2zM11 11H5a1 1 0 010-2h6a1 1 0 010 2zM13 7H3a1 1 0 010-2h10a1 1 0 010 2zM15 3H1a1 1 0 010-2h14a1 1 0 010 2z"
                />
            </svg>
        </button>
        <transition
            enter-active-class="transition ease-out duration-200 transform"
            enter-from-class="opacity-0 -translate-y-2"
            enter-to-class="opacity-100 translate-y-0"
            leave-active-class="transition ease-out duration-200"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div
                v-show="dropdownOpen"
                class="min-w-56 absolute left-0 top-full left-auto right-0 z-10 mt-1 origin-top-right overflow-visible rounded border border-gray-200 bg-white pt-1.5 shadow-lg"
                @keydown.enter="applyFilters"
            >
                <div ref="dropdown">
                    <div
                        class="flex items-center justify-between px-4 pt-1.5 pb-2 text-xs font-semibold uppercase text-gray-400"
                    >
                        {{ $t('filters') }}
                        <button
                            class="btn rounded border-none p-0 text-gray-300"
                            :class="{
                                'text-gray-500': pin,
                            }"
                            @click="togglePin"
                        >
                            <fa icon="fa-solid fa-thumbtack" />
                        </button>
                    </div>
                    <ul class="mb-4">
                        <slot />
                    </ul>
                    <div class="border-t border-gray-200 bg-gray-50 py-2 px-3">
                        <ul class="flex items-center justify-between gap-2">
                            <li>
                                <button
                                    class="btn-xs border-gray-200 bg-white text-gray-500 hover:border-gray-300 hover:text-gray-600"
                                    @click="clearFilters"
                                >
                                    {{ $t('clear') }}
                                </button>
                            </li>
                            <li>
                                <button
                                    class="btn-xs bg-primary-additional-500 hover:bg-primary-additional-600 text-white"
                                    @click="applyFilters"
                                    @focusout="onFocusout"
                                >
                                    {{ $t('apply') }}
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </transition>
    </div>
    <div
        v-else
        class="mt-4 w-full rounded border border-gray-200"
        @keydown.enter="applyFilters"
    >
        <div
            class="flex w-full items-center justify-between px-4 pt-1.5 text-xs font-semibold uppercase text-gray-400"
        >
            {{ $t('filters') }}
            <button
                class="btn rounded border-none p-0 text-gray-300"
                :class="{
                    'text-gray-500': pin,
                }"
                @click="togglePin"
            >
                <fa icon="fa-solid fa-thumbtack" />
            </button>
        </div>
        <div
            class="mt-2 mb-4 flex w-full flex-col px-2 lg:flex-row lg:items-center lg:justify-between"
        >
            <ul class="flex w-full flex-wrap">
                <slot />
            </ul>
        </div>
        <div
            class="mt-2 flex flex-col justify-end gap-2 border-t border-gray-200 bg-gray-50 px-3 py-2 md:flex-row"
        >
            <button
                class="btn-xs w-60 border-gray-200 bg-white py-1 text-gray-500 hover:border-gray-300 hover:text-gray-600 md:w-40"
                @click="clearFilters"
            >
                {{ $t('clear') }}
            </button>

            <button
                class="btn-xs bg-primary-additional-500 hover:bg-primary-additional-600 w-60 py-1 text-white md:w-40"
                @click="applyFilters"
                @focusout="onFocusout"
            >
                {{ $t('apply') }}
            </button>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useVModels } from '@vueuse/core'
import { ref, onMounted, onUnmounted } from 'vue'

const props = withDefaults(
    defineProps<{
        pin?: boolean
    }>(),
    {
        pin: false,
    }
)

const emit = defineEmits(['apply', 'clear', 'togglePin'])
const { pin } = useVModels(props, emit)

const dropdownOpen = ref(false)
const trigger = ref<HTMLElement | null>(null)
const dropdown = ref<HTMLElement | null>(null)

function openDropdown() {
    if (!pin.value) {
        dropdownOpen.value = !dropdownOpen.value
    }
}

// close on click outside
const clickHandler = (event: MouseEvent) => {
    const target: EventTarget | null = event.target
    if (
        pin.value ||
        (target &&
            (!dropdownOpen.value ||
                dropdown.value?.contains(target as Node) ||
                trigger.value?.contains(target as Node)))
    ) {
        return
    }
    dropdownOpen.value = false
}

// close if the esc key is pressed
const keyHandler = (event: KeyboardEvent) => {
    if (pin.value || !dropdownOpen.value || event.code !== 'Escape') return
    dropdownOpen.value = false
}

function onFocusout() {
    if (!pin.value) {
        dropdownOpen.value = false
    }
}

function togglePin() {
    emit('togglePin')
}

onMounted(() => {
    if (pin.value) {
        dropdownOpen.value = true
    }
    document.addEventListener('click', clickHandler)
    document.addEventListener('keydown', keyHandler)
})

onUnmounted(() => {
    document.removeEventListener('click', clickHandler)
    document.removeEventListener('keydown', keyHandler)
})

function applyFilters(): void {
    if (!pin.value) {
        dropdownOpen.value = false
    }
    emit('apply')
}

function clearFilters(): void {
    if (!pin.value) {
        dropdownOpen.value = false
    }

    emit('clear')
}
</script>
