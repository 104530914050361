<template>
    <div>
        {{ country }}
    </div>
</template>
<script setup lang="ts">
import { i18n } from '@src/utils/lang'
import { useCountryStore } from '@src/utils/stores/countries'
import { onMounted, ref, watch } from 'vue'

const countryStore = useCountryStore()

const props = defineProps<{
    rowData: Record<string, unknown>
}>()

const country = ref<string | null>(null)

async function translateCountry() {
    return (country.value = await countryStore.translate(
        (props.rowData.country || props.rowData.nationality) as string
    ))
}

watch(i18n.global.locale, async () => {
    return await translateCountry()
})

onMounted(async () => {
    return await translateCountry()
})
</script>
