<template>
    <div
        :key="refreshKey"
        class="my-4"
    >
        <div class="flex items-center justify-between">
            <label
                class="mb-1 block text-sm font-medium"
                :for="name"
                >{{ $t(label) }}
                <span
                    v-if="required === true"
                    class="text-danger-500"
                    >*</span
                ></label
            >
            <Tooltip
                class="ml-2"
                bg="dark"
                size="md"
                position="left"
            >
                <div class="text-sm text-gray-200">{{ $t(help) }}</div>
            </Tooltip>
        </div>

        <TinyMCE
            :id="name"
            v-model="modelValue"
            v-model:placeholders="placeholders"
            :name="name"
            class="form-input w-full"
            type="text"
        />
        <div
            v-if="bottomHelpText"
            class="mt-2 text-xs"
        >
            {{ $t(bottomHelpText) }}
        </div>
    </div>
</template>
<script setup lang="ts">
import { Tooltip } from '@src/components'
import TinyMCE from '@src/partials/editor/TinyMCE.vue'
import { Placeholder } from '@src/types'
import { useVModels } from '@vueuse/core'
import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

const props = withDefaults(
    defineProps<{
        name: string
        label: string
        help: string
        bottomHelpText?: string | null
        required?: boolean
        modelValue: string | null
        placeholders?: Placeholder[] | null
    }>(),
    {
        bottomHelpText: null,
        placeholders: null,
    }
)
const { locale } = useI18n()

const emit = defineEmits(['update:modelValue'])

const { modelValue, placeholders } = useVModels(props, emit)

const refreshKey = ref<number>(0)

watch(locale, () => {
    refreshKey.value++
})
</script>
