import { MultiActionButtonWrapper } from '@src/components'
import { h, ref } from 'vue'

export interface MultiActionButtonAction {
    label: string
    title?: string | { (): string | null }
    icon?: string
    class?: string
    action: () => void
    condition?: boolean | { (): boolean }
    disabled?: boolean | { (): boolean }
}

export interface MultiActionButtonWrapperProps {
    id: string
    actions: MultiActionButtonAction[]
}

export function useMultiActionButton(props: MultiActionButtonWrapperProps) {
    const actionsMenuProps = ref<MultiActionButtonWrapperProps>(props)

    const MultiActionButton = () =>
        h(MultiActionButtonWrapper, actionsMenuProps.value)

    return { MultiActionButton }
}
