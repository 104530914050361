<template>
    <Modal
        id="create-issuer-modal"
        ref="create-issuer-modal"
        v-model:open="open"
        title="create_issuer"
        width="xl"
        @submit="submit"
        @cancel="cancel"
    >
        <TextField
            v-model="modelValue.name"
            label="name"
            name="name"
            :required="true"
            help="help_issuer_name"
        />

        <SelectField
            v-model="modelValue.platform_name"
            v-model:options="platformSelectOptions"
            name="platform_name"
            label="platform"
            help="help_platform_name"
            :required="true"
            :on-search="loadPlatformNameOptions"
        />

        <template #actions>
            <MultiActionButton />
        </template>
    </Modal>
</template>
<script setup lang="ts">
import { useMultiActionButton } from '@composables/common'
import { Modal, SelectField, TextField } from '@src/components'
import { Issuer, Platform } from '@src/types'
import { getPlatforms } from '@src/utils/api/platforms'
import { useVModels } from '@vueuse/core'
import { computed, onMounted, ref } from 'vue'

const props = defineProps<{
    open: boolean
    modelValue: Issuer
}>()

const emit = defineEmits([
    'update:modelValue',
    'update:open',
    'cancel',
    'submit',
])

const { modelValue, open } = useVModels(props, emit)

const platformSelectOptions = ref<string[]>([])

async function loadPlatformNameOptions(search: string) {
    const initialPlatformsRequest = await getPlatforms({
        'filter[platform_name]': search,
    })
    platformSelectOptions.value = initialPlatformsRequest.data.platforms.map(
        (platform: Platform) => {
            return platform.platform_name as string
        }
    )
}

const { MultiActionButton } = useMultiActionButton({
    id: 'create-asset-button',
    actions: [
        {
            label: 'create',
            action: submit,
            disabled: () => disableCreate.value,
        },
        {
            label: 'create_and_view',
            action: submitAndView,
            disabled: () => disableCreate.value,
        },
        {
            label: 'create_and_edit',
            action: submitAndEdit,
            disabled: () => disableCreate.value,
        },
    ],
})

function cancel() {
    emit('cancel')
}

function submit() {
    emit('submit')
}

function submitAndView() {
    emit('submit', 'view')
}

function submitAndEdit() {
    emit('submit', 'edit')
}

onMounted(async () => {
    platformSelectOptions.value = []
})

const disableCreate = computed(() => {
    return !modelValue.value.name || !modelValue.value.platform_name
})
</script>
