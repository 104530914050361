<template>
    <td
        class="w-px whitespace-nowrap bg-white px-2 py-3 first:sticky first:left-0 first:pl-5 last:sticky last:right-0 last:pr-5"
    >
        <div class="float-right">
            <template v-for="(action, index) in actions">
                <button
                    v-if="checkCondition(action.condition)"
                    :key="index"
                    class="h-8 w-8 rounded-full align-top text-gray-400 hover:text-gray-500"
                    :title="
                        !action.disabled
                            ? $t(action.title)
                            : action.disabledText
                            ? $t(action.disabledText)
                            : ''
                    "
                    :disabled="checkDisabled(action.disabled)"
                    @click.stop="
                        () => handleOnClick(action.action, rowIndex, rowData)
                    "
                >
                    <span
                        v-if="!checkDisabled(action.disabled)"
                        class="sr-only"
                        >{{ $t(action.title) }}
                        <template v-if="action.disabled && action.disabledText">
                            {{ $t(action.disabledText) }}
                        </template>
                    </span>
                    <fa
                        :icon="action.icon"
                        class="fill-current"
                        v-bind="{
                            class:
                                action.color && !checkDisabled(action.disabled)
                                    ? action.color
                                    : checkDisabled(action.disabled)
                                    ? 'text-gray-300'
                                    : null,
                        }"
                    />
                    <span
                        v-if="action.extraHtml"
                        class="mx-1"
                    >
                        <component
                            :is="action.extraHtml"
                            v-bind="{ rowData: rowData }"
                        />
                    </span>
                </button>
            </template>
        </div>
    </td>
</template>

<script setup lang="ts">
import {
    RowExpandAction,
    TableAction,
    TableActionFunction,
} from '@composables/common'

const props = defineProps<{
    actions: TableAction[]
    rowData: Record<string, unknown>
    rowIndex: number
    expandAction?: RowExpandAction | null
}>()

function checkCondition(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    condition: boolean | { (rowData: any): boolean } | undefined
) {
    if (condition === undefined) {
        return true
    }
    if (typeof condition === 'boolean') {
        return condition
    }
    return condition(props.rowData)
}

function checkDisabled(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    disabled: boolean | boolean | { (rowData: any): boolean } | undefined
) {
    if (disabled === undefined) {
        return false
    }
    if (typeof disabled === 'boolean') {
        return disabled
    }
    return disabled(props.rowData)
}

function handleOnClick(
    action: TableActionFunction | string,
    rowIndex: number,
    rowData: Record<string, unknown>
) {
    if (props.expandAction && action === 'expand') {
        props.expandAction(rowIndex)
    } else if (typeof action === 'function') {
        action(rowData)
    }
}
</script>

<style scoped>
tr:hover td {
    @apply bg-gray-100;
}
</style>
