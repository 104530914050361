import {
    ContractEndCause,
    KistaRequestType,
    KistaServiceType,
} from '@src/enums'
import {
    Asset,
    Contract,
    ContractDetails,
    ContractOrder,
    InterestPayment,
    InterestPaymentCalculation,
    InterestPaymentCalculationWarnings,
    PayoutTask,
    TableSorting,
    TaxFeature,
} from '@src/types'
import { ObjectWithStringKeysAndStringValue } from '@src/types/ObjectWithStringKeysAndStringValue'
import { AxiosRequestConfig } from 'axios'
import { ApiRes, api } from './client'

export interface ContractsResponse {
    success: boolean
    contracts_count: number
    contracts: Contract[]
}

export interface ContractResponse {
    success: boolean
    contract: Contract
}

export interface AssociateOrdersRequest {
    success: boolean
    updated_orders_count: number
    total_orders_in_contract: number
}

export interface InterestPaymentsRequest {
    success: boolean
    interest_payments: InterestPayment[]
    interest_payments_count: number
}

export interface ContractsOrdersRequest {
    success: boolean
    interest_payments: InterestPayment[]
    orders: ContractOrder[]
    orders_count: number
}

export interface DownloadSepaCsvRequest {
    download_url: string
}

export interface PayoutTasksRequest {
    success: boolean
    tasks: PayoutTask[]
}

export interface DownloadFileResponse {
    download_url: string
}
export type ExportInterestPaymentResponse = DownloadFileResponse
export type ExportKistaResponse = DownloadFileResponse

export interface ImportKistaResponse {
    success: boolean
    taxFeatures: TaxFeature[]
}

export interface InterestPaymentCalculationRequest {
    download_url: string | null
    interest_payment: InterestPayment
    is_last_payment: boolean
    warnings: InterestPaymentCalculationWarnings
}

export const getContracts = (
    filters?: ObjectWithStringKeysAndStringValue,
    page?: number,
    limit?: number,
    sorting?: TableSorting[]
): ApiRes<ContractsResponse> => {
    const config: AxiosRequestConfig = {}

    config.params = {}

    if (filters && Object.keys(filters).length) {
        config.params = filters
    }

    if (typeof page !== 'undefined' && typeof limit !== 'undefined') {
        config.params['range'] = page * limit - limit + '-' + page * limit
    }

    if (sorting && sorting.length) {
        config.params['sort'] = sorting
            .map((sort) => {
                return (sort.direction === 'desc' ? '-' : '') + sort.field
            })
            .join(',')
    }

    return api.get('v2/manage/contracts', config)
}

export const associateOrders = (
    contract: Contract,
    asset: Asset
): ApiRes<AssociateOrdersRequest> =>
    api.post(
        'manage/contracts/' + contract.contract_uid + '/associate_orders',
        { asset_uid: asset.asset_uid }
    )

export const getContract = (id: string): ApiRes<ContractDetails> =>
    api.get('v2/manage/contracts/' + id)

export const updateContract = (
    contract: ContractDetails
): ApiRes<ContractResponse> =>
    api.patch('manage/contracts/' + contract.contract_uid, contract)

export const updateContractAfterInterestPayment = (
    contract: ContractDetails
): ApiRes<ContractResponse> =>
    api.put(
        'manage/contracts/' + contract.contract_uid + '/after_interest_payment',
        contract
    )

export const createContract = (
    contract: ContractDetails
): ApiRes<ContractResponse> => api.post('manage/contracts', contract)

export const deleteContract = (
    contract: Contract | ContractDetails
): ApiRes<ContractResponse> =>
    api.delete('manage/contracts/' + contract.contract_uid)

export const deleteContracts = (
    contracts: string[]
): ApiRes<ContractResponse> =>
    api.delete('v2/manage/contracts', { data: { contracts: contracts } })

export const endContract = (
    contractEndCause: ContractEndCause,
    id: string
): ApiRes<ContractResponse> =>
    api.post(`manage/contracts/${id}/end_contract`, { cause: contractEndCause })

export const getInterestPayments = (
    id: string,
    filters?: ObjectWithStringKeysAndStringValue,
    page?: number,
    limit?: number,
    sorting?: TableSorting[]
): ApiRes<InterestPaymentsRequest> => {
    const config: AxiosRequestConfig = {}

    config.params = {}

    if (filters && Object.keys(filters).length) {
        config.params = filters
    }

    if (typeof page !== 'undefined' && typeof limit !== 'undefined') {
        config.params['range'] = page * limit - limit + '-' + page * limit
    }

    if (sorting && sorting.length) {
        config.params['sort'] = sorting
            .map((sort) => {
                return (sort.direction === 'desc' ? '-' : '') + sort.field
            })
            .join(',')
    }

    return api.get(`manage/contracts/${id}/interest_payments`, config)
}

export const getContractOrders = (
    id: string,
    filters?: ObjectWithStringKeysAndStringValue,
    page?: number,
    limit?: number,
    sorting?: TableSorting[]
): ApiRes<ContractsOrdersRequest> => {
    const config: AxiosRequestConfig = {}

    config.params = {}

    if (filters && Object.keys(filters).length) {
        config.params = filters
    }

    if (typeof page !== 'undefined' && typeof limit !== 'undefined') {
        config.params['range'] = page * limit - limit + '-' + page * limit
    }

    if (sorting && sorting.length) {
        config.params['sort'] = sorting
            .map((sort) => {
                return (sort.direction === 'desc' ? '-' : '') + sort.field
            })
            .join(',')
    }

    return api.get(`manage/contracts/${id}/orders`, config)
}

export const downloadSepaCsv = (id: string): ApiRes<DownloadSepaCsvRequest> => {
    return api.get(`manage/interest_payments/${id}/sepa_csv`)
}

export const getInterestPaymentCalculation = (
    id: string,
    calculation: InterestPaymentCalculation
): ApiRes<InterestPaymentCalculationRequest> => {
    return api.post(
        `manage/contracts/${id}/interest_payment/calculate?async=1`,
        calculation
    )
}

export const getPayoutTasks = (id: string): ApiRes<PayoutTasksRequest> => {
    return api.get(`/manage/interest_payments/${id}/tasks`)
}

export const startPayout = (id: string): ApiRes<PayoutTasksRequest> => {
    return api.post(`/manage/interest_payments/${id}/payout`)
}

export const exportTransactions = (
    contractId: string
): ApiRes<ExportInterestPaymentResponse> =>
    api.get(
        '/manage/contracts/' + contractId + '/interest_payments/export?async=1'
    )

export const exportKistaRequest = (
    contractId: string,
    type: KistaRequestType,
    service: KistaServiceType
): ApiRes<ExportKistaResponse> =>
    api.get(
        `/manage/contracts/${contractId}/export_kista_csv?type=${type}&service=${service}`
    )

export const importKistaResponse = (
    contractId: string,
    uploadKey: string
): ApiRes<ImportKistaResponse> =>
    api.post(`/manage/contracts/${contractId}/import_kista_csv`, {
        upload_key: uploadKey,
    })
