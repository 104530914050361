<template>
    <Modal
        id="create-asset-modal"
        ref="create-asset-modal"
        v-model:open="open"
        title="create_asset"
        width="xl"
        @submit="submit"
        @cancel="cancel"
    >
        <TextField
            v-model="modelValue.asset_title"
            label="asset_title"
            name="asset_title"
            :required="true"
            help="help_asset_title"
            :disabled="disabledFields?.includes('asset_title')"
        />

        <SelectField
            v-model="modelValue.platform_name"
            name="platform_name"
            label="platform"
            help="help_platform_name"
            :options="platformSelectOptions"
            :required="true"
            :on-search="loadPlatformNameOptions"
            :disabled="disabledFields?.includes('platform_name')"
        />

        <SelectField
            v-model="modelValue.issuer_id"
            name="issuer_id"
            label="issuer"
            help="help_issuer"
            :options="issuersSelectOptions"
            :required="true"
            :on-search="loadIssuerOptions"
            :disabled="
                modelValue.platform_name === null ||
                disabledFields?.includes('issuer_id')
            "
        />

        <SelectField
            v-model="modelValue.currency"
            name="currency"
            label="currency"
            help="help_currency"
            :options="currencies"
            :required="true"
            :disabled="disabledFields?.includes('currency')"
        />

        <template #actions>
            <MultiActionButton />
        </template>
    </Modal>
</template>
<script setup lang="ts">
import { useMultiActionButton } from '@composables/common'
import { Modal, SelectField, TextField } from '@src/components'
import { Asset, Issuer, Platform } from '@src/types'
import { getCurrencies } from '@src/utils/api/currencies'
import { getPlatforms } from '@src/utils/api/platforms'
import { getIssuers } from '@utils/api/issuers'
import { useVModels } from '@vueuse/core'
import { computed, onMounted, ref } from 'vue'

const props = defineProps<{
    open: boolean
    modelValue: Asset
    disabledFields?: string[] | null
}>()

const emit = defineEmits([
    'update:modelValue',
    'update:open',
    'cancel',
    'submit',
])

const { modelValue, open } = useVModels(props, emit)

const currencies = ref<string[]>([])

const platformSelectOptions = ref<string[]>([])

const issuersSelectOptions = ref<{ label: string; value: string | null }[]>([])

async function loadPlatformNameOptions(search: string) {
    const initialPlatformsRequest = await getPlatforms({
        'filter[platform_name]': search,
    })
    platformSelectOptions.value = initialPlatformsRequest.data.platforms.map(
        (platform: Platform) => {
            return platform.platform_name as string
        }
    )
}

async function loadIssuerOptions(search: string) {
    const getIssuersResponse = await getIssuers({
        'filter[name]': search,
        'filter[platform_name]': modelValue.value.platform_name,
    })
    issuersSelectOptions.value = getIssuersResponse.data.issuers.map(
        (issuer: Issuer) => {
            return {
                value: issuer.id as string,
                label: issuer.name + ' (' + issuer.platform_name + ')',
            }
        }
    )
}

const { MultiActionButton } = useMultiActionButton({
    id: 'create-asset-button',
    actions: [
        {
            label: 'create',
            action: submit,
            disabled: () => disableCreate.value,
        },
        {
            label: 'create_and_view',
            action: submitAndView,
            disabled: () => disableCreate.value,
        },
        {
            label: 'create_and_edit',
            action: submitAndEdit,
            disabled: () => disableCreate.value,
        },
    ],
})

function cancel() {
    emit('cancel')
}

function submit() {
    emit('submit')
}

function submitAndView() {
    emit('submit', 'view')
}

function submitAndEdit() {
    emit('submit', 'edit')
}

const disableCreate = computed(() => {
    return (
        !modelValue.value.asset_title ||
        !modelValue.value.platform_name ||
        !modelValue.value.currency
    )
})

onMounted(async () => {
    if (modelValue.value.platform_name) {
        if (modelValue.value.platform) {
            platformSelectOptions.value = [
                modelValue.value.platform.platform_name as string,
            ]
        } else {
            await loadPlatformNameOptions(modelValue.value.platform_name)
        }
    }
    if (modelValue.value.issuer) {
        issuersSelectOptions.value = [
            {
                value: modelValue.value.issuer.id as string,
                label: modelValue.value.issuer.name as string,
            },
        ]
    }
    const currenciesRequest = await getCurrencies()
    currencies.value = currenciesRequest.data.currencies
})
</script>
