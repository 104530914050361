<template>
    <div>
        <Table
            :key="refreshKey"
            v-model:page="activePage"
            v-model:limit="limit"
            :total="total"
            :pagination="pagination"
            :hide-header-actions="hideHeaderActions"
            :help="help"
            :no-entries-info="data.length === 0 ? noEntriesInfo : null"
            :are-filters="areFilters"
            :pin-filters="pinFilters"
            class="relative"
        >
            <template
                v-if="label"
                #label
            >
                {{ $t(label as string) }}
            </template>
            <template #actions>
                <slot name="actions"></slot>
            </template>
            <template #filters>
                <slot name="filters"></slot>
            </template>
            <template #menu>
                <slot name="menu"></slot>
            </template>
            <template
                v-if="data.length > 0"
                #header
            >
                <template v-for="(header, index) in columns">
                    <TableHeaderItemSelectAll
                        v-if="header.select"
                        :key="`${id}-header-select-all-${index}`"
                        v-model:items="data"
                        v-model:selected="selected"
                        :item-key="header.key"
                    />
                    <TableHeaderItem
                        v-else
                        :key="`${id}-header-${index}`"
                        v-model:sorting="sorting"
                        :sort-key="header.sorting ? header.key : null"
                    >
                        {{ $t(header.label) }}
                        <Tooltip
                            v-if="header.help"
                            bg="dark"
                            size="md"
                            position="right"
                            class="ml-1 inline-block"
                        >
                            <div class="text-sm normal-case text-gray-200">
                                {{ $t(header.help) }}
                            </div>
                        </Tooltip>
                    </TableHeaderItem>
                </template>
                <TableHeaderItemActions v-if="actions" />
            </template>
            <template
                v-if="
                    data.length > 0 &&
                    sortableOrder &&
                    itemIdKey &&
                    onSortOrderChange
                "
                #body
            >
                <Sortable
                    :list="data"
                    :item-key="itemIdKey"
                    tag="tbody"
                    @end="(event) => handleOnSortChange(event)"
                >
                    <template #item="{ element, index }">
                        <TableRowWrapper
                            :id="id"
                            :key="`${id}-row-${index}`"
                            v-model:selected="selected"
                            :row-index="index"
                            :row-data="element"
                            :columns="columns"
                            :actions="actions"
                            :expand="expand"
                            :expands="expands"
                            :expand-action="expandAction"
                        />
                    </template>
                </Sortable>
            </template>
            <template v-if="data.length > 0 && !sortableOrder">
                <TableRowWrapper
                    v-for="(rowData, rowIndex) in data"
                    :id="id"
                    :key="`${id}-row-${rowIndex}`"
                    v-model:selected="selected"
                    :row-index="rowIndex"
                    :row-data="rowData"
                    :columns="columns"
                    :actions="actions"
                    :expand="expand"
                    :expands="expands"
                    :expand-action="expandAction"
                />
            </template>
        </Table>
    </div>
</template>
<script setup lang="ts">
import {
    RowExpandAction,
    RowExpandState,
    SortOrderChange,
    TableAction,
    TableColumn,
} from '@composables/common'
import {
    Table,
    TableHeaderItem,
    TableHeaderItemActions,
    TableHeaderItemSelectAll,
    TableRowWrapper,
    Tooltip,
} from '@src/components'
import { TableSorting } from '@src/types'
import { useVModels } from '@vueuse/core'
import { SortableEvent } from 'sortablejs'
import { Sortable } from 'sortablejs-vue3'
import { ref, watch, type Component } from 'vue'

const props = withDefaults(
    defineProps<{
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data: Record<string, any>[]
        columns: TableColumn[]
        total?: number
        id?: string | null
        label?: string | null
        help?: string | null
        noEntriesInfo?: string
        selected?: string[]
        activePage?: number
        limit?: number
        pagination?: boolean
        sorting?: TableSorting[]
        actions?: TableAction[] | null
        expand?: Component | null
        expands?: RowExpandState[] | []
        expandAction?: RowExpandAction | null
        hideHeaderActions?: boolean
        areFilters?: boolean
        pinFilters?: boolean
        sortableOrder?: boolean
        itemIdKey?: string | null
        onSortOrderChange?: SortOrderChange | null
    }>(),
    {
        total: 0,
        selected: () => [],
        activePage: 1,
        limit: 10,
        pagination: false,
        sorting: () => [],
        actions: null,
        expand: () => null,
        expands: () => [],
        expandAction: null,
        id: null,
        label: null,
        help: null,
        noEntriesInfo: 'no_table_entries',
        hideHeaderActions: false,
        areFilters: false,
        pinFilters: false,
        sortableOrder: false,
        itemIdKey: null,
        onSortOrderChange: null,
    }
)

const emit = defineEmits([
    'update:selected',
    'update:data',
    'update:activePage',
    'update:limit',
    'update:sorting',
    'selectAll',
    'reload:data',
])

const {
    id,
    data,
    label,
    help,
    selected,
    total,
    activePage,
    limit,
    sorting,
    columns,
    expand,
    expands,
    expandAction,
    hideHeaderActions,
    onSortOrderChange,
} = useVModels(props, emit)

const refreshKey = ref<number>(0)

function handleOnSortChange(event: SortableEvent) {
    if (onSortOrderChange.value) {
        onSortOrderChange.value(event)
        refreshKey.value++
    }
}

watch(data, () => {
    refreshKey.value++
})
</script>
