<template>
    <TasksTable>
        <template #filters>
            <DropdownFilter
                :pin="pinFilters"
                @apply="applyFilters"
                @clear="clearFilters"
                @toggle-pin="togglePinFilters"
            >
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <Datepicker
                            v-model="filtersFields.created_at"
                            placeholder="created_on"
                            name="created_on"
                            :disabled="disableFilter('created_on')"
                        />
                    </label>
                </DropdownFilterItem>
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <SelectField
                            v-model="filtersFields.type"
                            name="type"
                            class="w-full"
                            :options="mapEnumToArray(TaskType)"
                            :reduce="reduceEnumValue"
                            :disabled="disableFilter('type')"
                            :placeholder="$t('type')"
                        />
                    </label>
                </DropdownFilterItem>
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <SelectField
                            v-model="filtersFields.status"
                            name="status"
                            class="w-full"
                            :options="mapEnumToArray(TaskStatus)"
                            :reduce="reduceEnumValue"
                            :disabled="disableFilter('status')"
                            :placeholder="$t('status')"
                        />
                    </label>
                </DropdownFilterItem>
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <Datepicker
                            v-model="filtersFields.created_at"
                            placeholder="started_at"
                            name="started_at"
                            :disabled="disableFilter('started_at')"
                        />
                    </label>
                </DropdownFilterItem>
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <Datepicker
                            v-model="filtersFields.finished_at"
                            placeholder="finished_at"
                            name="finished_at"
                            :disabled="disableFilter('finished_at')"
                        />
                    </label>
                </DropdownFilterItem>
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <TextField
                            v-model="filtersFields.result_text"
                            name="result_text"
                            :disabled="disableFilter('result_text')"
                            :placeholder="$t('result_text')"
                        />
                    </label>
                </DropdownFilterItem>
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <Datepicker
                            v-model="filtersFields.expires_at"
                            placeholder="expires_at"
                            name="expires_at"
                            :disabled="disableFilter('expires_at')"
                        />
                    </label>
                </DropdownFilterItem>
            </DropdownFilter>
        </template>
    </TasksTable>
    <ModalWrapper v-if="isModalOpen" />
</template>

<script setup lang="ts">
import { ModalButtonStyles, useModal, useTable } from '@composables/common'
import {
    Badge,
    Datepicker,
    DropdownFilter,
    DropdownFilterItem,
    SelectField,
    TextField,
} from '@src/components'
import { useEnum } from '@src/composables/common'
import {
    MessageTypes,
    TaskFinishedType,
    TaskStatus,
    TaskTaskableType,
    TaskType,
} from '@src/enums'
import TaskDetails from '@src/partials/tasks/TaskDetails.vue'
import TaskResultDataCellRenderer from '@src/partials/tasks/TaskResultDataCellRenderer.vue'
import router from '@src/router'
import { TableSorting, Task } from '@src/types'
import { getTasks, deleteTask as apiDeleteTask } from '@src/utils/api/tasks'
import { useUserStore } from '@src/utils/stores/user'
import { downloadFile } from '@utils/helpers'
import moment from 'moment'
import { h, onMounted, ref, shallowRef, toRefs } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { useI18n } from 'vue-i18n'
import { useToastStore } from '@stores/toast'

const props = withDefaults(
    defineProps<{
        initialFilters?: {
            created_at?: string | null
            type?: string | null
            status?: string | null
            started_at?: string | null
            finished_at?: string | null
            finished_type?: string | null
            result_text?: string | null
            expires_at: string | null
        } | null
    }>(),
    {
        initialFilters: null,
        createInitialValues: null,
        createDisabledFields: null,
    }
)

const { initialFilters } = toRefs(props)

const userStore = useUserStore()
const { getEnumLabel, mapEnumToArray, reduceEnumValue } = useEnum()
const { ModalWrapper, openModal } = useModal()
const i18n = useI18n()
const toast = useToastStore()

const isModalOpen = ref(false)

const tasks = ref<Task[]>([])
const tasksCount = ref<number>(0)

const disableFilter = (filter: string) => {
    if (initialFilters.value) {
        return (
            Object.keys(initialFilters.value).filter((item) => {
                return item === filter
            }).length > 0
        )
    }
    return false
}

const tasksTableColumns = [
    {
        label: ' ',
        key: 'finished_type',
        sorting: false,
        cellRenderer: (props: { rowData: Task }) => {
            return props.rowData.finished_type ===
                TaskFinishedType.ERROR_WHILE_GENERATING &&
                props.rowData.status !== TaskStatus.STARTED
                ? h(FontAwesomeIcon, {
                      icon: 'fa-solid fa-triangle-exclamation',
                      title: props.rowData.result_text,
                      class: 'text-danger-500 mr-2',
                  })
                : ''
        },
    },
    {
        label: 'created_on',
        key: 'created_at',
        sorting: true,
        cellRenderer: (props: { rowData: Task }) => {
            return moment(props.rowData.created_at).format('DD.MM.yyyy')
        },
    },
    {
        label: 'type',
        key: 'type',
        sorting: true,
        cellRenderer: (props: { rowData: Task }) => {
            return getEnumLabel(TaskType, props.rowData.type)
        },
    },
    {
        label: 'status',
        key: 'status',
        sorting: true,
        cellRenderer: (props: { rowData: Task }) => {
            return h(
                Badge,
                {
                    type: MessageTypes.INFO,
                },
                {
                    default: () =>
                        getEnumLabel(
                            TaskStatus,
                            props.rowData.status
                        ) as string,
                }
            )
        },
    },
    {
        label: 'started_at',
        key: 'started_at',
        sorting: true,
        cellRenderer: (props: { rowData: Task }) => {
            return moment(props.rowData.started_at).format('DD.MM.yyyy')
        },
    },
    {
        label: 'finished_at',
        key: 'finished_at',
        sorting: true,
        cellRenderer: (props: { rowData: Task }) => {
            return props.rowData.status === TaskStatus.STARTED
                ? ' - '
                : moment(props.rowData.finished_at).format('DD.MM.yyyy')
        },
    },
    {
        label: 'finished_type',
        key: 'finished_type',
        sorting: true,
        cellRenderer: (props: { rowData: Task }) => {
            let badgeType: MessageTypes = MessageTypes.INFO

            if (props.rowData.status === TaskStatus.FINISHED) {
                if (props.rowData.finished_type === TaskFinishedType.SUCCESS) {
                    badgeType = MessageTypes.SUCCESS
                }

                if (props.rowData.finished_type === TaskFinishedType.WARNING) {
                    badgeType = MessageTypes.WARNING
                }

                if (
                    props.rowData.finished_type ===
                        TaskFinishedType.HAS_ERRORS ||
                    props.rowData.finished_type === TaskFinishedType.ERROR ||
                    props.rowData.finished_type ===
                        TaskFinishedType.ERROR_WHILE_GENERATING
                ) {
                    badgeType = MessageTypes.ERROR
                }
            }

            return h(
                Badge,
                {
                    type: badgeType,
                },
                {
                    default: () =>
                        getEnumLabel(
                            TaskFinishedType,
                            props.rowData.status === TaskStatus.STARTED
                                ? TaskFinishedType.PENDING
                                : props.rowData.finished_type
                        ) as string,
                }
            )
        },
    },
    {
        label: 'taskable',
        key: 'taskable',
        sorting: true,
        cellRenderer: (props: { rowData: Task }) => {
            if (
                props.rowData.taskable_type ===
                    TaskTaskableType.INTEREST_PAYMENT ||
                props.rowData.taskable_type === TaskTaskableType.ASSET
            ) {
                let path = ''
                let id = ''
                if (
                    props.rowData.taskable_type ===
                    TaskTaskableType.INTEREST_PAYMENT
                ) {
                    path = 'contract'
                    id = props.rowData.taskable?.contract_uid
                }

                if (props.rowData.taskable_type === TaskTaskableType.ASSET) {
                    path = 'asset'
                    id = props.rowData.taskable?.asset_uid
                }

                return h(
                    'a',
                    {
                        href: '#',
                        class: 'text-blue-500',
                        onClick(event: MouseEvent) {
                            event.preventDefault()
                            router.push({
                                name: path,
                                params: { id: id },
                            })
                        },
                    },
                    props.rowData.taskable_type
                )
            }
        },
    },
    {
        label: 'result_data',
        key: 'result_data',
        sorting: true,
        cellRenderer: shallowRef(TaskResultDataCellRenderer),
    },
    {
        label: 'result_text',
        key: 'result_text',
        sorting: true,
        cellRenderer: (props: { rowData: Task }) => {
            return h(
                'div',
                {
                    class: 'truncate max-w-[250px]',
                },
                [props.rowData.result_text]
            )
        },
    },
    {
        label: 'expires_at',
        key: 'expires_at',
        sorting: true,
        cellRenderer: (props: { rowData: Task }) => {
            return (
                props.rowData.expires_at &&
                moment(props.rowData.expires_at).format('DD.MM.yyyy')
            )
        },
    },
]

const tasksTableActions = [
    {
        action: 'expand',
        icon: 'fa-arrow-turn-down',
        extraHtml: (props: { rowData: Task }) => {
            return props.rowData.sub_tasks?.length
        },
        title: 'subtasks',
        condition: (task: Task) => {
            return !!(task.sub_tasks && task.sub_tasks?.length > 0)
        },
    },
    {
        action: handleViewTask,
        icon: 'fa-eye',
        title: 'view',
        condition: userStore.can('view_orders'),
    },
    {
        action: handleDownloadFile,
        icon: 'fa-solid fa-download',
        title: 'download',
        condition: (task: Task) => {
            return (
                userStore.can('view_tasks') && !!task.result_data?.download_url
            )
        },
    },
    {
        action: handleDeleteTask,
        icon: 'fas fa-trash-alt',
        title: 'delete',
        color: 'text-danger-500',
        condition: (task: Task) => {
            return (
                userStore.can('delete_tasks') &&
                task.finished_type ===
                    TaskFinishedType.ERROR_WHILE_GENERATING &&
                task.status !== TaskStatus.STARTED
            )
        },
    },
]

const subtasksExpand = (props: { rowData: Task }) => {
    const { TableWrapper: SubTasksTable } = useTable({
        tableProps: {
            id: 'subtask-table',
            label: 'subtasks',
            help: 'help_tasks_table',
            pagination: false,
            data: props.rowData.sub_tasks,
            total: props.rowData.sub_tasks?.length,
            sorting: ref<TableSorting[]>([
                {
                    field: 'created_at',
                    direction: 'desc',
                },
            ]),
            columns: tasksTableColumns,
            actions: tasksTableActions,
        },
    })

    return h(
        'td',
        {
            colspan: tasksTableColumns.length + 1,
            class: 'bg-gray-100',
        },
        h(
            'div',
            {
                class: 'w-full px-4',
            },
            h(SubTasksTable)
        )
    )
}

const {
    TableWrapper: TasksTable,
    selected,
    limit,
    sorting,
    page,
    filtersFields,
    filters,
    pinFilters,
    togglePinFilters,
    applyFilters,
    clearFilters,
} = useTable({
    loadData: loadTasks,
    filters: {
        created_at: null,
        type: null,
        status: null,
        started_at: null,
        finished_at: null,
        finished_type: null,
        result_text: null,
        expires_at: null,
        search: null,
    },
    disableFilters: initialFilters.value
        ? Object.keys(initialFilters.value)
        : [],
    tableProps: {
        id: 'task-table',
        label: 'tasks',
        help: 'help_tasks_table',
        pagination: true,
        data: tasks,
        total: tasksCount,
        sorting: ref<TableSorting[]>([
            {
                field: 'created_at',
                direction: 'desc',
            },
        ]),
        columns: tasksTableColumns,
        actions: tasksTableActions,
        expand: subtasksExpand,
    },
})

function handleViewTask(task: Task) {
    isModalOpen.value = true
    openModal(
        {
            id: 'task-details',
            title: 'modal_title_task_details',
            open: isModalOpen,
            onCancel: () => (isModalOpen.value = false),
        },
        () =>
            h(TaskDetails, {
                task: task,
            })
    )
}

function handleDownloadFile(task: Task) {
    if (task.result_data?.download_url) {
        downloadFile(task.result_data?.download_url)
    }
}

async function loadTasks() {
    const { data } = await getTasks(
        filters.value,
        page.value,
        limit.value,
        sorting.value
    )
    tasks.value = data.tasks
    tasksCount.value = data.tasks_count
    selected.value = []
}

function handleDeleteTask(task: Task) {
    isModalOpen.value = true
    openModal(
        {
            id: 'delete_task',
            title: 'modal_title_delete_task',
            open: isModalOpen,
            width: '2xl',
            onCancel: () => (isModalOpen.value = false),
            onSubmit: () => deleteTask(task),
        },
        i18n.t('modal_text_delete_task', {
            type: task.type,
            created: moment(task.created_at).format('DD.MM.yyyy'),
        }),
        {
            submitButtonText: 'delete',
            styles: {
                submitButton: ModalButtonStyles['RED'],
            },
        }
    )
}

async function deleteTask(task: Task) {
    if (!task.id) return
    await apiDeleteTask(task.id)
    toast.success(i18n.t('toast_success_delete_task'))
    isModalOpen.value = false
    await loadTasks()
}

onMounted(async () => {
    await loadTasks()
})
</script>

<style scoped></style>
