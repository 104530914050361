export enum AssetDocumentType {
    CONTRACT_DATA = 0,
    REPORTING = 1,
    CONSUMER_INFORMATION = 2,
}

export enum AssetCategory {
    DEFAULT = 0,
    STARTUP = 1,
    SME = 2,
    REAL_ESTATE = 3,
    SPECIAL = 4,
}

export enum AssetStatus {
    ACTIVE = 'active',
    UNDER_OBSERVATION = 'under_observation',
    SETTLED = 'settled',
    INACTIVE = 'inactive',
}

export enum AssetSubStatus {
    REPORTING_DELAY = 'reporting_delay',
    PAYMENT_OUTSTANDING = 'payment_outstanding',
    REPAID = 'repaid',
    EARLY_REPAYMENT = 'early_repayment',
}
