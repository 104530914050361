import { useFilters } from '@composables/common'
import { TableWrapper as TableWrapperComponent } from '@src/components'
import { TableSorting } from '@src/types'
import { SortableEvent } from 'sortablejs'
import { Ref, h, ref, useSlots, watch, type Component } from 'vue'
import { useRoute } from 'vue-router'

export interface TableUrl {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (rowData: any): string
}

export interface TableActionFunction {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (rowData: any): void
}

export interface TableAction {
    action: TableActionFunction | string
    icon: string
    title: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    extraHtml?: Component
    color?: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    condition?: boolean | { (rowData: any): boolean }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    disabled?: boolean | { (rowData: any): boolean }
    disabledText?: string
}

export interface RowExpandState {
    isExpanded: boolean
}

export interface RowExpandAction {
    (rowIndex: number): void
}

export interface SortOrderChange {
    (event: SortableEvent): void
}

export interface TableColumn {
    label: string
    key: string
    select?: boolean
    sorting?: boolean
    url?: TableUrl
    center?: boolean
    targetBlank?: boolean
    cellRenderer?: Component
    condition?: boolean
    help?: string
    copy?: boolean
}

export type TableActions = TableAction[] | null

export interface TableProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: Ref<any> | any
    columns: TableColumn[]
    total?: Ref<number> | number
    id: string | null
    label?: string | null
    help?: string | null
    noEntriesInfo?: string
    selected?: Ref<string[]>
    activePage?: Ref<number>
    limit?: Ref<number>
    pagination?: boolean
    sorting?: Ref<TableSorting[]>
    actions?: TableAction[]
    expand?: Component
    expands?: Ref<RowExpandState[] | []>
    expandAction?: RowExpandAction | null
    hideHeaderActions?: boolean
    areFilters?: Ref<boolean>
    pinFilters?: Ref<boolean>
    sortableOrder?: boolean
    itemIdKey?: string | null
    itemOrderKey?: string | null
    onSortOrderChange?: SortOrderChange | null
}

export interface UpdateData {
    (args?: unknown): Promise<void>
}

export interface UseTableProps {
    loadData?: (args?: unknown) => Promise<void>
    updateData?: UpdateData
    tableProps: TableProps
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    filters?: Record<string, any>
    disableFilters?: string[]
}

export function useTable(props: UseTableProps) {
    const selected = ref<string[]>([])
    const limit = ref<number>(
        parseInt(import.meta.env.VITE_DEFAULT_TABLE_LIMIT) ?? 10
    )
    const sorting = props.tableProps.sorting || ref<TableSorting[]>([])
    const page = ref<number>(1)
    const areFilters = ref<boolean>(false)

    const url = new URL(window.location.href)
    const urlParams = new URLSearchParams(url.search)

    const route = useRoute()
    const refreshKey = ref<number>(0)

    const pageValue = parseInt(
        urlParams.get(`${props.tableProps.id}_page`) as string
    )
    const limitValue = parseInt(
        urlParams.get(`${props.tableProps.id}_limit`) as string
    )
    const sortingValue = JSON.parse(
        urlParams.get(`${props.tableProps.id}_sorting`) as string
    )
    const selectedValue = JSON.parse(
        urlParams.get(`${props.tableProps.id}_selected`) as string
    )

    if (pageValue) {
        page.value = pageValue
    }
    if (limitValue) {
        limit.value = limitValue
    }
    if (sortingValue) {
        sorting.value = sortingValue
    }
    if (selectedValue) {
        selected.value = selectedValue
    }

    function storeURLParams(values: {
        page?: number
        limit?: number
        sorting?: TableSorting[]
        selected?: string[]
    }) {
        const url = new URL(window.location.href)
        const urlParams = new URLSearchParams(url.search)
        if (values.page) {
            urlParams.set(`${props.tableProps.id}_page`, values.page.toString())
        }
        if (values.limit) {
            urlParams.set(
                `${props.tableProps.id}_limit`,
                values.limit.toString()
            )
        }
        if (values.sorting) {
            urlParams.set(
                `${props.tableProps.id}_sorting`,
                JSON.stringify(values.sorting)
            )
        }
        if (values.selected) {
            if (values.selected.length === 0) {
                urlParams.delete(`${props.tableProps.id}_selected`)
            } else {
                urlParams.set(
                    `${props.tableProps.id}_selected`,
                    JSON.stringify(values.selected)
                )
            }
        }

        window.history.replaceState(
            {},
            '',
            urlParams.size > 0
                ? `${window.location.pathname}?${urlParams}`
                : window.location.pathname
        )
    }

    storeURLParams({
        page: page.value,
        limit: limit.value,
        sorting: sorting.value,
        selected: selected.value,
    })

    const tableExpands = ref<RowExpandState[]>([])
    const initExpands = () => {
        if (props.tableProps.expand) {
            const data = props.tableProps.data.value
                ? props.tableProps.data.value
                : props.tableProps.data
            data.forEach(() => {
                tableExpands.value.push({
                    isExpanded: false,
                })
            })
        }
    }

    const expandAction = (rowIndex: number) => {
        if (tableExpands.value.length === 0) {
            initExpands()
        }
        if (rowIndex !== undefined) {
            tableExpands.value[rowIndex].isExpanded =
                !tableExpands.value[rowIndex].isExpanded
        }
    }

    props.tableProps.columns = props.tableProps.columns.filter(
        (column) => column.condition !== false
    )

    const {
        filtersFields,
        filters,
        pinFilters,
        togglePinFilters,
        initFiltersFields,
        setFilters,
        resetFilters,
        setFiltersSuccess,
        resetFiltersSuccess,
    } = useFilters(
        props.filters || {},
        props.disableFilters,
        props.tableProps.id as string
    )

    async function applyFilters(showSuccessToast = true) {
        setFilters()

        if (!Object.values(filters.value).length) {
            areFilters.value = false
        } else {
            areFilters.value = true
            page.value = 1
            props.loadData && (await props.loadData())
            if (showSuccessToast) {
                setFiltersSuccess()
            }
        }
    }

    async function clearFilters() {
        resetFilters()
        page.value = 1
        props.loadData && (await props.loadData())
        resetFiltersSuccess()
        areFilters.value = false
    }

    function moveItemInArray(array: [], from: number, to: number) {
        const item = array.splice(from, 1)[0]
        array.splice(to, 0, item)
    }

    function updateSortOrder() {
        Array.from(props.tableProps.data.value.keys()).forEach((index) => {
            props.tableProps.data.value[index as number][
                props.tableProps
                    .itemOrderKey as keyof typeof props.tableProps.data.value
            ] = index
        })
    }

    async function onSortOrderChange(event: SortableEvent) {
        moveItemInArray(
            props.tableProps.data.value as [],
            event.oldIndex as number,
            event.newIndex as number
        )
        updateSortOrder()
        if (props.updateData) {
            await props.updateData()
        }
    }

    const tableProps = ref<TableProps>({
        ...props.tableProps,
        selected: selected,
        activePage: page,
        limit: limit,
        areFilters: areFilters,
        pinFilters: pinFilters,
        sorting: sorting,
        expands: tableExpands,
        expandAction: expandAction,
        onSortOrderChange: onSortOrderChange,
    })

    const TableWrapper = () => {
        const slots = useSlots()

        return h(
            TableWrapperComponent,
            {
                ...tableProps.value,
                'key': refreshKey.value,
                'onUpdate:activePage': async (value: number) => {
                    page.value = value
                    storeURLParams({
                        page: page.value,
                    })
                    props.loadData && (await props.loadData())
                },
                'onUpdate:limit': async (value: number) => {
                    page.value = 1
                    limit.value = value
                    storeURLParams({
                        page: page.value,
                        limit: limit.value,
                    })
                    props.loadData && (await props.loadData())
                },
                'onUpdate:sorting': async (value: TableSorting[]) => {
                    sorting.value = value
                    storeURLParams({
                        sorting: sorting.value,
                    })

                    props.loadData && (await props.loadData())
                },
                'onUpdate:selected': (value: string[]) => {
                    selected.value = value
                    storeURLParams({
                        selected: selected.value,
                    })
                },
            },
            slots
        )
    }

    watch(
        () => route.query,
        () => {
            initFiltersFields()
            applyFilters(false)
        }
    )

    return {
        TableWrapper,
        selected,
        limit,
        sorting,
        page,
        filtersFields,
        filters,
        setFilters,
        applyFilters,
        clearFilters,
        updateSortOrder,
        pinFilters,
        togglePinFilters,
    }
}
