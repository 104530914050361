export class Account {
    account_uid: string | null = null
    active: boolean | null = null
    account_type: string | null = null
    platform_name: string | null = null
    account_title: string | null = null
    created_on: string | number | Date = 0
    email: string | null = null
    wallet_status: number | null = null
    wallet_id: string | null = null
    natural_profile_uid: string | null = null
    legal_profile_uid: string | null = null
    updated_at: string | null = null
    membership_number: string | null = null
}
