import { Country } from '@src/types'
import { getCountries } from '@utils/api/countries'
import { i18n } from '@utils/lang'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useCountryStore = defineStore('country', () => {
    const countries = ref<Country[]>([])

    async function loadCountries() {
        if (!countries.value.length) {
            countries.value = (await getCountries()).data.countries
        }
    }

    async function translate(country: string | null | undefined) {
        if (!country) return ''

        const locale = i18n.global.locale.value as keyof Country
        const foundCountry: Country | undefined = countries.value.find(
            (c) => c.alpha3 && c.alpha3 === country.toUpperCase()
        )
        return foundCountry ? foundCountry[locale] : country
    }

    async function loadAndSortCountriesOptionsAsync() {
        const translatedCountries = await Promise.all(
            countries.value.map(async (country) => {
                return {
                    label: await translate(country.alpha3),
                    value: country.alpha3,
                }
            })
        )

        return translatedCountries.sort((a, b) =>
            a.label.localeCompare(b.label)
        )
    }

    return {
        countries,
        loadCountries,
        translate,
        loadAndSortCountriesOptionsAsync,
    }
})
