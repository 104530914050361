import { useTable } from '@composables/common'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import InterestPaymentsTableExpand from '@partials/contracts/InterestPaymentsTableExpand.vue'
import { InterestPayment, TableSorting } from '@src/types'
import { formatNumericValues } from '@src/utils/helpers'
import { downloadSepaCsv } from '@utils/api/contracts'
import { downloadFile } from '@utils/helpers'
import moment from 'moment'
import { Ref, h, ref } from 'vue'

export function useContractInterestPaymentsTable(
    interestPayments: Ref<InterestPayment[]>,
    interestPaymentsCount: Ref<number>,
    currency: Ref<string | null>
) {
    const interestPaymentsTableColumns = ref([
        {
            label: 'date',
            key: 'date',
            cellRenderer: (props: { rowData: InterestPayment }) => {
                return h(
                    'span',
                    moment(props.rowData.date).format('DD.MM.yyyy')
                )
            },
        },
        {
            label: 'paid_interest',
            key: 'paid_interest',
            cellRenderer: (props: { rowData: InterestPayment }) => {
                if (props.rowData.paid_interest) {
                    return h(FontAwesomeIcon, {
                        icon: 'fa-solid fa-check',
                    })
                }
            },
        },
        {
            label: 'paid_clearance',
            key: 'paid_clearance',
            cellRenderer: (props: { rowData: InterestPayment }) => {
                if (props.rowData.paid_clearance) {
                    return h(FontAwesomeIcon, {
                        icon: 'fa-solid fa-check',
                    })
                }
            },
        },
        {
            label: 'payment',
            key: 'paid_percent_of_rest',
            cellRenderer: (props: { rowData: InterestPayment }) => {
                return `${props.rowData.paid_percent_of_rest}%`
            },
        },
        {
            label: 'total_amount',
            key: 'sum_paid_total',
            cellRenderer: (props: { rowData: InterestPayment }) => {
                return formatNumericValues(
                    props.rowData.sum_paid_total as number,
                    currency.value as string
                )
            },
        },
        {
            label: 'interest',
            key: 'sum_paid_interest',
        },
        {
            label: 'sum_paid_clearance',
            key: 'sum_paid_clearance',
            cellRenderer: (props: { rowData: InterestPayment }) => {
                return formatNumericValues(
                    props.rowData.sum_paid_clearance as number,
                    currency.value as string
                )
            },
        },
        {
            label: 'bonus',
            key: 'sum_paid_bonus',
        },
        {
            label: 'created_on',
            key: 'created_at',
            sorting: true,
            cellRenderer: (props: { rowData: InterestPayment }) => {
                return moment(props.rowData.created_at).format('DD.MM.yyyy')
            },
        },
    ])

    const interestPaymentsTableActions = ref([
        {
            action: handleDownloadSepaCsv,
            icon: 'fa-solid fa-download',
            title: 'download_sepa_csv',
        },
        {
            action: 'expand',
            icon: 'fa-solid fa-money-bill',
            title: 'start_payment',
        },
    ])

    const interestPaymentsTableExpand = (props: {
        rowData: InterestPayment
    }) => {
        return h(
            'td',
            {
                colspan: interestPaymentsTableColumns.value.length + 1,
            },
            [
                h(InterestPaymentsTableExpand, {
                    id: props.rowData.interest_payment_uid as string,
                }),
            ]
        )
    }

    const { TableWrapper: ContractInterestPaymentsTable } = useTable({
        tableProps: {
            id: 'interest-payments-table',
            label: 'interest_payments',
            total: interestPaymentsCount,
            data: interestPayments,
            sorting: ref<TableSorting[]>([
                {
                    field: 'created_at',
                    direction: 'desc',
                },
            ]),
            columns: interestPaymentsTableColumns.value,
            actions: interestPaymentsTableActions.value,
            expand: interestPaymentsTableExpand,
        },
    })

    async function handleDownloadSepaCsv(rowData: InterestPayment) {
        const downloadSepaCsvRequest = await downloadSepaCsv(
            rowData.interest_payment_uid as string
        )
        if (downloadSepaCsvRequest.data.download_url) {
            downloadFile(downloadSepaCsvRequest.data.download_url)
        }
    }

    return {
        ContractInterestPaymentsTable,
    }
}
