<template>
    <div
        class="relative my-4 rounded-sm border border-gray-200 bg-white shadow-lg"
    >
        <header
            v-if="!hideHeaderActions"
            class="px-5 py-4"
        >
            <div class="flex items-center justify-between gap-2">
                <div class="flex items-center gap-2">
                    <Tooltip
                        v-if="help"
                        bg="dark"
                        size="md"
                        position="right"
                    >
                        <div class="text-sm text-gray-200">
                            {{ $t(help) }}
                        </div>
                    </Tooltip>

                    <h2 class="font-semibold text-gray-800">
                        <slot name="label" />&nbsp;
                        <span
                            v-if="total > 0"
                            class="font-medium text-gray-400"
                            >{{ total }}
                        </span>
                    </h2>
                </div>

                <div class="flex items-center gap-2">
                    <slot name="actions"></slot>
                    <slot
                        v-if="!pinFilters"
                        name="filters"
                    ></slot>
                    <slot name="menu"></slot>
                </div>
            </div>
            <slot
                v-if="pinFilters"
                name="filters"
            ></slot>
            <div v-if="noEntriesInfo && !getLoading()">
                {{ $t(noEntriesInfo) }}
            </div>
            <div v-if="noEntriesInfo && areFilters && !getLoading()">
                {{ $t('check_table_filters') }}
            </div>
            <div v-if="total === 0 && getLoading()">{{ $t('loading') }}</div>
        </header>

        <div>
            <!-- Table -->
            <div class="overflow-x-auto">
                <table class="table w-full table-auto divide-y divide-gray-200">
                    <!-- Table header -->
                    <thead
                        class="border-t border-gray-200 bg-gray-50 text-xs font-semibold uppercase text-gray-500"
                    >
                        <tr>
                            <slot name="header" />
                        </tr>
                    </thead>

                    <!-- Table body -->
                    <tbody class="text-sm">
                        <slot />
                    </tbody>
                    <slot name="body" />
                </table>
            </div>
        </div>
        <!-- pagination -->
        <Pagination
            v-if="pagination && total > 0 && page && limit"
            v-model:active="page"
            v-model:limit="limit"
            :total="total"
        />
    </div>
</template>
<script setup lang="ts">
import { Pagination, Tooltip } from '@src/components'
import { useLoadingStore } from '@stores/loading'
import { useVModels } from '@vueuse/core'

const props = withDefaults(
    defineProps<{
        total: number
        pagination?: boolean
        page?: number
        limit?: number | 5 | 10 | 25 | 50 | 100 | 200
        help?: string | null
        hideHeaderActions?: boolean
        noEntriesInfo?: string | null
        areFilters?: boolean
        pinFilters?: boolean
    }>(),
    {
        total: 0,
        pagination: false,
        page: 1,
        limit: 10,
        help: null,
        hideHeaderActions: false,
        noEntriesInfo: null,
        areFilters: false,
        pinFilters: false,
    }
)

const emit = defineEmits(['update:page', 'update:limit'])

const {
    total,
    pagination,
    page,
    limit,
    help,
    hideHeaderActions,
    noEntriesInfo,
} = useVModels(props, emit)

const { getLoading } = useLoadingStore()
</script>
