import { ModalButtonStyles, OpenModal, useTable } from '@composables/common'
import UpdateOrCreatePlatformPolicyForm from '@partials/platforms/UpdateOrCreatePlatformPolicyForm.vue'
import ViewPlatformPolicy from '@partials/platforms/ViewPlatformPolicy.vue'
import CountryColumnCellRenderer from '@partials/tables/CountryColumnCellRenderer.vue'
import LanguageColumnCellRenderer from '@partials/tables/LanguageColumnCellRenderer.vue'
import { NameAndId } from '@src/components'
import { PlatformPolicy } from '@src/types'
import { useUserStore } from '@src/utils/stores/user'
import { Ref, computed, h, ref, shallowRef } from 'vue'
import { useI18n } from 'vue-i18n'

export function usePlatformPoliciesTable(
    policies: Ref<PlatformPolicy[]>,
    update: (successMessage?: string | null) => Promise<void>,
    openModal: OpenModal,
    isModalOpen: Ref<boolean>
) {
    const userStore = useUserStore()
    const i18n = useI18n()

    const total = computed(() => {
        return policies.value.length
    })

    const {
        TableWrapper: PlatformPoliciesTable,
        selected: selectedPlatformPoliciesIds,
    } = useTable({
        tableProps: {
            id: 'platform_policies',
            label: 'platform_policies',
            help: 'help_platform_policies_table',
            data: policies,
            total: total,
            columns: [
                {
                    label: 'id',
                    key: 'id',
                    select: true,
                },
                {
                    label: 'id',
                    key: 'id',
                    condition: userStore.can('see_id_columns'),
                    copy: true,
                },
                {
                    label: 'type',
                    key: 'policy_name',
                    cellRenderer: (props: { rowData: PlatformPolicy }) => {
                        return props.rowData.policy_name
                            ? i18n.t(
                                  props.rowData.policy_name?.toUpperCase() ?? ''
                              )
                            : props.rowData.policy_name
                    },
                },
                {
                    label: 'country',
                    key: 'country',
                    cellRenderer: shallowRef(CountryColumnCellRenderer),
                },
                {
                    label: 'language',
                    key: 'language',
                    cellRenderer: shallowRef(LanguageColumnCellRenderer),
                },
            ],
            actions: [
                {
                    action: handleViewPlatformPolicy,
                    icon: 'fa-eye',
                    title: 'view',
                },
                {
                    action: handleEditOrCreatePlatformPolicy,
                    icon: 'fa-solid fa-pen',
                    title: 'edit',
                    condition: userStore.can('update_platform'),
                },
                {
                    action: handleDeletePlatformPolicy,
                    icon: 'fas fa-trash-alt',
                    title: 'delete',
                    color: 'text-danger-500',
                    condition: userStore.can('update_platform'),
                },
            ],
        },
    })

    function handleViewPlatformPolicy(platformPolicy: PlatformPolicy) {
        isModalOpen.value = true
        openModal(
            {
                id: 'view_platform_policy',
                title: 'platform_policy',
                open: isModalOpen,
                onCancel: () => (isModalOpen.value = false),
            },
            () =>
                h(ViewPlatformPolicy, {
                    modelValue: platformPolicy,
                })
        )
    }

    function handleDeletePlatformPolicy(platformPolicy: PlatformPolicy) {
        isModalOpen.value = true
        openModal(
            {
                id: 'delete_platform_policy',
                title: 'modal_title_delete_platform_policy',
                open: isModalOpen,
                onCancel: () => (isModalOpen.value = false),
                onSubmit: () => deletePlatformPolicy(platformPolicy),
            },
            () =>
                h(NameAndId, {
                    idKey: 'id',
                    nameKey: 'name',
                    modelValue: [platformPolicy],
                }),
            {
                submitButtonText: 'delete',
                styles: {
                    submitButton: ModalButtonStyles['RED'],
                },
            }
        )
    }

    function handleDeletePlatformPolicies() {
        isModalOpen.value = true
        openModal(
            {
                id: 'delete_platform_policies',
                title: 'modal_title_delete_platform_policies',
                open: isModalOpen,
                onCancel: () => (isModalOpen.value = false),
                onSubmit: () =>
                    deletePlatformPolicies(selectedPlatformPolicies.value),
            },
            () =>
                h(NameAndId, {
                    idKey: 'id',
                    nameKey: 'name',
                    modelValue: selectedPlatformPolicies.value,
                }),
            {
                submitButtonText: 'delete',
                styles: {
                    submitButton: ModalButtonStyles['RED'],
                },
            }
        )
    }

    function deletePlatformPolicy(
        platformPolicyToDelete: PlatformPolicy,
        submit = true
    ) {
        const platformPoliciesTemp = [...policies.value]
        policies.value.splice(
            policies.value.findIndex(
                (platformPolicy) =>
                    platformPolicyToDelete.id == platformPolicy.id
            ),
            1
        )
        if (submit) {
            update(
                i18n.t('toast_success_platform_policy_deleted', {
                    policy: platformPolicyToDelete.policy_name,
                })
            )
                .catch((error) => {
                    policies.value = platformPoliciesTemp
                    throw error
                })
                .then(() => {
                    isModalOpen.value = false
                })
        }
    }

    function deletePlatformPolicies(policiesToDelete: PlatformPolicy[]) {
        const platformPoliciesTemp = [...policies.value]
        policiesToDelete.forEach((platformPolicy) => {
            deletePlatformPolicy(platformPolicy, false)
        })

        update(
            i18n.t('toast_success_platform_policies_deleted', {
                policies: policiesToDelete
                    .map((platformPolicy) => platformPolicy.policy_name)
                    .join(', '),
            })
        )
            .catch((error) => {
                policies.value = platformPoliciesTemp
                throw error
            })
            .then(() => {
                selectedPlatformPoliciesIds.value = []
                isModalOpen.value = false
            })
    }

    function handleEditOrCreatePlatformPolicy(
        policy: PlatformPolicy = new PlatformPolicy()
    ) {
        const editedPolicy = ref(new PlatformPolicy())
        editedPolicy.value = { ...policy }
        isModalOpen.value = true

        const disableCreate = computed(() => {
            return !(
                editedPolicy.value.country &&
                editedPolicy.value.language &&
                editedPolicy.value.policy_content
            )
        })

        openModal(
            {
                id: 'edit_platform_policy',
                title: editedPolicy.value.id
                    ? 'modal_title_update_platform_policy'
                    : 'modal_title_create_platform_policy',
                open: isModalOpen,
                onCancel: () => (isModalOpen.value = false),
                onSubmit: () => savePolicy(editedPolicy.value),
            },
            () =>
                h(UpdateOrCreatePlatformPolicyForm, {
                    modelValue: editedPolicy,
                }),
            {
                submitButtonText: editedPolicy.value.id ? 'update' : 'create',
                styles: {
                    submitButton: ModalButtonStyles['BLUE'],
                },
                submitButtonDisabled: disableCreate,
            }
        )
    }

    function savePolicy(platformPolicy: PlatformPolicy) {
        if (platformPolicy.id) {
            policies.value[
                policies.value.findIndex(
                    (policy: PlatformPolicy) => platformPolicy.id == policy.id
                )
            ] = platformPolicy
        } else {
            policies.value.push(platformPolicy)
        }

        let toast = 'toast_success_platform_policy_updated'
        if (!platformPolicy.id) {
            toast = 'toast_success_platform_policy_created'
        }
        update(
            i18n.t(toast, {
                policy: platformPolicy.policy_name,
            })
        )
        isModalOpen.value = false
    }

    const selectedPlatformPolicies = computed(() => {
        return policies.value.filter(
            (policy) =>
                policy.id &&
                selectedPlatformPoliciesIds.value.includes(policy.id)
        )
    })

    return {
        PlatformPoliciesTable,
        selectedPlatformPoliciesIds,
        handleDeletePlatformPolicies,
        handleEditOrCreatePlatformPolicy,
    }
}
