import {
    ModalButtonStyles,
    OpenModal,
    UpdateData,
    useTable,
} from '@composables/common'
import { NameAndId } from '@src/components'
import CampaignIncentiveEditForm from '@src/partials/campaigns/CampaignIncentiveEditForm.vue'
import CampaignIncentiveView from '@src/partials/campaigns/CampaignIncentiveView.vue'
import { AssetsIncentive } from '@src/types'
import { formatNumericValues } from '@src/utils/helpers'
import { useUserStore } from '@src/utils/stores/user'
import { Ref, computed, h, ref } from 'vue'
import { useI18n } from 'vue-i18n'

export function useCampaignIncentivesTable(
    incentives: Ref<AssetsIncentive[]>,
    currency: Ref<string | null>,
    update: (successMessage?: string | null) => Promise<void>,
    openModal: OpenModal,
    isModalOpen: Ref<boolean>
) {
    const userStore = useUserStore()
    const i18n = useI18n()

    const tableLength = computed(() => {
        return incentives.value.length
    })

    const {
        TableWrapper: CampaignIncentivesTable,
        selected: selectedIncentivesIds,
        updateSortOrder: updateIncentivesSortOrder,
    } = useTable({
        updateData: update as UpdateData,
        tableProps: {
            id: 'incentives_table',
            label: 'incentives',
            help: 'help_incentives_table',
            data: incentives,
            total: tableLength,
            sortableOrder: userStore.can('update_campaign'),
            itemOrderKey: 'position',
            itemIdKey: 'asset_inc_uid',
            columns: [
                {
                    label: 'id',
                    key: 'asset_inc_uid',
                    select: true,
                },
                {
                    label: 'id',
                    key: 'asset_inc_uid',
                    condition: userStore.can('see_id_columns'),
                    copy: true,
                },
                {
                    label: 'name',
                    key: 'incentives_title',
                    copy: true,
                },
                {
                    label: 'amount',
                    key: 'incentives_amount',
                    copy: true,
                    cellRenderer: (props: { rowData: AssetsIncentive }) => {
                        return formatNumericValues(
                            props.rowData.incentives_amount as number,
                            currency.value as string
                        )
                    },
                },
                {
                    label: 'rank',
                    key: 'position',
                    help: userStore.can('update_campaign')
                        ? 'help_drag_and_drop'
                        : '',
                    cellRenderer: (props: { rowData: AssetsIncentive }) => {
                        return props.rowData.position + 1
                    },
                },
            ],
            actions: [
                {
                    action: handleViewIncentive,
                    icon: 'fa-eye',
                    title: 'view',
                },
                {
                    action: handleEditOrCreateIncentive,
                    icon: 'fa-solid fa-pen',
                    title: 'edit',
                    condition: userStore.can('update_campaign'),
                },
                {
                    action: handleDeleteIncentive,
                    icon: 'fas fa-trash-alt',
                    title: 'delete',
                    color: 'text-danger-500',
                    condition: userStore.can('update_campaign'),
                },
            ],
        },
    })

    function handleViewIncentive(incentive: AssetsIncentive) {
        isModalOpen.value = true
        openModal(
            {
                id: 'view_campaign_incentive',
                title: 'modal_title_view_campaign_incentive',
                open: isModalOpen,
                onCancel: () => (isModalOpen.value = false),
            },
            () =>
                h(CampaignIncentiveView, {
                    modelValue: incentive,
                })
        )
    }

    function handleDeleteIncentive(incentive: AssetsIncentive) {
        isModalOpen.value = true
        openModal(
            {
                id: 'delete_campaign_incentive',
                title: 'modal_title_delete_campaign_incentive',
                open: isModalOpen,
                onCancel: () => (isModalOpen.value = false),
                onSubmit: () => deleteIncentive(incentive),
            },
            () =>
                h(NameAndId, {
                    idKey: 'asset_inc_uid',
                    nameKey: 'incentives_title',
                    modelValue: [incentive],
                }),
            {
                submitButtonText: 'delete',
                styles: {
                    submitButton: ModalButtonStyles['RED'],
                },
            }
        )
    }

    function handleDeleteIncentives() {
        isModalOpen.value = true
        openModal(
            {
                id: 'delete_campaign_incentives',
                title: 'modal_title_delete_campaign_incentives',
                open: isModalOpen,
                onCancel: () => (isModalOpen.value = false),
                onSubmit: () => deleteIncentives(selectedIncentives.value),
            },
            () =>
                h(NameAndId, {
                    idKey: 'asset_inc_uid',
                    nameKey: 'incentives_title',
                    modelValue: selectedIncentives.value,
                }),
            {
                submitButtonText: 'delete',
                styles: {
                    submitButton: ModalButtonStyles['RED'],
                },
            }
        )
    }

    function deleteIncentive(
        incentiveToDelete: AssetsIncentive,
        submit = true
    ) {
        const incentivesTemp = [...incentives.value]
        incentives.value.splice(
            incentives.value.findIndex(
                (incentive) =>
                    incentiveToDelete.asset_inc_uid == incentive.asset_inc_uid
            ),
            1
        )
        if (submit) {
            updateIncentivesSortOrder()
            update(
                i18n.t('toast_success_campaign_incentive_deleted', {
                    incentive: incentiveToDelete.incentives_title,
                })
            )
                .catch((error) => {
                    incentives.value = incentivesTemp
                    throw error
                })
                .then(() => {
                    isModalOpen.value = false
                })
        }
    }

    function deleteIncentives(incentivesToDelete: AssetsIncentive[]) {
        const incentivesTemp = [...incentives.value]
        incentivesToDelete.forEach((incentive) => {
            deleteIncentive(incentive, false)
        })
        updateIncentivesSortOrder()
        update(
            i18n.t('toast_success_campaign_incentives_deleted', {
                incentives: incentivesToDelete
                    .map((incentive) => incentive.incentives_title)
                    .join(', '),
            })
        )
            .catch((error) => {
                incentives.value = incentivesTemp
                throw error
            })
            .then(() => {
                selectedIncentivesIds.value = []
                isModalOpen.value = false
            })
    }

    function handleEditOrCreateIncentive(
        incentive: AssetsIncentive = new AssetsIncentive()
    ) {
        const editedIncentive = ref(new AssetsIncentive())
        editedIncentive.value = { ...incentive }
        isModalOpen.value = true

        const disableCreate = computed(() => {
            return !(
                editedIncentive.value.incentives_title &&
                editedIncentive.value.incentives_description
            )
        })
        openModal(
            {
                id: 'edit_campaign_incentive',
                title: editedIncentive.value.asset_inc_uid
                    ? 'modal_title_edit_campaign_incentive'
                    : 'modal_title_create_campaign_incentive',
                open: isModalOpen,
                onCancel: () => (isModalOpen.value = false),
                onSubmit: () => saveIncentive(editedIncentive.value),
            },
            () =>
                h(CampaignIncentiveEditForm, {
                    modelValue: editedIncentive,
                }),
            {
                submitButtonText: editedIncentive.value.asset_inc_uid
                    ? 'update'
                    : 'create',
                styles: {
                    submitButton: ModalButtonStyles['BLUE'],
                },
                submitButtonDisabled: disableCreate,
            }
        )
    }

    function saveIncentive(incentive: AssetsIncentive) {
        const incentivesTemp = [...incentives.value]
        let toast = 'toast_success_campaign_incentive_updated'
        if (!incentive.asset_inc_uid) {
            toast = 'toast_success_campaign_incentive_created'
            delete incentive.asset_inc_uid
            incentive.position = incentives.value.length
            incentives.value.push(incentive)
        } else {
            incentives.value[
                incentives.value.findIndex(
                    (item) => item.asset_inc_uid === incentive.asset_inc_uid
                )
            ] = incentive
        }

        update(
            i18n.t(toast, {
                incentive: incentive.incentives_title,
            })
        )
            .catch((error) => {
                incentives.value = incentivesTemp
                throw error
            })
            .then(() => {
                isModalOpen.value = false
            })
    }

    const selectedIncentives = computed(() => {
        return incentives.value.filter(
            (item: AssetsIncentive) =>
                item.asset_inc_uid &&
                selectedIncentivesIds.value.includes(item.asset_inc_uid)
        )
    })

    return {
        CampaignIncentivesTable,
        selectedIncentivesIds,
        handleEditOrCreateIncentive,
        handleDeleteIncentives,
    }
}
