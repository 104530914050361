<template>
    <div
        v-if="task.result_text"
        class="flex items-center gap-2"
    >
        <div class="max-w-[250px] truncate">
            {{ task.result_text }}
        </div>
        <button
            class="btn bg-primary-additional-500 hover:bg-primary-additional-600 whitespace-nowrap text-white"
            @click.stop="handleShowMore"
        >
            <fa icon="fa-eye" />
        </button>
    </div>
    <div v-else>-</div>
</template>

<script setup lang="ts">
import { PayoutTask } from '@src/types'
import { toRefs } from 'vue'

const props = defineProps<{
    task: PayoutTask
}>()
const { task } = toRefs(props)
const emit = defineEmits(['viewTaskResultDetails'])

function handleShowMore() {
    emit('viewTaskResultDetails', task.value)
}
</script>

<style scoped></style>
