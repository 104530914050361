<template>
    <div
        class="min-w-60"
        :class="{
            'my-4': label,
        }"
    >
        <div
            v-if="label"
            class="flex items-center justify-between"
        >
            <label
                class="mb-1 block text-sm font-medium"
                :for="name"
                >{{ $t(label) }}
                <span
                    v-if="required === true"
                    class="text-danger-500"
                    >*</span
                ></label
            >
            <Tooltip
                v-if="help"
                class="ml-2"
                bg="dark"
                size="md"
                position="left"
            >
                <div class="text-sm text-gray-200">{{ $t(help) }}</div>
            </Tooltip>
        </div>
        <input
            v-if="rows === 1"
            :id="name"
            v-model="textfieldValue"
            v-maska
            :autocomplete="autocomplete ? 'on' : 'off'"
            :data-maska="mask"
            :disabled="disabled"
            :name="name"
            :placeholder="placeholder"
            :required="required"
            :type="type"
            class="form-input w-full"
        />
        <textarea
            v-if="rows > 1"
            :id="name"
            v-model="textfieldValue"
            :autocomplete="autocomplete ? 'on' : 'off'"
            :disabled="disabled"
            :name="name"
            :required="required"
            :rows="rows"
            class="form-input w-full"
            type="text"
        ></textarea>
        <div
            v-if="bottomHelpText"
            class="mt-2 text-xs"
        >
            {{ $t(bottomHelpText) }}
        </div>
        <div
            v-if="errorText"
            class="text-danger-500 mt-2 text-xs"
        >
            {{ $t(errorText) }}
        </div>
    </div>
</template>
<script setup lang="ts">
import { Tooltip } from '@src/components'
import { useVModels } from '@vueuse/core'
import { vMaska } from 'maska'
import { computed } from 'vue'

const props = withDefaults(
    defineProps<{
        modelValue: string | null
        autocomplete?: boolean
        bottomHelpText?: string | null
        errorText?: string | null
        disabled?: boolean
        help?: string | null
        label?: string | null
        mask?: string
        name: string
        placeholder?: string
        required?: boolean
        rows?: number
        type?: string
    }>(),
    {
        modelValue: '',
        autocomplete: true,
        bottomHelpText: null,
        errorText: null,
        help: null,
        label: null,
        mask: '',
        placeholder: '',
        rows: 1,
        type: 'text',
    }
)

const emit = defineEmits(['update:modelValue'])

const { modelValue } = useVModels(props, emit)

const textfieldValue = computed({
    get() {
        return modelValue.value ?? ''
    },
    set(value) {
        modelValue.value = value
    },
})
</script>
