import { ContractType, OrderStatus } from '@src/enums'
import { Asset } from './Assets'
export class Contract {
    active = false
    contract_uid: string | null = null
    asset_uid: string | null = null
    asset_title: string | null = null
    contract_end_cause: string | null = null
    contract_ended_at: string | null = null
    contract_type: ContractType | null = null
    days_until_next_payment: number | null = null
    contract_payments_count: number | null = null
    interest_rate: number | null = null
    is_bullet_loan = false
    next_payment_date: Date | null = null
    platform_name: string | null = null
    payment_start_date: Date | null = null
    payment_end_date: Date | null = null
    orders_count: number | null = null
    created_at: Date | null = null
    updated_at: Date | null = null
    contract_period_type: 'fixed' | 'continuous' | null = null
    calculation_start_date: 'acceptance_date' | 'paid_date' | null = null
    contract_period_start: 'current' | 'next' | null = null
    deferred_clearance: number | null = null
    has_early_bird_promotion = false
    early_bird_deadline: Date | null = null
    early_bird_interest_rate: number | null = null
    external_wallet_id: string | null = null
    tax_calculation_enabled = false
    interest_rate_calculation_method:
        | 'IR_ACT_360'
        | 'IR_ACT_365'
        | 'IR_30_360'
        | null = null
    payment_dates: string[] = []
    confirmed = false
}

export class ContractDetails {
    asset: Asset | null = null
    allow_edit = true
    asset_uid: string | null = null
    calculation_start_date: 'acceptance_date' | 'paid_date' | null = null
    contract_end_cause: string | null = null
    contract_ended_at: string | null = null
    contract_payments_count: number | null = null
    contract_period_start: 'current' | 'next' | null = null
    contract_period_type: 'fixed' | 'continuous' | null = null
    contract_type: ContractType | null = null
    contract_uid: string | null = null
    deferred_clearance: number | null = null
    early_bird_deadline: Date | null = null
    early_bird_interest_rate: number | null = null
    external_wallet_id: string | null = null
    has_early_bird_promotion = false
    interest_rate: number | null = null
    tax_calculation_enabled = false
    interest_rate_calculation_method:
        | 'IR_ACT_360'
        | 'IR_ACT_365'
        | 'IR_30_360'
        | null = null
    is_bullet_loan = false
    next_payment_date: Date | null = null
    platform_name: string | null = null
    payment_start_date: Date | null = null
    payment_end_date: Date | null = null
    payment_dates: string[] = []
    confirmed = false
    created_at: Date | null = null
    updated_at: Date | null = null
}

export class InterestPayment {
    interest_payment_uid: string | null = null
    contract_uid: string | null = null
    date: Date | null = null
    paid_interest = false
    paid_clearance = false
    paid_percent_of_rest: number | null = null
    interest_bonus: number | null = null
    value_increase_bonus: number | null = null
    sum_paid_interest: number | null = null
    sum_paid_clearance: number | null = null
    sum_paid_rest: number | null = null
    sum_paid_bonus: number | null = null
    sum_paid_total: number | null = null
    created_at: Date | null = null
    updated_at: Date | null = null
}

export class TaxFeature {
    id: string | null = null
    natural_profile_id: string | null = null
    tax_id: string | null = null
    church_tax_rate: string | null = null
    kista_response: string | null = null
    created_at: Date | null = null
    updated_at: Date | null = null
}

export class InterestPaymentCalculation {
    pay_interest = true
    pay_clearance = true
    pay_percent_of_rest = 100
    interest_bonus: number | null = null
    value_increase_bonus: number | null = null
    export_csv = false
    persist = false
    export: 'sepa' | 'excel' | null = null
}

export class InterestPaymentCalculationWarnings {
    missing_bic: InterestPaymentCalculationWarningsBankAccount[] = []
    missing_iban: InterestPaymentCalculationWarningsBankAccount[] = []
    failed_transactions_count = 0
}

export interface InterestPaymentCalculationWarningsBankAccount {
    account_title: string
    order_uid: string
}

export class ContractOrder {
    account_bic: string | null = null
    account_iban: string | null = null
    account_title: string | null = null
    account_uid: string | null = null
    amount: number | null = null
    asset_uid: string | null = null
    asset_var_uid: string | null = null
    country: string | null = null
    created_on: Date | null = null
    incentive_uid: string | null = null
    interest_payment_transactions: InterestPaymentTransaction[] | null = []
    interest_payment_warnings: InterestPaymentCalculationWarnings | null = null
    failed_transactions_count: number | null = null
    missing_bic = false
    missing_iban = false
    investment_acceptance_date: Date | null = null
    order_uid: string | null = null
    paid_date: Date | null = null
    platform_name: string | null = null
    status = OrderStatus['PENDING']
    total_amount: number | null = null
}

export class InterestPaymentTransaction {
    clearance: number | null = null
    created_at: Date | null = null
    cumulative_rest: number | null = null
    current_loan_amount: number | null = null
    current_rest: number | null = null
    date: Date | null = null
    interest: number | null = null
    interest_payment_transaction_nr: number | null = null
    interest_payment_transaction_payout_successful = false
    interest_payment_transaction_uid: string | null = null
    interest_payment_uid: string | null = null
    new_basis: number | null = null
    order_uid: string | null = null
    paid_bonus: number | null = null
    paid_clearance: number | null = null
    paid_interest: number | null = null
    paid_rest: number | null = null
    paid_total_gross: number | null = null
    paid_total_net: number | null = null
    total_tax: number | null = null
    church_tax: number | null = null
    soli: number | null = null
    tax: number | null = null
    previous_interest_payment_transaction_uid: string | null = null
    transaction_failed = false
    updated_at: Date | null = null
}

export class PayoutTaskResource {
    id: string | null = null
    taskable_id: string | null = null
    taskable_type: string | null = null
    status: string | null = null
    type: string | null = null
    parent_task_id: string | null = null
    input_data: PayoutTaskInputData | null = null
    started_at: Date | null = null
    finished_at: Date | null = null
    finished_type:
        | 'info'
        | 'warning'
        | 'has-errors'
        | 'error'
        | 'success'
        | null = null
    expires_at: Date | null = null
    result_text: string | null = null
    result_data: PayoutTaskResultData | null = null
    created_at: Date | null = null
}

export class PayoutTaskInputData {
    interestPaymentId: string | null = null
    interestPaymentTransactionIds: string[] = []
}

export class PayoutTaskResultData {
    exception: string | null = null
    message: string | null = null
    errorCount = 0
    totalCount = 0
}

export class PayoutTask extends PayoutTaskResource {
    all_children: PayoutTaskResource[] = []
}
