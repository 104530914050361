<template>
    <div
        class="my-4 mb-2 grid grid-flow-col justify-start gap-2 sm:auto-cols-max sm:justify-end"
    >
        <FileUploadButton
            v-if="
                userStore.can('perform_interest_payments') &&
                taxCalculationEnabled
            "
            :key="uploadKistaResponseKey"
            title="upload_kista_response"
            accept=".csv"
            mode="private"
            @submit="onKistaResponseUpload"
        />
        <CreateButton
            v-if="userStore.can('perform_interest_payments')"
            title="new_calculation"
            @click.stop="handleNewInterestPaymentCalculation"
        />
    </div>
    <ContractInterestPaymentsTable />
    <NewInterestPaymentCalculationModal
        v-if="isPaymentCalculationModal"
        v-model="interestPaymentCalculation"
        :currency="assetCurrency"
        :interest-payment="interestPayment"
        :warnings="interestPaymentCalculationWarnings"
        :is-last-payment="isLastPayment"
        :open="isPaymentCalculationModal"
        width="2xl"
        @cancel="handleNewInterestPaymentCalculationCancel"
        @submit="newInterestPaymentCalculationSubmit"
    />
</template>

<script setup lang="ts">
import { useContractInterestPaymentsTable } from '@composables/contracts/useContractInterestPaymentsTable'
import CreateButton from '@src/partials/actions/CreateButton.vue'
import NewInterestPaymentCalculationModal from '@src/partials/contracts/NewInterestPaymentCalculationModal.vue'
import {
    InterestPayment,
    InterestPaymentCalculation,
    InterestPaymentCalculationWarnings,
} from '@src/types'
import {
    getInterestPaymentCalculation,
    getInterestPayments,
    importKistaResponse,
} from '@src/utils/api/contracts'
import { downloadFile } from '@src/utils/helpers'
import { useToastStore } from '@src/utils/stores/toast'
import { useUserStore } from '@src/utils/stores/user'
import { onMounted, ref, toRefs } from 'vue'
import { useI18n } from 'vue-i18n'
import FileUploadButton from '@partials/actions/FileUploadButton.vue'
import { UploadFileResponse } from '@utils/api/s3'

const props = defineProps<{
    contractId: string | null
    assetCurrency: string | null
    taxCalculationEnabled: boolean | null
}>()

const emit = defineEmits(['getCount', 'update'])
const { contractId, assetCurrency } = toRefs(props)

const toast = useToastStore()
const i18n = useI18n()
const userStore = useUserStore()

const interestPaymentCalculation = ref<InterestPaymentCalculation>(
    new InterestPaymentCalculation()
)

const interestPayments = ref<InterestPayment[]>([])
const interestPaymentsCount = ref<number>(0)
const interestPayment = ref<InterestPayment>(new InterestPayment())
const isLastPayment = ref(false)
const interestPaymentCalculationWarnings =
    ref<InterestPaymentCalculationWarnings>(
        new InterestPaymentCalculationWarnings()
    )

const isPaymentCalculationModal = ref(false)

const { ContractInterestPaymentsTable } = useContractInterestPaymentsTable(
    interestPayments,
    interestPaymentsCount,
    assetCurrency
)

async function getInterestPaymentCalculationRequest() {
    return await getInterestPaymentCalculation(
        contractId.value as string,
        interestPaymentCalculation.value
    )
}

async function handleNewInterestPaymentCalculation() {
    interestPaymentCalculation.value = new InterestPaymentCalculation()
    const interestPaymentCalculationRequest =
        await getInterestPaymentCalculationRequest()
    isLastPayment.value = interestPaymentCalculationRequest.data.is_last_payment
    interestPayment.value =
        interestPaymentCalculationRequest.data.interest_payment
    interestPaymentCalculationWarnings.value =
        interestPaymentCalculationRequest.data.warnings
    isPaymentCalculationModal.value = true
}

function handleNewInterestPaymentCalculationCancel() {
    isPaymentCalculationModal.value = false
}

async function loadInterestPayments() {
    const interestPaymentsRequest = await getInterestPayments(
        contractId.value as string
    )
    interestPayments.value = interestPaymentsRequest.data.interest_payments
    interestPaymentsCount.value =
        interestPaymentsRequest.data.interest_payments_count
    emit('getCount', interestPaymentsCount.value)
}

async function newInterestPaymentCalculationSubmit() {
    isPaymentCalculationModal.value = false
    const interestPaymentCalculationRequest =
        await getInterestPaymentCalculationRequest()
    interestPayment.value =
        interestPaymentCalculationRequest.data.interest_payment
    if (interestPaymentCalculation.value.persist !== true) {
        interestPaymentCalculation.value.persist = false
        isPaymentCalculationModal.value = true
    } else {
        toast.success(i18n.t('toast_success_interest_payment_execute'))
    }

    if (interestPaymentCalculationRequest.data.download_url) {
        downloadFile(interestPaymentCalculationRequest.data.download_url)
    }

    await loadInterestPayments()
    emit('update')
}

const uploadKistaResponseKey = ref(0)
async function onKistaResponseUpload(response: UploadFileResponse) {
    console.log(response)
    try {
        const { data } = await importKistaResponse(
            contractId.value as string,
            response.key
        )
        toast.success(
            i18n.t('toast_success_kista_response_import', {
                newTaxFeaturesCount: data.taxFeatures.length,
            })
        )
        await loadInterestPayments()
    } catch (e) {
        toast.error(e)
        console.log(e)
    } finally {
        uploadKistaResponseKey.value++ // triggers re-mount of upload button for new import
    }
}

onMounted(async () => {
    await loadInterestPayments()
})
</script>

<style scoped></style>
