<template>
    <Modal
        id="create-campaign-modal"
        ref="create-campaign-modal"
        v-model:open="open"
        title="create_campaign"
        width="xl"
        @submit="submit"
        @cancel="cancel"
    >
        <TextField
            v-model="modelValue.asset_title"
            label="campaign_title"
            name="campaign_title"
            :required="true"
            :disabled="disabledFields?.includes('asset_title')"
            help="help_campaign_title"
        />

        <TextField
            v-model="modelValue.asset_subtitle"
            label="campaign_subtitle"
            name="campaign_subtitle"
            :required="true"
            :disabled="disabledFields?.includes('asset_subtitle')"
            help="help_campaign_subtitle"
        />

        <SelectField
            v-model="modelValue.platform_name"
            name="platform_name"
            label="platform"
            help="help_platform_name_campaign"
            :options="platformsSelectOptions"
            :required="true"
            :disabled="disabledFields?.includes('platform_name')"
            :on-search="loadPlatformsSelectOptions"
        />
        <SelectField
            v-model="modelValue.asset_uid"
            name="asset_title"
            label="asset"
            help="help_asset_title_campaign"
            :options="assetSelectOptions"
            :required="true"
            :disabled="disabledFields?.includes('asset_uid')"
            :on-search="loadAssetSelectOptions"
        />

        <SelectField
            v-model="modelValue.country"
            name="country"
            label="country"
            help="help_country_campaign"
            :options="countries"
            :required="true"
            :disabled="disabledFields?.includes('country')"
        />
        <SelectField
            v-model="modelValue.language"
            name="language"
            label="language"
            help="help_language_campaign"
            :options="languages"
            :required="true"
            :disabled="disabledFields?.includes('language')"
        />

        <template #actions>
            <MultiActionButton />
        </template>
    </Modal>
</template>
<script setup lang="ts">
import { useMultiActionButton } from '@composables/common'
import { Modal, SelectField, TextField } from '@src/components'
import { Asset, Campaign, Platform } from '@src/types'
import { getAsset, getAssets } from '@src/utils/api/assets'
import { getPlatforms } from '@src/utils/api/platforms'
import { useCountryStore } from '@src/utils/stores/countries'
import { useLanguageStore } from '@src/utils/stores/languages'
import { useVModels } from '@vueuse/core'
import { computed, onMounted, ref, watch } from 'vue'

const props = defineProps<{
    open: boolean
    modelValue: Campaign
    disabledFields?: string[] | null
}>()

const languages = ref<{ label: string; value: string | null }[]>([])
const countries = ref<{ label: string; value: string | null }[]>([])
const platformsSelectOptions = ref<string[]>([])
const assetSelectOptions = ref<{ label: string; value: string | null }[]>([])
const platforms = ref<Platform[]>([])
const assets = ref<Asset[]>([])

async function loadPlatformsSelectOptions(search: string) {
    const initialPlatformsRequest = await getPlatforms({
        'filter[platform_name]': search,
    })
    platformsSelectOptions.value = initialPlatformsRequest.data.platforms.map(
        (platform: Platform) => {
            return platform.platform_name as string
        }
    )
}

async function loadAssetSelectOptions(search: string) {
    const assetsRequest = await getAssets({
        'filter[asset_title]': search,
    })
    assetSelectOptions.value = assetsRequest.data.assets.map((asset: Asset) => {
        return {
            label: asset.asset_title as string,
            value: asset.asset_uid,
        }
    })
}

const emit = defineEmits([
    'update:modelValue',
    'update:open',
    'cancel',
    'submit',
])

const countryStore = useCountryStore()
const languageStore = useLanguageStore()

const { modelValue, open } = useVModels(props, emit)

const disableCreate = computed(() => {
    return (
        !modelValue.value.asset_title ||
        !modelValue.value.asset_subtitle ||
        !modelValue.value.platform_name ||
        !modelValue.value.asset_uid ||
        !modelValue.value.country ||
        !modelValue.value.language
    )
})

const { MultiActionButton } = useMultiActionButton({
    id: 'create-campaign-button',
    actions: [
        {
            label: 'create',
            action: submit,
            disabled: () => disableCreate.value,
        },
        {
            label: 'create_and_view',
            action: submitAndView,
            disabled: () => disableCreate.value,
        },
        {
            label: 'create_and_edit',
            action: submitAndEdit,
            disabled: () => disableCreate.value,
        },
    ],
})

function cancel() {
    emit('cancel')
}

function submit() {
    emit('submit')
}

function submitAndView() {
    emit('submit', 'view')
}

function submitAndEdit() {
    emit('submit', 'edit')
}

onMounted(async () => {
    if (modelValue.value.platform_name) {
        await loadPlatformsSelectOptions(modelValue.value.platform_name)
    }
    if (modelValue.value.asset_uid) {
        const assetRequest = await getAsset(modelValue.value.asset_uid)
        await loadAssetSelectOptions(
            assetRequest.data.asset.asset_title as string
        )
    }

    const platformsRequest = await getPlatforms()
    platforms.value = platformsRequest.data.platforms

    const assetsRequest = await getAssets()
    assets.value = assetsRequest.data.assets

    loadData()
})

async function loadData() {
    countries.value = await countryStore.loadAndSortCountriesOptionsAsync()
    languages.value = await languageStore.loadAndSortLanguagesOptionsAsync()
}

watch(open, (newValue) => {
    if (newValue) {
        loadData()
    }
})
</script>
