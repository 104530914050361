<template>
    <Modal
        id="create-legal-account-modal"
        ref="create-legal-account-modal"
        v-model:open="open"
        title="legal_account_create"
        width="xl"
        @submit="submit"
        @cancel="cancel"
    >
        <TextField
            v-model.trim="modelValue.company_name"
            label="company_name"
            name="company_name"
            :required="true"
            help="help_account_company_name"
        />

        <TextField
            v-model.trim="modelValue.registration_number"
            label="profile_company_registration_number"
            name="registration_number"
            :required="false"
            help="help_account_company_registration_number"
        />

        <TextField
            v-model.trim="modelValue.description"
            label="profile_company_description"
            name="description"
            :required="false"
            help="help_account_company_description"
        />

        <template #actions>
            <MultiActionButton />
        </template>
    </Modal>
</template>
<script setup lang="ts">
import { useMultiActionButton } from '@composables/common'
import { Modal, TextField } from '@src/components'
import { LegalProfile } from '@src/types'
import { useVModels } from '@vueuse/core'
import { computed } from 'vue'

const props = defineProps<{
    open: boolean
    modelValue: LegalProfile
}>()

const emit = defineEmits([
    'update:modelValue',
    'update:open',
    'cancel',
    'submit',
])

const { modelValue, open } = useVModels(props, emit)

const { MultiActionButton } = useMultiActionButton({
    id: 'create-asset-button',
    actions: [
        {
            label: 'create',
            action: submit,
            disabled: () => disableCreate.value,
        },
        {
            label: 'create_and_view',
            action: submitAndView,
            disabled: () => disableCreate.value,
        },
        {
            label: 'create_and_edit',
            action: submitAndEdit,
            disabled: () => disableCreate.value,
        },
    ],
})

function cancel() {
    emit('cancel')
}

function submit() {
    emit('submit')
}

function submitAndView() {
    emit('submit', 'view')
}

function submitAndEdit() {
    emit('submit', 'edit')
}

const disableCreate = computed(() => {
    return !modelValue.value.company_name
})
</script>
