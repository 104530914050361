<template>
    <div>
        <!-- Sidebar backdrop (mobile only) -->
        <div
            class="bg-primary-800 fixed inset-0 z-[98] bg-opacity-30 transition-opacity duration-200 lg:z-auto lg:hidden"
            :class="
                sidebarOpen ? 'opacity-100' : 'pointer-events-none opacity-0'
            "
            aria-hidden="true"
        ></div>

        <!-- Sidebar -->
        <div
            id="sidebar"
            ref="sidebar"
            class="no-scrollbar lg:sidebar-expanded:!w-64 bg-primary-700 absolute left-0 top-0 z-[99] flex h-screen w-64 shrink-0 transform flex-col overflow-y-scroll p-4 transition-all duration-200 ease-in-out lg:static lg:left-auto lg:top-auto lg:w-20 lg:translate-x-0 lg:overflow-y-auto 2xl:!w-64"
            :class="sidebarOpen ? 'translate-x-0' : '-translate-x-64'"
        >
            <!-- Sidebar header -->
            <div class="mb-10 flex justify-between pr-3 sm:px-2">
                <!-- Close button -->
                <button
                    class="text-gray-500 hover:text-gray-400 lg:hidden"
                    @click.stop="toggleSidebarOpen"
                >
                    <span class="sr-only">Close sidebar</span>
                    <svg
                        class="h-6 w-6 fill-current"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z"
                        />
                    </svg>
                </button>
                <!-- Logo -->
                <router-link
                    class="block"
                    to="/"
                >
                    <img
                        src="@src/images/conda-logo.png"
                        alt="logo"
                        width="48"
                        height="48"
                        class="rounded-full"
                    />
                </router-link>
            </div>

            <!-- Links -->
            <div class="space-y-8">
                <!-- Admin-UI group -->
                <div>
                    <h3
                        class="pl-3 text-xs font-semibold uppercase text-gray-500"
                    >
                        <span
                            class="lg:sidebar-expanded:hidden hidden w-6 text-center lg:block 2xl:hidden"
                            aria-hidden="true"
                            >•••</span
                        >
                        <span
                            class="lg:sidebar-expanded:block lg:hidden 2xl:block"
                            >{{ $t('main') }}</span
                        >
                    </h3>
                    <ul class="mt-3">
                        <!-- Dashboard -->
                        <SidebarSingleLink
                            v-if="userStore.can('view_dashboard')"
                            label="dashboard"
                            :to="{
                                name: 'dashboard',
                            }"
                        >
                            <fa icon="fa-house" />
                        </SidebarSingleLink>
                        <!-- Platforms -->
                        <SidebarSingleLink
                            v-if="userStore.can('view_platforms')"
                            label="platforms"
                            :to="{ name: 'platforms' }"
                        >
                            <fa icon="fa-store" />
                        </SidebarSingleLink>

                        <!-- Platforms -->

                        <SidebarSingleLink
                            v-if="userStore.can('view_issuers')"
                            label="issuers"
                            :to="{ name: 'issuers' }"
                        >
                            <fa icon="fa-building" />
                        </SidebarSingleLink>

                        <SidebarSingleLink
                            v-if="userStore.can('view_assets')"
                            label="assets"
                            :to="{ name: 'assets' }"
                        >
                            <fa icon="fa-sack-dollar" />
                        </SidebarSingleLink>

                        <SidebarSingleLink
                            v-if="userStore.can('view_campaigns')"
                            label="campaigns"
                            :to="{ name: 'campaigns' }"
                        >
                            <fa icon="fa-solid fa-rocket" />
                        </SidebarSingleLink>

                        <SidebarLinkGroup
                            v-if="userStore.can('view_orders')"
                            label="orders"
                            :keep-open="
                                currentRoute.fullPath.includes('orders') ||
                                currentRoute.fullPath.includes('open-basket')
                            "
                            :sidebar-open="sidebarOpen"
                            @toggle-sidebar="toggleSidebarOpen"
                        >
                            <template #icon>
                                <fa icon="fa-cart-shopping" />
                            </template>

                            <SidebarLinkInGroup
                                v-if="userStore.can('view_orders')"
                                label="orders"
                                to="/orders"
                            />

                            <SidebarLinkInGroup
                                v-if="userStore.can('view_orders')"
                                label="open_baskets"
                                to="/open-baskets"
                            />
                        </SidebarLinkGroup>

                        <!-- Contracts -->
                        <SidebarSingleLink
                            v-if="userStore.can('view_contracts')"
                            label="contracts"
                            to="/contracts"
                        >
                            <fa icon="fa-solid fa-file-signature" />
                        </SidebarSingleLink>

                        <!-- Accounts -->
                        <SidebarSingleLink
                            v-if="userStore.can('view_accounts')"
                            label="accounts"
                            to="/accounts"
                        >
                            <fa icon="fa-users" />
                        </SidebarSingleLink>

                        <!-- E-Mails -->
                        <SidebarSingleLink
                            v-if="userStore.can('view_email_audits')"
                            label="emails"
                            to="/emails"
                        >
                            <fa icon="fa-envelope" />
                        </SidebarSingleLink>
                        <!-- Transactions -->
                        <SidebarSingleLink
                            v-if="userStore.can('view_transactions')"
                            label="transactions"
                            to="/transactions"
                        >
                            <fa icon="fa-money-bill-transfer" />
                        </SidebarSingleLink>
                        <!-- PayoutTasks -->
                        <SidebarSingleLink
                            v-if="userStore.can('view_tasks')"
                            label="tasks"
                            to="/tasks"
                        >
                            <fa icon="fa-list-check" />
                        </SidebarSingleLink>
                    </ul>
                </div>
            </div>

            <!-- Expand / collapse button -->
            <div
                class="mt-auto hidden justify-end pt-3 lg:inline-flex 2xl:hidden"
            >
                <div class="px-3 py-2">
                    <button @click.stop="toggleSidebarOpen">
                        <span class="sr-only">Expand / collapse sidebar</span>
                        <svg
                            class="sidebar-expanded:rotate-180 h-6 w-6 fill-current"
                            viewBox="0 0 24 24"
                        >
                            <path
                                class="text-gray-400"
                                d="M19.586 11l-5-5L16 4.586 23.414 12 16 19.414 14.586 18l5-5H7v-2z"
                            />
                            <path
                                class="text-gray-600"
                                d="M3 23H1V1h2z"
                            />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted } from 'vue'

import SidebarSingleLink from '@src/partials/sidebar/SidebarSingleLink.vue'
// NOTE: You also have the option to use these components to structure the sidebar links in groups
import SidebarLinkGroup from '@src/partials/sidebar/SidebarLinkGroup.vue'
import SidebarLinkInGroup from '@src/partials/sidebar/SidebarLinkInGroup.vue'

import { useUserStore } from '@src/utils/stores/user'
import { useVModels } from '@vueuse/core'
import { useRouter } from 'vue-router'

const props = defineProps<{
    sidebarOpen: boolean
}>()

const emit = defineEmits(['toggle-sidebar', 'update:sidebarOpen'])
const currentRoute = useRouter().currentRoute.value

const { sidebarOpen } = useVModels(props, emit)

const userStore = useUserStore()

// close if the esc key is pressed
const keyHandler = (event: KeyboardEvent) => {
    if (!sidebarOpen.value || event.code !== 'Escape') return
    toggleSidebarOpen()
}

function toggleSidebarOpen() {
    emit('toggle-sidebar')
}

onMounted(() => {
    document.addEventListener('keydown', keyHandler)
})

onUnmounted(() => {
    document.removeEventListener('keydown', keyHandler)
})
</script>
