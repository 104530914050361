<template>
    <div class="flex items-center">
        <router-link
            :to="'/platforms/' + rowData.platform_name"
            class="text-blue-500"
        >
            {{ rowData.platform_name }}
        </router-link>
        <template v-if="rowData.parent_platform_name">
            ({{ rowData.parent_platform_name }})
        </template>
        <CopyButton :value="platformNameText" />
    </div>
</template>

<script setup lang="ts">
import { Platform } from '@src/types'
import CopyButton from '@src/partials/actions/CopyButton.vue'
import { computed } from 'vue'

const props = defineProps<{
    rowData: Platform | Record<string, unknown>
}>()

const platformNameText = computed(() => {
    if (props.rowData.parent_platform_name) {
        return `${props.rowData.platform_name} (${props.rowData.parent_platform_name})`
    }
    return props.rowData.platform_name
})
</script>

<style scoped></style>
