import { Summary as SummaryVue } from '@src/components'
import { SummaryItem } from '@src/types/Summary'
import { Ref, h } from 'vue'
import { RouteLocationRaw } from 'vue-router'

export interface SummaryOptions {
    title?: string
    url?: RouteLocationRaw
    icon?: string
}

export function useSummary(
    items: Ref<SummaryItem[]>,
    options?: SummaryOptions
) {
    const Summary = () => {
        return h(SummaryVue, {
            items: items.value,
            ...options,
        })
    }

    return {
        Summary,
    }
}
