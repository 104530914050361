<template>
    <div>
        <div class="mb-2 flex flex-row gap-2">
            <FileUploadButton
                title="upload_nyala_registry_extract"
                accept=".zip"
                mode="private"
                :file-name="nyalaRegistryExtractFileName"
                @submit="onNyalaRegistryExtractUpload"
                @delete="onNyalaRegistryExtractDelete"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import FileUploadButton from '@partials/actions/FileUploadButton.vue'
import { UploadFileResponse } from '@src/utils/api/s3'
import { Ref, ref, toRefs } from 'vue'

const props = defineProps<{
    modelValue: Ref<UploadFileResponse | null>
}>()

const { modelValue } = toRefs(props)
const emit = defineEmits(['update:modelValue'])

const nyalaRegistryExtractFileName = ref<string | null>(null)

function onNyalaRegistryExtractUpload(
    response: UploadFileResponse,
    fileName: string
) {
    nyalaRegistryExtractFileName.value = fileName
    modelValue.value = response
    emit('update:modelValue', response)
}

function onNyalaRegistryExtractDelete() {
    modelValue.value = null
    nyalaRegistryExtractFileName.value = null
}
</script>

<style scoped></style>
