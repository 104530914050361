<template>
    <SelectField
        v-model="modelValue.action"
        name="action"
        label="event"
        help="help_platform_webhook_event"
        :options="Object.values(PlatformWebhookAction).map((value: string) => value)"
        :required="true"
    />
    <TextField
        v-model="modelValue.url"
        name="url"
        label="url"
        help="help_platform_webhook_url"
        :required="true"
    />
    <TextField
        v-if="!modelValue.id"
        v-model="modelValue.secret"
        name="secret"
        label="secret"
        help="help_platform_webhook_secret"
        :required="true"
    />
</template>
<script setup lang="ts">
import { SelectField, TextField } from '@src/components'
import { PlatformWebhookAction } from '@src/enums/Platform'
import { PlatformWebhook } from '@src/types'
import { Ref, toRefs, watch } from 'vue'

const props = defineProps<{
    modelValue: Ref<PlatformWebhook>
}>()

const { modelValue } = toRefs(props)
const emit = defineEmits(['update:modelValue'])

watch(modelValue.value, (value) => {
    emit('update:modelValue', value)
})
</script>
