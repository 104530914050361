<template>
    <div>
        <TextField
            v-model="modelValue.name"
            name="campaign_team_member_name"
            label="campaign_team_member_name"
            help="help_campaign_team_member_name"
            :required="true"
        />
        <TextField
            v-model="modelValue.title"
            name="campaign_team_member_title"
            label="campaign_team_member_title"
            help="help_campaign_team_member_title"
            :required="true"
        />
        <ImageUpload
            v-model="modelValue.picture"
            name="campaign_team_member_image"
            label="campaign_team_member_image"
            help="help_campaign_team_member_image"
        />
        <EditorField
            v-model="modelValue.bio"
            name="campaign_team_member_bio"
            label="campaign_team_member_bio"
            help="help_campaign_team_member_bio"
        />
    </div>
</template>

<script setup lang="ts">
import { TeamMember } from '@src/types'

import { EditorField, ImageUpload, TextField } from '@src/components'
import { Ref, toRefs, watch } from 'vue'

const props = defineProps<{
    modelValue: Ref<TeamMember>
}>()

const { modelValue } = toRefs(props)

const emit = defineEmits(['update:modelValue'])

watch(
    modelValue,
    (value) => {
        emit('update:modelValue', value)
    },
    { deep: true }
)
</script>

<style lang="scss" scoped></style>
