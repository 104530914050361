import { defineStore } from 'pinia'
import { ToastNotification, ToastNotificationType } from '@src/types'
import { ref } from 'vue'

export const useToastStore = defineStore('toast', () => {
    const notifications = ref<ToastNotification[]>([])

    function push(toast: ToastNotification): void {
        notifications.value.push(toast)
    }

    function pop(): void {
        notifications.value.shift()
    }

    function success(message: string) {
        notifications.value.push({
            type: ToastNotificationType.SUCCESS,
            message: message,
        })
    }

    function warning(message: string) {
        notifications.value.push({
            type: ToastNotificationType.WARNING,
            message: message,
        })
    }

    function error(message: string) {
        notifications.value.push({
            type: ToastNotificationType.ERROR,
            message: message,
        })
    }

    return {
        notifications,
        push,
        pop,
        success,
        warning,
        error,
    }
})
