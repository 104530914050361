<template>
    <div>
        <FileUploadButton
            title="button_id_ecsp_document_upload"
            accept=".png,.jpg,.jpeg,.pdf"
            mode="ECSP"
            :deletable="false"
            @submit="onUpload"
        />
        <div v-if="modelValue.key">
            <SelectField
                v-model="modelValue.ecsp_status"
                name="ecsp_status"
                label="ecsp_status"
                help="help_scsp_status"
                :options="mapEnumToArray(EcspStatusType)"
                :reduce="reduceEnumValue"
                :required="true"
            />
        </div>
        <div v-if="modelValue.key">
            <TextField
                v-model="modelValue.verification_comment"
                label="comment"
                name="verification_comment"
                :rows="4"
                help="help_comment"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import { useEnum } from '@composables/common'
import FileUploadButton from '@partials/actions/FileUploadButton.vue'
import { SelectField, TextField } from '@src/components'
import { EcspStatusType } from '@src/enums'
import { ECSPDocument } from '@src/types'
import { UploadFileResponse } from '@src/utils/api/s3'
import { Ref, toRefs, watch } from 'vue'

const props = defineProps<{
    modelValue: Ref<ECSPDocument>
}>()

const { modelValue } = toRefs(props)

const emit = defineEmits(['update:modelValue'])

const { mapEnumToArray, reduceEnumValue } = useEnum()

watch(
    modelValue,
    (value) => {
        emit('update:modelValue', value)
    },
    { deep: true }
)

function onUpload(response: UploadFileResponse) {
    modelValue.value = response
}
</script>

<style lang="scss" scoped></style>
