<template>
    <Modal
        id="transfer-to-project-wallet-modal"
        v-model:open="open"
        title="modal_title_transfer_to_project_wallet"
        @cancel="cancel"
        @submit="submit"
    >
        <div>
            <TextField
                v-model="wallet"
                label="wallet"
                name="wallet_id"
                help="help_transfer_to_project_wallet_wallet"
            />
        </div>
        <div>
            <Datepicker
                v-model="date"
                label="date"
                name="date"
                help="help_transfer_to_project_wallet_date"
            />
        </div>

        <!-- Modal footer -->
        <template #actions>
            <button
                :disabled="disableSubmit"
                type="submit"
                class="btn-sm bg-gray-500 text-white"
                :class="{
                    'bg-primary-accent-500 hover:bg-primary-accent-600':
                        !disableSubmit,
                }"
            >
                {{ $t('transfer') }}
            </button>
        </template>
    </Modal>
</template>
<script setup lang="ts">
import { Datepicker, Modal, TextField } from '@src/components'
import { useVModels } from '@vueuse/core'
import { format } from 'date-fns'
import { computed, ref } from 'vue'

const props = defineProps<{
    open: boolean
}>()

const emit = defineEmits(['update:open', 'cancel', 'submit'])

const { open } = useVModels(props, emit)

const wallet = ref('')
const date = ref(new Date())

const disableSubmit = computed(() => {
    return wallet.value && date.value ? false : true
})

function submit(): void {
    emit('submit', {
        wallet: wallet.value,
        date: format(new Date(date.value), 'yyyy-MM-dd'),
    })
    reset()
}

function cancel() {
    emit('cancel')
    reset()
}

function reset() {
    wallet.value = ''
    date.value = new Date()
}
</script>
