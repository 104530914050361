import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import * as Sentry from '@sentry/vue'
import App from '@src/App.vue'
import '@src/css/style.scss'
import router from '@src/router'
import { i18n } from '@src/utils/lang'
import { createPinia } from 'pinia'
import { createApp } from 'vue'
import VueSecureHTML from 'vue-html-secure'
import VueSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import Vue3ColorPicker from 'vue3-colorpicker'
import 'vue3-colorpicker/style.css'

import {
    faAngleLeft,
    faAngleRight,
    faAnglesLeft,
    faAnglesRight,
    faArrowDownShortWide,
    faArrowLeft,
    faArrowRightToBracket,
    faArrowTurnDown,
    faArrowUpWideShort,
    faArrowsRotate,
    faArrowsUpDown,
    faBan,
    faBook,
    faBug,
    faBuilding,
    faBuildingColumns,
    faCalendar,
    faCalendarXmark,
    faCartShopping,
    faChair,
    faCheck,
    faChevronDown,
    faCircleCheck,
    faCircleNodes,
    faCircleNotch,
    faCircleXmark,
    faCloudArrowDown,
    faComment,
    faComments,
    faCopy,
    faCubes,
    faDownLeftAndUpRightToCenter,
    faDownload,
    faEllipsis,
    faEnvelope,
    faEye,
    faFile,
    faFileCsv,
    faFileSignature,
    faFloppyDisk,
    faForward,
    faGear,
    faHandHoldingDollar,
    faHouse,
    faInbox,
    faLink,
    faList,
    faListCheck,
    faMagnifyingGlass,
    faMoneyBill,
    faMoneyBillTransfer,
    faPaperPlane,
    faPen,
    faPerson,
    faPlus,
    faPlusCircle,
    faQuestion,
    faRocket,
    faRotate,
    faRotateLeft,
    faSackDollar,
    faScrewdriverWrench,
    faSection,
    faShop,
    faSquareCheck,
    faStore,
    faTableCells,
    faTableCellsLarge,
    faThumbtack,
    faTrashCan,
    faTriangleExclamation,
    faUpRightAndDownLeftFromCenter,
    faUser,
    faUsers,
    faXmark,
} from '@fortawesome/free-solid-svg-icons'
library.add(
    faStore,
    faInbox,
    faCalendar,
    faRocket,
    faHouse,
    faStore,
    faSection,
    faShop,
    faUser,
    faUsers,
    faMoneyBillTransfer,
    faMoneyBill,
    faBuilding,
    faListCheck,
    faComment,
    faComments,
    faGear,
    faScrewdriverWrench,
    faArrowRightToBracket,
    faCircleNodes,
    faCubes,
    faChevronDown,
    faPlus,
    faFloppyDisk,
    faCartShopping,
    faLink,
    faList,
    faTableCells,
    faTableCellsLarge,
    faArrowLeft,
    faSackDollar,
    faEye,
    faCloudArrowDown,
    faHandHoldingDollar,
    faBan,
    faChair,
    faSquareCheck,
    faArrowLeft,
    faPaperPlane,
    faArrowsUpDown,
    faArrowUpWideShort,
    faArrowDownShortWide,
    faSackDollar,
    faBuildingColumns,
    faPerson,
    faCircleCheck,
    faCircleXmark,
    faForward,
    faTrashCan,
    faPen,
    faCheck,
    faEllipsis,
    faFileSignature,
    faXmark,
    faDownload,
    faArrowsRotate,
    faFileCsv,
    faAnglesLeft,
    faAnglesRight,
    faAngleLeft,
    faAngleRight,
    faFile,
    faRotate,
    faRotateLeft,
    faPlusCircle,
    faCircleNotch,
    faTriangleExclamation,
    faEnvelope,
    faCopy,
    faUpRightAndDownLeftFromCenter,
    faDownLeftAndUpRightToCenter,
    faCalendarXmark,
    faThumbtack,
    faQuestion,
    faBug,
    faBook,
    faMagnifyingGlass,
    faArrowTurnDown
)

const pinia = createPinia()

// create the application
const app = createApp(App)
// setup sentry
if (import.meta.env.VITE_SENTRY_ENABLED === 'true') {
    Sentry.init({
        app,
        dsn: 'https://0231728cfb5048bfb80c8257db46dbf1@o136928.ingest.sentry.io/4505069623902208',
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            }),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        // Set the environment
        environment: import.meta.env.VITE_SENTRY_ENV,
    })
}
// register global objects
app.use(pinia)
app.use(router)
app.use(i18n)
app.use(VueSecureHTML)
app.use(Vue3ColorPicker)
app.component('VueSelect', VueSelect)
app.component('Fa', FontAwesomeIcon)

// mount the application
app.mount('#app')
