<template>
    <div class="min-w-56">
        <div class="my-2 flex items-center">
            <div class="form-switch">
                <input
                    :id="name"
                    v-model="inputValue"
                    type="checkbox"
                    class="sr-only"
                    :disabled="disabled"
                    @change="() => $emit('toggle')"
                />
                <label
                    class="bg-gray-400"
                    :for="name"
                >
                    <span
                        class="bg-white shadow-sm"
                        aria-hidden="true"
                    ></span>
                    <span
                        v-if="label"
                        class="sr-only"
                        >{{ $t(label) }}</span
                    >
                </label>
            </div>
            <div
                v-if="label"
                class="ml-2 text-sm italic text-gray-400"
            >
                {{ $t(label) }}
            </div>
        </div>
        <div
            v-if="help"
            class="mt-2 text-xs"
        >
            {{ $t(help) }}
        </div>
    </div>
</template>
<script setup lang="ts">
import { useVModels } from '@vueuse/core'
import { computed } from 'vue'

const props = defineProps<{
    name: string
    label?: string
    modelValue: boolean | unknown
    help?: string
    disabled?: boolean
}>()

const emit = defineEmits(['update:modelValue', 'toggle'])

const { modelValue, label, help } = useVModels(props, emit)

const inputValue = computed({
    get() {
        if (modelValue.value === undefined || modelValue.value === null) {
            return false
        }
        return modelValue.value
    },
    set(value) {
        modelValue.value = value
    },
})
</script>
