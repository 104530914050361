import {
    ModalButtonStyles,
    OpenModal,
    UpdateData,
    useTable,
} from '@composables/common'
import { NameAndId } from '@src/components'
import CampaignTeamMemberEditForm from '@src/partials/campaigns/CampaignTeamMemberEditForm.vue'
import CampaignTeamMemberView from '@src/partials/campaigns/CampaignTeamMemberView.vue'
import { TeamMember } from '@src/types'
import { useUserStore } from '@src/utils/stores/user'
import { Ref, computed, h, ref } from 'vue'
import { useI18n } from 'vue-i18n'

export function useCampaignTeamMembersTable(
    teamMembers: Ref<TeamMember[]>,
    update: (successMessage?: string | null) => Promise<void>,
    openModal: OpenModal,
    isModalOpen: Ref<boolean>
) {
    const userStore = useUserStore()
    const i18n = useI18n()

    const tableLength = computed(() => {
        return teamMembers.value.length
    })

    const {
        TableWrapper: CampaignTeamMembersTable,
        selected: selectedTeamMembersIds,
        updateSortOrder: updateTeamMembersSortOrder,
    } = useTable({
        updateData: update as UpdateData,
        tableProps: {
            id: 'team_members_table',
            label: 'team_members',
            help: 'help_team_members_table',
            data: teamMembers,
            total: tableLength,
            sortableOrder: userStore.can('update_campaign'),
            itemOrderKey: 'position',
            itemIdKey: 'id',
            columns: [
                {
                    label: 'id',
                    key: 'id',
                    select: true,
                },
                {
                    label: 'id',
                    key: 'id',
                    condition: userStore.can('see_id_columns'),
                    copy: true,
                },
                {
                    label: 'name',
                    key: 'name',
                    copy: true,
                },
                {
                    label: 'job_title',
                    key: 'title',
                    copy: true,
                },
                {
                    label: 'rank',
                    key: 'position',
                    help: userStore.can('update_campaign')
                        ? 'help_drag_and_drop'
                        : '',
                    cellRenderer: (props: { rowData: TeamMember }) => {
                        return props.rowData.position + 1
                    },
                },
            ],
            actions: [
                {
                    action: handleViewTeamMember,
                    icon: 'fa-eye',
                    title: 'view',
                },
                {
                    action: handleEditOrCreateTeamMember,
                    icon: 'fa-solid fa-pen',
                    title: 'edit',
                    condition: userStore.can('update_campaign'),
                },
                {
                    action: handleDeleteTeamMember,
                    icon: 'fas fa-trash-alt',
                    title: 'delete',
                    color: 'text-danger-500',
                    condition: userStore.can('update_campaign'),
                },
            ],
        },
    })

    function handleDeleteTeamMember(teamMember: TeamMember) {
        isModalOpen.value = true
        openModal(
            {
                id: 'delete_campaign_team_member',
                title: 'modal_title_delete_campaign_team_member',
                open: isModalOpen,
                onCancel: () => (isModalOpen.value = false),
                onSubmit: () => deleteTeamMember(teamMember),
            },
            () =>
                h(NameAndId, {
                    idKey: 'id',
                    nameKey: 'name',
                    modelValue: [teamMember],
                }),
            {
                submitButtonText: 'delete',
                styles: {
                    submitButton: ModalButtonStyles['RED'],
                },
            }
        )
    }

    function handleDeleteTeamMembers() {
        isModalOpen.value = true
        openModal(
            {
                id: 'delete_campaign_team_members',
                title: 'modal_title_delete_campaign_team_members',
                open: isModalOpen,
                onCancel: () => (isModalOpen.value = false),
                onSubmit: () => deleteTeamMembers(selectedTeamMembers.value),
            },
            () =>
                h(NameAndId, {
                    idKey: 'id',
                    nameKey: 'name',
                    modelValue: selectedTeamMembers.value,
                }),
            {
                submitButtonText: 'delete',
                styles: {
                    submitButton: ModalButtonStyles['RED'],
                },
            }
        )
    }

    function deleteTeamMember(teamMemberToDelete: TeamMember, submit = true) {
        const teamMembersTemp = [...teamMembers.value]
        teamMembers.value.splice(
            teamMembers.value.findIndex(
                (teamMember) => teamMemberToDelete.id == teamMember.id
            ),
            1
        )
        if (submit) {
            updateTeamMembersSortOrder()
            update(
                i18n.t('toast_success_campaign_team_member_deleted', {
                    teamMember: teamMemberToDelete.name,
                })
            )
                .catch((error) => {
                    teamMembers.value = teamMembersTemp
                    throw error
                })
                .then(() => {
                    isModalOpen.value = false
                })
        }
    }

    function deleteTeamMembers(teamMembersToDelete: TeamMember[]) {
        const teamMembersTemp = [...teamMembers.value]
        teamMembersToDelete.forEach((teamMember) => {
            deleteTeamMember(teamMember, false)
        })
        updateTeamMembersSortOrder()
        update(
            i18n.t('toast_success_campaign_team_members_deleted', {
                teamMembers: teamMembersToDelete
                    .map((teamMember) => teamMember.name)
                    .join(', '),
            })
        )
            .catch((error) => {
                teamMembers.value = teamMembersTemp
                throw error
            })
            .then(() => {
                selectedTeamMembersIds.value = []
                isModalOpen.value = false
            })
    }

    function handleViewTeamMember(teamMember: TeamMember) {
        isModalOpen.value = true
        openModal(
            {
                id: 'view_campaign_team_member',
                title: 'modal_title_view_campaign_team_member',
                open: isModalOpen,
                onCancel: () => (isModalOpen.value = false),
            },
            () =>
                h(CampaignTeamMemberView, {
                    modelValue: teamMember,
                })
        )
    }

    function handleEditOrCreateTeamMember(
        teamMember: TeamMember = new TeamMember()
    ) {
        const editedTeamMember = ref(teamMember)
        editedTeamMember.value = { ...teamMember }
        isModalOpen.value = true

        const disableCreate = computed(() => {
            return !(
                editedTeamMember.value.name && editedTeamMember.value.title
            )
        })

        openModal(
            {
                id: 'edit_campaign_team_member',
                title: editedTeamMember.value.id
                    ? 'modal_title_edit_campaign_team_member'
                    : 'modal_title_create_campaign_team_member',
                open: isModalOpen,
                onCancel: () => (isModalOpen.value = false),
                onSubmit: () => saveTeamMember(editedTeamMember.value),
            },
            () =>
                h(CampaignTeamMemberEditForm, {
                    modelValue: editedTeamMember,
                }),
            {
                submitButtonText: editedTeamMember.value.id
                    ? 'update'
                    : 'create',
                styles: {
                    submitButton: ModalButtonStyles['BLUE'],
                },
                submitButtonDisabled: disableCreate,
            }
        )
    }

    function saveTeamMember(teamMember: TeamMember) {
        const teamMembersTemp = [...teamMembers.value]
        let toast = 'toast_success_campaign_team_member_updated'
        if (!teamMember.id) {
            toast = 'toast_success_campaign_team_member_created'
            delete teamMember.id
            teamMember.position = teamMembers.value.length
            teamMembers.value.push(teamMember)
        } else {
            teamMembers.value[
                teamMembers.value.findIndex((item) => item.id === teamMember.id)
            ] = teamMember
        }
        update(
            i18n.t(toast, {
                teamMember: teamMember.name,
            })
        )
            .catch((error) => {
                teamMembers.value = teamMembersTemp
                throw error
            })
            .then(() => {
                isModalOpen.value = false
            })
    }

    const selectedTeamMembers = computed(() => {
        return teamMembers.value.filter(
            (item: TeamMember) =>
                item.id && selectedTeamMembersIds.value.includes(item.id)
        )
    })

    return {
        CampaignTeamMembersTable,
        selectedTeamMembersIds,
        handleDeleteTeamMembers,
        handleEditOrCreateTeamMember,
    }
}
