<template>
    <div>
        <FileUploadButton
            title="order_attachment_upload"
            accept=".png,.jpg,.jpeg,.pdf"
            mode="private"
            :deletable="false"
            @submit="onUpload"
        />
        <div v-if="modelValue.url">
            <SelectField
                v-model="modelValue.type"
                name="order_attachment_type"
                label="type"
                help="help_order_attachment_type"
                :options="mapEnumToArray(OrderAttachmentType)"
                :reduce="reduceEnumValue"
                :required="true"
            />
            <TextField
                v-model="modelValue.name"
                name="order_attachment_name"
                label="name"
                help="help_order_attachment_name"
                :required="true"
            />
            <TextField
                v-model="modelValue.description"
                name="order_attachment_description"
                label="description"
                help="help_order_attachment_description"
                :rows="4"
                :required="true"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import { useEnum } from '@composables/common'
import FileUploadButton from '@partials/actions/FileUploadButton.vue'
import { SelectField, TextField } from '@src/components'
import { OrderAttachmentType } from '@src/enums'
import { OrderAttachment } from '@src/types'
import { UploadFileResponse } from '@src/utils/api/s3'
import { Ref, toRefs, watch } from 'vue'

const props = defineProps<{
    modelValue: Ref<OrderAttachment>
}>()

const { modelValue } = toRefs(props)

const emit = defineEmits(['update:modelValue'])

const { mapEnumToArray, reduceEnumValue } = useEnum()

function onUpload(response: UploadFileResponse, fileName: string) {
    modelValue.value.url = response.temp_storage_url
    modelValue.value.name = fileName
    modelValue.value.upload_key = response.key
}

watch(
    modelValue,
    (value) => {
        emit('update:modelValue', value)
    },
    { deep: true }
)
</script>

<style lang="scss" scoped></style>
