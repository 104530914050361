import { useSummary } from '@composables/common'
import { Asset } from '@src/types'
import { SummaryItem } from '@src/types/Summary'
import { Ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'

export function useAssetSummary(asset: Ref<Asset>) {
    const i18n = useI18n()

    const items = computed<SummaryItem[]>(() => {
        return [
            {
                label: i18n.t('id'),
                value: asset.value.asset_uid,
                special: 'link',
                linkURL: `/assets/${asset.value.asset_uid}`,
                copy: true,
            },
            {
                label: i18n.t('asset'),
                value: asset.value.asset_title,
                special: 'link',
                linkURL: `/assets/${asset.value.asset_uid}`,
                copy: true,
            },
            {
                label: i18n.t('company_name'),
                value: asset.value.company_name,
                copy: true,
            },
            {
                label: i18n.t('clearing_system'),
                value: asset.value.clearing_system,
            },
        ]
    })

    const { Summary: AssetSummary } = useSummary(items, {
        title: 'asset',
    })

    return {
        AssetSummary,
    }
}
