<template>
    <div>
        <p class="font-bold">{{ $t('name') }}:</p>
        <p>{{ modelValue.policy_name }}</p>
        <p class="mt-2 font-bold">{{ $t('country') }}</p>
        <p>{{ country }}</p>
        <p class="mt-2 font-bold">{{ $t('language') }}:</p>
        <p>{{ language }}</p>
        <p class="mt-2 font-bold">{{ $t('content') }}:</p>
        <div v-html-safe="modelValue.policy_content"></div>
    </div>
</template>
<script setup lang="ts">
import { PlatformPolicy } from '@src/types'
import { ref, onMounted, toRefs, computed } from 'vue'
import { useCountryStore } from '@src/utils/stores/countries'
import { useLanguageStore } from '@src/utils/stores/languages'

const props = defineProps<{
    modelValue: PlatformPolicy
}>()

const { modelValue } = toRefs(props)

const countryStore = useCountryStore()
const languageStore = useLanguageStore()

const countries = ref<{ label: string; value: string | null }[]>([])
const languages = ref<{ label: string; value: string | null }[]>([])

const country = computed(() => {
    return countries.value.find(
        (item) => item.value === modelValue.value.country
    )?.label
})

const language = computed(() => {
    return languages.value.find(
        (item) => item.value === modelValue.value.language
    )?.label
})

onMounted(async () => {
    countries.value = await countryStore.loadAndSortCountriesOptionsAsync()
    languages.value = await languageStore.loadAndSortLanguagesOptionsAsync()
})
</script>
