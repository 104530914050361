<template>
    <TextField
        v-model="modelValue.risk_title"
        name="risk_title"
        label="name"
        help="help_platform_disclaimer_name"
        :required="true"
    />

    <SelectField
        v-model="modelValue.language"
        name="language"
        label="language"
        help="help_platform_disclaimer_language"
        :options="languages"
        :required="true"
    />

    <SelectField
        v-model="modelValue.countries_applied"
        name="countries_applied"
        label="countries_applied"
        help="help_platform_disclaimers_countries_applied"
        :options="countries"
        :multiple="true"
    />

    <SelectField
        v-model="modelValue.countries_not_applied"
        name="countries_not_applied"
        label="countries_not_applied"
        help="help_platform_disclaimers_countries_not_applied"
        :options="countries"
        :required="false"
        :multiple="true"
    />

    <EditorField
        v-model="modelValue.risk_content"
        name="risk_content"
        :required="true"
        label="content"
        help="help_platform_disclaimer_content"
    />
</template>
<script setup lang="ts">
import { EditorField, SelectField, TextField } from '@src/components'
import { PlatformDisclaimer } from '@src/types'
import { useCountryStore } from '@src/utils/stores/countries'
import { useLanguageStore } from '@src/utils/stores/languages'
import { Ref, onMounted, ref, toRefs, watch } from 'vue'

const props = defineProps<{
    modelValue: Ref<PlatformDisclaimer>
}>()

const { modelValue } = toRefs(props)
const emit = defineEmits(['update:modelValue'])
const countryStore = useCountryStore()
const languageStore = useLanguageStore()

const languages = ref<{ label: string; value: string | null }[]>([])
const countries = ref<{ label: string; value: string | null }[]>([])

watch(modelValue.value, (value) => {
    emit('update:modelValue', value)
})

onMounted(async () => {
    countries.value = await countryStore.loadAndSortCountriesOptionsAsync()
    languages.value = await languageStore.loadAndSortLanguagesOptionsAsync()
})
</script>
