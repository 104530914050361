import { Ref, computed } from 'vue'
import { VoucherifyVoucher } from '@src/types'
import { useEnum, useTable } from '@composables/common'
import { VoucherType } from '@src/enums'
const { getEnumLabel } = useEnum()

export function useVoucherifyVoucherTable(
    voucherifyVouchers: Ref<VoucherifyVoucher[]>
) {
    const total = computed(() => {
        return voucherifyVouchers.value.length
    })

    const { TableWrapper: VoucherifyVouchersTable } = useTable({
        tableProps: {
            id: 'voucher-table',
            label: 'vouchers',
            total: total,
            data: voucherifyVouchers,
            columns: [
                {
                    label: 'code',
                    key: 'code',
                    sorting: true,
                    copy: true,
                },
                {
                    label: 'campaign',
                    key: 'campaign',
                    sorting: true,
                    copy: true,
                },
                {
                    label: 'type',
                    key: 'type',
                    sorting: true,
                    cellRenderer: (props: { rowData: VoucherifyVoucher }) => {
                        return getEnumLabel(VoucherType, props.rowData.type)
                    },
                },
                {
                    label: 'voucher_amount',
                    key: 'gift_card_amount',
                    sorting: true,
                },
                {
                    label: 'voucher_balance',
                    key: 'gift_card_balance',
                    sorting: true,
                },
            ],
        },
    })

    return {
        VoucherifyVouchersTable,
    }
}
