import {
    ModalButtonStyles,
    OpenModal,
    useEnum,
    useTable,
} from '@composables/common'
import UpdateMembershipDocumentForm from '@partials/accounts/UpdateMembershipDocumentForm.vue'
import {
    MembershipDocumentStatusType,
    MembershipDocumentPostStatusType,
} from '@src/enums'
import { MembershipDocument } from '@src/types'
import { updateMembershipDocument } from '@src/utils/api/accounts'
import { useToastStore } from '@src/utils/stores/toast'
import { useUserStore } from '@src/utils/stores/user'
import moment from 'moment'
import { Ref, computed, h, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { getEnumLabel } = useEnum()

export function useMembershipDocumentsTable(
    membershipDocuments: Ref<MembershipDocument[]>,
    openModal: OpenModal,
    isModalOpen: Ref<boolean>,
    assignAndFilterMembershipDocuments: () => void,
    loadAccount: () => Promise<void>,
    label: string,
    help?: string
) {
    const userStore = useUserStore()
    const i18n = useI18n()
    const toast = useToastStore()
    const total = computed(() => {
        return membershipDocuments.value.length
    })
    const { TableWrapper: MembershipDocumentTable } = useTable({
        tableProps: {
            data: membershipDocuments,
            total: total,
            label: label,
            id: label,
            help: help,
            columns: [
                {
                    label: 'status',
                    key: 'status',
                    sorting: false,
                    cellRenderer: (props: { rowData: MembershipDocument }) => {
                        return getEnumLabel(
                            MembershipDocumentStatusType,
                            props.rowData.status
                        )
                    },
                },
                {
                    label: 'created_at',
                    key: 'created_at',
                    sorting: false,
                    cellRenderer: (props: { rowData: MembershipDocument }) => {
                        return props.rowData.created_at
                            ? moment(props.rowData.created_at).format(
                                  'DD.MM.yyyy'
                              )
                            : '-'
                    },
                },
                {
                    label: 'post_status',
                    key: 'post_status',
                    sorting: false,
                    cellRenderer: (props: { rowData: MembershipDocument }) => {
                        return props.rowData.post_status !== null
                            ? getEnumLabel(
                                  MembershipDocumentPostStatusType,
                                  props.rowData.post_status
                              )
                            : '-'
                    },
                },
            ],
            actions: [
                {
                    action: handleViewDocument,
                    icon: 'fa-eye',
                    title: 'help_open_file_in_new_tab',
                },
                {
                    action: handleUpdateMembershipDocument,
                    icon: 'fa-solid fa-pen',
                    title: 'edit',
                    condition: () => {
                        return userStore.can('update_account')
                    },
                },
            ],
        },
    })

    function handleViewDocument(rowData: MembershipDocument) {
        const url = rowData.url
        isModalOpen.value = true
        openModal(
            {
                id: 'view_membership_document',
                title: 'modal_title_view_membership_document',
                open: isModalOpen,
                onCancel: () => (isModalOpen.value = false),
            },
            () =>
                h('iframe', {
                    src: `${url}#zoom=100`,
                    class: 'full-size-iframe',
                })
        )
    }

    function handleUpdateMembershipDocument(
        membershipDocument: MembershipDocument = new MembershipDocument()
    ) {
        const editedMembershipDocument = ref(new MembershipDocument())
        editedMembershipDocument.value = { ...membershipDocument }
        isModalOpen.value = true

        openModal(
            {
                id: 'edit_membership_document',
                title: 'modal_title_update_membership_document',
                open: isModalOpen,
                onCancel: () => (isModalOpen.value = false),
                onSubmit: () =>
                    saveMembershipDocument(editedMembershipDocument.value),
            },
            () =>
                h(UpdateMembershipDocumentForm, {
                    modelValue: editedMembershipDocument,
                }),
            {
                submitButtonText: 'update',
                styles: {
                    submitButton: ModalButtonStyles['BLUE'],
                },
            }
        )
    }

    async function saveMembershipDocument(document: MembershipDocument) {
        try {
            await updateMembershipDocument(document)
            assignAndFilterMembershipDocuments()
            await loadAccount()
            toast.success(i18n.t('toast_success_membership_document_updated'))
        } catch (error) {
            toast.error(i18n.t('toast_error_membership_document_updated'))
            throw error
        }

        isModalOpen.value = false
    }

    return {
        MembershipDocumentTable,
    }
}
