<template>
    <PageWrapper>
        <template #headline>
            <fa icon="fa-sack-dollar" />
            {{ $t('edit_order') }}
        </template>
        <template #actions>
            <BackButton
                v-if="$router.options.history.state.back"
                @click="router.go(-1)"
            />
            <BackButton
                v-if="
                    order.order_uid &&
                    $router.options.history.state.back !==
                        `/orders/${order.order_uid}`
                "
                icon="fa-eye"
                title="order"
                @click="goToDetails"
            />
            <BackButton
                v-if="$router.options.history.state.back !== '/orders'"
                icon="fa-list"
                title="orders"
                @click="toOrders"
            />
            <SaveButton @click="saveOrder" />
        </template>
        <AccordionBasic
            title="order_details"
            class="mb-2"
            :open="true"
        >
            <NumberField
                v-model="order.amount"
                label="invested_amount"
                name="order_invested_amount"
                help="help_order_invested_amount"
                :required="true"
            />
            <SelectField
                v-model="order.incentive_uid"
                name="incentive"
                label="incentive"
                help="help_select_incentive"
                :options="incentiveSelectOptions"
            />
            <Datepicker
                v-model="order.created_on"
                label="date_of_order"
                name="date_of_order"
                placeholder="date_of_order"
                help="help_date_of_order"
                :required="true"
            />
        </AccordionBasic>

        <AccordionBasic
            v-if="campaign.additional_fields && order.additional_fields"
            title="additional_fields"
            class="mb-2"
            :open="false"
        >
            <TextField
                v-model="order.additional_fields.depot_owner"
                name="depot_owner"
                label="depot_owner"
                help="help_depot_owner"
                :required="campaign.additional_fields.depot_owner.required"
            />
            <TextField
                v-model="order.additional_fields.depot_number"
                name="depot_number"
                label="depot_number"
                help="help_depot_number"
                :required="campaign.additional_fields.depot_number.required"
            />
            <TextField
                v-model="order.additional_fields.depot_bank"
                name="depot_bank"
                label="depot_bank"
                help="help_depot_bank"
                :required="campaign.additional_fields.depot_bank.required"
            />
            <TextField
                v-model="order.additional_fields.depot_bic"
                name="depot_bic"
                label="depot_bic"
                help="help_depot_bic"
                :required="campaign.additional_fields.depot_bic.required"
            />
        </AccordionBasic>
        <template v-if="order.regulatory_requirements">
            <h3
                v-if="
                    campaign.regulatory_requirements ===
                    RegulatoryRequirements.VERMANLG
                "
                class="mb-2 mt-4 text-xl font-bold"
            >
                {{ $t('regulatory_requirements') }}
            </h3>
            <template
                v-if="
                    campaign.regulatory_requirements ===
                    RegulatoryRequirements.VERMANLG
                "
            >
                <AccordionBasic
                    title="investor_information_for_germany"
                    class="mb-2"
                    :open="false"
                >
                    <div class="text-right">
                        <button
                            class="btn-sm bg-primary-additional-500 text-white"
                            @click.stop="fillInvestorInformationForGermany"
                        >
                            {{ $t('fill') }}
                        </button>
                    </div>
                    <TextField
                        v-model="order.regulatory_requirements.city"
                        name="city"
                        label="city"
                        help="help_city"
                        :required="true"
                    />
                    <Datepicker
                        v-model="order.regulatory_requirements.date"
                        label="date"
                        name="date"
                        placeholder="date"
                        help="help_date"
                        :required="true"
                    />
                </AccordionBasic>
                <AccordionBasic
                    title="please_confirm_data"
                    class="mb-2"
                    :open="false"
                >
                    <div class="text-right">
                        <button
                            class="btn-sm bg-primary-additional-500 text-white"
                            @click.stop="fillConfirmData"
                        >
                            {{ $t('fill') }}
                        </button>
                    </div>
                    <TextField
                        v-model="order.regulatory_requirements.email_confirm"
                        name="email"
                        label="email"
                        help="help_email"
                        :required="true"
                    />
                    <TextField
                        v-model="
                            order.regulatory_requirements.first_name_confirm
                        "
                        name="first_name"
                        label="first_name"
                        help="help_first_name"
                        :required="true"
                    />
                    <TextField
                        v-model="
                            order.regulatory_requirements.last_name_confirm
                        "
                        name="last_name"
                        label="last_name"
                        help="help_last_name"
                        :required="true"
                    />
                    <TextField
                        v-model="
                            order.regulatory_requirements.place_of_birth_confirm
                        "
                        name="place_of_birth"
                        label="place_of_birth"
                        help="help_place_of_birth"
                        :required="true"
                    />
                    <Datepicker
                        v-model="
                            order.regulatory_requirements.date_of_birth_confirm
                        "
                        label="date_of_birth"
                        name="date_of_birth"
                        placeholder="date_of_birth"
                        help="help_date_of_birth"
                        :required="true"
                    />
                    <TextField
                        v-model="
                            order.regulatory_requirements
                                .identity_card_passport_number_confirm
                        "
                        name="id_card_passport_number"
                        label="id_card_passport_number"
                        help="help_id_card_passport_number"
                        :required="true"
                    />
                    <TextField
                        v-model="
                            order.regulatory_requirements
                                .issuing_authority_confirm
                        "
                        name="issuing_authority"
                        label="issuing_authority"
                        help="help_issuing_authority"
                        :required="true"
                    />
                    <TextField
                        v-model="order.regulatory_requirements.address_confirm"
                        name="address"
                        label="address"
                        help="help_address"
                        :required="true"
                    />
                    <TextField
                        v-model="
                            order.regulatory_requirements.postal_code_confirm
                        "
                        name="postal_code"
                        label="postal_code"
                        help="help_postal_code"
                        :required="true"
                    />
                    <TextField
                        v-model="order.regulatory_requirements.city_confirm"
                        name="city"
                        label="city"
                        help="help_city"
                        :required="true"
                    />
                    <TextField
                        v-model="order.regulatory_requirements.state_confirm"
                        name="state"
                        label="state"
                        help="help_state"
                        :required="true"
                    />
                    <TextField
                        v-model="order.regulatory_requirements.tax_number"
                        name="tax_number"
                        label="tax_number"
                        help="help_tax_number"
                    />
                    <ToggleSwitch
                        v-model="
                            order.regulatory_requirements
                                .accept_investment_information
                        "
                        label="vib_investment_information"
                        name="vib_investment_information"
                    />
                    <ToggleSwitch
                        v-model="
                            order.regulatory_requirements
                                .accept_investment_under_1000
                        "
                        label="vib_investment_under_1000"
                        name="accept_investment_under_1000"
                        @change="
                            () => {
                                if (order.regulatory_requirements) {
                                    order.regulatory_requirements.accept_investment_income = false
                                    order.regulatory_requirements.accept_investment_financial_assets_over_10000 = false
                                }
                            }
                        "
                    />
                    <ToggleSwitch
                        v-model="
                            order.regulatory_requirements
                                .accept_investment_income
                        "
                        label="vib_investment_income"
                        name="vib_investment_income"
                        @change="
                            () => {
                                if (order.regulatory_requirements) {
                                    order.regulatory_requirements.accept_investment_under_1000 = false
                                    order.regulatory_requirements.accept_investment_financial_assets_over_10000 = false
                                }
                            }
                        "
                    />
                    <ToggleSwitch
                        v-model="
                            order.regulatory_requirements
                                .accept_investment_financial_assets_over_10000
                        "
                        label="vib_investment_financial_assets_over_10000"
                        name="vib_investment_financial_assets_over_10000"
                        @change="
                            () => {
                                if (order.regulatory_requirements) {
                                    order.regulatory_requirements.accept_investment_under_1000 = false
                                    order.regulatory_requirements.accept_investment_income = false
                                }
                            }
                        "
                    />
                    <ToggleSwitch
                        v-model="
                            order.regulatory_requirements
                                .accept_investment_limit
                        "
                        label="vib_investment_limit"
                        name="vib_investment_limit"
                    />
                </AccordionBasic>
            </template>
            <template
                v-if="
                    campaign.regulatory_requirements ===
                    RegulatoryRequirements.WPHG
                "
            >
                <AccordionBasic
                    title="regulatory_requirements"
                    class="mb-2"
                    :open="false"
                >
                    <div class="text-right">
                        <button
                            class="btn-sm bg-primary-additional-500 text-white"
                            @click.stop="fillConfirmData"
                        >
                            {{ $t('fill') }}
                        </button>
                    </div>
                    <ToggleSwitch
                        v-model="
                            order.regulatory_requirements
                                .accept_investment_under_1000
                        "
                        label="wphg_investment_under_1000"
                        name="wphg_investment_under_1000"
                    />
                    <ToggleSwitch
                        v-model="
                            order.regulatory_requirements
                                .accept_investment_limit
                        "
                        label="wphg_investment_limit"
                        name="wphg_investment_limit"
                    />
                    <ToggleSwitch
                        v-model="
                            order.regulatory_requirements
                                .accept_inappropriate_investment
                        "
                        label="wphg_inappropriate_investment"
                        name="wphg_inappropriate_investment"
                    />
                </AccordionBasic>
            </template>
        </template>
        <template #modals>
            <ModalWrapper v-if="isModalOpen" />
        </template>
    </PageWrapper>
</template>

<script setup lang="ts">
import { useModal } from '@composables/common'
import {
    AccordionBasic,
    Datepicker,
    NumberField,
    SelectField,
    TextField,
    ToggleSwitch,
} from '@src/components'
import { RegulatoryRequirements } from '@src/enums'
import BackButton from '@src/partials/actions/BackButton.vue'
import SaveButton from '@src/partials/actions/SaveButton.vue'
import PageWrapper from '@src/partials/pages/PageWrapper.vue'
import {
    AdditionalFields,
    Asset,
    AssetsIncentive,
    Campaign,
    NaturalProfile,
    Order,
    OrderRegulatoryRequirements,
} from '@src/types'
import { getNaturalProfile } from '@src/utils/api/accounts'
import { getAsset } from '@src/utils/api/assets'
import { getCampaign } from '@src/utils/api/campaigns'
import { getOrder, updateOrder } from '@src/utils/api/orders'
import { formatNumericValues } from '@src/utils/helpers'
import { useToastStore } from '@src/utils/stores/toast'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'

const router = useRouter()
const route = useRoute()
const toast = useToastStore()
const i18n = useI18n()
const { ModalWrapper } = useModal()

const order = ref<Order>(new Order())
const campaign = ref<Campaign>(new Campaign())
const naturalProfile = ref<NaturalProfile>(new NaturalProfile())
const asset = ref<Asset>(new Asset())
const assetCurrency = ref<string | null>(null)
const isModalOpen = ref(false)

const incentiveSelectOptions = computed(() => {
    return campaign.value.assets_incentives.map(
        (incentive: AssetsIncentive) => {
            return {
                label: `${incentive.incentives_title} ${formatNumericValues(
                    incentive.incentives_amount as number,
                    assetCurrency.value || ''
                )}`,
                value: incentive.asset_inc_uid as string,
            }
        }
    )
})

const additionalFieldsRequired = computed(() => {
    if (campaign.value.additional_fields) {
        return (
            Object.values(campaign.value.additional_fields).filter(
                (field) => field.required
            ).length > 0
        )
    }
    return false
})

async function loadCampaign(id: string) {
    const campaignRequest = await getCampaign(id)
    campaign.value = campaignRequest.data.campaign
}

async function loadNaturalProfile(order: Order) {
    if (order.natural_profile_id) {
        const naturalProfileRequest = await getNaturalProfile(
            order.natural_profile_id
        )
        naturalProfile.value = naturalProfileRequest.data.natural_profile
    }
}

async function loadOrder(id: string) {
    const orderRequest = await getOrder(id)
    order.value = orderRequest.data.order

    await loadCampaign(order.value.asset_var_uid as string)
    await loadNaturalProfile(order.value)

    const assetRequest = await getAsset(order.value.asset_uid as string)
    asset.value = assetRequest.data.asset
    assetCurrency.value = asset.value.currency

    if (additionalFieldsRequired.value) {
        order.value.additional_fields = {
            ...new AdditionalFields(),
            ...order.value.additional_fields,
        }
    }

    if (
        campaign.value.regulatory_requirements ===
            RegulatoryRequirements.VERMANLG ||
        campaign.value.regulatory_requirements === RegulatoryRequirements.WPHG
    ) {
        order.value.regulatory_requirements = {
            ...new OrderRegulatoryRequirements(),
            ...order.value.regulatory_requirements,
        }
    }
}

async function saveOrder() {
    await updateOrder(order.value)
    toast.success(
        i18n.t('toast_success_order_updated', {
            order: order.value.order_uid,
        })
    )
}

function goToDetails() {
    router.push({
        name: 'order',
        params: { id: order.value.order_uid },
    })
}

function toOrders() {
    router.push({ name: 'orders' })
}

onMounted(() => {
    const id = route.params.id as string
    if (id) {
        loadOrder(id)
    }
})

function fillInvestorInformationForGermany() {
    if (
        campaign.value.regulatory_requirements ===
        RegulatoryRequirements.VERMANLG
    ) {
        order.value.regulatory_requirements = {
            ...order.value.regulatory_requirements,
            regulatory_requirement: campaign.value.regulatory_requirements,
            city: naturalProfile.value.city,
            date: new Date(),
        } as OrderRegulatoryRequirements
    }
}

function fillConfirmData() {
    if (
        campaign.value.regulatory_requirements ===
        RegulatoryRequirements.VERMANLG
    ) {
        order.value.regulatory_requirements = {
            ...order.value.regulatory_requirements,
            regulatory_requirement: campaign.value.regulatory_requirements,
            email_confirm: naturalProfile.value.email || '',
            first_name_confirm: naturalProfile.value.first_name || '',
            last_name_confirm: naturalProfile.value.last_name || '',
            date_of_birth_confirm: naturalProfile.value.date_of_birth
                ? new Date(naturalProfile.value.date_of_birth)
                : new Date(),
            address_confirm: naturalProfile.value.address || '',
            postal_code_confirm: naturalProfile.value.postal_code || '',
            city_confirm: naturalProfile.value.city || '',
            state_confirm: naturalProfile.value.state || '',
            tax_number: naturalProfile.value.tax_number || '',
            accept_investment_information: true,
            accept_investment_limit: true,
        } as OrderRegulatoryRequirements
    }
    if (
        campaign.value.regulatory_requirements === RegulatoryRequirements.WPHG
    ) {
        order.value.regulatory_requirements = {
            ...order.value.regulatory_requirements,
            regulatory_requirement: campaign.value.regulatory_requirements,
            accept_investment_under_1000: true,
            accept_investment_limit: true,
            accept_inappropriate_investment: true,
        } as OrderRegulatoryRequirements
    }
}
</script>
