<template>
    <div>
        <TextField
            v-model="modelValue.asset_title"
            label="campaign_title"
            name="campaign_title"
            :required="true"
            help="help_campaign_title"
        />
        <SelectField
            v-model="modelValue.platform_name"
            v-model:options="platformSelectOptions"
            name="platform_name"
            label="platform"
            help="help_platform_name_campaign"
            :on-search="loadPlatformSelectOptions"
            :required="true"
        />
    </div>
</template>

<script setup lang="ts">
import { DuplicatedCampaign, Platform } from '@src/types'

import { SelectField, TextField } from '@src/components'
import { getPlatforms } from '@src/utils/api/platforms'
import { Ref, ref, toRefs, watch } from 'vue'

const props = defineProps<{
    modelValue: Ref<DuplicatedCampaign>
}>()

const { modelValue } = toRefs(props)

const emit = defineEmits(['update:modelValue'])

const platformSelectOptions = ref<string[]>([])

async function loadPlatformSelectOptions(search: string) {
    const initialPlatformsRequest = await getPlatforms({
        'filter[platform_name]': search,
    })
    platformSelectOptions.value = initialPlatformsRequest.data.platforms.map(
        (platform: Platform) => {
            return platform.platform_name as string
        }
    )
}

watch(modelValue, (value) => {
    emit('update:modelValue', value)
})
</script>

<style lang="scss" scoped></style>
