import { LanguageOption } from '@src/types'
import { getLanguages } from '@utils/api/languages'
import { i18n } from '@utils/lang'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useLanguageStore = defineStore('language', () => {
    const languages = ref<LanguageOption[]>([])

    const { t } = i18n.global

    async function loadLanguages() {
        if (!languages.value.length) {
            languages.value = (await getLanguages()).data.languages
        }
    }

    async function translate(language: string) {
        const foundLanguage: LanguageOption | undefined = languages.value.find(
            (l) => l.value === language
        )
        if (!foundLanguage) return language
        return t(foundLanguage.value)
    }

    async function loadAndSortLanguagesOptionsAsync() {
        const translatedLanguages = await Promise.all(
            languages.value.map(async (lang) => {
                return {
                    label: await translate(lang.value),
                    value: lang.value,
                }
            })
        )

        return translatedLanguages.sort((a, b) =>
            a.label.localeCompare(b.label)
        )
    }

    return {
        languages,
        loadLanguages,
        translate,
        loadAndSortLanguagesOptionsAsync,
    }
})
