<template>
    <h4 class="mb-3 text-xl font-bold">{{ modelValue.name }}</h4>
    <h4 class="text-l mb-3 font-bold">{{ modelValue.title }}</h4>
    <div v-html-safe="modelValue.bio"></div>
    <img
        v-if="modelValue.picture?.url"
        :src="modelValue.picture?.url"
        :alt="modelValue.name ?? 'Picture'"
        class="mt-3"
    />
</template>

<script setup lang="ts">
import { TeamMember } from '@src/types'
import { toRefs } from 'vue'

const props = defineProps<{
    modelValue: TeamMember
}>()

const { modelValue } = toRefs(props)
</script>

<style lang="scss" scoped></style>
