<template>
    <Modal
        :id="id"
        v-model:open="open"
        :title="title"
        @cancel="cancel"
        @submit="submit"
    >
        <div>
            <ul class="list-disc pl-8">
                <li
                    v-for="item in modelValue"
                    :key="(item[idKey] as string)"
                    class="py-2"
                >
                    <span class="font-bold">{{ item[nameKey] }}</span> ({{
                        item[idKey]
                    }})
                </li>
            </ul>
        </div>

        <!-- Modal footer -->
        <template #actions>
            <button
                type="submit"
                class="btn-sm bg-danger-500 hover:bg-danger-600 text-white"
            >
                {{ $t('delete') }}
            </button>
        </template>
    </Modal>
</template>
<script setup lang="ts">
import { Modal } from '@src/components'
import { ObjectWithStringKeys } from '@src/types/ObjectWithStringKeys'
import { useVModels } from '@vueuse/core'

const props = withDefaults(
    defineProps<{
        id: string
        title: string
        idKey?: string
        nameKey?: string
        modelValue: ObjectWithStringKeys[]
        open: boolean
    }>(),
    {
        idKey: 'id',
        nameKey: 'name',
    }
)

const emit = defineEmits(['update:open', 'cancel', 'submit'])

const { modelValue, open } = useVModels(props, emit)

function submit(): void {
    emit('submit', modelValue.value)
}

function cancel() {
    emit('cancel')
}
</script>
