<template>
    <div>
        {{ countries }}
    </div>
</template>
<script setup lang="ts">
import { PlatformDisclaimer } from '@src/types'
import { i18n } from '@src/utils/lang'
import { useCountryStore } from '@src/utils/stores/countries'
import { onMounted, ref, watch } from 'vue'

const countryStore = useCountryStore()

const props = defineProps<{
    rowData: PlatformDisclaimer | Record<string, unknown>
}>()

const countries = ref<string | null>(null)

async function translateCountries() {
    if (!props.rowData || !props.rowData.countries_applied) {
        countries.value = null
        return
    }
    const translatedCountries = await Promise.all(
        (props.rowData.countries_applied as string[]).map(
            async (country: string): Promise<string> => {
                return await countryStore.translate(country)
            }
        )
    )
    countries.value = translatedCountries.join(', ')
}

watch(i18n.global.locale, async () => {
    return await translateCountries()
})

onMounted(async () => {
    return await translateCountries()
})
</script>
