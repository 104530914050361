<template>
    <!-- Modal backdrop -->
    <transition
        enter-active-class="transition ease-out duration-200"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="transition ease-out duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
    >
        <div
            v-show="open"
            class="fixed inset-0 z-50 bg-gray-900 bg-opacity-30 transition-opacity"
            aria-hidden="true"
        ></div>
    </transition>
    <!-- Modal dialog -->
    <transition
        enter-active-class="transition ease-in-out duration-200"
        enter-from-class="opacity-0 translate-y-4"
        enter-to-class="opacity-100 translate-y-0"
        leave-active-class="transition ease-in-out duration-200"
        leave-from-class="opacity-100 translate-y-0"
        leave-to-class="opacity-0 translate-y-4"
    >
        <div
            v-show="open"
            :id="id"
            class="fixed inset-0 z-50 my-4 flex transform flex-col items-center justify-center px-4 sm:px-6"
            role="dialog"
            aria-modal="true"
        >
            <div
                ref="modalContent"
                class="h-auto max-h-[90%] w-full rounded bg-white shadow-lg"
                :class="{
                    'max-w-lg': width == 'lg',
                    'max-w-xl': width == 'xl',
                    'max-w-2xl': width == '2xl',
                    'max-w-full': isFullScreen,
                    'min-h-full': isFullScreen,
                }"
            >
                <!-- Modal header -->
                <div>
                    <div class="border-b border-gray-200 px-5 py-3">
                        <div class="flex items-center justify-between">
                            <div class="font-semibold text-gray-800">
                                {{ title ? $t(title) : '' }}
                            </div>
                            <div>
                                <button
                                    v-if="!isFullScreen"
                                    class="mr-3 text-gray-400 hover:text-gray-500"
                                    @click.stop="expand()"
                                >
                                    <div class="sr-only">
                                        {{ $t('expand') }}
                                    </div>
                                    <fa
                                        icon="fa-solid fa-up-right-and-down-left-from-center"
                                    />
                                </button>
                                <button
                                    v-if="isFullScreen"
                                    class="mr-3 text-gray-400 hover:text-gray-500"
                                    @click.stop="collapse()"
                                >
                                    <div class="sr-only">
                                        {{ $t('collapse') }}
                                    </div>
                                    <fa
                                        icon="fa-solid fa-down-left-and-up-right-to-center"
                                    />
                                </button>

                                <button
                                    class="text-gray-400 hover:text-gray-500"
                                    @click.stop="cancel"
                                >
                                    <div class="sr-only">{{ $t('close') }}</div>
                                    <fa icon="fa-solid fa-xmark" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <form
                    v-if="slots.actions"
                    @submit.prevent="submit"
                >
                    <!-- content -->
                    <div
                        class="max-h-[70vh] overflow-y-auto px-5 py-4"
                        :class="{
                            'min-h-[85vh]': isFullScreen,
                        }"
                    >
                        <slot />
                    </div>

                    <!-- Modal footer (actions) -->
                    <div
                        v-if="slots.actions ?? false"
                        class="border-t border-gray-200 px-5 py-4"
                    >
                        <div class="flex flex-wrap justify-end space-x-2">
                            <button
                                class="btn-sm border-gray-200 text-gray-600 hover:border-gray-300"
                                @click.prevent="cancel"
                            >
                                {{ $t('cancel') }}
                            </button>
                            <slot name="actions" />
                        </div>
                    </div>
                </form>

                <!-- content without form -->
                <div
                    v-else
                    class="modal-content max-h-[70vh] overflow-y-auto px-5 py-4"
                    :class="{
                        'min-h-[90vh]': isFullScreen,
                        'modal-content--fullscreen': isFullScreen,
                    }"
                >
                    <slot />
                </div>
            </div>
        </div>
    </transition>
</template>

<script setup lang="ts">
import { useVModels } from '@vueuse/core'
import { ref, useSlots } from 'vue'

const slots = useSlots()

const props = withDefaults(
    defineProps<{
        id: string
        open: boolean
        title: string
        width?: 'lg' | 'xl' | '2xl'
    }>(),
    {
        width: 'xl',
    }
)

const emit = defineEmits(['update:open', 'cancel', 'submit'])

const { open } = useVModels(props, emit)

const isFullScreen = ref(false)

function expand() {
    isFullScreen.value = true
}

function collapse() {
    isFullScreen.value = false
}

function cancel() {
    open.value = false
    emit('cancel')
}

function submit() {
    emit('submit')
}
</script>

<style lang="scss">
.modal-content {
    .full-size-iframe {
        width: 100%;
        height: 60vh;
    }

    &--fullscreen {
        .full-size-iframe {
            height: 85vh;
        }
    }
}
</style>
