<template>
    <div class="flex items-center gap-2">
        <CreateButton
            :title="title"
            type="button"
            :disabled="disabled"
            class="disabled"
            @click.stop="open"
        />
        <a
            v-if="(url || newUrl) && (fileName || newFileName)"
            class="text-blue-500 hover:text-blue-600"
            :href="url || newUrl"
            target="blank"
            >{{ fileName || newFileName }}</a
        >
        <div v-if="deletable">
            <button
                v-if="fileName || newFileName"
                class="btn text-danger-600 border-gray-200 bg-white hover:border-gray-300"
                :title="$t('delete')"
                @click.stop="handelDelete"
            >
                <fa icon="fa-solid fa-xmark" />
            </button>
            <ModalWrapper v-if="isModalOpen" />
        </div>
    </div>
</template>

<script setup lang="ts">
import { ModalButtonStyles, useModal } from '@src/composables/common'
import CreateButton from '@src/partials/actions/CreateButton.vue'
import {
    uploadKYC,
    uploadECSP,
    uploadPrivate,
    uploadPublic,
} from '@src/utils/api/s3'
import { useFileDialog, useVModels } from '@vueuse/core'
import { h, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

export type FileUploadMode =
    | 'private'
    | 'public'
    | 'KYC'
    | 'ECSP'
    | 'MembershipDocument'

const newFileName = ref<string | null>(null)
const newUrl = ref<string>('')

const props = withDefaults(
    defineProps<{
        mode?: FileUploadMode
        title?: string
        accept?: string
        fileName?: string | null
        url?: string | null
        deletable?: boolean
        disabled?: boolean
    }>(),
    {
        mode: 'private',
        title: 'upload',
        accept: '',
        fileName: null,
        url: null,
        deletable: true,
        disabled: false,
    }
)

const emit = defineEmits(['submit', 'delete'])

const { mode, title, accept, fileName, deletable } = useVModels(props, emit)

const { open, files, reset } = useFileDialog({
    multiple: false,
    accept: accept.value,
})

const i18n = useI18n()

const isModalOpen = ref(false)

const { ModalWrapper, openModal } = useModal()

function handelDelete() {
    isModalOpen.value = true
    openModal(
        {
            id: 'delete_file',
            title: 'modal_title_delete_file',
            open: isModalOpen,
            onCancel: () => (isModalOpen.value = false),
            onSubmit: () => {
                emit('delete')
                newFileName.value = null
                isModalOpen.value = false
            },
        },
        () =>
            h(
                'span',
                i18n.t('modal_text_delete_file', { file: fileName.value })
            ),
        {
            styles: {
                submitButton: ModalButtonStyles['RED'],
            },
        }
    )
}

watch(files, async function (files) {
    if (!files) {
        return
    }
    const file = files?.item(0)
    if (!file) {
        return
    }

    newFileName.value = file.name

    if (mode.value === 'private' || mode.value === 'MembershipDocument') {
        const response = await uploadPrivate(file)
        newUrl.value = response.data.temp_storage_url
        emit('submit', response.data, newFileName.value)
    }

    if (mode.value === 'public') {
        const response = await uploadPublic(file)
        emit('submit', response.data, newFileName.value)
    }

    if (mode.value === 'KYC') {
        const response = await uploadKYC(file)
        emit('submit', response, newFileName.value)
    }

    if (mode.value === 'ECSP') {
        const response = await uploadECSP(file)
        emit('submit', response, newFileName.value)
    }

    reset()
})
</script>
