<template>
    <input
        :id="name"
        v-model="modelValue"
        :name="name"
        class="form-input w-full"
        type="hidden"
        :required="required"
    />
</template>
<script setup lang="ts">
import { useVModels } from '@vueuse/core'

const props = defineProps<{
    name: string
    required?: boolean
    modelValue?: string | null
}>()

const emit = defineEmits(['update:modelValue'])

const { modelValue } = useVModels(props, emit)
</script>
