<template>
    <PageWrapper>
        <template #headline>
            <fa icon="fa-building" />&nbsp;{{ issuer.name }}
        </template>

        <template #actions>
            <BackButton
                v-if="$router.options.history.state.back"
                @click="router.go(-1)"
            />
            <BackButton
                v-if="
                    issuer.id &&
                    $router.options.history.state.back !==
                        `/issuers/${issuer.id}`
                "
                icon="fa-eye"
                title="issuer"
                @click="goToDetails"
            />
            <BackButton
                v-if="$router.options.history.state.back !== '/issuers'"
                icon="fa-list"
                title="issuers"
                @click="toIssuers"
            />
            <SaveButton @click="save" />
        </template>

        <AccordionBasic
            title="issuer_details"
            class="mb-2"
            :open="true"
        >
            <TextField
                v-model="issuer.name"
                name="issuer_name"
                label="issuer_name"
                help="help_issuer_name"
                :required="true"
            />

            <ImageUpload
                v-model="issuer.company_logo"
                name="issuer_logo"
                label="issuer_logo"
                help="help_issuer_logo"
                type="company"
            />

            <SelectField
                v-model="issuer.platform_name"
                v-model:options="platformOptions"
                name="platform_name"
                label="platform_name"
                help="help_platform_name"
                :required="true"
                :on-search="loadPlatformNameOptions"
            />

            <hr />

            <SelectField
                v-model="issuer.status"
                name="issuer_status"
                label="issuer_status"
                help="help_issuer_status"
                :options="mapEnumToArray(IssuerStatus)"
                :reduce="reduceEnumValue"
                :required="true"
            />

            <SelectField
                v-model="issuer.internal_status"
                name="issuer_internal_status"
                label="issuer_internal_status"
                help="help_issuer_internal_status"
                :options="mapEnumToArray(IssuerInternalStatus)"
                :reduce="reduceEnumValue"
                :required="true"
            />

            <SelectField
                v-model="issuer.industry"
                name="issuer_industry"
                label="issuer_industry"
                help="help_issuer_industry"
                :options="mapEnumToArray(IssuerIndustry)"
                :reduce="reduceEnumValue"
                :required="true"
            />

            <hr />

            <TextField
                v-model="issuer.address"
                name="asset_company_address"
                label="address"
                help="help_company_address"
                :required="true"
            />

            <TextField
                v-model="issuer.city"
                name="issuer_company_city"
                label="city"
                help="help_company_city"
                :required="true"
            />

            <TextField
                v-model="issuer.postal_code"
                name="issuer_company_postal_code"
                label="postal_code"
                help="help_company_postal_code"
                :required="true"
            />

            <TextField
                v-model="issuer.state"
                name="issuer_company_state"
                label="state"
                help="help_company_state"
                :required="true"
            />

            <SelectField
                v-model="issuer.country"
                name="issuer_company_country"
                label="country"
                help="help_company_country"
                :options="countries"
                :required="true"
            />

            <hr />

            <TextField
                v-model="issuer.phone_number"
                name="company_phone_number"
                label="phone_number"
                help="help_company_phone_number"
                :required="true"
            />

            <TextField
                v-model="issuer.email"
                name="company_email"
                label="email"
                help="help_company_email"
                :required="true"
            />

            <TextField
                v-model="issuer.website"
                name="company_website"
                label="company_website"
                help="help_company_website"
                :required="true"
            />

            <hr />

            <TextField
                v-model="issuer.wallet_id"
                name="wallet_id"
                label="wallet_id"
                help="help_wallet_id"
                :required="false"
            />
        </AccordionBasic>
    </PageWrapper>
</template>
<script setup lang="ts">
import {
    IssuerIndustry,
    IssuerInternalStatus,
    IssuerStatus,
} from '@enums/Issuer'
import {
    AccordionBasic,
    ImageUpload,
    SelectField,
    TextField,
} from '@src/components'
import { useEnum } from '@src/composables/common'
import BackButton from '@src/partials/actions/BackButton.vue'
import SaveButton from '@src/partials/actions/SaveButton.vue'
import PageWrapper from '@src/partials/pages/PageWrapper.vue'
import { Issuer, Platform } from '@src/types'
import { getPlatforms } from '@src/utils/api/platforms'
import { useCountryStore } from '@src/utils/stores/countries'
import { useToastStore } from '@src/utils/stores/toast'
import { getIssuer, updateIssuer } from '@utils/api/issuers'
import { onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'

const { mapEnumToArray, reduceEnumValue } = useEnum()

const router = useRouter()
const route = useRoute()
const toast = useToastStore()
const i18n = useI18n()

const issuer = ref<Issuer>(new Issuer())
const platformOptions = ref<{ label: string; value: string }[]>([])
const countries = ref<{ label: string; value: string }[]>([])
const countryStore = useCountryStore()

function goToDetails(): void {
    router.push({ name: 'issuer', params: { id: issuer.value.id } })
}

function toIssuers(): void {
    router.push({ name: 'issuers' })
}

async function save() {
    await updateIssuer(issuer.value)
    toast.success(
        i18n.t('toast_success_issuer_saved', { issuer: issuer.value.name })
    )
    if (issuer.value.company_logo) {
        issuer.value.company_logo.upload_key = null
    }
}

async function loadPlatformNameOptions(search: string) {
    const initialPlatformsRequest = await getPlatforms({
        'filter[platform_name]': search,
    })
    platformOptions.value = initialPlatformsRequest.data.platforms.map(
        (platform: Platform) => {
            return {
                label: platform.platform_name as string,
                value: platform.platform_name as string,
            }
        }
    )
}

async function loadData() {
    countries.value = await countryStore.loadAndSortCountriesOptionsAsync()
}

onMounted(async () => {
    if (route.params.id) {
        const request = await getIssuer(route.params.id as string)
        issuer.value = request.data

        if (issuer.value.platform_name) {
            await loadPlatformNameOptions(issuer.value.platform_name)
        }
    }

    loadData()
})

watch(i18n.locale, async () => {
    loadData()
})
</script>
