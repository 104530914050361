<template>
    <div class="absolute right-8 bottom-2 z-[99] max-w-xs">
        <Toast
            v-for="(toast, index) in toasts.notifications"
            :key="`toast-${index}`"
            :type="toast.type"
        >
            {{ toast.message }}
        </Toast>
    </div>
</template>
<script setup lang="ts">
import { Toast } from '@src/components'
import { useToastStore } from '@stores/toast'

const toasts = useToastStore()
</script>
