import { useLocalStorage } from '@vueuse/core'
import Keycloak from 'keycloak-js'
import { Ref, ref, watchEffect } from 'vue'

const keycloak: Ref<Keycloak> = ref(new Keycloak())
const keycloakAccessToken: Ref<string | null> = useLocalStorage(
    'keycloak_access_token',
    null
)
const keycloakRefreshToken: Ref<string | null> = useLocalStorage(
    'keycloak_refresh_token',
    null
)

const keycloakHasInitialized = ref(false)

/**
 * This method can be called to ensure that the keycloak object is initialized
 */
export async function initializeKeycloak() {
    // Only initialize keycloak if it has not been initialized already
    keycloak.value = new Keycloak({
        url: import.meta.env.VITE_KEYCLOAK_ENDPOINT,
        realm: import.meta.env.VITE_KEYCLOAK_REALM,
        clientId: import.meta.env.VITE_KEYCLOAK_CLIENT_ID,
    })

    if (keycloakAccessToken.value && keycloakRefreshToken.value) {
        try {
            await keycloak.value.init({
                token: keycloakAccessToken.value,
                refreshToken: keycloakRefreshToken.value,
                checkLoginIframe: false,
            })
            await keycloak.value.updateToken(10)
        } catch (error) {
            await keycloak.value.init({
                onLoad: 'login-required',
                checkLoginIframe: false,
            })
        }
    } else {
        await keycloak.value.init({
            onLoad: 'login-required',
            checkLoginIframe: false,
        })
    }
    updateStoredAccessTokens()

    keycloakHasInitialized.value = true
}

/**
 * This method can be used to obtain a valid access token
 */
export async function getKeycloakAccessToken() {
    await waitForKeycloakToInitialize()

    // Make sure that the token is still valid for at least 10 Seconds
    // otherwise it will be refreshed
    keycloak.value.updateToken(10)
    updateStoredAccessTokens()
    return keycloak.value.token
}

export async function getKeycloakUser() {
    if (keycloakHasInitialized.value) {
        return keycloak.value.tokenParsed
    }
    await initializeKeycloak()
    return keycloak.value.tokenParsed
}

export function getAccountUrl() {
    const url: string = keycloak.value.createAccountUrl()
    if (url) {
        return url
    }
    return null
}

export function logOut() {
    keycloak.value.logout()
}

function waitForKeycloakToInitialize() {
    return new Promise((resolve) => {
        watchEffect(() => {
            if (keycloakHasInitialized.value === true) {
                resolve(keycloak.value)
            }
        })
    })
}

function updateStoredAccessTokens() {
    if (keycloak.value.token && keycloak.value.refreshToken) {
        keycloakAccessToken.value = keycloak.value.token
        keycloakRefreshToken.value = keycloak.value.refreshToken
    }
}
