import { Asset, ImageInterface, Platform } from '@src/types'

export class Issuer {
    id: string | null = null
    name: string | null = null
    status: string | null = null
    internal_status: string | null = null
    wallet_id: string | null = null
    industry: string | null = null
    address: string | null = null
    city: string | null = null
    postal_code: string | null = null
    state: string | null = null
    country: string | null = null
    phone_number: string | null = null
    email: string | null = null
    website: string | null = null
    platform: Platform | null = null
    platform_name: string | null = null
    company_logo: ImageInterface | null = null

    created_at: Date | null = null
    updated_at: Date | null = null

    assets: Asset[] = []
    assets_count: number | null = null
}
