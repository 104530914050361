<template>
    <div
        v-if="showAccordion"
        class="mb-3 rounded-sm bg-white px-5 py-4 shadow-lg"
        :class="borderClass"
    >
        <div class="flex items-center justify-between gap-2">
            <Tooltip
                v-if="help"
                bg="dark"
                size="md"
                position="right"
            >
                <div class="text-sm text-gray-200">
                    {{ $t(help) }}
                </div>
            </Tooltip>
            <button
                class="group mb-1 flex w-full items-center justify-between py-2"
                :aria-expanded="open"
                @click.prevent="open = !open"
            >
                <div class="text-base font-bold text-gray-800">
                    {{ $t(title)
                    }}{{ addToTitle !== null ? ` (${addToTitle})` : '' }}
                </div>
                <fa
                    icon="fa-chevron-down"
                    :class="{ 'rotate-180': open }"
                />
            </button>
        </div>
        <div
            v-show="open"
            class="pt-4 pb-2 text-sm"
        >
            <slot />
        </div>
    </div>

    <div
        v-else
        class="my-6"
    >
        <slot />
    </div>
</template>

<script setup lang="ts">
import { Tooltip } from '@src/components'
import { computed, ref } from 'vue'

const props = withDefaults(
    defineProps<{
        title: string
        addToTitle?: string | number | null
        help?: string | null
        open?: boolean
        showAccordion?: boolean
    }>(),
    {
        showAccordion: true,
        addToTitle: null,
        help: null,
    }
)

const open = ref(props.open === true)

const borderClass = computed(() => {
    return open.value ? '' : 'border border-gray-200'
})
</script>
