import { Issuer, TableSorting } from '@src/types'
import { ApiRes, api } from '@src/utils/api/client'
import { AxiosRequestConfig } from 'axios'

export interface IssuersResponse {
    success: boolean
    issuers_count: number
    issuers: Issuer[]
}

export const getIssuers = (
    filters?: object,
    page?: number,
    limit?: number,
    sorting?: TableSorting[]
): ApiRes<IssuersResponse> => {
    const config: AxiosRequestConfig = {}

    config.params = {}

    if (filters && Object.keys(filters).length) {
        config.params = filters
    }

    if (typeof page === 'number' && typeof limit === 'number') {
        config.params['range'] = page * limit - limit + '-' + page * limit
    }

    if (sorting && sorting.length) {
        config.params['sort'] = sorting
            .map((sort) => {
                return (sort.direction === 'desc' ? '-' : '') + sort.field
            })
            .join(',')
    }

    return api.get('manage/issuers', config)
}

export const createIssuer = (issuer: Issuer): ApiRes<Issuer> =>
    api.post('manage/issuers', issuer)

export const getIssuer = (id: string): ApiRes<Issuer> =>
    api.get('manage/issuers/' + id)

export const deleteIssuer = (id: string): ApiRes<string> =>
    api.delete('manage/issuers/' + id)

export const deleteIssuers = (ids: string[]): ApiRes<string> =>
    api.delete('manage/issuers', { data: { issuers: ids } })

export const updateIssuer = (issuer: Issuer): ApiRes<Issuer> =>
    api.patch('manage/issuers/' + issuer.id, issuer)
