<template>
    <PageWrapper>
        <template #headline>
            <fa icon="fa-building-columns" />
            {{ $t('profile_information_legal') }}:
            {{ profileLegal.company_name }}
        </template>

        <template #actions>
            <BackButton
                v-if="$router.options.history.state.back"
                @click="router.go(-1)"
            />
            <BackButton
                v-if="
                    profileLegal.legal_profile_uid &&
                    $router.options.history.state.back !==
                        `/legalProfile/${profileLegal.legal_profile_uid}`
                "
                icon="fa-eye"
                title="account"
                @click="goToDetails"
            />
            <BackButton
                v-if="$router.options.history.state.back !== '/accounts'"
                icon="fa-list"
                title="accounts"
                @click="toAccounts"
            />
            <SaveButton @click="saveLegalProfile" />
        </template>

        <AccordionBasic
            title="company_details"
            class="mb-2"
            :open="true"
        >
            <TextField
                v-model="profileLegal.company_name"
                name="company_name"
                label="company_name"
                help="help_company_name"
                :required="true"
            />
            <TextField
                v-model="profileLegal.registration_number"
                name="registration_number"
                label="profile_company_registration_number"
                help="help_account_company_registration_number"
                :required="true"
            />
            <TextField
                v-model="profileLegal.description"
                name="description"
                label="profile_company_description"
                help="help_account_company_description"
                :required="true"
            />
            <TextField
                v-model="profileLegal.website"
                name="website"
                label="company_website"
                help="help_company_website"
            />
            <TextField
                v-if="userStore.can('update_company_email')"
                v-model="profileLegal.company_email"
                name="company_email"
                label="profile_company_company_email"
                help="help_company_email"
            />
        </AccordionBasic>

        <AccordionBasic
            title="account_address_details"
            class="mb-2"
            :open="false"
        >
            <h4 class="font-bold">{{ $t('billing_address') }}</h4>
            <TextField
                v-model="profileLegal.address"
                name="address"
                label="account_address"
                help="help_account_address"
            />
            <TextField
                v-model="profileLegal.postal_code"
                name="postal_code"
                label="account_zip"
                help="help_account_zip"
            />
            <TextField
                v-model="profileLegal.city"
                name="city"
                label="account_city"
                help="help_account_city"
            />
            <SelectField
                v-model="profileLegal.country"
                name="country"
                label="account_country"
                help="help_account_country"
                :options="countries"
            />
            <TextField
                v-model="profileLegal.state"
                name="state"
                label="account_state"
                help="help_account_state"
            />

            <h4 class="block pt-4 font-bold">{{ $t('postal_address') }}</h4>

            <ToggleSwitch
                v-model="isPostalAddressSameAsBillingAddress"
                name="is_postal_address_same_as_billing_address"
                label="same_as_billing_address"
                @toggle="handleTogglePostalAddress"
            />
            <div v-if="!isPostalAddressSameAsBillingAddress">
                <TextField
                    v-model="profileLegal.postal_address"
                    name="address"
                    label="account_address"
                    help="help_account_address"
                />
                <TextField
                    v-model="profileLegal.postal_address_postal_code"
                    name="postal_code"
                    label="account_zip"
                    help="help_account_zip"
                />
                <TextField
                    v-model="profileLegal.postal_address_city"
                    name="city"
                    label="account_city"
                    help="help_account_city"
                />
                <SelectField
                    v-model="profileLegal.postal_address_country"
                    name="country"
                    label="account_country"
                    help="help_account_country"
                    :options="countries"
                />
                <TextField
                    v-model="profileLegal.postal_address_state"
                    name="state"
                    label="account_state"
                    help="help_account_state"
                />
            </div>
        </AccordionBasic>

        <AccordionBasic
            title="tax_details"
            class="mb-2"
            :open="false"
        >
            <TextField
                v-model="profileLegal.tax_number"
                name="tax_number"
                label="tax_number"
                help="help_account_tax_number"
            />
        </AccordionBasic>
    </PageWrapper>
</template>
<script setup lang="ts">
import {
    AccordionBasic,
    SelectField,
    TextField,
    ToggleSwitch,
} from '@src/components'
import BackButton from '@src/partials/actions/BackButton.vue'
import SaveButton from '@src/partials/actions/SaveButton.vue'
import PageWrapper from '@src/partials/pages/PageWrapper.vue'
import { LegalProfile } from '@src/types'
import { useCountryStore } from '@src/utils/stores/countries'
import { getLegalProfile, updateLegalProfile } from '@utils/api/accounts'
import { onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { useToastStore } from '@stores/toast'
import { useUserStore } from '@stores/user'
import { useI18n } from 'vue-i18n'
const profileLegal = ref<LegalProfile>(new LegalProfile())

const router = useRouter()
const route = useRoute()
const toast = useToastStore()
const i18n = useI18n()
const userStore = useUserStore()

const countries = ref<{ label: string; value: string | null }[]>([])

const isPostalAddressSameAsBillingAddress = ref(true)
const countryStore = useCountryStore()

function goToDetails(): void {
    router.push({
        name: 'legalProfile',
        params: { id: profileLegal.value.legal_profile_uid },
    })
}

function toAccounts() {
    router.push({ name: 'accounts' })
}

function initPostalAddress() {
    if (
        profileLegal.value.postal_address &&
        profileLegal.value.postal_address_postal_code &&
        profileLegal.value.postal_address_city &&
        profileLegal.value.postal_address_country &&
        profileLegal.value.postal_address_state
    ) {
        isPostalAddressSameAsBillingAddress.value = false
    }
}

function handleTogglePostalAddress() {
    if (!isPostalAddressSameAsBillingAddress.value) {
        profileLegal.value.postal_address = profileLegal.value.address
        profileLegal.value.postal_address_postal_code =
            profileLegal.value.postal_code
        profileLegal.value.postal_address_city = profileLegal.value.city
        profileLegal.value.postal_address_country = profileLegal.value.country
        profileLegal.value.postal_address_state = profileLegal.value.state
    } else {
        profileLegal.value.postal_address = null
        profileLegal.value.postal_address_postal_code = null
        profileLegal.value.postal_address_city = null
        profileLegal.value.postal_address_country = null
        profileLegal.value.postal_address_state = null
    }
}

onMounted(async () => {
    if (route.params.id) {
        const legalProfileRequest = await getLegalProfile(
            route.params.id as string
        )

        profileLegal.value = legalProfileRequest.data.legal_profile

        initPostalAddress()

        loadData()
        if (
            profileLegal.value.postal_address &&
            profileLegal.value.postal_address_postal_code &&
            profileLegal.value.postal_address_city &&
            profileLegal.value.postal_address_country &&
            profileLegal.value.postal_address_state
        ) {
            isPostalAddressSameAsBillingAddress.value = false
        }
    }
})

async function loadData() {
    countries.value = await countryStore.loadAndSortCountriesOptionsAsync()
}

async function saveLegalProfile() {
    try {
        await updateLegalProfile(profileLegal.value)
        toast.success(
            i18n.t('toast_success_legal_profile_updated', {
                profileLegal: profileLegal.value.natural_profile_uid,
            })
        )
    } catch (error) {
        console.error(error)
        toast.error(i18n.t('toast_error_legal_profile_update_failed'))
    }
}

watch(i18n.locale, async () => {
    loadData()
})
</script>
