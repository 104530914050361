import { OpenModal, useTable } from '@composables/common'
import { NyalaRegistryDocument } from '@src/types'
import { Ref, computed, h } from 'vue'

export function useNyalaRegistryDocumentsTable(
    nyalaRegistryDocuments: Ref<NyalaRegistryDocument[]>,
    openModal: OpenModal,
    isModalOpen: Ref<boolean>,
    label: string,
    help?: string
) {
    const total = computed(() => {
        return nyalaRegistryDocuments.value.length
    })
    const { TableWrapper: NyalaRegistryDocumentsTable } = useTable({
        tableProps: {
            data: nyalaRegistryDocuments,
            total: total,
            label: label,
            id: label,
            help: help,
            columns: [
                {
                    label: 'name',
                    key: 'file_name',
                    copy: true,
                    sorting: false,
                },
                {
                    label: 'asset',
                    key: 'asset_title',
                    url: assetsUrl,
                    copy: true,
                    sorting: false,
                },
            ],
            actions: [
                {
                    action: handleViewDocument,
                    icon: 'fa-eye',
                    title: 'help_open_file_in_new_tab',
                },
            ],
        },
    })

    function assetsUrl(nyalaRegistryDocument: NyalaRegistryDocument) {
        return '/assets/' + nyalaRegistryDocument.asset_id
    }

    function handleViewDocument(rowData: NyalaRegistryDocument) {
        const url = rowData.url
        isModalOpen.value = true
        openModal(
            {
                id: 'nyala_registry_document_modal',
                title: 'nyala_registry_document',
                open: isModalOpen,
                onCancel: () => (isModalOpen.value = false),
            },
            () =>
                h('iframe', {
                    src: `${url}#zoom=100`,
                    class: 'full-size-iframe',
                })
        )
    }

    return {
        NyalaRegistryDocumentsTable,
    }
}
