import { useEnum, useSummary } from '@composables/common'
import { RegulatoryRequirements } from '@src/enums'
import { Campaign } from '@src/types'
import { SummaryItem } from '@src/types/Summary'
import { Ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'

export function useCampaignSummary(campaign: Ref<Campaign>) {
    const i18n = useI18n()
    const { getEnumLabel } = useEnum()

    const items = computed<SummaryItem[]>(() => {
        return [
            {
                label: i18n.t('id'),
                value: campaign.value.asset_var_uid,
                linkURL: `/campaigns/${campaign.value.asset_var_uid}`,
                special: 'link',
                copy: true,
            },
            {
                label: i18n.t('name'),
                value: campaign.value.asset_title,
                linkURL: `/campaigns/${campaign.value.asset_var_uid}`,
                special: 'link',
                copy: true,
            },
            {
                label: i18n.t('regulatory_requirements'),
                value: getEnumLabel(
                    RegulatoryRequirements,
                    campaign.value.regulatory_requirements,
                    true
                ),
            },
        ]
    })

    const { Summary: CampaignSummary } = useSummary(items, {
        title: 'campaign',
    })

    return { CampaignSummary }
}
