import { ObjectWithStringKeysAndStringValue } from '@src/types/ObjectWithStringKeysAndStringValue'
import { i18n } from '@src/utils/lang'
import { useToastStore } from '@src/utils/stores/toast'
import { computed, ref } from 'vue'

const { t } = i18n.global

export function useFilters(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: Record<string, any>,
    disableFilters?: string[],
    id?: string
) {
    const filtersFields = ref<{
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [key: string]: any
    }>({})
    const filters = ref<ObjectWithStringKeysAndStringValue>({})
    const pinnedFilters = ref<string[]>([])

    const parseUrlParam = (value: string | null) => {
        if (!value) {
            return null
        }
        if (/^\d+$/.test(value)) {
            return parseInt(value)
        }
        return value
    }

    const initFiltersFields = () => {
        const url = new URL(window.location.href)
        const urlParams = new URLSearchParams(url.search)
        Object.entries(data).forEach(([key, value]) => {
            const urlParam = parseUrlParam(urlParams.get(`${id}_filter_${key}`))
            filtersFields.value[key] = urlParam !== null ? urlParam : value
        })

        const userPinnedFilters = sessionStorage.getItem('conda-pinned-filters')

        if (userPinnedFilters) {
            pinnedFilters.value = JSON.parse(userPinnedFilters)
        }

        setFilters()
    }

    const togglePinFilters = () => {
        if (pinnedFilters.value.includes(id as string)) {
            pinnedFilters.value = pinnedFilters.value.filter(
                (value: string) => value !== id
            )
        } else {
            pinnedFilters.value.push(id as string)
        }

        sessionStorage.setItem(
            'conda-pinned-filters',
            JSON.stringify(pinnedFilters.value)
        )
    }

    const pinFilters = computed(() => {
        return pinnedFilters.value.includes(id as string)
    })

    const setFilters = () => {
        const url = new URL(window.location.href)
        const urlParams = new URLSearchParams(url.search)
        filters.value = {}
        Object.entries(filtersFields.value).forEach(([key, value]) => {
            if (value !== null && value !== undefined && value !== '') {
                filters.value[`filter[${key}]`] =
                    typeof value !== 'string' ? value.toString() : value
                urlParams.set(`${id}_filter_${key}`, value as string)
            } else {
                urlParams.delete(`${id}_filter_${key}`)
            }
        })

        if (!Object.values(filters.value).length) {
            return
        }

        if (urlParams.size > 0) {
            window.history.replaceState(
                {},
                '',
                `${window.location.pathname}?${urlParams}`
            )
        }
    }

    const resetFilters = () => {
        const url = new URL(window.location.href)
        const urlParams = new URLSearchParams(url.search)
        Object.keys(filtersFields.value).forEach((key) => {
            if (!disableFilters?.includes(key)) {
                filtersFields.value[key] = null
                urlParams.delete(`${id}_filter_${key}`)
            }
        })
        window.history.replaceState(
            {},
            '',
            urlParams.size > 0
                ? `${window.location.pathname}?${urlParams}`
                : window.location.pathname
        )
        setFilters()
    }

    const setFiltersSuccess = () => {
        const toast = useToastStore()
        toast.success(
            t('toast_success_applying_filters', {
                filters: Object.keys(filters.value)
                    .map((key) => {
                        return key + ': ' + filters.value[key]
                    })
                    .join(', '),
            })
        )
    }

    const resetFiltersSuccess = () => {
        const toast = useToastStore()
        toast.success(t('toast_success_clear_filters'))
    }

    initFiltersFields()

    return {
        filtersFields,
        filters,
        initFiltersFields,
        setFilters,
        resetFilters,
        setFiltersSuccess,
        resetFiltersSuccess,
        togglePinFilters,
        pinFilters,
    }
}
