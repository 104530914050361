import { ModalButtonStyles, OpenModal, useTable } from '@composables/common'
import { NameAndId } from '@src/components'
import { ContentImage } from '@src/types'
import { UploadFileResponse } from '@utils/api/s3'
import { useUserStore } from '@utils/stores/user'
import { Ref, computed, h } from 'vue'
import { useI18n } from 'vue-i18n'

export function useCampaignContentImagesTable(
    contentImages: Ref<ContentImage[]>,
    update: (successMessage?: string | null) => Promise<void>,
    openModal: OpenModal,
    isModalOpen: Ref<boolean>
) {
    const userStore = useUserStore()
    const i18n = useI18n()

    const total = computed(() => {
        return contentImages.value.length
    })

    const {
        TableWrapper: CampaignContentImagesTable,
        selected: selectedContentImagesIds,
    } = useTable({
        tableProps: {
            id: 'content_images_table',
            label: 'content_images',
            help: 'help_campaign_content_images_table',
            data: contentImages,
            total: total,
            columns: [
                {
                    label: 'id',
                    key: 'uuid',
                    select: true,
                },
                {
                    label: 'id',
                    key: 'uuid',
                    sorting: true,
                    url: contentImagesTableUrl,
                    targetBlank: true,
                    condition: userStore.can('see_id_columns'),
                },
                {
                    label: 'name',
                    key: 'name',
                    sorting: true,
                    url: contentImagesTableUrl,
                    targetBlank: true,
                },
            ],
            actions: [
                {
                    action: handleViewContentImage,
                    icon: 'fa-eye',
                    title: 'view',
                },
                {
                    action: handleDeleteContentImage,
                    icon: 'fas fa-trash-alt',
                    title: 'delete',
                    color: 'text-danger-500',
                    condition: userStore.can('update_campaign'),
                },
            ],
        },
    })

    function contentImagesTableUrl(contentImage: ContentImage) {
        return (contentImage.url ?? contentImage.thumb_image_url) as string
    }

    function handleViewContentImage(contentImage: ContentImage) {
        const url = contentImagesTableUrl(contentImage)
        if (url) {
            window.open(url)
        }
    }

    function handleDeleteContentImage(contentImage: ContentImage) {
        isModalOpen.value = true
        openModal(
            {
                id: 'delete_campaign_content_image',
                title: 'modal_title_delete_campaign_content_image',
                open: isModalOpen,
                onCancel: () => (isModalOpen.value = false),
                onSubmit: () => deleteContentImage(contentImage),
            },
            () =>
                h(NameAndId, {
                    idKey: 'uuid',
                    nameKey: 'name',
                    modelValue: [contentImage],
                }),
            {
                submitButtonText: 'delete',
                styles: {
                    submitButton: ModalButtonStyles['RED'],
                },
            }
        )
    }

    function handleDeleteContentImages() {
        isModalOpen.value = true
        openModal(
            {
                id: 'delete_campaign_content_images',
                title: 'modal_title_delete_campaign_content_images',
                open: isModalOpen,
                onCancel: () => (isModalOpen.value = false),
                onSubmit: () => deleteContentImages(),
            },
            () =>
                h(NameAndId, {
                    idKey: 'uuid',
                    nameKey: 'name',
                    modelValue: selectedContentImages.value,
                }),
            {
                submitButtonText: 'delete',
                styles: {
                    submitButton: ModalButtonStyles['RED'],
                },
            }
        )
    }

    function deleteContentImage(
        contentImageToDelete: ContentImage,
        submit = true
    ) {
        contentImages.value.splice(
            contentImages.value.findIndex(
                (contentImage) => contentImageToDelete.uuid == contentImage.uuid
            ),
            1
        )
        if (submit) {
            update(
                i18n.t('toast_success_campaign_content_image_deleted', {
                    contentImage: contentImageToDelete.name,
                })
            )
            isModalOpen.value = false
        }
    }

    function deleteContentImages() {
        selectedContentImages.value.forEach((contentImage) => {
            deleteContentImage(contentImage, false)
        })
        update(
            i18n.t('toast_success_campaign_content_images_deleted', {
                contentImages: selectedContentImages.value
                    .map((contentImage) => contentImage.name)
                    .join(', '),
            })
        )
        selectedContentImagesIds.value = []
        isModalOpen.value = false
    }

    const selectedContentImages = computed(() => {
        return contentImages.value.filter(
            (item: ContentImage) =>
                item.uuid && selectedContentImagesIds.value.includes(item.uuid)
        )
    })

    function handleCreateContentImage(
        response: UploadFileResponse,
        fileName: string
    ) {
        const newImage = new ContentImage()
        newImage.upload_key = response.key
        newImage.thumb_image_url = response.temp_storage_url
        contentImages.value.push(newImage)
        update(
            i18n.t('toast_success_campaign_content_image_created', {
                contentImage: fileName,
            })
        )
    }

    return {
        CampaignContentImagesTable,
        selectedContentImagesIds,
        handleDeleteContentImages,
        handleCreateContentImage,
    }
}
