import { SummaryOptions, useSummary } from '@composables/common'
import { Address } from '@src/types'
import { SummaryItem } from '@src/types/Summary'
import { useCountryStore } from '@src/utils/stores/countries'
import { computedAsync } from '@vueuse/core'
import { Ref } from 'vue'
import { useI18n } from 'vue-i18n'

export function useAddressSummary(
    address: Ref<Address>,
    options?: SummaryOptions
) {
    const i18n = useI18n()
    const countryStore = useCountryStore()
    const items = computedAsync<SummaryItem[]>(async () => {
        return [
            {
                label: i18n.t('address'),
                value: address.value.address,
                copy: true,
            },
            {
                label: i18n.t('city'),
                value: address.value.city,
                copy: true,
            },
            {
                label: i18n.t('postal_code'),
                value: address.value.zip,
                copy: true,
            },
            {
                label: i18n.t('country'),
                value: await countryStore.translate(
                    address.value.country as string
                ),
                copy: true,
            },
        ]
    }, [])

    const { Summary: AddressSummary } = useSummary(items, {
        ...{ title: 'address' },
        ...options,
    })

    return {
        AddressSummary,
    }
}
