<template>
    <div class="flex h-screen overflow-hidden">
        <!-- Sidebar -->
        <Sidebar
            :sidebar-open="sidebarOpen"
            @toggle-sidebar="toggleSidebar"
        />

        <!-- Content area -->
        <div
            ref="pageBody"
            class="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden"
        >
            <!-- Site header -->
            <Header
                :sidebar-open="sidebarOpen"
                @toggle-sidebar="() => (sidebarOpen = !sidebarOpen)"
            />

            <main>
                <div
                    class="sticky left-0 top-16 z-40 transition-all duration-200"
                    :class="{
                        'h-0 drop-shadow-none': !isPageHeaderSticky,
                        'h-28 w-full bg-gray-100 drop-shadow-md md:h-20':
                            isPageHeaderSticky,
                    }"
                ></div>
                <div
                    :class="`${mainMaxWidth} mx-auto w-full px-4 py-8 sm:px-6 lg:px-8`"
                >
                    <!-- Page header -->

                    <div
                        class="mb-8 bg-gray-100 transition-all duration-200 sm:items-center sm:justify-between md:flex"
                        :class="{
                            'sticky top-16 z-40 w-full pt-3':
                                isPageHeaderSticky,
                        }"
                    >
                        <!-- Left: Title -->
                        <h1
                            class="mb-4 mr-4 grow overflow-hidden truncate text-2xl font-bold text-gray-800 md:text-3xl"
                        >
                            <slot name="headline" />
                        </h1>

                        <!-- Right: Actions  -->
                        <div
                            class="grid auto-cols-max grid-flow-row grid-flow-col justify-end gap-2"
                        >
                            <slot name="actions" />
                        </div>
                    </div>

                    <slot name="modals" />

                    <!-- Main: Content -->
                    <slot />
                </div>
            </main>
        </div>
    </div>
</template>
<script setup lang="ts">
import Header from '@src/partials/Header.vue'
import Sidebar from '@src/partials/Sidebar.vue'
import { useScroll } from '@vueuse/core'
import { computed, ref } from 'vue'

withDefaults(
    defineProps<{
        mainMaxWidth?: string
    }>(),
    {
        mainMaxWidth: 'max-w-9xl',
    }
)

const sidebarOpen = ref(false)

const pageBody = ref<HTMLElement | null>(null)

const { y: scrollY } = useScroll(pageBody)

const isPageHeaderSticky = computed(() => {
    return scrollY.value > 100
})

function toggleSidebar() {
    sidebarOpen.value = !sidebarOpen.value
    if (sidebarOpen.value) {
        document.querySelector('body')?.classList.add('sidebar-expanded')
    } else {
        document.querySelector('body')?.classList.remove('sidebar-expanded')
    }
}
</script>
