<template>
    <th
        class="whitespace-nowrap bg-white px-2 py-3 first:left-0 first:pl-5 last:sticky last:right-0 last:pr-5"
    >
        <div
            v-if="sortKey"
            class="text-left font-semibold"
            :class="{
                'cursor-pointer': useSorting,
            }"
            @click="changeSorting"
        >
            <slot />
            <fa
                v-if="useSorting && direction === null"
                icon="fa-arrows-up-down"
                class="ml-2"
            />
            <fa
                v-if="useSorting && direction === 'asc'"
                icon="fa-arrow-down-short-wide"
                class="ml-2"
            />
            <fa
                v-if="useSorting && direction === 'desc'"
                icon="fa-arrow-up-wide-short"
                class="ml-2"
            />
        </div>
        <div
            v-else
            class="text-left font-semibold"
        >
            <slot />
        </div>
    </th>
</template>
<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'
import { TableSorting } from '@src/types'
import { useVModels } from '@vueuse/core'

const props = defineProps<{
    sorting?: TableSorting[]
    sortKey?: string | null
}>()

const emit = defineEmits(['update:sorting'])

const { sorting } = useVModels(props, emit)

const direction = ref<null | 'asc' | 'desc'>(null)

const useSorting = computed(() => sorting?.value && props?.sortKey)

function changeSorting() {
    if (!useSorting.value) return

    // handle direction
    toggleDirection()

    // remove the entry if already present
    if (sorting && sorting.value) {
        const possibleKey = sorting.value.findIndex((sort) => {
            return sort.field == props.sortKey
        })

        if (typeof possibleKey !== 'undefined' && possibleKey >= 0) {
            sorting?.value?.splice(possibleKey, 1)
        }

        // add new sorting
        if (direction.value) {
            sorting.value.push({
                field: props.sortKey as string,
                direction: direction.value,
            })
        }

        // fire emit
        emit('update:sorting', sorting?.value)
    }
}

function toggleDirection() {
    if (typeof direction.value === 'undefined' || direction.value === null) {
        direction.value = 'asc'
    } else if (direction.value === 'asc') {
        direction.value = 'desc'
    } else if (direction.value === 'desc') {
        direction.value = null
    }
}

onMounted(() => {
    if (!useSorting.value) return

    if (sorting && sorting.value) {
        // handle direction
        const possibleKey = sorting.value.findIndex((sort) => {
            return sort.field == props.sortKey
        })

        if (typeof possibleKey !== 'undefined' && possibleKey >= 0) {
            direction.value = sorting.value[possibleKey].direction as
                | 'asc'
                | 'desc'
                | null
        }
    }
})
</script>
