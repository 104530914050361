import { defineStore } from 'pinia'
import { ref } from 'vue'

const LOADING_DEBOUNCE = 100

export const useLoadingStore = defineStore('loading', () => {
    const loading = ref<boolean>(false)

    const debounceTimer = ref<ReturnType<typeof setTimeout> | null>(null)

    function setLoading(value: boolean) {
        if (debounceTimer.value) {
            clearTimeout(debounceTimer.value)
        }
        debounceTimer.value = setTimeout(() => {
            loading.value = value
        }, LOADING_DEBOUNCE)
    }

    function getLoading() {
        return loading.value
    }

    return {
        getLoading,
        setLoading,
    }
})
