import { useMask, useTable } from '@composables/common'
import { MoneyWithdrawal } from '@src/types'
import { formatNumericValues } from '@src/utils/helpers'
import moment from 'moment'
import { Ref } from 'vue'
import { useI18n } from 'vue-i18n'

export function useWithdrawalTable(
    moneyWithdrawals: Ref<MoneyWithdrawal[]>,
    moneyWithdrawalsNum: Ref<number>
) {
    const i18n = useI18n()
    const { everyFourSpace } = useMask()

    const { TableWrapper: WithdrawalTable } = useTable({
        tableProps: {
            id: 'withdrawals-table',
            label: 'withdrawals',
            total: moneyWithdrawalsNum,
            data: moneyWithdrawals,
            help: 'help_withdrawals_table',
            columns: [
                {
                    label: 'created_at',
                    key: 'created_at',
                    sorting: false,
                    cellRenderer: (props: { rowData: MoneyWithdrawal }) => {
                        return moment(props.rowData.created_at).format(
                            'DD.MM.yyyy HH:mm'
                        )
                    },
                },
                {
                    label: 'amount',
                    key: 'amount',
                    sorting: false,
                    cellRenderer: (props: { rowData: MoneyWithdrawal }) => {
                        return formatNumericValues(props.rowData.amount)
                    },
                },
                {
                    label: 'status',
                    key: 'status',
                    sorting: false,
                },
                {
                    label: 'iban',
                    key: 'iban',
                    sorting: false,
                    cellRenderer: (props: { rowData: MoneyWithdrawal }) => {
                        return (
                            props.rowData.bankAccount.iban &&
                            everyFourSpace(props.rowData.bankAccount.iban)
                        )
                    },
                    copy: true,
                },
                {
                    label: 'comment',
                    key: 'comment',
                    sorting: false,
                },
                {
                    label: 'wallet_withdrawal_started_from_frontend',
                    key: 'was_started_from_frontend',
                    sorting: false,
                    cellRenderer: (props: { rowData: MoneyWithdrawal }) => {
                        return props.rowData.was_started_from_frontend
                            ? i18n.t('yes')
                            : i18n.t('no')
                    },
                },
            ],
        },
    })
    return {
        WithdrawalTable,
    }
}
