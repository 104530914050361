<template>
    <PageWrapper>
        <template #headline>
            <fa icon="fa-solid fa-rocket" />&nbsp;{{ campaign.asset_title }}
        </template>
        <template #actions>
            <BackButton
                v-if="$router.options.history.state.back"
                @click="router.go(-1)"
            />
            <BackButton
                v-if="$router.options.history.state.back !== '/campaigns'"
                icon="fa-list"
                title="campaigns"
                @click="toCampaigns"
            />
            <EditButton
                v-if="userStore.can('update_campaign')"
                title="edit"
                @click="edit"
            />
            <DeleteButton
                v-if="userStore.can('delete_campaign')"
                @click="handleDelateCampaign"
            />
            <ActionsMenu
                v-if="
                    userStore.can('create_campaign') ||
                    userStore.can('update_campaign')
                "
            />
        </template>

        <SummaryWrapper>
            <CampaignSummary v-if="campaignDetailsItems" />
            <template #sidebar>
                <AssetSummary />
                <PlatformSummary v-if="userStore.is('developer')" />
            </template>
        </SummaryWrapper>

        <CampaignContentImagesTable>
            <template #actions>
                <FileUploadButton
                    v-if="userStore.can('update_campaign')"
                    :deletable="false"
                    mode="public"
                    accept=".jpg,.png,.webp,.gif"
                    @submit="handleCreateContentImage"
                />

                <DeleteBulkButton
                    v-if="userStore.can('update_campaign')"
                    v-model:selected="selectedContentImagesIds"
                    @click.stop="() => handleDeleteContentImages()"
                />
            </template>
        </CampaignContentImagesTable>
        <CampaignContentBlocksTable>
            <template #actions>
                <CreateButton
                    v-if="userStore.can('update_campaign')"
                    @click.stop="() => handleEditOrCreateContentBlock()"
                />
                <DeleteBulkButton
                    v-if="userStore.can('update_campaign')"
                    v-model:selected="selectedContentBlocksIds"
                    @click.stop="() => handleDeleteContentBlocks()"
                />
            </template>
        </CampaignContentBlocksTable>
        <CampaignTeamMembersTable>
            <template #actions>
                <CreateButton
                    v-if="userStore.can('update_campaign')"
                    @click.stop="() => handleEditOrCreateTeamMember()"
                />
                <DeleteBulkButton
                    v-if="userStore.can('update_campaign')"
                    v-model:selected="selectedTeamMembersIds"
                    @click.stop="() => handleDeleteTeamMembers()"
                />
            </template>
        </CampaignTeamMembersTable>
        <CampaignIncentivesTable>
            <template #actions>
                <CreateButton
                    v-if="userStore.can('update_campaign')"
                    @click.stop="() => handleEditOrCreateIncentive()"
                />
                <DeleteBulkButton
                    v-if="userStore.can('update_campaign')"
                    v-model:selected="selectedIncentivesIds"
                    @click.stop="() => handleDeleteIncentives()"
                />
            </template>
        </CampaignIncentivesTable>
        <OrdersTable
            v-if="campaign.asset_var_uid"
            :initial-filters="{
                asset_var_uid: campaign.asset_var_uid,
            }"
            :create-initial-values="ordersTableCreateInitialValue"
            :create-disabled-fields="['asset_var_uid']"
        />
        <ModalWrapper v-if="isModalOpen" />
    </PageWrapper>
</template>

<script setup lang="ts">
/* eslint-disable quote-props */
import { useCampaignContentBlocksTable } from '@composables/campaigns/useCampaignContentBlocksTable'
import { useCampaignContentImagesTable } from '@composables/campaigns/useCampaignContentImagesTable'
import { useCampaignIncentivesTable } from '@composables/campaigns/useCampaignIncentivesTable'
import { useCampaignTeamMembersTable } from '@composables/campaigns/useCampaignTeamMembersTable'
import {
    ModalButtonStyles,
    useActionsMenu,
    useModal,
} from '@composables/common'
import BackButton from '@partials/actions/BackButton.vue'
import EditButton from '@partials/actions/EditButton.vue'
import FileUploadButton from '@partials/actions/FileUploadButton.vue'
import DuplicateCampaignForm from '@partials/campaigns/DuplicateCampaignForm.vue'
import OrdersTable from '@partials/orders/OrdersTable.vue'
import PageWrapper from '@partials/pages/PageWrapper.vue'
import { NameAndId, SelectField, SummaryWrapper } from '@src/components'
import { useEnum, useSummary } from '@src/composables/common'
import { useAssetSummary } from '@src/composables/summaries/useAssetSummary'
import { usePlatformSummary } from '@src/composables/summaries/usePlatformSummary'
import {
    AgioDisagio,
    AgioDisagioType,
    CampaignBadge,
    CampaignStatus,
    RegulatoryRequirements,
} from '@src/enums'
import CreateButton from '@src/partials/actions/CreateButton.vue'
import DeleteBulkButton from '@src/partials/actions/DeleteBulkButton.vue'
import DeleteButton from '@src/partials/actions/DeleteButton.vue'
import {
    Asset,
    AssetsIncentive,
    AssetsMeta,
    Campaign,
    ContentImage,
    CreateOrderPayload,
    DuplicatedCampaign,
    Platform,
    TeamMember,
} from '@src/types'
import { SummaryItem } from '@src/types/Summary'
import { getAsset } from '@src/utils/api/assets'
import {
    deleteCampaign as apiDeleteCampaign,
    duplicateCampaign as apiDuplicateCampaign,
    getCampaign,
    updateCampaign,
} from '@src/utils/api/campaigns'
import { getPlatform } from '@src/utils/api/platforms'
import { formatNumericValues } from '@src/utils/helpers'
import { useCountryStore } from '@src/utils/stores/countries'
import { useLanguageStore } from '@src/utils/stores/languages'
import { useToastStore } from '@src/utils/stores/toast'
import { useUserStore } from '@src/utils/stores/user'
import { computedAsync } from '@vueuse/core'
import moment from 'moment'
import { computed, h, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'

const router = useRouter()
const route = useRoute()
const toast = useToastStore()
const i18n = useI18n()
const { ModalWrapper, openModal } = useModal()
const userStore = useUserStore()
const countryStore = useCountryStore()
const languageStore = useLanguageStore()

const isModalOpen = ref(false)

const campaign = ref<Campaign>(new Campaign())
const asset = ref<Asset>(new Asset())
const assetCurrency = ref<string | null>(null)
const platform = ref<Platform>(new Platform())

const contentImages = ref<ContentImage[]>([])
const contentBlocks = ref<AssetsMeta[]>([])
const incentives = ref<AssetsIncentive[]>([])
const teamMembers = ref<TeamMember[]>([])

const { mapEnumToArray, reduceEnumValue, getEnumLabel } = useEnum()

const { ActionsMenu } = useActionsMenu(
    {
        id: 'campaign-actions-menu',
        align: 'right',
    },
    [
        {
            label: 'duplicate',
            icon: 'fa-plus',
            color: 'text-primary-accent-600',
            action: handleDuplicateCampaign,
            condition: () => userStore.can('create_campaign'),
        },
        {
            label: 'change_status',
            icon: 'fa-solid fa-arrows-rotate',
            color: 'text-gray-600',
            action: handleChangeCampaignStatus,
            condition: () => userStore.can('update_campaign'),
        },
    ]
)

const ordersTableCreateInitialValue = computed(() => {
    return {
        ...new CreateOrderPayload(),
        ...{
            asset_var_uid: campaign.value.asset_var_uid as string,
        },
    }
})

/* @ts-ignore */
const campaignDetailsItems = computedAsync<SummaryItem[]>(async () => {
    return [
        {
            label: i18n.t('id'),
            value: campaign.value.asset_var_uid,
            copy: true,
        },
        {
            label: i18n.t('name'),
            value: campaign.value.asset_title,
            copy: true,
        },
        {
            label: i18n.t('campaign_subtitle'),
            value: campaign.value.asset_subtitle,
            copy: true,
        },
        {
            label: i18n.t('platform'),
            value: campaign.value.platform_name,
            special: 'link',
            linkURL: `/platforms/${campaign.value.platform_name}`,
            copy: true,
        },
        {
            label: i18n.t('language'),
            value: await languageStore.translate(
                campaign.value.language as string
            ),
        },
        {
            label: i18n.t('country'),
            value: await countryStore.translate(
                campaign.value.country as string
            ),
        },
        {
            label: i18n.t('status'),
            value: campaign.value.status
                ? i18n.t(CampaignStatus[campaign.value.status])
                : null,
        },
        {
            label: i18n.t('funding_stats_visible'),
            value: campaign.value.funding_stats_visible,
            special: 'check',
        },
        { label: i18n.t('funding'), value: formattedFounding.value },
        {
            label: i18n.t('regulatory_requirements'),
            value: getEnumLabel(
                RegulatoryRequirements,
                campaign.value.regulatory_requirements
            ),
        },
        {
            label: i18n.t('campaign_badge'),
            value: getEnumLabel(CampaignBadge, campaign.value.badge),
            condition: campaign.value.badge !== null,
        },
        {
            label: i18n.t('asset_video'),
            value: campaign.value.asset_video,
            special: 'link',
            linkURL: campaign.value.asset_video as string,
        },
        {
            label: i18n.t('campaign_thumb_image'),
            value: campaign.value.thumb_image?.thumb_image_url ?? null,
            special: 'image',
        },
        {
            label: i18n.t('campaign_hero_image'),
            value: campaign.value.hero_image?.thumb_image_url ?? null,
            special: 'image',
        },
        {
            label: i18n.t('campaign_asset_needs_contract_selection'),
            value: campaign.value.asset_needs_contract_selection,
            special: 'check',
        },
        {
            label: i18n.t('broker_status'),
            value: campaign.value.broker?.broker_status,
            special: 'check',
        },
        {
            label: i18n.t('show_amount_slider'),
            value: campaign.value.show_amount_slider,
            special: 'check',
        },
        {
            label: i18n.t('additional_fields'),
            value: campaign.value.additional_fields_enabled,
            special: 'check',
        },
        {
            label: i18n.t('depot_owner'),
            value: campaign.value.additional_fields?.depot_owner,
            condition: campaign.value.additional_fields?.depot_owner !== null,
        },
        {
            label: i18n.t('depot_number'),
            value: campaign.value.additional_fields?.depot_number,
            condition: campaign.value.additional_fields?.depot_number !== null,
        },
        {
            label: i18n.t('depot_bank'),
            value: campaign.value.additional_fields?.depot_bank,
            condition: campaign.value.additional_fields?.depot_bank !== null,
        },
        {
            label: i18n.t('depot_bic'),
            value: campaign.value.additional_fields?.depot_bic,
            condition: campaign.value.additional_fields?.depot_bic !== null,
        },
        {
            label: i18n.t('broker_disclaimer'),
            value: campaign.value.broker?.broker_disclaimer,
            copy: true,
            condition: campaign.value.broker?.broker_disclaimer !== null,
        },
        {
            label: i18n.t('voucher_code'),
            value: campaign.value.voucher_code,
            copy: true,
            condition: campaign.value.voucher_code !== null,
        },
        {
            label: i18n.t('voucher_rate'),
            value: formatNumericValues(campaign.value.voucher_rate as number),
            condition: campaign.value.voucher_rate !== null,
        },
        {
            label: i18n.t('external_project_url'),
            value: campaign.value.external_project_url,
            special: 'link',
            linkURL: campaign.value.external_project_url,
            condition: campaign.value.external_project_url !== null,
        },
        {
            label: i18n.t('agio_disagio'),
            value: getEnumLabel(AgioDisagio, campaign.value.agio_disagio),
        },
        {
            label: i18n.t('agio_disagio_type'),
            value: getEnumLabel(
                AgioDisagioType,
                campaign.value.agio_disagio_type
            ),
            condition:
                campaign.value.agio_disagio !== AgioDisagio['DEACTIVATED'],
        },
        {
            label: i18n.t('agio_disagio_rate'),
            value: campaign.value.agio_disagio_rate,
            condition:
                campaign.value.agio_disagio !== AgioDisagio['DEACTIVATED'],
        },
        {
            label: i18n.t('savings_plan'),
            value: campaign.value.savings_plan,
            special: 'check',
        },
        {
            label: i18n.t('created_at'),
            value: moment(campaign.value.created_on).format('DD.MM.yyyy HH:mm'),
        },
        {
            label: i18n.t('updated_at'),
            value: moment(campaign.value.updated_at).format('DD.MM.yyyy HH:mm'),
        },
    ]
}, [])

const { Summary: CampaignSummary } = useSummary(campaignDetailsItems, {
    title: 'summary',
})

const { AssetSummary } = useAssetSummary(asset)

const { PlatformSummary } = usePlatformSummary(platform)

const {
    CampaignContentImagesTable,
    selectedContentImagesIds,
    handleDeleteContentImages,
    handleCreateContentImage,
} = useCampaignContentImagesTable(contentImages, update, openModal, isModalOpen)

const {
    CampaignContentBlocksTable,
    selectedContentBlocksIds,
    handleDeleteContentBlocks,
    handleEditOrCreateContentBlock,
} = useCampaignContentBlocksTable(
    contentBlocks,
    update,
    openModal,
    isModalOpen,
    campaign
)

const {
    CampaignIncentivesTable,
    selectedIncentivesIds,
    handleEditOrCreateIncentive,
    handleDeleteIncentives,
} = useCampaignIncentivesTable(
    incentives,
    assetCurrency,
    update,
    openModal,
    isModalOpen
)

const {
    CampaignTeamMembersTable,
    selectedTeamMembersIds,
    handleDeleteTeamMembers,
    handleEditOrCreateTeamMember,
} = useCampaignTeamMembersTable(teamMembers, update, openModal, isModalOpen)

const formattedFounding = computed<string>(() => {
    if (campaign.value.funding_start && campaign.value.funding_end) {
        return `${moment(campaign.value.funding_start).format(
            'DD.MM.yyyy'
        )} - ${moment(campaign.value.funding_end).format('DD.MM.yyyy')}`
    }
    if (campaign.value.funding_start && !campaign.value.funding_end) {
        return `${moment(campaign.value.funding_start).format('DD.MM.yyyy')} -`
    }
    return '-'
})

function sortTablesByRank() {
    campaign.value.assets_meta.sort((a, b) => a.placing - b.placing)
    campaign.value.team_members.sort((a, b) => a.position - b.position)
    campaign.value.assets_incentives.sort((a, b) => a.position - b.position)
}

async function load() {
    const id = route.params.id as string
    if (id) {
        const campaignRequest = await getCampaign(id)
        campaign.value = campaignRequest.data.campaign
        sortTablesByRank()

        const assetRequest = await getAsset(campaign.value.asset_uid as string)
        asset.value = assetRequest.data.asset
        assetCurrency.value = asset.value.currency

        const platformRequest = await getPlatform(
            campaign.value.platform_name as string
        )
        platform.value = platformRequest.data.platform

        contentImages.value = campaign.value.content_images
        contentBlocks.value = campaign.value.assets_meta
        incentives.value = campaign.value.assets_incentives
        teamMembers.value = campaign.value.team_members
    }
}

async function update(successMessage: string | null = null): Promise<void> {
    sortTablesByRank()
    const updateCampaignResponse = await updateCampaign(
        campaign.value,
        campaign.value.asset_var_uid as string
    )
    if (updateCampaignResponse.data.success) {
        if (successMessage) {
            toast.success(successMessage)
        }
    }
    await load()
}

function handleDuplicateCampaign() {
    isModalOpen.value = true
    const duplicatedCampaign = ref<DuplicatedCampaign>(new DuplicatedCampaign())

    const disableCreate = computed(() => {
        return !(
            duplicatedCampaign.value.asset_title &&
            duplicatedCampaign.value.platform_name
        )
    })

    openModal(
        {
            id: 'duplicate_campaign',
            title: 'modal_title_duplicate_campaign',
            open: isModalOpen,
            onCancel: () => (isModalOpen.value = false),
            onSubmit: () => duplicateCampaign(duplicatedCampaign.value),
        },
        () =>
            h(DuplicateCampaignForm, {
                modelValue: duplicatedCampaign,
            }),
        {
            submitButtonText: 'duplicate',
            styles: {
                submitButton: ModalButtonStyles['BLUE'],
            },
            submitButtonDisabled: disableCreate,
        }
    )
}
async function duplicateCampaign(duplicatedCampaign: DuplicatedCampaign) {
    const duplicateCampaignResponse = await apiDuplicateCampaign(
        campaign.value.asset_var_uid as string,
        duplicatedCampaign
    )
    isModalOpen.value = false

    const newCampaign = duplicateCampaignResponse.data.campaign

    toast.success(i18n.t('toast_success_campaign_duplicated'))

    await router.push({
        name: 'campaign',
        params: { id: newCampaign.asset_var_uid },
    })
    router.go(0)
}

function handleChangeCampaignStatus() {
    isModalOpen.value = true

    const disableChangeStatus = computed(() => {
        return (
            !campaign.value.status &&
            campaign.value.status !== CampaignStatus['DRAFT']
        )
    })
    openModal(
        {
            id: 'change_campaign_status_modal',
            title: 'modal_title_change_campaign_status',
            open: isModalOpen,
            onCancel: () => {
                isModalOpen.value = false
            },
            onSubmit: () => {
                update(i18n.t('toast_success_campaign_status_changed'))
                isModalOpen.value = false
            },
        },
        () => {
            return h('div', null, [
                h(SelectField, {
                    name: 'status',
                    label: 'status',
                    help: 'help_status_campaign',
                    modelValue: campaign.value.status,
                    'onUpdate:modelValue': (value) =>
                        (campaign.value.status = value),
                    options: mapEnumToArray(CampaignStatus),
                    reduce: reduceEnumValue,
                    class: 'mb-16',
                }),
            ])
        },
        {
            styles: {
                submitButton: ModalButtonStyles['BLUE'],
            },
            submitButtonDisabled: disableChangeStatus,
        }
    )
}

function toCampaigns() {
    router.push({ name: 'campaigns' })
}

function edit() {
    router.push({
        name: 'campaign.edit',
        params: { id: campaign.value?.asset_var_uid },
    })
}
async function deleteCampaign() {
    await apiDeleteCampaign(campaign.value.asset_var_uid as string)
    toast.success(
        i18n.t('toast_success_campaign_deleted', {
            campaign: campaign.value.asset_asset_title,
        })
    )
    router.push({ name: 'campaigns' })
}

function handleDelateCampaign() {
    isModalOpen.value = true
    openModal(
        {
            id: 'delete_campaign',
            title: 'modal_title_delete_campaign',
            open: isModalOpen,
            onCancel: () => (isModalOpen.value = false),
            onSubmit: () => deleteCampaign(),
        },
        () =>
            h(NameAndId, {
                idKey: 'asset_var_uid',
                nameKey: 'asset_title',
                modelValue: [campaign.value],
            }),
        {
            submitButtonText: 'delete',
            styles: {
                submitButton: ModalButtonStyles['RED'],
            },
        }
    )
}

watch(
    () => route.params.id,
    async () => {
        await load()
    }
)

onMounted(async () => {
    await load()
})
</script>

<style lang="scss" scoped></style>
