<template>
    <SelectField
        v-model="modelValue"
        name="post_status"
        label="post_status"
        :options="membershipDocumentPostStatusOptions"
        :required="true"
    />
</template>
<script setup lang="ts">
import { SelectField } from '@src/components'
import { ref, toRefs, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { MembershipDocumentPostStatusType } from '@src/enums'

const i18n = useI18n()

const props = defineProps<{
    modelValue: string
}>()

const { modelValue } = toRefs(props)
const emit = defineEmits(['update:modelValue'])

const membershipDocumentPostStatusOptions = ref([
    {
        label: i18n.t('select_all'),
        value: 'all',
    },
    {
        label: i18n.t('REQUESTED'),
        value: MembershipDocumentPostStatusType.REQUESTED,
    },
])

watch(modelValue.value, (value) => {
    emit('update:modelValue', value)
})
</script>
