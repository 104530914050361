<template>
    <div class="my-4">
        <div class="flex items-center justify-between">
            <label
                class="mb-1 block text-sm font-medium"
                :for="name"
                >{{ $t(label) }}
                <span
                    v-if="required === true"
                    class="text-danger-500"
                    >*</span
                >
            </label>
            <Tooltip
                class="ml-2"
                bg="dark"
                size="md"
                position="left"
            >
                <div class="text-sm text-gray-200">{{ $t(help) }}</div>
            </Tooltip>
        </div>
        <div class="flex items-center justify-between">
            <div>
                <img
                    v-if="
                        (modelValue?.url || modelValue?.thumb_image_url) &&
                        !modelValue?.delete
                    "
                    class="h-20 w-20 rounded-full"
                    :src="(modelValue?.url as string) || (modelValue?.thumb_image_url as string)"
                    width="80"
                    height="80"
                    alt="User upload"
                />
                <div
                    v-if="
                        (!modelValue?.url && !modelValue?.thumb_image_url) ||
                        modelValue?.delete
                    "
                    class="flex h-20 w-20 items-center rounded-full bg-gray-500"
                >
                    <span class="block w-full text-center text-white">N/A</span>
                </div>
            </div>
            <div>
                <div class="m-2 text-right">
                    <button
                        class="btn-sm bg-primary-additional-500 hover:bg-primary-additional-600 text-white"
                        type="button"
                        @click="openFileBrowser"
                    >
                        {{ $t('choose_image') }}
                    </button>
                </div>
                <div class="m-2 text-right">
                    <button
                        class="btn-sm bg-danger-500 hover:bg-danger-600 text-white"
                        type="button"
                        @click="clear"
                    >
                        {{ $t('clear') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { Tooltip } from '@src/components'
import {
    CampaignImage,
    CompanyLogo,
    ImageInterface,
    PlatformLogo,
    stdImage,
} from '@src/types'
import { uploadPrivate, uploadPublic } from '@src/utils/api/s3'
import { useFileDialog, useVModels } from '@vueuse/core'
import { onMounted, watch } from 'vue'

const props = withDefaults(
    defineProps<{
        label: string
        name: string
        help: string
        required?: boolean
        modelValue: ImageInterface | null
        private?: boolean
        type?: 'platform' | 'company' | 'campaign' | 'image'
    }>(),
    {
        type: 'image',
    }
)

const emit = defineEmits(['update:modelValue'])

const { modelValue } = useVModels(props, emit)

const { open, files, reset } = useFileDialog({
    multiple: false,
    accept: '.png, .jpg, .jpeg',
})

function init(): void {
    if (props.type === 'platform') {
        modelValue.value = (modelValue.value ??
            new PlatformLogo()) as ImageInterface
    } else if (props.type === 'company') {
        modelValue.value = (modelValue.value ??
            new CompanyLogo()) as ImageInterface
    } else if (props.type === 'campaign') {
        modelValue.value = (modelValue.value ??
            new CampaignImage()) as ImageInterface
    } else if (props.type === 'image') {
        modelValue.value = (modelValue.value ??
            new stdImage()) as ImageInterface
    }
}

function openFileBrowser(): void {
    open()
}

function clear(): void {
    reset()
    if (modelValue.value) {
        modelValue.value.delete = true
    }
}

watch(files, async function (files) {
    if (!files) {
        return
    }

    const file = files?.item(0)

    if (!file) {
        return
    }

    const uploadResponse = props.private
        ? await uploadPrivate(file)
        : await uploadPublic(file)

    if (modelValue.value) {
        modelValue.value.file_name = file.name
        modelValue.value.name = file.name

        if (props.type === 'platform' || props.type === 'image') {
            modelValue.value.upload_key = uploadResponse.data.key
            modelValue.value.url = uploadResponse.data.temp_storage_url
        }
        if (props.type === 'company' || props.type === 'campaign') {
            modelValue.value.thumb_image_url =
                uploadResponse.data.temp_storage_url
            modelValue.value.upload_key = uploadResponse.data.key
        }
        if (modelValue.value.delete) {
            delete modelValue.value.delete
        }
    }
})

onMounted(() => {
    init()
})
</script>
