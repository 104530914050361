<template>
    <p v-if="noResults">
        {{ $t('nothing_found') }}
    </p>
    <SearchResultsDropdownEntry
        title="platforms"
        :entries="results.platforms"
        :count="results.platformsCount"
        :limit="entityResultsLimit"
        path="/platforms"
        entry-property-in-path="platform_name"
        entry-property-to-show="platform_name"
        list-filter-name="platforms-table_filter_search"
        :search="search"
    />
    <SearchResultsDropdownEntry
        title="issuers"
        :entries="results.issuers"
        :count="results.issuersCount"
        :limit="entityResultsLimit"
        path="/issuers"
        entry-property-in-path="id"
        entry-property-to-show="name"
        list-filter-name="issuers-table_filter_search"
        :search="search"
    />
    <SearchResultsDropdownEntry
        title="assets"
        :entries="results.assets"
        :count="results.assetsCount"
        :limit="entityResultsLimit"
        path="/assets"
        entry-property-in-path="asset_uid"
        entry-property-to-show="asset_title"
        list-filter-name="assets-table_filter_search"
        :search="search"
    />
    <SearchResultsDropdownEntry
        title="campaigns"
        :entries="results.campaigns"
        :count="results.campaignsCount"
        :limit="entityResultsLimit"
        path="/campaigns"
        entry-property-in-path="asset_var_uid"
        entry-property-to-show="asset_title"
        list-filter-name="campaigns-table_filter_search"
        :search="search"
    />
    <SearchResultsDropdownEntry
        title="accounts"
        :entries="results.accounts"
        :count="results.accountsCount"
        :limit="entityResultsLimit"
        path="/accounts"
        entry-property-in-path="account_uid"
        entry-property-to-show="account_title"
        list-filter-name="accounts-table_filter_search"
        :search="search"
    />
    <SearchResultsDropdownEntry
        title="orders"
        :entries="results.orders"
        :count="results.ordersCount"
        :limit="entityResultsLimit"
        path="/orders"
        entry-property-in-path="order_uid"
        entry-property-to-show="account_title"
        list-filter-name="orders-table_filter_search"
        :search="search"
    />
    <SearchResultsDropdownEntry
        title="emails"
        :entries="results.emails"
        :count="results.emailsCount"
        :limit="entityResultsLimit"
        path="/emails"
        entry-property-in-path="id"
        entry-property-to-show="subject"
        list-filter-name="emails-table_filter_search"
        :search="search"
    />
    <SearchResultsDropdownEntry
        title="contracts"
        :entries="results.contracts"
        :count="results.contractsCount"
        :limit="entityResultsLimit"
        path="/contracts"
        entry-property-in-path="contract_uid"
        entry-property-to-show="contract_uid"
        list-filter-name="contracts-table_search"
        :search="search"
    />
</template>

<script setup lang="ts">
import SearchResultsDropdownEntry from '@src/partials/header/SearchResultsDropdownEntry.vue'
import { useSearch } from '@stores/search'
import { storeToRefs } from 'pinia'

const searchStore = useSearch()

const { search, results, noResults, entityResultsLimit } =
    storeToRefs(searchStore)
</script>

<style scoped></style>
