import {
    ModalButtonStyles,
    OpenModal,
    useEnum,
    useTable,
} from '@composables/common'
import UpdateECSPDocumentForm from '@partials/accounts/UpdateECSPDocumentForm.vue'
import { EcspStatusType } from '@src/enums'
import { ECSPDocument } from '@src/types'
import { updateECSPDocument } from '@src/utils/api/accounts'
import { useToastStore } from '@src/utils/stores/toast'
import { useUserStore } from '@src/utils/stores/user'
import moment from 'moment'
import { Ref, computed, h, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { getEnumLabel } = useEnum()

export function useECSPDocumentsTable(
    ecspDocuments: Ref<ECSPDocument[]>,
    openModal: OpenModal,
    isModalOpen: Ref<boolean>,
    assignAndFilterECSPDocuments: (
        accountID?: string | undefined
    ) => Promise<void>,
    loadAccount: () => Promise<void>,
    label: string,
    help?: string
) {
    const userStore = useUserStore()
    const i18n = useI18n()
    const toast = useToastStore()
    const total = computed(() => {
        return ecspDocuments.value.length
    })
    const { TableWrapper: ECSPDocumentTable } = useTable({
        tableProps: {
            data: ecspDocuments,
            total: total,
            label: label,
            id: label,
            help: help,
            columns: [
                {
                    label: 'ecsp_status',
                    key: 'ecsp_status',
                    sorting: false,
                    cellRenderer: (props: { rowData: ECSPDocument }) => {
                        return getEnumLabel(
                            EcspStatusType,
                            props.rowData.ecsp_status
                        )
                    },
                },
                {
                    label: 'created_at',
                    key: 'created_at',
                    sorting: false,
                    cellRenderer: (props: { rowData: ECSPDocument }) => {
                        return props.rowData.created_at
                            ? moment(props.rowData.created_at).format(
                                  'DD.MM.yyyy'
                              )
                            : '-'
                    },
                },
                {
                    label: 'verification_date',
                    key: 'verification_date',
                    sorting: false,
                    cellRenderer: (props: { rowData: ECSPDocument }) => {
                        return props.rowData.verification_date
                            ? moment(props.rowData.verification_date).format(
                                  'DD.MM.yyyy'
                              )
                            : '-'
                    },
                },
                {
                    label: 'verification_comment',
                    key: 'verification_comment',
                    sorting: false,
                },
            ],
            actions: [
                {
                    action: handleViewDocument,
                    icon: 'fa-eye',
                    title: 'help_open_file_in_new_tab',
                },
                {
                    action: handleUpdateECSPDocument,
                    icon: 'fa-solid fa-pen',
                    title: 'edit',
                    condition: () => {
                        return userStore.can('update_account')
                    },
                },
            ],
        },
    })

    function handleViewDocument(rowData: ECSPDocument) {
        const url = rowData.url
        isModalOpen.value = true
        openModal(
            {
                id: 'view_ecsp_document',
                title: 'modal_title_view_ecsp_document',
                open: isModalOpen,
                onCancel: () => (isModalOpen.value = false),
            },
            () =>
                h('iframe', {
                    src: `${url}#zoom=100`,
                    class: 'full-size-iframe',
                })
        )
    }

    function handleUpdateECSPDocument(
        ecspDocument: ECSPDocument = new ECSPDocument()
    ) {
        const editedECSPDocument = ref(new ECSPDocument())
        editedECSPDocument.value = { ...ecspDocument }
        isModalOpen.value = true

        openModal(
            {
                id: 'edit_ecsp_document',
                title: 'modal_title_update_ecsp_document',
                open: isModalOpen,
                onCancel: () => (isModalOpen.value = false),
                onSubmit: () => saveECSPDocument(editedECSPDocument.value),
            },
            () =>
                h(UpdateECSPDocumentForm, {
                    modelValue: editedECSPDocument,
                }),
            {
                submitButtonText: 'update',
                styles: {
                    submitButton: ModalButtonStyles['BLUE'],
                },
            }
        )
    }

    async function saveECSPDocument(document: ECSPDocument) {
        try {
            await updateECSPDocument(document)
            await assignAndFilterECSPDocuments()
            // refreshing account after ecsp update
            await loadAccount()
            toast.success(i18n.t('toast_success_ecsp_document_updated'))
        } catch (error) {
            toast.error(i18n.t('toast_error_ecsp_document_create'))
            throw error
        }

        isModalOpen.value = false
    }

    return {
        ECSPDocumentTable,
    }
}
