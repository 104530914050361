<template>
    <div class="col-md-offset-3 col-md-6">
        <form>
            <div class="form-group">
                <div class="flex justify-center">
                    <div class="mx-2 inline-block">
                        <input
                            v-model="days"
                            class="w-20 text-center"
                            type="number"
                            min="0"
                            @change="calcModelValue"
                        />
                        <div class="w-20 text-center">{{ $t('days') }}</div>
                    </div>
                    <div class="mx-2 inline-block content-center">
                        <input
                            v-model="hours"
                            class="w-20 text-center"
                            type="number"
                            min="0"
                            max="23"
                            @change="calcModelValue"
                        />
                        <div class="w-20 text-center">{{ $t('hours') }}</div>
                    </div>
                    <div class="mx-2 inline-block">
                        <input
                            v-model="minutes"
                            class="w-20 text-center"
                            type="number"
                            min="0"
                            max="59"
                            @change="calcModelValue"
                        />
                        <div class="w-20 text-center">{{ $t('minutes') }}</div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script setup lang="ts">
import { useVModels } from '@vueuse/core'
import { ref, onMounted } from 'vue'

const days = ref(0)
const hours = ref(0)
const minutes = ref(0)

const props = defineProps<{
    modelValue: number | null
}>()

const emit = defineEmits(['update:modelValue'])

const { modelValue } = useVModels(props, emit)

onMounted(() => {
    if (props.modelValue !== null) {
        days.value = Math.floor(props.modelValue / 1440)
        hours.value = Math.floor((props.modelValue - days.value * 1440) / 60)
        minutes.value = Math.floor(
            props.modelValue - days.value * 1440 - hours.value * 60
        )
    }
})

function calcModelValue(): void {
    modelValue.value = days.value * 1440 + hours.value * 60 + minutes.value
}
</script>
