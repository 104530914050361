<template>
    <div
        v-if="userStore.user"
        class="relative inline-flex"
    >
        <button
            ref="trigger"
            class="group inline-flex items-center justify-center"
            aria-haspopup="true"
            :aria-expanded="dropdownOpen"
            @click.prevent="
                () => {
                    dropdownOpen = !dropdownOpen
                }
            "
        >
            <img
                class="h-8 w-8 rounded-full"
                :src="userAvatar"
                width="32"
                height="32"
                alt="User"
            />
            <div class="flex items-center truncate">
                <span
                    class="ml-2 truncate text-sm font-medium group-hover:text-gray-800"
                >
                    {{ userStore.user?.name }}
                </span>
                <svg
                    class="ml-1 h-3 w-3 shrink-0 fill-current text-gray-400"
                    viewBox="0 0 12 12"
                >
                    <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                </svg>
            </div>
        </button>
        <transition
            enter-active-class="transition ease-out duration-200 transform"
            enter-from-class="opacity-0 -translate-y-2"
            enter-to-class="opacity-100 translate-y-0"
            leave-active-class="transition ease-out duration-200"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div
                v-show="dropdownOpen"
                class="min-w-44 absolute top-full left-0 z-10 mt-1 origin-top-right overflow-hidden rounded border border-gray-200 bg-white py-1.5 shadow-lg"
            >
                <div class="mb-1 border-b border-gray-200 px-3 pt-0.5 pb-2">
                    <div class="font-medium text-gray-800">
                        {{ userStore.user?.name }}
                    </div>
                    <div class="text-xs italic text-gray-500">
                        {{
                            getEnumLabel(
                                PrimaryUserRoles,
                                userStore.user?.primaryRole
                            )
                        }}
                    </div>
                </div>
                <ul
                    ref="dropdown"
                    @focusin="
                        () => {
                            dropdownOpen = true
                        }
                    "
                    @focusout="
                        () => {
                            dropdownOpen = false
                        }
                    "
                >
                    <li>
                        <a
                            v-if="userStore.user?.accountUrl"
                            class="text-primary-additional-500 hover:text-primary-additional-600 flex items-center py-1 px-3 text-sm font-medium"
                            :href="userStore.user?.accountUrl"
                            target="blank"
                            @click="
                                () => {
                                    dropdownOpen = false
                                }
                            "
                            >{{ $t('settings') }}</a
                        >
                    </li>
                    <li>
                        <a
                            class="text-primary-additional-500 hover:text-primary-additional-600 flex items-center py-1 px-3 text-sm font-medium"
                            href="#"
                            @click="
                                () => {
                                    dropdownOpen = false
                                    userStore.logOutUser()
                                }
                            "
                            >{{ $t('sign_out') }}</a
                        >
                    </li>
                </ul>
            </div>
        </transition>
    </div>
</template>

<script setup lang="ts">
import { useEnum } from '@composables/common'
import { PrimaryUserRoles } from '@src/enums'
import { useUserStore } from '@src/utils/stores/user'
import md5 from 'md5'
import { onMounted, onUnmounted, ref } from 'vue'

const dropdownOpen = ref(false)
const trigger = ref<HTMLElement | null>(null)
const dropdown = ref<HTMLElement | null>(null)

const userStore = useUserStore()
const { getEnumLabel } = useEnum()

const userAvatar = ref<string>('')

// close on click outside
const clickHandler = (event: MouseEvent) => {
    const target: EventTarget | null = event.target
    if (
        !dropdownOpen.value ||
        dropdown.value?.contains(target as Node) ||
        trigger.value?.contains(target as Node)
    )
        return
    dropdownOpen.value = false
}

// close if the esc key is pressed
const keyHandler = (event: KeyboardEvent) => {
    if (!dropdownOpen.value || event.code !== 'Escape') return
    dropdownOpen.value = false
}

onMounted(() => {
    document.addEventListener('click', clickHandler)
    document.addEventListener('keydown', keyHandler)

    if (userStore?.user?.email) {
        const hash = md5(userStore.user.email.trim().toLowerCase())
        userAvatar.value = `https://www.gravatar.com/avatar/${hash}`
    } else {
        userAvatar.value = 'https://www.gravatar.com/avatar/'
    }
})

onUnmounted(() => {
    document.removeEventListener('click', clickHandler)
    document.removeEventListener('keydown', keyHandler)
})
</script>
