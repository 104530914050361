import { ActionsMenuEntry, ActionsMenuWrapper } from '@src/components'
import { h, ref } from 'vue'

export interface ActionsMenuWrapperProps {
    id: string
    align: 'left' | 'right'
}

export interface ActionsMenuEntryProps {
    label: string
    icon: string
    color: string
    action: () => void
    condition?: () => boolean
}

export function useActionsMenu(
    props: ActionsMenuWrapperProps,
    entries: ActionsMenuEntryProps[]
) {
    const actionsMenuProps = ref<ActionsMenuWrapperProps>(props)
    const actionsMenuEntries = ref<ActionsMenuEntryProps[]>(entries)
    const children = actionsMenuEntries.value.map((entry) => {
        return h(ActionsMenuEntry, entry)
    })
    const ActionsMenu = () =>
        h(ActionsMenuWrapper, actionsMenuProps.value, () => children)
    return { ActionsMenu }
}
