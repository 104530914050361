import { OpenModal, useEnum, useMask, useTable } from '@composables/common'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { BankAccountStatus } from '@src/enums'
import { KycDocumentStatus } from '@src/enums/Account'
import { BankAccount, BankKYCDocument } from '@src/types/Profiles'
import moment from 'moment'
import { Ref, computed, h } from 'vue'

export function useBankAccountsTable(
    bankAccounts: Ref<BankAccount[]>,
    openModal: OpenModal,
    isModalOpen: Ref<boolean>
) {
    const { getEnumLabel } = useEnum()
    const { everyFourSpace } = useMask()

    const total = computed(() => {
        return bankAccounts.value.length
    })

    const { TableWrapper: BankAccountsTable } = useTable({
        tableProps: {
            data: bankAccounts,
            total: total,
            label: 'account_bank_account_headline',
            id: 'help_bank_accounts_table',
            help: 'help_bank_accounts_table',
            columns: [
                {
                    label: 'account_holder',
                    key: 'holder',
                    sorting: false,
                    copy: true,
                },
                {
                    label: 'iban',
                    key: 'iban',
                    sorting: false,
                    cellRenderer: (props: { rowData: BankAccount }) => {
                        return (
                            props.rowData.iban &&
                            everyFourSpace(props.rowData.iban)
                        )
                    },
                    copy: true,
                },
                {
                    label: 'status',
                    key: 'status',
                    sorting: false,
                    cellRenderer: (props: { rowData: BankAccount }) => {
                        return getEnumLabel(
                            BankAccountStatus,
                            props.rowData.status
                        )
                    },
                },
                {
                    label: 'is_main',
                    key: 'is_main',
                    sorting: false,
                    cellRenderer: (props: { rowData: BankAccount }) => {
                        if (props.rowData.is_main) {
                            return h(FontAwesomeIcon, {
                                icon: 'fa-solid fa-check',
                            })
                        }
                    },
                },
                {
                    label: 'created_at',
                    key: 'created_at',
                    sorting: false,
                    cellRenderer: (props: { rowData: BankAccount }) => {
                        return moment(props.rowData.created_at).format(
                            'DD.MM.yyyy'
                        )
                    },
                },
            ],
            actions: [
                {
                    action: handleViewDocument,
                    icon: 'fa-eye',
                    title: 'help_open_file_in_new_tab',
                    condition: (bankAccount: BankAccount) =>
                        !!getKycDocument(bankAccount),
                },
            ],
        },
    })

    function handleViewDocument(rowData: BankAccount) {
        const kycDocument = getKycDocument(rowData)

        if (!kycDocument) {
            return
        }

        const url = kycDocument.url
        isModalOpen.value = true
        openModal(
            {
                id: 'view_kyc_bank_document',
                title: 'modal_title_view_kyc_bank_document',
                open: isModalOpen,
                onCancel: () => (isModalOpen.value = false),
            },
            () =>
                h('iframe', {
                    src: `${url}#zoom=100`,
                    class: 'full-size-iframe',
                })
        )
    }

    function getKycDocument(bankAccount: BankAccount) {
        const kycDocuments = bankAccount.kyc_documents.filter(
            (document: BankKYCDocument) =>
                document.verification_status ===
                KycDocumentStatus['KYC_DOC_STATUS_ACCEPTED']
        )

        if (!kycDocuments.length) {
            return
        }

        return kycDocuments[0]
    }

    return {
        BankAccountsTable,
    }
}
