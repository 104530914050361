import {
    ModalButtonStyles,
    OpenModal,
    UpdateData,
    useTable,
} from '@composables/common'
import CampaignContentBlockEditForm from '@partials/campaigns/CampaignContentBlockEditForm.vue'
import CampaignContentBlockView from '@partials/campaigns/CampaignContentBlockView.vue'
import { NameAndId } from '@src/components'
import { AssetsMeta, Campaign } from '@src/types'
import { useUserStore } from '@utils/stores/user'
import { Ref, computed, h, ref } from 'vue'
import { useI18n } from 'vue-i18n'

export function useCampaignContentBlocksTable(
    contentBlocks: Ref<AssetsMeta[]>,
    update: (successMessage?: string | null) => Promise<void>,
    openModal: OpenModal,
    isModalOpen: Ref<boolean>,
    campaign: Ref<Campaign>
) {
    const userStore = useUserStore()
    const i18n = useI18n()

    const total = computed(() => {
        return contentBlocks.value.length
    })

    const {
        TableWrapper: CampaignContentBlocksTable,
        selected: selectedContentBlocksIds,
        updateSortOrder: updateContentBlocksSortOrder,
    } = useTable({
        updateData: update as UpdateData,
        tableProps: {
            id: 'content_blocks_table',
            label: 'content_blocks',
            help: 'help_campaign_content_blocks_table',
            data: contentBlocks,
            total: total,
            sortableOrder: userStore.can('update_campaign'),
            itemOrderKey: 'placing',
            itemIdKey: 'asset_meta_uid',
            columns: [
                {
                    label: 'id',
                    key: 'asset_meta_uid',
                    select: true,
                },
                {
                    label: 'id',
                    key: 'asset_meta_uid',
                    condition: userStore.can('see_id_columns'),
                    copy: true,
                },
                {
                    label: 'name',
                    key: 'meta_title',
                    copy: true,
                },
                {
                    label: 'rank',
                    key: 'placing',
                    help: userStore.can('update_campaign')
                        ? 'help_drag_and_drop'
                        : '',
                    cellRenderer: (props: { rowData: AssetsMeta }) => {
                        return props.rowData.placing + 1
                    },
                },
            ],
            actions: [
                {
                    action: handleViewContentBlock,
                    icon: 'fa-eye',
                    title: 'view',
                },
                {
                    action: handleEditOrCreateContentBlock,
                    icon: 'fa-solid fa-pen',
                    title: 'edit',
                    condition: userStore.can('update_campaign'),
                },
                {
                    action: handleDeleteContentBlock,
                    icon: 'fas fa-trash-alt',
                    title: 'delete',
                    color: 'text-danger-500',
                    condition: userStore.can('update_campaign'),
                },
            ],
        },
    })

    function handleViewContentBlock(contentBlock: AssetsMeta) {
        isModalOpen.value = true
        openModal(
            {
                id: 'view_campaign_content_block',
                title: 'modal_title_view_campaign_content_block',
                open: isModalOpen,
                onCancel: () => (isModalOpen.value = false),
                width: '2xl',
            },
            () =>
                h(CampaignContentBlockView, {
                    modelValue: contentBlock,
                })
        )
    }

    function handleEditOrCreateContentBlock(
        contentBlock: AssetsMeta = new AssetsMeta()
    ) {
        const editedContentBlock = ref(new AssetsMeta())
        editedContentBlock.value = { ...contentBlock }
        isModalOpen.value = true

        const disableCreate = computed(() => {
            return !(
                editedContentBlock.value.meta_title &&
                editedContentBlock.value.meta_value
            )
        })
        openModal(
            {
                id: 'edit_campaign_content_block',
                title: editedContentBlock.value.asset_meta_uid
                    ? 'modal_title_edit_campaign_content_block'
                    : 'modal_title_create_campaign_content_block',
                open: isModalOpen,
                width: '2xl',
                onCancel: () => (isModalOpen.value = false),
                onSubmit: () => saveContentBlock(editedContentBlock.value),
            },
            () =>
                h(CampaignContentBlockEditForm, {
                    modelValue: editedContentBlock,
                    campaign: campaign,
                }),
            {
                submitButtonText: editedContentBlock.value.asset_meta_uid
                    ? 'update'
                    : 'create',
                styles: {
                    submitButton: ModalButtonStyles['BLUE'],
                },
                submitButtonDisabled: disableCreate,
            }
        )
    }

    function handleDeleteContentBlock(contentBlock: AssetsMeta) {
        isModalOpen.value = true
        openModal(
            {
                id: 'delete_campaign_content_block',
                title: 'modal_title_delete_campaign_content_block',
                open: isModalOpen,
                onCancel: () => (isModalOpen.value = false),
                onSubmit: () => deleteContentBlock(contentBlock),
            },
            () =>
                h(NameAndId, {
                    idKey: 'asset_meta_uid',
                    nameKey: 'meta_title',
                    modelValue: [contentBlock],
                }),
            {
                submitButtonText: 'delete',
                styles: {
                    submitButton: ModalButtonStyles['RED'],
                },
            }
        )
    }

    function handleDeleteContentBlocks() {
        isModalOpen.value = true
        openModal(
            {
                id: 'delete_campaign_content_blocks',
                title: 'modal_title_delete_campaign_content_blocks',
                open: isModalOpen,
                onCancel: () => (isModalOpen.value = false),
                onSubmit: () =>
                    deleteContentBlocks(selectedContentBlocks.value),
            },
            () =>
                h(NameAndId, {
                    idKey: 'asset_meta_uid',
                    nameKey: 'meta_title',
                    modelValue: selectedContentBlocks.value,
                }),
            {
                submitButtonText: 'delete',
                styles: {
                    submitButton: ModalButtonStyles['RED'],
                },
            }
        )
    }

    function deleteContentBlock(
        contentBlockToDelete: AssetsMeta,
        submit = true
    ) {
        const contentBlocksTemp = [...contentBlocks.value]
        contentBlocks.value.splice(
            contentBlocks.value.findIndex(
                (contentBlock) =>
                    contentBlockToDelete.asset_meta_uid ==
                    contentBlock.asset_meta_uid
            ),
            1
        )
        if (submit) {
            updateContentBlocksSortOrder()

            update(
                i18n.t('toast_success_campaign_content_block_deleted', {
                    contentBlock: contentBlockToDelete.meta_title,
                })
            )
                .catch((error) => {
                    contentBlocks.value = contentBlocksTemp
                    throw error
                })
                .then(() => {
                    isModalOpen.value = false
                })
        }
    }

    function deleteContentBlocks(contentBlocksToDelete: AssetsMeta[]) {
        const contentBlocksTemp = [...contentBlocks.value]
        contentBlocksToDelete.forEach((contentBlock) => {
            deleteContentBlock(contentBlock, false)
        })
        updateContentBlocksSortOrder()

        update(
            i18n.t('toast_success_campaign_content_blocks_deleted', {
                contentBlocks: contentBlocksToDelete
                    .map((contentBlock) => contentBlock.meta_title)
                    .join(', '),
            })
        )
            .catch((error) => {
                contentBlocks.value = contentBlocksTemp
                throw error
            })
            .then(() => {
                selectedContentBlocksIds.value = []
                isModalOpen.value = false
            })
    }

    function saveContentBlock(contentBlockToDelete: AssetsMeta) {
        const contentBlocksTemp = [...contentBlocks.value]
        let toast = 'toast_success_campaign_content_block_updated'
        if (!contentBlockToDelete.asset_meta_uid) {
            toast = 'toast_success_campaign_content_block_created'
            delete contentBlockToDelete.asset_meta_uid
            contentBlockToDelete.placing = contentBlocks.value.length
            contentBlocks.value.push(contentBlockToDelete)
        } else {
            contentBlocks.value[
                contentBlocks.value.findIndex(
                    (item) =>
                        item.asset_meta_uid ===
                        contentBlockToDelete.asset_meta_uid
                )
            ] = contentBlockToDelete
        }

        update(
            i18n.t(toast, {
                contentBlock: contentBlockToDelete.meta_title,
            })
        )
            .catch((error) => {
                contentBlocks.value = contentBlocksTemp
                throw error
            })
            .then(() => {
                isModalOpen.value = false
            })
    }

    const selectedContentBlocks = computed(() => {
        return contentBlocks.value.filter(
            (item: AssetsMeta) =>
                item.asset_meta_uid &&
                selectedContentBlocksIds.value.includes(item.asset_meta_uid)
        )
    })

    return {
        CampaignContentBlocksTable,
        handleDeleteContentBlocks,
        handleEditOrCreateContentBlock,
        selectedContentBlocksIds,
    }
}
