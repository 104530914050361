<template>
    <SelectField
        v-model="modelValue.policy_name"
        name="type"
        label="type"
        help="help_platform_policy_name"
        :required="true"
        :options="mapEnumToArray(PlatformPolicyType)"
    />
    <SelectField
        v-model="modelValue.country"
        name="country"
        label="country"
        help="help_platform_policy_country"
        :options="countries"
        :required="true"
    />
    <SelectField
        v-model="modelValue.language"
        name="language"
        label="language"
        help="help_platform_policy_language"
        :options="languages"
        :required="true"
    />
    <EditorField
        v-model="modelValue.policy_content"
        name="policy_content"
        :required="true"
        label="content"
        help="help_platform_policy_content"
    />
</template>
<script setup lang="ts">
import { useEnum } from '@composables/common'
import { EditorField, SelectField } from '@src/components'
import { PlatformPolicyType } from '@src/enums/Platform'
import { PlatformPolicy } from '@src/types'
import { useCountryStore } from '@src/utils/stores/countries'
import { useLanguageStore } from '@src/utils/stores/languages'
import { Ref, onMounted, ref, toRefs, watch } from 'vue'

const props = defineProps<{
    modelValue: Ref<PlatformPolicy>
}>()

const { modelValue } = toRefs(props)
const emit = defineEmits(['update:modelValue'])
const countryStore = useCountryStore()
const languageStore = useLanguageStore()

const countries = ref<{ label: string; value: string | null }[]>([])
const languages = ref<{ label: string; value: string | null }[]>([])

const { mapEnumToArray } = useEnum()

onMounted(async () => {
    countries.value = await countryStore.loadAndSortCountriesOptionsAsync()
    languages.value = await languageStore.loadAndSortLanguagesOptionsAsync()
})

watch(modelValue.value, (value) => {
    emit('update:modelValue', value)
})
</script>
