export enum CampaignStatus {
    DRAFT = 0,
    ANNOUNCEMENT = 1,
    PRE_FUNDING = 2,
    FUNDING = 3,
    FUNDING_SUCCESSFUL = 4,
    FUNDING_UNSUCCESSFUL = 5,
    PRIVATE_PLACEMENT = 6,
}

export enum RegulatoryRequirements {
    DEFAULT = 0,
    ALTFG = 1,
    VERMANLG = 2,
    WPHG = 3,
    ECSP = 4,
}

export enum AgioDisagio {
    DEACTIVATED = 0,
    AGIO_ACTIVATED = 1,
    DISAGIO_ACTIVATED = 2,
}

export enum AgioDisagioType {
    PERCENT = 0,
    FIXED_VALUE = 1,
    FIXED_VALUE_PER_SHARE = 2,
}

export enum CampaignBadge {
    NEW = 0,
    WE_LOVE_THIS = 1,
    FAVORITE = 2,
    STARTING_SOON = 3,
    IMPACT_INVESTMENT = 6,
}

export enum ClearingSystem {
    CI = 'CI',
    CF = 'CF',
    ICO = 'ICO',
    RISE = 'RISE',
    EMISSION = 'EMISSION',
}
