import { TableSorting, Task } from '@src/types'
import { ObjectWithStringKeysAndStringValue } from '@src/types/ObjectWithStringKeysAndStringValue'
import { AxiosRequestConfig } from 'axios'
import { ApiRes, api } from './client'

export interface TasksResponse {
    success: boolean
    tasks: Task[]
    tasks_count: number
}

export const getTasks = (
    filters?: ObjectWithStringKeysAndStringValue,
    page?: number,
    limit?: number,
    sorting?: TableSorting[]
): ApiRes<TasksResponse> => {
    const config: AxiosRequestConfig = {}

    config.params = {}

    if (filters && Object.keys(filters).length) {
        config.params = filters
    }

    if (typeof page !== 'undefined' && typeof limit !== 'undefined') {
        config.params['range'] = page * limit - limit + '-' + page * limit
    }

    if (sorting && sorting.length) {
        config.params['sort'] = sorting
            .map((sort) => {
                return (sort.direction === 'desc' ? '-' : '') + sort.field
            })
            .join(',')
    }

    return api.get('manage/tasks', config)
}

export const deleteTask = (slug: string): ApiRes<string> =>
    api.delete('manage/tasks/' + slug)
