<template>
    <h4 class="mb-3 text-xl font-bold">{{ modelValue.incentives_title }}</h4>
    <h4 class="text-l mb-3 font-bold">{{ modelValue.incentives_amount }}</h4>
    <div v-html-safe="modelValue.incentives_description"></div>
</template>

<script setup lang="ts">
import { AssetsIncentive } from '@src/types'
import { toRefs } from 'vue'

const props = defineProps<{
    modelValue: AssetsIncentive
}>()

const { modelValue } = toRefs(props)
</script>

<style lang="scss" scoped></style>
