export enum TransactionType {
    MONEY_IN = 0,
    MONEY_OUT = 1,
    P2P = 2,
}

export enum TransactionStatus {
    SUCCESS_MONEY_IN_OUT_OR_PENDING_P2P = 0,
    SUCCESS_P2P_OR_MONEY_IN_OUT_ERROR = 3,
    PENDING = 4,
    TRANSACTION_ERROR = 6,
    CANCELLED = 7,
    WAIT_FOR_VALIDATION_FINALIZATION = 16,
}

export enum PaymentMethod {
    BANK_CARD = 0,
    INCOMING_WIRE_TRANSFER = 1,
    OUTGOING_WIRE_TRANSFER = 3,
    P2P = 4,
    IDEAL = 13,
    SEPA_DIRECT = 14,
    CHEQUE = 15,
    NEOSURF = 16,
    SOFORT = 17,
    PFS_PHYSICAL_CARD = 18,
    MULTIBANCO = 19,
    PAYSHOP = 20,
    MB_WAY = 21,
    POLISH_INSTANT_TRANSFER = 22,
    PAGARE = 23,
    MOBILE_PAY = 24,
    PAY_TRAIL = 25,
    WE_CHAT = 26,
    P24 = 27,
    MONEY_IN_BY_TPE = 28,
    TRUSTLY = 29,
}
