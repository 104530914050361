<template>
    <div class="my-4">
        <div class="flex items-center justify-between">
            <label
                class="mb-1 block text-sm font-medium"
                :for="name"
                >{{ $t(label) }}
                <span
                    v-if="required === true"
                    class="text-danger-500"
                    >*</span
                ></label
            >
            <Tooltip
                class="ml-2"
                bg="dark"
                size="md"
                position="left"
            >
                <div class="text-sm text-gray-200">{{ $t(help) }}</div>
            </Tooltip>
        </div>
        <div class="flex">
            <Vue3ColorPicker
                v-model:pureColor="modelValue"
                shape="circle"
                format="hex"
            />
        </div>
        <div
            v-if="bottomHelpText"
            class="mt-2 text-xs"
        >
            {{ $t(bottomHelpText) }}
        </div>
    </div>
</template>
<script setup lang="ts">
import { Tooltip } from '@src/components'
import { useVModels } from '@vueuse/core'

const props = defineProps<{
    name: string
    label: string
    help: string
    bottomHelpText?: string | null
    required?: boolean
    modelValue: string | null
}>()

const emit = defineEmits(['update:modelValue'])

const { modelValue } = useVModels(props, emit)
</script>
