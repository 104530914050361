<template>
    <EmailsTable>
        <template #filters>
            <DropdownFilter
                :pin="pinFilters"
                @apply="applyFilters"
                @clear="clearFilters"
                @toggle-pin="togglePinFilters"
            >
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <SelectField
                            v-model="filtersFields.platform_name_exact"
                            v-model:options="platformsSelectOptions"
                            name="platform"
                            :placeholder="$t('platform')"
                            :disabled="disableFilter('platform_name_exact')"
                            :on-search="loadPlatformsSelectOptions"
                        />
                    </label>
                </DropdownFilterItem>
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <TextField
                            v-model="filtersFields.subject"
                            name="subject"
                            :placeholder="$t('subject')"
                            :disabled="disableFilter('subject')"
                        />
                    </label>
                </DropdownFilterItem>
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <TextField
                            v-model="filtersFields.mail_to_address"
                            name="mail_to_address"
                            :placeholder="$t('recipient')"
                            :disabled="disableFilter('mail_to_address')"
                        />
                    </label>
                </DropdownFilterItem>
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <SelectField
                            v-model="filtersFields.mail_type"
                            name="mail_type"
                            :options="emailTemplatesSelectOptions"
                            :placeholder="$t('email_template')"
                            :reduce="(item: EmailTemplate) => item.value"
                            :disabled="disableFilter('mail_type')"
                            @keydown.enter.prevent="handleEnterPress"
                        />
                    </label>
                </DropdownFilterItem>

                <DropdownFilterItem>
                    <label class="flex items-center">
                        <Datepicker
                            v-model="filtersFields.created_at"
                            placeholder="created_at"
                            name="filter_created_at"
                            :disabled="disableFilter('created_at')"
                        />
                    </label>
                </DropdownFilterItem>
            </DropdownFilter>
        </template>
    </EmailsTable>

    <ModalWrapper v-if="isModalOpen" />
</template>

<script setup lang="ts">
import { computed, h, onMounted, ref, shallowRef, toRefs, watch } from 'vue'

import { useModal, useTable } from '@composables/common'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import PlatformNameCellRenderer from '@partials/platforms/PlatformNameCellRenderer.vue'
import {
    Datepicker,
    DropdownFilter,
    DropdownFilterItem,
    SelectField,
    TextField,
} from '@src/components'
import { EmailAudit, EmailTemplate, Platform, TableSorting } from '@src/types'
import { getEmailTemplates, getEmails } from '@src/utils/api/emails'
import { getPlatforms } from '@src/utils/api/platforms'
import { handleEnterPress } from '@src/utils/helpers'
import { useUserStore } from '@src/utils/stores/user'
import moment from 'moment'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'

const props = withDefaults(
    defineProps<{
        initialFilters?: {
            account_title?: string | null
            email?: string | null
            platform_name_exact?: string | null
            wallet_status?: string | null
            wallet_id?: string | null
        } | null
    }>(),
    {
        initialFilters: null,
    }
)

const { initialFilters } = toRefs(props)

const { ModalWrapper, openModal } = useModal()
const router = useRouter()
const route = useRoute()
const i18n = useI18n()
const isModalOpen = ref(false)
const emails = ref<EmailAudit[]>([])
const emailsCount = ref<number>(0)
const userStore = useUserStore()
const emailTemplates = ref<EmailTemplate[]>([])
const platformsSelectOptions = ref<string[]>([])

const disableFilter = (filter: string) => {
    if (initialFilters.value) {
        return (
            Object.keys(initialFilters.value).filter((item) => {
                return item === filter
            }).length > 0
        )
    }
    return false
}

const {
    TableWrapper: EmailsTable,
    limit,
    sorting,
    page,
    filtersFields,
    filters,
    pinFilters,
    togglePinFilters,
    setFilters,
    applyFilters,
    clearFilters,
} = useTable({
    loadData: loadEmails,
    filters: {
        id: null,
        subject: null,
        mail_type: null,
        mail_to_address: null,
        created_at: null,
        platform_name_exact: null,
        search: null,
    },
    disableFilters: initialFilters.value
        ? Object.keys(initialFilters.value)
        : [],
    tableProps: {
        id: 'emails-table',
        label: 'emails',
        help: 'help_emails_table',
        pagination: true,
        data: emails,
        total: emailsCount,
        sorting: ref<TableSorting[]>([
            {
                field: 'created_at',
                direction: 'desc',
            },
        ]),
        columns: [
            {
                label: 'id',
                key: 'id',
                sorting: true,
                condition: userStore.can('see_id_columns'),
                copy: true,
            },
            {
                label: 'platform',
                key: 'platform_name',
                sorting: true,
                cellRenderer: shallowRef(PlatformNameCellRenderer),
            },
            {
                label: 'subject',
                key: 'subject',
                sorting: true,
            },
            {
                label: 'recipient',
                key: 'mail_to_address',
                sorting: true,
                copy: true,
            },
            {
                label: 'email_template',
                key: 'mail_type',
                sorting: true,
                cellRenderer: (props: { rowData: EmailAudit }) => {
                    const matchedTemplate = emailTemplates.value.find(
                        (template) => {
                            return (
                                template.name ===
                                'EMAIL_TEMPLATE_' +
                                    props.rowData.mail_type?.toUpperCase()
                            )
                        }
                    )

                    return matchedTemplate
                        ? i18n.t(matchedTemplate.name ?? '')
                        : props.rowData.mail_type
                },
            },
            {
                label: 'is_test',
                key: 'is_test',
                sorting: false,
                cellRenderer: (props: { rowData: EmailAudit }) => {
                    if (
                        props.rowData.natural_account_id === null &&
                        props.rowData.legal_account_id === null
                    ) {
                        return h(FontAwesomeIcon, {
                            icon: 'fa-solid fa-check',
                        })
                    }
                },
            },
            {
                label: 'created_on',
                key: 'created_at',
                sorting: true,
                cellRenderer: (props: { rowData: EmailAudit }) => {
                    return moment(props.rowData.created_at).format('DD.MM.yyyy')
                },
            },
        ],
        actions: [
            {
                action: (emailAudit: EmailAudit) =>
                    redirectToProfile('legalProfile', emailAudit),
                icon: 'fa-building-columns',
                title: 'legal_account',
                condition: (email: EmailAudit) => {
                    return email.legal_profile_id !== null
                },
            },
            {
                action: (emailAudit: EmailAudit) =>
                    redirectToProfile('naturalProfile', emailAudit),
                icon: 'fa-person',
                title: 'natural_account',
                condition: (email: EmailAudit) => {
                    return email.natural_profile_id !== null
                },
            },
            {
                action: openViewEmailContentModal,
                icon: 'fa-eye',
                title: 'view',
            },
        ],
    },
})

async function loadEmails() {
    const emailRequest = await getEmails(
        filters.value,
        page.value,
        limit.value,
        sorting.value
    )
    emails.value = emailRequest.data.email_audits
    emailsCount.value = emailRequest.data.email_audits_count

    const emailTemplatesRequest = await getEmailTemplates()
    emailTemplates.value = emailTemplatesRequest.data.email_templates

    if (route.params.id) {
        const emailToShow = emails.value.find(
            (email: EmailAudit) => (email.id = route.params.id as string)
        )
        if (emailToShow) {
            openViewEmailContentModal(emailToShow)
        }
    }
}

const emailTemplatesSelectOptions = computed(() => {
    return emailTemplates.value.map((template: EmailTemplate) => ({
        label: i18n.t(template.name ?? ''),
        value: template.value ?? '',
    }))
})

function redirectToProfile(type: string, emailAudit: EmailAudit) {
    let viewProfileID

    if (type === 'legalProfile') {
        viewProfileID = emailAudit.legal_profile_id
    } else {
        viewProfileID = emailAudit.natural_profile_id
    }

    router.push({
        name: type,
        params: { id: viewProfileID },
    })
}

async function loadPlatformsSelectOptions(search: string) {
    const initialPlatformsRequest = await getPlatforms({
        'filter[platform_name]': search,
    })
    platformsSelectOptions.value = initialPlatformsRequest.data.platforms.map(
        (platform: Platform) => {
            return platform.platform_name as string
        }
    )
}

function openViewEmailContentModal(emailAudit: EmailAudit) {
    isModalOpen.value = true

    openModal(
        {
            id: 'view_email_content',
            title:
                'EMAIL_TEMPLATE_' + emailAudit.mail_type?.toLocaleUpperCase(),
            open: isModalOpen,
            onCancel: () => (isModalOpen.value = false),
        },
        () =>
            h('div', { class: 'm-5 p-5 border-2 border-gray-300' }, [
                h('div', { class: 'email-header mb-5' }, [
                    h(
                        'div',
                        { class: 'font-bold' },
                        `To: ${emailAudit.mail_to_address}`
                    ),
                    h(
                        'div',
                        { class: 'font-bold' },
                        `Date: ${moment(emailAudit.created_at).format(
                            'DD.MM.yyyy HH:mm'
                        )}`
                    ),
                ]),
                h('hr', { class: 'my-4' }),
                h('iframe', {
                    srcdoc: emailAudit.content as string,
                    class: 'email-content-iframe',
                    sandbox: '',
                }),
            ])
    )
}

watch(
    () => route.params.id,
    async () => {
        await loadEmails()
    }
)

onMounted(async () => {
    if (initialFilters.value) {
        filtersFields.value = {
            ...filtersFields.value,
            ...initialFilters.value,
        }

        if (filtersFields.value.platform_name_exact) {
            await loadPlatformsSelectOptions(
                filtersFields.value.platform_name_exact
            )
        }
        setFilters()
    }
    await loadEmails()
})
</script>

<style lang="scss">
.modal-content {
    .email-content-iframe {
        width: 100%;
        height: 45vh;
    }

    &--fullscreen {
        .email-content-iframe {
            height: 65vh;
        }
    }
}
</style>
