<template>
    <PageWrapper>
        <template #headline>
            <fa
                icon="fa-building-columns"
                title="legal_account"
            />
            {{ $t('profile_information_legal') }}:
            {{ legalProfile.company_name }}
        </template>

        <template #actions>
            <BackButton
                v-if="$router.options.history.state.back"
                @click="router.go(-1)"
            />
            <BackButton
                v-if="$router.options.history.state.back !== '/accounts'"
                icon="fa-list"
                title="accounts"
                @click="toAccounts"
            />
            <EditButton
                v-if="userStore.can('update_account')"
                title="edit"
                @click="editLegalProfile"
            />
            <ActionsMenu
                v-if="
                    userStore.can('delete_account') ||
                    userStore.can('create_order')
                "
            />
        </template>

        <!-- Overview -->
        <SummaryWrapper>
            <LegalProfileSummary v-if="legalProfileSummaryItems" />
            <template #sidebar>
                <AccountSummary />
                <PlatformSummary />
                <WalletSummary
                    v-if="legalProfile.wallet && legalProfile.wallet.id"
                />
            </template>
        </SummaryWrapper>

        <AccordionBasic
            title="orders"
            :add-to-title="userOrdersNum"
            class="mb-2"
            :open="true"
        >
            <OrdersTable
                v-if="legalProfile.account_uid"
                :initial-filters="{
                    account_uid: legalProfile.account_uid,
                }"
            />
        </AccordionBasic>

        <AccordionBasic
            title="address_details"
            :open="false"
        >
            <BillingAddressSummary />
            <template v-if="isPostalAddress">
                <PostalAddressSummary />
            </template>
        </AccordionBasic>

        <AccordionBasic
            title="linked_natural_profiles"
            :add-to-title="profileNaturalAllNum"
            class="mb-2"
            :open="false"
        >
            <NaturalProfilesTable />
        </AccordionBasic>

        <AccordionBasic
            title="account_bank_accounts"
            :open="false"
            :add-to-title="bankAccounts.length"
        >
            <BankAccountsTable />
            <Summary
                title="account_bank_historical_data"
                :items="bankAccountsHistoricalDetailsItems"
            />
        </AccordionBasic>

        <AccordionBasic
            v-if="legalProfile.wallet && legalProfile.wallet.id"
            title="wallet_transactions"
            :add-to-title="userTransactionsCount"
            class="mb-2"
            :open="false"
        >
            <TransactionsTable
                :initial-filters="{
                    legal_profile_id: legalProfile.legal_profile_uid,
                }"
                @get-count="getTransactionsCount"
            />
        </AccordionBasic>

        <AccordionBasic
            v-if="legalProfile.wallet && legalProfile.wallet.id"
            title="wallet_money_withdrawals"
            :add-to-title="moneyWithdrawals.length"
            class="mb-2"
            :open="false"
        >
            <WithdrawalTable>
                <template #actions>
                    <CreateButton @click.stop="createMoneyWithdrawal" />
                </template>
            </WithdrawalTable>
        </AccordionBasic>

        <AccordionBasic
            title="kyc_documents"
            :add-to-title="legalKYCDocuments.length + bankKYCDocuments.length"
            class="mb-2"
            :open="false"
        >
            <KYCLegalTable>
                <template #actions>
                    <CreateButton @click.stop="createLegalKYCDocument" />
                </template>
            </KYCLegalTable>

            <KYCBankTable>
                <template #actions>
                    <CreateButton @click.stop="createBankKYCDocument" />
                </template>
            </KYCBankTable>
        </AccordionBasic>

        <AccordionBasic
            v-if="
                platform.compliance_profile === PlatformComplianceProfile.ECSP
            "
            title="ecsp_document"
            :add-to-title="idECSPDocuments.length"
            class="mb-2"
            :open="false"
        >
            <ECSPDocumentTable>
                <template #actions>
                    <CreateButton
                        v-if="userStore.can('update_account')"
                        @click.stop="createECSPDocumentModal"
                    />
                </template>
            </ECSPDocumentTable>
        </AccordionBasic>

        <AccordionBasic
            v-if="platform.membership_management_active === true"
            title="membership_management"
            class="mb-2"
            :open="false"
        >
            <MembershipDocumentTable>
                <template #actions>
                    <CreateButton
                        v-if="userStore.can('update_account')"
                        @click.stop="createMembershipDocumentModal"
                    />
                </template>
            </MembershipDocumentTable>
        </AccordionBasic>

        <AccordionBasic
            v-if="signatures.length > 0"
            title="signatures"
            :add-to-title="signatures.length"
            class="mb-2"
            :open="false"
        >
            <SignaturesTable />
        </AccordionBasic>
        <AccordionBasic
            v-if="beneficialOwners.length > 0"
            title="beneficial_owners"
            :add-to-title="beneficialOwners.length"
            class="mb-2"
            :open="false"
        >
            <BeneficialOwnersTable />
        </AccordionBasic>

        <AccordionBasic
            v-if="
                nyalaRegistryDocuments.length &&
                userStore.can('view_nyala_registry_document_email')
            "
            title="nyala_registry_documents"
            :add-to-title="nyalaRegistryDocuments.length"
            class="mb-2"
            :open="false"
        >
            <NyalaRegistryDocumentsTable></NyalaRegistryDocumentsTable>
        </AccordionBasic>

        <template #modals>
            <CreateOrderSelectUserModal
                v-if="createOrderSelectUserModalOpen"
                ref="createOrderSelectUserModal"
                v-model="tmpOrder"
                v-model:open="createOrderSelectUserModalOpen"
                :preselected="account.account_uid"
                @submit="createOrder"
                @cancel="closeCreateOrderModal"
            />
        </template>
        <ModalWrapper v-if="isModalOpen" />
    </PageWrapper>
</template>

<script setup lang="ts">
import EditButton from '@partials/actions/EditButton.vue'
import { AccordionBasic, Summary, SummaryWrapper } from '@src/components'
import { EcspStatusType, ProfileType } from '@src/enums'
import BackButton from '@src/partials/actions/BackButton.vue'
import PageWrapper from '@src/partials/pages/PageWrapper.vue'
import {
    Account,
    Address,
    BankAccount,
    BankKYCDocument,
    BeneficialOwner,
    CreateMoneyWithdrawal,
    CreateOrderPayload,
    ECSPDocument,
    EcspComplianceProfileLegal,
    EcspInvestorClassification,
    EmissionSignature,
    KYCDocument,
    LegalProfile,
    MembershipDocument,
    MoneyWithdrawal,
    NaturalProfile,
    NyalaRegistryDocument,
    Order,
    Platform,
    Wallet,
} from '@src/types'
import {
    createMoneyWithdrawal as apiCreateMoneyWithdrawal,
    aprroveMembershipDocument,
    createECSPDocument,
    createKYCDocument,
    createMembershipDocument,
    deleteAccountLegal,
    getAccount,
    getECSPDocuments,
    getKYCDocuments,
    getLegalProfile,
    getNaturalProfilesOfLegalProfile,
    syncWallet,
    updateComplianceProfile,
    updateLegalProfile,
} from '@src/utils/api/accounts'
import { createOrder as apiCreateOrder, getOrders } from '@src/utils/api/orders'
import moment from 'moment'
import { computed, h, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { useMembershipDocumentsTable } from '@composables/accounts/useMembershipDocumentsTable'
import {
    ModalButtonStyles,
    useActionsMenu,
    useMask,
    useModal,
} from '@composables/common'
import { PlatformComplianceProfile } from '@enums/Platform'
import MembershipDocumentCreateForm from '@partials/accounts/MembershipDocumentCreateForm.vue'
import OrdersTable from '@partials/orders/OrdersTable.vue'
import { useBankAccountsTable } from '@src/composables/accounts/useBankAccountsTable'
import { useBeneficialOwnersTable } from '@src/composables/accounts/useBeneficialOwnersTable'
import { useECSPDocumentsTable } from '@src/composables/accounts/useECSPDocumentsTable'
import { useKYCBankDocumentsTable } from '@src/composables/accounts/useKYCBankDocumentsTable'
import { useKYCDocumentsTable } from '@src/composables/accounts/useKYCDocumentsTable'
import { useNaturalProfilesTable } from '@src/composables/accounts/useNaturalProfilesTable'
import { useNyalaRegistryDocumentsTable } from '@src/composables/accounts/useNyalaRegistryDocumentsTable'
import { useWithdrawalTable } from '@src/composables/accounts/useWithdrawalTable'
import { useSummary } from '@src/composables/common'
import { useSignaturesTable } from '@src/composables/signatures/useSignaturesTable'
import { useAccountSummary } from '@src/composables/summaries/useAccountSummary'
import { useAddressSummary } from '@src/composables/summaries/useAddressSummary'
import { usePlatformSummary } from '@src/composables/summaries/usePlatformSummary'
import { useWalletSummary } from '@src/composables/summaries/useWalletSummary'
import BankKYCDocumentCreateForm from '@src/partials/accounts/BankKYCDocumentCreateForm.vue'
import ECSPDocumentCreateForm from '@src/partials/accounts/ECSPDocumentCreateForm.vue'
import LegalKYCDocumentCreateForm from '@src/partials/accounts/LegalKYCDocumentCreateForm.vue'
import MoneyWithdrawalCreateForm from '@src/partials/accounts/MoneyWithdrawalCreateForm.vue'
import CreateButton from '@src/partials/actions/CreateButton.vue'
import CreateOrderSelectUserModal from '@src/partials/orders/CreateOrderSelectUserModal.vue'
import TransactionsTable from '@src/partials/transactions/TransactionsTable.vue'
import { SummaryItem } from '@src/types/Summary'
import { getPlatform } from '@src/utils/api/platforms'
import { useToastStore } from '@src/utils/stores/toast'
import { useUserStore } from '@src/utils/stores/user'
import { useI18n } from 'vue-i18n'

const route = useRoute()
const router = useRouter()
const userStore = useUserStore()
const { everyFourSpace } = useMask()

const { ModalWrapper, openModal } = useModal()
const account = ref<Account>(new Account())
const profileNaturalOwner = ref<NaturalProfile>(new NaturalProfile())
const profileNaturalAll = ref<NaturalProfile[]>([])
const legalProfile = ref<LegalProfile>(new LegalProfile())
const wallet = ref<Wallet>(new Wallet())

const userOrders = ref<Order[]>([])
const bankAccounts = ref<BankAccount[]>([])
const moneyWithdrawals = ref<MoneyWithdrawal[]>([])
const KYCDocuments = ref<KYCDocument[]>([])
const ECSPDocuments = ref<ECSPDocument[]>([])
const newECSPDocument = ref(new EcspInvestorClassification())
const membershipDocuments = ref<MembershipDocument[]>([])
const newMembershipDocument = ref(new MembershipDocument())
const createOrderSelectUserModal = ref(false)
const createOrderSelectUserModalOpen = ref(false)
const tmpOrder = ref<CreateOrderPayload>(new CreateOrderPayload())
const moneyWithdrawalsNum = ref(0)
const userTransactionsCount = ref(0)
const profileNaturalAllNum = ref(0)
const userOrdersNum = ref(0)
const profileNaturalUrl = ref<string>('')
const legalKYCDocuments = ref<KYCDocument[]>([])
const bankKYCDocuments = ref<BankKYCDocument[]>([])
const bankKYCDocumentsNum = ref(0)
const legalKYCDocumentsNum = ref(0)
const newBankKYCDocument = ref(new BankKYCDocument())
const newKYCDocument = ref(new KYCDocument())
const platformUrl = ref<string>('')
const platform = ref<Platform>(new Platform())
const tmpMoneyWithdrawal = ref(new CreateMoneyWithdrawal())
const signatures = ref<EmissionSignature[]>([])
const beneficialOwners = ref<BeneficialOwner[]>([])
const idECSPDocuments = ref<ECSPDocument[]>([])
const idECSPDocumentsNum = ref(0)
const idMembershipDocuments = ref<MembershipDocument[]>([])
const idMembershipDocumentsNum = ref(0)
const nyalaRegistryDocuments = ref<NyalaRegistryDocument[]>([])

const toast = useToastStore()
const i18n = useI18n()
const isModalOpen = ref(false)

const { ActionsMenu } = useActionsMenu(
    {
        id: 'asset-actions-menu',
        align: 'right',
    },
    [
        {
            label: 'button_delete_legal',
            icon: 'fa-trash-alt',
            color: 'text-danger-500',
            action: handleOpenDeleteLegalProfileModal,
            condition: () => userStore.can('delete_account'),
        },
        {
            label: 'modal_title_create_order',
            icon: 'fa-eye',
            color: 'text-gray-500',
            action: openCreateOrderSelectUser,
            condition: () => userStore.can('create_order'),
        },
        {
            label: 'confirm_kyc_company_details',
            icon: 'fa-check',
            color: 'text-primary-accent-500',
            action: confirmKYCCompanyDetails,
            condition: () =>
                userStore.can('update_account') &&
                legalProfile.value.platform_has_payment_account === false &&
                legalProfile.value.kyc_legal === false,
        },
        {
            label: 'reject_kyc_company_details',
            icon: 'fa-xmark',
            color: 'text-danger-500',
            action: rejectKYCCompanyDetails,
            condition: () =>
                userStore.can('update_account') &&
                legalProfile.value.platform_has_payment_account === false &&
                legalProfile.value.kyc_legal === true,
        },
        {
            label: 'button_sync_wallet',
            icon: 'fa-rotate',
            color: 'text-gray-500',
            action: openWalletSync,
            condition: () => {
                return (
                    !!legalProfile.value.wallet && userStore.can('sync_wallet')
                )
            },
        },
    ]
)

async function openWalletSync() {
    isModalOpen.value = true
    try {
        if (legalProfile.value.wallet.id != null) {
            const walletId = legalProfile.value.wallet.id
            await syncWallet(walletId)
            toast.success(i18n.t('toast_success_wallet_synced'))
            await loadAccount()
        } else {
            toast.error(i18n.t('toast_error_wallet_id_null'))
        }
    } catch (error) {
        toast.error(i18n.t('toast_error_wallet_sync_failed'))
    } finally {
        isModalOpen.value = false
    }
}

async function confirmKYCCompanyDetails() {
    legalProfile.value.kyc_legal = true
    try {
        await updateLegalProfile(legalProfile.value)
        toast.success(
            i18n.t('toast_success_update_legal_profile_kyc_legal_confirmed')
        )
    } catch (error) {
        toast.error(i18n.t('toast_error_update_legal_profile_kyc_legal'))
    }
    loadAccount()
}

async function rejectKYCCompanyDetails() {
    legalProfile.value.kyc_legal = false
    try {
        await updateLegalProfile(legalProfile.value)
        toast.success(
            i18n.t('toast_success_update_legal_profile_kyc_legal_rejected')
        )
    } catch (error) {
        toast.error(i18n.t('toast_error_update_legal_profile_kyc_legal'))
    }
    loadAccount()
}

function handleOpenDeleteLegalProfileModal() {
    isModalOpen.value = true
    openModal(
        {
            id: 'delete_legal_modal',
            title: 'modal_title_delete_legal_profile',
            open: isModalOpen,
            onCancel: () => {
                isModalOpen.value = false
            },
            onSubmit: async () => deleteLegalProfile(),
        },
        () => h('p', i18n.t('modal_text_delete_legal_profile')),
        {
            submitButtonText: 'delete',
            styles: {
                submitButton: ModalButtonStyles['RED'],
            },
        }
    )
}

function openCreateOrderSelectUser(): void {
    createOrderSelectUserModalOpen.value = true
}

const { NaturalProfilesTable } = useNaturalProfilesTable(
    profileNaturalAll,
    profileNaturalAllNum
)

const { BankAccountsTable } = useBankAccountsTable(
    bankAccounts,
    openModal,
    isModalOpen
)

const { WithdrawalTable } = useWithdrawalTable(
    moneyWithdrawals,
    moneyWithdrawalsNum
)

const { KYCTable: KYCLegalTable } = useKYCDocumentsTable(
    legalKYCDocuments,
    openModal,
    isModalOpen,
    assignAndFilterKYCDocuments,
    'kyc_legal_documents',
    'help_kyc_legal_documents'
)

const { ECSPTable: ECSPDocumentTable } = useECSPDocumentsTable(
    idECSPDocuments,
    openModal,
    isModalOpen,
    assignAndFilterECSPDocuments,
    loadAccount,
    'ecsp_id_document',
    'help_ecsp_id_document'
)

const { MembershipDocumentTable } = useMembershipDocumentsTable(
    idMembershipDocuments,
    openModal,
    isModalOpen,
    assignAndFilterMembershipDocuments,
    loadAccount,
    'membership_id_document',
    'help_membership_id_document'
)

const { SignaturesTable } = useSignaturesTable(signatures)

const { BeneficialOwnersTable } = useBeneficialOwnersTable(beneficialOwners)

const { NyalaRegistryDocumentsTable } = useNyalaRegistryDocumentsTable(
    nyalaRegistryDocuments,
    openModal,
    isModalOpen,
    'nyala_registry_documents',
    'nyala_registry_documents_help'
)

async function saveMoneyWithdrawal(moneyWithdrawal: CreateMoneyWithdrawal) {
    moneyWithdrawal.natural_profile_id =
        profileNaturalOwner.value?.natural_profile_uid
    try {
        await apiCreateMoneyWithdrawal(moneyWithdrawal)

        toast.success(i18n.t('toast_success_withdrawal_created'))
    } catch (error) {
        toast.error(i18n.t('toast_error_withdrawal_creation_failed'))
    }

    tmpMoneyWithdrawal.value = new CreateMoneyWithdrawal()

    isModalOpen.value = false
}

function createECSPDocumentModal() {
    newECSPDocument.value = {
        ...new EcspInvestorClassification(),
    }
    isModalOpen.value = true
    openModal(
        {
            id: 'create_id_ecsp_document',
            title: 'modal_title_create_id_ecsp_document',
            open: isModalOpen,
            onCancel: () => (isModalOpen.value = false),
            onSubmit: () => saveECSPDocument(newECSPDocument.value),
        },
        () =>
            h(ECSPDocumentCreateForm, {
                modelValue: newECSPDocument,
            }),
        {
            submitButtonText: 'create',
            styles: {
                submitButton: ModalButtonStyles['BLUE'],
            },
        }
    )
}

function createMembershipDocumentModal() {
    newMembershipDocument.value = { ...new MembershipDocument() }
    isModalOpen.value = true
    openModal(
        {
            id: 'create_id_membership_document',
            title: 'modal_title_create_id_membership_document',
            open: isModalOpen,
            onCancel: () => (isModalOpen.value = false),
            onSubmit: () => saveMembershipDocument(newMembershipDocument.value),
        },
        () =>
            h(MembershipDocumentCreateForm, {
                modelValue: newMembershipDocument,
            }),
        {
            submitButtonText: 'create',
            styles: {
                submitButton: ModalButtonStyles['BLUE'],
            },
        }
    )
}

function createMoneyWithdrawal() {
    isModalOpen.value = true
    const disableCreate = ref(true)

    const onValidate = (isFormValid: boolean) => {
        disableCreate.value = !isFormValid
    }

    openModal(
        {
            id: 'create_money_withdrawal',
            title: 'modal_title_create_money_withdrawal',
            open: isModalOpen,
            onCancel: () => (isModalOpen.value = false),
            onSubmit: async () =>
                await saveMoneyWithdrawal(tmpMoneyWithdrawal.value),
        },
        () =>
            h(MoneyWithdrawalCreateForm, {
                modelValue: tmpMoneyWithdrawal,
                naturalProfile: profileNaturalOwner.value,
                legalProfile: legalProfile.value,
                createForProfile: ProfileType.LEGAL,
                onValidate: (value: boolean) => onValidate(value),
            }),
        {
            submitButtonText: 'create',
            styles: {
                submitButton: ModalButtonStyles['BLUE'],
            },
            submitButtonDisabled: disableCreate,
        }
    )
}

async function saveECSPDocument(document: ECSPDocument) {
    try {
        legalProfile.value.compliance_profiles = {
            ecsp: new EcspComplianceProfileLegal(),
        }

        legalProfile.value.compliance_profiles.ecsp.investor_classification.self_disclosure =
            {
                ...{
                    key: document.key,
                    mime_type: document.mime_type,
                    status: document.status,
                    temp_storage_url: document.temp_storage_url,
                },
            }

        document.account_id = legalProfile.value.account_uid

        await createECSPDocument(document)
        await updateComplianceProfile(legalProfile.value, ProfileType.LEGAL)
        // refreshing account after ecsp update
        await loadAccount()

        toast.success(i18n.t('toast_success_ecsp_document_updated'))
    } catch (error) {
        toast.error(i18n.t('toast_error_ecsp_document_create'))
        throw error
    }
    assignAndFilterECSPDocuments()
    isModalOpen.value = false
}

async function saveMembershipDocument(document: MembershipDocument) {
    try {
        document.legal_profile_id = legalProfile.value.legal_profile_uid

        await createMembershipDocument(document)
        await loadAccount()

        toast.success(i18n.t('toast_success_membership_document_create'))
    } catch (error) {
        toast.error(i18n.t('toast_error_membership_document_create'))
        throw error
    }
    assignAndFilterMembershipDocuments()
    isModalOpen.value = false
}

async function deleteLegalProfile() {
    isModalOpen.value = false

    if (legalProfile.value.legal_profile_uid) {
        await deleteAccountLegal(legalProfile.value.legal_profile_uid)
        toast.success(i18n.t('toast_success_legal_profile_deleted'))
    }

    if (profileNaturalOwner.value) {
        router.push({
            name: 'naturalProfile',
            params: { id: profileNaturalOwner.value.natural_profile_uid },
        })
    } else {
        router.push({ name: 'accounts' })
    }
}

async function createOrder(next?: string) {
    const createOrderRequest = await apiCreateOrder(tmpOrder.value)
    const { order_uid } = createOrderRequest.data.order

    createOrderSelectUserModalOpen.value = false

    if (next && next == 'edit') {
        router.push({
            name: 'order.edit',
            params: { id: order_uid },
        })
    } else {
        if (legalProfile.value.account_uid) {
            getAccountOrders(legalProfile.value.account_uid)
        }
    }

    tmpOrder.value = tmpOrder.value = new CreateOrderPayload()

    toast.success(i18n.t('toast_success_order_created'))

    closeCreateOrderModal()
}

function closeCreateOrderModal() {
    tmpOrder.value = new CreateOrderPayload()
    createOrderSelectUserModalOpen.value = false
}

const legalProfileSummaryItems = computed<SummaryItem[]>((): SummaryItem[] => {
    return [
        {
            label: i18n.t('id'),
            value: legalProfile.value.legal_profile_uid,
            copy: true,
        },
        {
            label: i18n.t('profile_company_name'),
            value: legalProfile.value.company_name,
            copy: true,
        },
        {
            label: i18n.t('profile_company_description'),
            value: legalProfile.value.description,
            copy: true,
        },
        {
            label: i18n.t('profile_company_registration_number'),
            value: legalProfile.value.registration_number,
        },
        {
            label: i18n.t('profile_company_website_url'),
            value: legalProfile.value.website,
            special: legalProfile.value.website as string,
            condition: !!legalProfile.value.website,
            copy: true,
        },
        {
            label: i18n.t('profile_company_details_kyc'),
            value: legalProfile.value.kyc_legal,
            special: 'check',
        },
        {
            label: i18n.t('account_bank_kyc_status'),
            value: legalProfile.value.kyc_bank,
            special: 'check',
        },
        {
            label: i18n.t('ecsp_status'),
            value: legalProfile.value.ecsp_status === EcspStatusType.EXPERT,
            condition:
                legalProfile.value.compliance_profile ===
                PlatformComplianceProfile.ECSP,
            special: 'check',
        },
        {
            label: i18n.t('tax_number'),
            value: legalProfile.value.tax_number,
            condition: !!legalProfile.value.tax_number,
            copy: true,
        },
        {
            label: i18n.t('membership_number'),
            value: legalProfile.value.membership_number,
            copy: true,
            condition:
                platform.value.membership_management_active === true &&
                platform.value.membership_number_active === true,
        },
        {
            label: i18n.t('profile_company_company_email'),
            value: legalProfile.value.company_email,
            copy: true,
        },
        {
            label: i18n.t('created_at'),
            value: moment(legalProfile.value.created_on).format(
                'DD.MM.yyyy HH:mm'
            ),
        },
        {
            label: i18n.t('updated_at'),
            value: moment(legalProfile.value.updated_at).format(
                'DD.MM.yyyy HH:mm'
            ),
        },
    ]
})

const { Summary: LegalProfileSummary } = useSummary(legalProfileSummaryItems, {
    title: 'summary',
})

const { AccountSummary } = useAccountSummary(profileNaturalOwner, undefined, {
    title: 'account_owner',
})

const { PlatformSummary } = usePlatformSummary(platform)

const { WalletSummary } = useWalletSummary(wallet)

const billingAddress = computed<Address>(() => {
    return {
        address: legalProfile.value.address,
        zip: legalProfile.value.postal_code,
        city: legalProfile.value.city,
        country: legalProfile.value.country,
        state: legalProfile.value.state,
    }
})

const { AddressSummary: BillingAddressSummary } = useAddressSummary(
    billingAddress,
    { title: 'billing_address' }
)

const postalAddress = computed<Address>(() => {
    return {
        address: legalProfile.value.postal_address,
        zip: legalProfile.value.postal_address_postal_code,
        city: legalProfile.value.postal_address_city,
        country: legalProfile.value.postal_address_country,
        state: legalProfile.value.postal_address_state,
    }
})

const { AddressSummary: PostalAddressSummary } = useAddressSummary(
    postalAddress,
    { title: 'postal_address' }
)

const isPostalAddress = computed(() => {
    return (
        legalProfile.value.postal_address &&
        legalProfile.value.postal_address_postal_code &&
        legalProfile.value.postal_address_city &&
        legalProfile.value.country &&
        legalProfile.value.postal_address_state
    )
})

const bankAccountsHistoricalDetailsItems = computed<
    { label: string; value: string | object | unknown; special?: string }[]
>(() => {
    return [
        {
            label: i18n.t('account_iban'),
            value:
                legalProfile.value.iban &&
                everyFourSpace(legalProfile.value.iban),
            copy: true,
        },
        {
            label: i18n.t('account_bic'),
            value: legalProfile.value.bic,
            copy: true,
        },
    ]
})

async function getECSPs(accountID: string): Promise<ECSPDocument[]> {
    const ECSPDocumentsRequest = await getECSPDocuments(accountID)
    return ECSPDocumentsRequest.data
}

function createLegalKYCDocument() {
    newKYCDocument.value = {
        ...new KYCDocument(),
        account_id: legalProfile.value.account_uid,
        verification_type: 'kyc_legal',
    }

    isModalOpen.value = true
    openModal(
        {
            id: 'create_legal_kyc_document',
            title: 'modal_title_create_legal_kyc_document',
            open: isModalOpen,
            onCancel: () => (isModalOpen.value = false),
            onSubmit: () => saveKYCDocument(newKYCDocument.value),
        },
        () =>
            h(LegalKYCDocumentCreateForm, {
                modelValue: newKYCDocument,
            }),
        {
            submitButtonText: 'create',
            styles: {
                submitButton: ModalButtonStyles['BLUE'],
            },
            submitButtonDisabled: disableCreateKYC,
        }
    )
}

async function saveKYCDocument(document: KYCDocument) {
    try {
        await createKYCDocument(document)

        toast.success(i18n.t('toast_success_kyc_document_created'))
    } catch (error) {
        toast.error(i18n.t('toast_error_kyc_document_create'))
    }
    assignAndFilterKYCDocuments()
    isModalOpen.value = false
}

const disableCreateKYC = computed(() => {
    return !newKYCDocument.value.type
})

const { KYCBankTable } = useKYCBankDocumentsTable(
    bankKYCDocuments,
    openModal,
    isModalOpen,
    assignAndFilterKYCDocuments,
    'kyc_bank_documents',
    'help_kyc_bank_documents'
)

function createBankKYCDocument() {
    newBankKYCDocument.value = {
        ...new KYCDocument(),
        bankAccount: {} as BankAccount,
        account_id: legalProfile.value.account_uid,
        verification_type: 'kyc_bank',
    }

    isModalOpen.value = true
    openModal(
        {
            id: 'create_bank_kyc_document',
            title: 'modal_title_create_bank_kyc_document',
            open: isModalOpen,
            onCancel: () => (isModalOpen.value = false),
            onSubmit: () => saveBankKYCDocument(newBankKYCDocument.value),
        },
        () => h(BankKYCDocumentCreateForm, { modelValue: newBankKYCDocument }),
        {
            submitButtonText: 'create',
            styles: {
                submitButton: ModalButtonStyles['BLUE'],
            },
            submitButtonDisabled: disableCreateBankKYC,
        }
    )
}

async function saveBankKYCDocument(document: BankKYCDocument) {
    try {
        await createKYCDocument(document)

        toast.success(i18n.t('toast_success_kyc_document_created'))
    } catch (error) {
        toast.error(i18n.t('toast_error_kyc_document_create'))
    }

    assignAndFilterKYCDocuments()
    isModalOpen.value = false
}

const disableCreateBankKYC = computed(() => {
    return !(
        newBankKYCDocument.value.bankAccount.iban &&
        newBankKYCDocument.value.type
    )
})

async function getKYCs(accountID: string): Promise<KYCDocument[]> {
    const KYCDocumentsRequest = await getKYCDocuments(accountID)
    return KYCDocumentsRequest.data
}

async function getAccountOrders(accountID: string) {
    const orderRequest = await getOrders(
        { 'filter[account_uid]': accountID },
        1,
        10,
        [{ field: 'status', direction: 'desc' }]
    )

    userOrders.value = orderRequest.data.orders
    userOrdersNum.value = userOrders.value.length
}

async function getTransactionsCount(count: number) {
    userTransactionsCount.value = count
}

async function loadAccount() {
    const legalProfileRequest = await getLegalProfile(route.params.id as string)
    legalProfile.value = legalProfileRequest.data.legal_profile

    if (legalProfile.value.legal_profile_uid) {
        const naturalProfilesRequest = await getNaturalProfilesOfLegalProfile(
            legalProfile.value.legal_profile_uid
        )

        profileNaturalAll.value = naturalProfilesRequest.data.natural_profiles

        profileNaturalAllNum.value = profileNaturalAll.value.length

        nyalaRegistryDocuments.value =
            legalProfile.value.nyala_registry_documents

        const ownerProfile = naturalProfilesRequest.data.natural_profiles.find(
            (profile) => profile.owner === true
        )
        if (ownerProfile) {
            profileNaturalOwner.value = ownerProfile
            if (profileNaturalOwner.value.platform_name) {
                const platformRequest = await getPlatform(
                    profileNaturalOwner.value.platform_name
                )
                platform.value = platformRequest.data.platform
                platformUrl.value = '/platforms/' + platform.value.platform_name
            }
        } else {
            console.log('No owner profile found')
        }

        profileNaturalUrl.value =
            '/naturalProfile/' + ownerProfile?.natural_profile_uid
    }

    if (legalProfile.value.account_uid) {
        await getAccountOrders(legalProfile.value.account_uid)

        assignAndFilterKYCDocuments(legalProfile.value.account_uid)

        assignAndFilterECSPDocuments(
            legalProfileRequest.data.legal_profile.account_uid
        )

        const accountRequest = await getAccount(legalProfile.value.account_uid)
        account.value = accountRequest.data.account
    }

    if (
        legalProfile.value.wallet &&
        legalProfile.value.wallet?.moneyWithdrawals
    ) {
        wallet.value = legalProfile.value.wallet as Wallet
        moneyWithdrawals.value = legalProfile.value.wallet?.moneyWithdrawals
        moneyWithdrawalsNum.value = moneyWithdrawals.value.length
    }

    if (platform.value.membership_management_active) {
        assignAndFilterMembershipDocuments()
    }

    signatures.value = legalProfile.value.signatures
    beneficialOwners.value = legalProfile.value.beneficialOwners
}

async function assignAndFilterKYCDocuments(accountID?: string) {
    const accountIdToUse = accountID ?? legalProfile.value.account_uid
    if (!accountIdToUse) {
        return
    }

    KYCDocuments.value = await getKYCs(accountIdToUse)

    legalKYCDocuments.value = KYCDocuments.value
        .filter((document) => document.verification_type === 'kyc_legal')
        .sort(
            (a, b) =>
                moment(b.created_at).valueOf() - moment(a.created_at).valueOf()
        )

    legalKYCDocumentsNum.value = legalKYCDocuments.value.length

    const mappedDocuments = KYCDocuments.value
        .filter((document) => document.verification_type === 'kyc_bank')
        .map((kycDocument) => {
            const bankKYCDocument = new BankKYCDocument()
            Object.assign(bankKYCDocument, kycDocument)
            return bankKYCDocument
        })

    bankKYCDocuments.value = mappedDocuments
    bankKYCDocumentsNum.value = bankKYCDocuments.value.length
}

async function assignAndFilterECSPDocuments(accountID?: string) {
    const accountIdToUse = accountID ?? legalProfile.value.account_uid

    if (!accountIdToUse) {
        return
    }
    ECSPDocuments.value = await getECSPs(accountIdToUse)

    idECSPDocuments.value = ECSPDocuments.value.sort(
        (a, b) =>
            moment(b.created_at).valueOf() - moment(a.created_at).valueOf()
    )

    idECSPDocumentsNum.value = idECSPDocuments.value.length
}

function assignAndFilterMembershipDocuments() {
    membershipDocuments.value = legalProfile.value.membership_document
    idMembershipDocuments.value = legalProfile.value.membership_document

    idMembershipDocuments.value = membershipDocuments.value.sort(
        (a, b) =>
            moment(b.created_at).valueOf() - moment(a.created_at).valueOf()
    )

    idMembershipDocumentsNum.value = idECSPDocuments.value.length
}

function toAccounts() {
    router.push({ name: 'accounts' })
}

function editLegalProfile() {
    router.push({
        name: 'legalProfile.edit',
        params: { id: legalProfile.value?.legal_profile_uid },
    })
}

async function approveMembershipDocument(
    membershipDocumentIdToApprove: string
) {
    try {
        await aprroveMembershipDocument(membershipDocumentIdToApprove)
        toast.success(i18n.t('toast_success_membership_document_approved'))
    } catch (error) {
        toast.error(i18n.t('toast_error_membership_document_approved'))
        throw error
    }
}

watch(
    () => route.params.id,
    async () => {
        await loadAccount()
    }
)

onMounted(async () => {
    if (route.query.membershipDocumentIdToApprove) {
        await approveMembershipDocument(
            route.query.membershipDocumentIdToApprove as string
        )
    }

    await loadAccount()
    bankAccounts.value = legalProfile.value.bankAccounts
})
</script>
