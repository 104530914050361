import { useTable } from '@composables/common'
import CountryColumnCellRenderer from '@partials/tables/CountryColumnCellRenderer.vue'
import { useSignaturesTable } from '@src/composables/signatures/useSignaturesTable'
import { BeneficialOwner, EmissionSignature } from '@src/types'
import moment from 'moment'
import { Ref, computed, h, ref, shallowRef } from 'vue'
import { useI18n } from 'vue-i18n'

export function useBeneficialOwnersTable(
    beneficialOwners: Ref<BeneficialOwner[]>
) {
    const i18n = useI18n()

    const total = computed(() => {
        return beneficialOwners.value.length
    })

    const { TableWrapper: BeneficialOwnersTable } = useTable({
        tableProps: {
            id: 'beneficial-owners-table',
            label: 'beneficial_owners',
            help: 'help_beneficial_owners',
            total: total,
            data: beneficialOwners,
            columns: [
                {
                    label: 'first_name',
                    key: 'first_name',
                    sorting: false,
                    copy: true,
                },
                {
                    label: 'last_name',
                    key: 'last_name',
                    sorting: false,
                    copy: true,
                },
                {
                    label: 'date_of_birth',
                    key: 'date_of_birth',
                    sorting: false,
                    cellRenderer: (props: { rowData: BeneficialOwner }) => {
                        return moment(props.rowData.date_of_birth).format(
                            'DD.MM.yyyy'
                        )
                    },
                },
                {
                    label: 'nationality',
                    key: 'nationality',
                    sorting: false,
                    cellRenderer: shallowRef(CountryColumnCellRenderer),
                },
                {
                    label: 'status',
                    key: 'active',
                    sorting: false,
                    cellRenderer: (props: { rowData: BeneficialOwner }) => {
                        return props.rowData.active ? i18n.t('active') : ''
                    },
                },
            ],
            actions: [
                {
                    action: 'expand',
                    icon: 'fa-solid fa-file-signature',
                    title: 'show_signatures',
                    condition: (beneficialOwner: BeneficialOwner) => {
                        return !!beneficialOwner.signatures
                    },
                },
            ],
            expand: (props: { rowData: BeneficialOwner }) => {
                const signatures = ref<EmissionSignature[]>([])
                signatures.value = props.rowData.signatures
                const { SignaturesTable } = useSignaturesTable(signatures)
                return h(
                    'td',
                    {
                        colspan: 6,
                    },
                    h(SignaturesTable)
                )
            },
        },
    })

    return {
        BeneficialOwnersTable,
    }
}
