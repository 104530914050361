import { i18n } from '@src/utils/lang'

const { t } = i18n.global

export interface EnumArrayItem {
    label: string
    value: number | string
}

export const useEnum = () => {
    const mapEnumToArray = (obj: object, translate = true) => {
        const array: EnumArrayItem[] = []
        Object.entries(obj).forEach(([label, value]) => {
            if (Number.isNaN(parseInt(label))) {
                if (translate) {
                    label = t(label)
                }
                array.push({
                    label,
                    value,
                })
            }
        })
        return array
    }

    const getEnumLabel = (obj: object, value: unknown, translate = true) => {
        if (value == null) {
            return
        }
        const keyIndex = Object.values(obj).indexOf(value as string | number)
        if (keyIndex === -1) {
            return value
        }
        if (translate) {
            return t(Object.keys(obj)[keyIndex])
        }
        return Object.keys(obj)[keyIndex]
    }

    const reduceEnumValue = (item: EnumArrayItem) => item.value

    const reduceEnumLabel = (obj: object) => (item: EnumArrayItem) => {
        return getEnumLabel(obj, item.value, false)
    }

    return {
        mapEnumToArray,
        reduceEnumValue,
        getEnumLabel,
        reduceEnumLabel,
    }
}
