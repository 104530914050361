<template>
    <th
        class="w-px whitespace-nowrap bg-white px-2 py-3 first:sticky first:left-0 first:pl-5 last:sticky last:right-0 last:pr-5"
    >
        <div class="flex items-center">
            <label class="inline-flex">
                <span class="sr-only">{{ $t('select_all') }}</span>
                <input
                    class="form-checkbox"
                    type="checkbox"
                    :checked="selected.length === items.length"
                    @click="checkAll"
                />
            </label>
        </div>
    </th>
</template>
<script setup lang="ts">
import { useVModels } from '@vueuse/core'

const emit = defineEmits(['update:items', 'update:selected'])

const props = withDefaults(
    defineProps<{
        itemKey: boolean | string
        items: unknown[]
        selected: unknown[]
    }>(),
    {
        itemKey: false,
    }
)

const { items, selected } = useVModels(props, emit)

function checkAll() {
    if (selected.value.length === items.value.length) {
        selected.value = []
    } else {
        if (typeof props.itemKey === 'string') {
            // @ts-ignore - we have the type check above
            selected.value = items.value.map((item) => item[props.itemKey])
        } else {
            selected.value = items.value
        }
    }
}
</script>
