import { Modal } from '@src/components'
import { ObjectWithStringKeysAndStringValue } from '@src/types/ObjectWithStringKeysAndStringValue'
import { i18n } from '@src/utils/lang'
import { h, Ref, ref } from 'vue'

const { t } = i18n.global

interface ModalComponentProps {
    id: string
    open: boolean
    title: string
    width?: 'lg' | 'xl' | '2xl'
    onSubmit?: VoidFunction
    onCancel?: VoidFunction
}

interface ModalProps extends Omit<ModalComponentProps, 'open'> {
    open: Ref<boolean> | unknown
}

interface ModalSlots {
    default: VoidFunction
    actions?: VoidFunction
}
interface ModalStyles {
    submitButton?: ModalButtonStyles
}

interface ModalOptions {
    submitButtonText?: string
    styles?: ModalStyles
    submitButtonDisabled?: Ref<boolean> | unknown
}

export interface OpenModal {
    (
        props: ModalProps,
        content: VoidFunction,
        options?: ModalOptions | null
    ): void
}

export enum ModalButtonStyles {
    WHITE = 'white',
    RED = 'red',
    BLUE = 'blue',
    DISABLED = 'disabled',
}

const BUTTON_STYLES: ObjectWithStringKeysAndStringValue = {
    white: 'btn-sm border-gray-200 hover:border-gray-300 text-gray-600',
    red: 'btn-sm bg-danger-500 hover:bg-danger-600 text-white',
    blue: 'btn-sm bg-primary-additional-500 text-white hover:bg-primary-additional-600',
    disabled: 'btn-sm bg-primary-additional-500 text-white bg-gray-300',
}

const DEFAULT_SUBMIT_BUTTON_TEXT = 'proceed'

export function useModal() {
    const modalProps = ref<ModalProps | null>(null)
    const modalSlots = ref<ModalSlots | null>(null)
    const modalOptions = ref<ModalOptions | null>(null)

    const ModalWrapper = () => {
        if (modalProps.value && modalSlots.value) {
            return h(
                Modal,
                modalProps.value as ModalComponentProps,
                modalSlots.value
            )
        }
    }

    const getSubmitButtonStyle = () => {
        if (modalOptions.value?.submitButtonDisabled) {
            return BUTTON_STYLES[ModalButtonStyles['DISABLED']]
        }
        if (modalOptions.value?.styles?.submitButton) {
            return BUTTON_STYLES[modalOptions.value?.styles?.submitButton]
        }
        return BUTTON_STYLES[ModalButtonStyles['WHITE']]
    }

    const openModal: OpenModal = (
        props: ModalProps,
        content: VoidFunction,
        options: ModalOptions | null = null
    ) => {
        modalProps.value = props
        modalSlots.value = {
            default: content,
        }
        modalOptions.value = options
        if (props.onSubmit) {
            modalSlots.value.actions = () =>
                h(
                    'button',
                    {
                        type: 'submit',
                        class: getSubmitButtonStyle(),
                        disabled: modalOptions.value?.submitButtonDisabled,
                    },
                    t(options?.submitButtonText ?? DEFAULT_SUBMIT_BUTTON_TEXT)
                )
        }
    }

    return {
        ModalWrapper,
        openModal,
    }
}
