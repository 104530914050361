<template>
    <div>
        <p class="font-bold">{{ $t('name') }}:</p>
        <p>{{ modelValue.risk_title }}</p>
        <p class="mt-2 font-bold">{{ $t('language') }}:</p>
        <p>{{ language }}</p>
        <p class="mt-2 font-bold">{{ $t('countries_applied') }}:</p>
        <p v-if="modelValue.countries_applied">
            <span
                v-for="(country, index) in modelValue.countries_applied"
                :key="`country${index}`"
            >
                {{ getCountryName(country)
                }}<span v-if="index < modelValue.countries_applied?.length - 1"
                    >,&nbsp;</span
                >
            </span>
        </p>
        <p class="mt-2 font-bold">{{ $t('countries_not_applied') }}:</p>
        <p v-if="modelValue.countries_not_applied">
            <span
                v-for="(country, index) in modelValue.countries_not_applied"
                :key="`country${index}`"
            >
                {{ getCountryName(country)
                }}<span
                    v-if="index < modelValue.countries_not_applied?.length - 1"
                    >,&nbsp;</span
                >
            </span>
        </p>
        <p class="mt-2 font-bold">{{ $t('content') }}:</p>
        <div v-html-safe="modelValue.risk_content"></div>
    </div>
</template>
<script setup lang="ts">
import { PlatformDisclaimer } from '@src/types'
import { ref, onMounted, toRefs, computed } from 'vue'
import { useLanguageStore } from '@src/utils/stores/languages'
import { useCountryStore } from '@src/utils/stores/countries'

const props = defineProps<{
    modelValue: PlatformDisclaimer
}>()

const { modelValue } = toRefs(props)

const languageStore = useLanguageStore()
const countryStore = useCountryStore() // Assume you have this
const languages = ref<{ label: string; value: string | null }[]>([])
const countries = ref<{ label: string; value: string | null }[]>([])

const language = computed(() => {
    return languages.value.find(
        (item) => item.value === modelValue.value.language
    )?.label
})

function getCountryName(countryId: string) {
    return countries.value.find((country) => country.value === countryId)?.label
}

onMounted(async () => {
    countries.value = await countryStore.loadAndSortCountriesOptionsAsync()
    languages.value = await languageStore.loadAndSortLanguagesOptionsAsync()
})
</script>
