<template>
    <div>
        {{ language }}
    </div>
</template>
<script setup lang="ts">
import { i18n } from '@src/utils/lang'
import { useLanguageStore } from '@src/utils/stores/languages'
import { onMounted, ref, watch } from 'vue'

const languageStore = useLanguageStore()

const props = defineProps<{
    rowData: Record<string, unknown>
}>()

const language = ref<string | null>(null)

async function translateLanguage() {
    language.value = await languageStore.translate(
        props.rowData.language as string
    )
}

watch(i18n.global.locale, async () => {
    translateLanguage()
})

onMounted(async () => {
    translateLanguage()
})
</script>
