import axios from 'axios'
import saveAs from 'file-saver'

export function downloadFile(url: string) {
    const urlSplit = url.split('/')
    let filename = urlSplit[urlSplit.length - 1]
    filename = filename.slice(0, filename.indexOf('?'))

    axios.get(url, { responseType: 'blob' }).then((response) => {
        saveAs(response.data, filename)
    })
}
