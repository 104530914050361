<template>
    <TableRow>
        <template v-for="(row, columnIndex) in columns">
            <TableRowItemSelect
                v-if="row.select && rowData[row.key]"
                :id="(rowData[row.key] as string)"
                :key="`${id}-row-select-${columnIndex}`"
                v-model="selected"
            />
            <TableRowItem
                v-else
                :key="`${id}-row-${columnIndex}`"
                v-bind="{
                    url: row.url ? row.url(rowData) : null,
                    targetBlank: row.targetBlank,
                    center: row.center,
                    copy: row.copy,
                    copyText: rowData[row.key] as string,
                }"
            >
                <template v-if="row.cellRenderer">
                    <component
                        :is="row.cellRenderer"
                        v-bind="{
                            rowData: rowData,
                        }"
                    />
                </template>
                <template v-else>
                    {{ !!rowData[row.key] ? rowData[row.key] : '-' }}
                </template>
            </TableRowItem>
        </template>
        <TableRowItemActions
            v-if="actions"
            :actions="actions"
            :row-data="rowData"
            :expands="expands"
            :row-index="rowIndex"
            :expand-action="expandAction"
        />
    </TableRow>
    <TableRow
        v-if="expands && expands[rowIndex] && expands[rowIndex].isExpanded"
    >
        <component
            :is="expand"
            v-bind="{
                rowData: rowData,
            }"
        />
    </TableRow>
</template>

<script setup lang="ts">
import {
    RowExpandAction,
    RowExpandState,
    TableAction,
    TableColumn,
} from '@composables/common'
import {
    TableRow,
    TableRowItem,
    TableRowItemActions,
    TableRowItemSelect,
} from '@src/components'
import { useVModels } from '@vueuse/core'
import type { Component } from 'vue'

const props = withDefaults(
    defineProps<{
        id?: string | null
        rowIndex: number
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        rowData: Record<string, any>
        columns: TableColumn[]
        selected?: string[]
        expand?: Component | null
        expands?: RowExpandState[] | []
        actions?: TableAction[] | null
        expandAction?: RowExpandAction | null
    }>(),
    {
        id: null,
        selected: () => [],
        actions: null,
        expand: () => null,
        expands: () => [],
        expandAction: null,
    }
)

const emit = defineEmits(['update:selected'])

const { columns, expand, expands, expandAction, id, selected } = useVModels(
    props,
    emit
)
</script>

<style scoped></style>
