import axios from 'axios'
import { api, ApiRes } from '@src/utils/api/client'

export interface UploadFileResponse {
    key: string
    upload_url: string
    temp_storage_url: string
}

interface UploadFileResponseKYC {
    status: boolean
    temp_storage_url: string
    mime_type: string
    key: string
}

interface UploadFileResponseECSP {
    status: boolean
    temp_storage_url: string
    mime_type: string
    key: string
}

export const uploadPublic = (file: File): ApiRes<UploadFileResponse> => {
    return api
        .post('manage/cdn/upload_url', { filename: file.name })
        .then(async (response) => {
            await axios.put(
                response.data.upload_url,
                await file.arrayBuffer(),
                {
                    headers: {
                        'Content-Type': file.type,
                    },
                }
            )
            return response
        })
}

export const uploadPrivate = (file: File): ApiRes<UploadFileResponse> => {
    return api
        .post('manage/cdn/private_upload_url', { filename: file.name })
        .then(async (response) => {
            await axios.put(
                response.data.upload_url,
                await file.arrayBuffer(),
                {
                    headers: {
                        'Content-Type': file.type,
                    },
                }
            )
            return response
        })
}

export const uploadKYC = (file: File): Promise<UploadFileResponseKYC> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = function () {
            if (typeof reader.result === 'string') {
                const dataUrl = reader.result
                const base64 = dataUrl.split(',')[1]
                const binary = atob(base64)
                const array = new Uint8Array(binary.length)
                for (let i = 0; i < binary.length; i++) {
                    array[i] = binary.charCodeAt(i)
                }
                api.post('manage/kyc_documents/upload', { image: dataUrl })
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(reject)
            } else {
                reject(new Error('Failed to read file as Data URL'))
            }
        }
        reader.readAsDataURL(file)
    })
}

export const uploadECSP = (file: File): Promise<UploadFileResponseECSP> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = function () {
            if (typeof reader.result === 'string') {
                const dataUrl = reader.result
                const base64 = dataUrl.split(',')[1]
                const binary = atob(base64)
                const array = new Uint8Array(binary.length)
                for (let i = 0; i < binary.length; i++) {
                    array[i] = binary.charCodeAt(i)
                }
                api.post('compliance_profiles/upload', {
                    image: dataUrl,
                    file_name: file.name,
                })
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(reject)
            } else {
                reject(new Error('Failed to read file as Data URL'))
            }
        }
        reader.readAsDataURL(file)
    })
}
