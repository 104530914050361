import { ToastNotificationType } from '@src/types'
import { useToastStore } from '@stores/toast'
import { useLoadingStore } from '@stores/loading'
import axios, { AxiosResponse } from 'axios'
import { getKeycloakAccessToken } from '@src/utils/auth'

export const api = axios.create({
    baseURL: getApiBaseUrl(),
    timeout: 100000,
})

export function getApiBaseUrl() {
    return import.meta.env.VITE_API_ENDPOINT
}

export type ApiRes<A = unknown> = Promise<AxiosResponse<A>>

api.interceptors.request.use(
    async (config) => {
        const { setLoading } = useLoadingStore()
        setLoading(true)
        if (config.headers) {
            config.headers.Authorization = `Bearer ${await getKeycloakAccessToken()}`
        } else {
            config.headers = {
                Authorization: `Bearer ${await getKeycloakAccessToken()}`,
            } as never
        }
        return config
    },
    (error) => {
        const { setLoading } = useLoadingStore()
        setLoading(false)
        return Promise.reject(error)
    }
)

// General Laravel Error Message Catching
api.interceptors.response.use(
    (response) => {
        const { setLoading } = useLoadingStore()
        setLoading(false)
        return response
    },
    (error) => {
        const toast = useToastStore()
        const { setLoading } = useLoadingStore()
        setLoading(false)
        if (error?.response?.status === 422) {
            let errors = []

            if (typeof error.response.data.errors === 'object') {
                Object.keys(error.response.data.errors).forEach((key) => {
                    if (typeof error.response.data.errors[key] === 'object') {
                        if (
                            error.response.data.errors[key].field &&
                            error.response.data.errors[key]?.message
                        ) {
                            errors.push({
                                message: `${error.response.data.errors[key].field}: ${error.response.data.errors[key]?.message}`,
                            })
                        } else {
                            Object.values(
                                error.response.data.errors[key]
                            ).forEach((value) => {
                                errors.push({ message: value })
                            })
                        }
                    } else {
                        errors.push({
                            message: `${key}: '${error.response.data.errors[key]}`,
                        })
                    }
                })
            } else {
                errors = error.response.data.errors
            }

            const message = errors
                .map((error: { message: string | string[] }) => {
                    return error.message
                })
                .join('\n')

            toast.push({
                type: ToastNotificationType.ERROR,
                message: `Validation Error: ${message}`,
            })
        } else {
            const message =
                error.response?.data?.message ??
                error.response?.data?.errorMessage

            const needsConfirm = error.response?.data?.needs_confirm

            if (!needsConfirm) {
                toast.push({
                    type: ToastNotificationType.ERROR,
                    message: `General error: ${message}`,
                })
            }
        }

        return Promise.reject(error)
    }
)
