import { useEnum, useSummary } from '@composables/common'
import {
    IssuerIndustry,
    IssuerInternalStatus,
    IssuerStatus,
} from '@enums/Issuer'
import { Issuer } from '@src/types'
import { SummaryItem } from '@src/types/Summary'
import { Ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
const { getEnumLabel } = useEnum()

export function useIssuerSummary(issuer: Ref<Issuer>) {
    const i18n = useI18n()

    const items = computed<SummaryItem[]>(() => {
        return [
            {
                label: i18n.t('id'),
                value: issuer.value.id,
                special: 'link',
                linkURL: `/issuers/${issuer.value.id}`,
                copy: true,
            },
            {
                label: i18n.t('name'),
                value: issuer.value.name,
                special: 'link',
                linkURL: `/issuers/${issuer.value.id}`,
                copy: true,
            },
            {
                label: i18n.t('status'),
                value: getEnumLabel(IssuerStatus, issuer.value.status),
            },
            {
                label: i18n.t('internal_status'),
                value: getEnumLabel(
                    IssuerInternalStatus,
                    issuer.value.internal_status
                ),
            },
            {
                label: i18n.t('issuer_logo'),
                value: issuer.value.company_logo?.thumb_image_url ?? null,
                special: 'image',
            },
            {
                label: i18n.t('issuer_industry'),
                value: getEnumLabel(IssuerIndustry, issuer.value.industry),
            },
        ]
    })

    const { Summary: IssuerSummary } = useSummary(items, {
        title: 'issuer',
    })

    return {
        IssuerSummary,
    }
}
