export enum IssuerStatus {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
    IN_LIQUIDATION = 'in_liquidation',
    INSOLVENT = 'insolvent',
    BUSINESS_LICENSES_DELETED = 'business_licenses_deleted',
}

export enum IssuerInternalStatus {
    ACTIVE = 'active',
    UNDER_OBSERVATION = 'under_observation',
    INACTIVE = 'inactive',
}

export enum IssuerIndustry {
    AGRICULTURE = 'agriculture',
    ENERGY_AND_UTILITIES = 'energy_and_utilities',
    MINING = 'mining',
    CONSTRUCTION = 'construction',
    INDUSTRY = 'industry',
    TRADE_AND_RETAIL = 'trade_and_retail',
    HOSPITALITY_AND_TOURISM = 'hospitality_and_tourism',
    TRANSPORTATION_AND_LOGISTICS = 'transportation_and_logistics',
    FINANCE_AND_INSURANCE = 'finance_and_insurance',
    INFORMATION_TECHNOLOGY = 'information_technology',
    HEALTHCARE = 'healthcare',
    EDUCATION_AND_RESEARCH = 'education_and_research',
}
