import { EmailAudit, EmailTemplate, TableSorting } from '@src/types'
import { api, ApiRes } from '@src/utils/api/client'
import { AxiosRequestConfig } from 'axios'

export interface EmailsResponse {
    success: string
    email_audits: EmailAudit[]
    email_audits_count: number
}

export interface EmailTemplatesResponse {
    email_templates: EmailTemplate[]
}

export const getEmails = (
    filters?: Record<string, unknown>,
    page?: number,
    limit?: number,
    sorting?: TableSorting[]
): ApiRes<EmailsResponse> => {
    const config: AxiosRequestConfig = {}

    config.params = {}

    if (filters && Object.keys(filters).length) {
        config.params = filters
    }

    if (typeof page === 'number' && typeof limit === 'number') {
        config.params['range'] = page * limit - limit + '-' + page * limit
    }

    if (sorting && sorting.length) {
        config.params['sort'] = sorting
            .map((sort) => {
                return (sort.direction === 'desc' ? '-' : '') + sort.field
            })
            .join(',')
    }

    return api.get('manage/email-audits', config)
}

export const getEmailTemplates = (): ApiRes<EmailTemplatesResponse> =>
    api.get('v2/manage/platforms/email-templates')
