<template>
    <div
        ref="searchBar"
        class="absolute left-0 top-14 z-50 hidden w-full max-w-lg px-4 lg:relative lg:top-0 lg:inline-flex lg:px-0"
        :class="{
            '!block': mobileSearchOpen,
        }"
    >
        <div
            class="w-full rounded border border-gray-200 bg-white p-4 shadow-lg lg:border-0 lg:p-0 lg:shadow-none"
        >
            <div
                class="flex w-full items-center rounded rounded-sm border border-gray-300 px-2"
            >
                <span class="sr-only">{{ $t('search') }}</span>
                <fa icon="fa-magnifying-glass" />
                <input
                    ref="searchInput"
                    v-model="searchInputValue"
                    name="search"
                    class="w-full appearance-none border-0 p-2 placeholder-gray-400 focus:ring-transparent"
                    type="search"
                    autocomplete="off"
                    :placeholder="searchInputPlaceholder"
                    @focusin="searchInputOnFocus"
                />
            </div>
            <div
                class="relative top-full mt-2 hidden max-h-[50vh] w-full max-w-lg overflow-y-auto rounded rounded-sm border-0 bg-white px-4 py-2 lg:absolute lg:mt-0 lg:border lg:border-gray-300 lg:p-4 lg:shadow-lg"
                :class="{
                    '!block':
                        (resultsOpen && !noResults) ||
                        (resultsOpen &&
                            noResults &&
                            search &&
                            search.length > 2),
                }"
            >
                <SearchResultsDropdown />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useSearch } from '@stores/search'
import { storeToRefs } from 'pinia'
import {
    computed,
    nextTick,
    onMounted,
    onUnmounted,
    ref,
    toRefs,
    watch,
} from 'vue'
import { useI18n } from 'vue-i18n'
import SearchResultsDropdown from './SearchResultsDropdown.vue'

const props = defineProps<{
    mobileSearchOpen: boolean
}>()

const emit = defineEmits(['toggle-mobile-search'])

const { mobileSearchOpen } = toRefs(props)

const i18n = useI18n()

const searchBar = ref<HTMLElement | null>(null)
const searchInput = ref<HTMLElement | null>(null)
const searchInputPlaceholder = ref<string>(i18n.t('global_search_placeholder'))
const resultsOpen = ref<boolean>(false)
const searchStore = useSearch()

const { search, noResults } = storeToRefs(searchStore)

const searchInputValue = computed({
    get() {
        return search.value as string
    },
    set(value: string) {
        searchStore.setSearch(value)
    },
})

function searchInputOnFocus() {
    searchInputPlaceholder.value = i18n.t('help_search_field')
    resultsOpen.value = true
}

// close on click outside
const clickHandler = (event: MouseEvent) => {
    const target = event.target
    if (searchBar.value?.contains(target as Node)) {
        return
    }
    resultsOpen.value = false
    searchInputPlaceholder.value = i18n.t('global_search_placeholder')
    if (mobileSearchOpen.value) {
        emit('toggle-mobile-search')
    }
}

const keyHandler = (event: KeyboardEvent) => {
    if (event.code !== 'Escape') return
    if (mobileSearchOpen.value) {
        emit('toggle-mobile-search')
    }
}

watch(mobileSearchOpen, (value) => {
    if (value) {
        nextTick(() => {
            searchInput.value?.focus()
        })
    }
})

onMounted(() => {
    document.addEventListener('click', clickHandler)
    document.addEventListener('keydown', keyHandler)
    if (search.value) {
        searchStore.setSearch(search.value)
    }
})

onUnmounted(() => {
    document.removeEventListener('click', clickHandler)
    document.removeEventListener('keydown', keyHandler)
})
</script>
