import { SummaryOptions, useEnum, useSummary } from '@composables/common'
import { ContractPeriodType, ContractType } from '@src/enums'
import { Contract } from '@src/types'
import { SummaryItem } from '@src/types/Summary'
import { Ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'

export function useContractSummary(
    contract?: Ref<Contract>,
    options?: SummaryOptions
) {
    const i18n = useI18n()
    const { getEnumLabel } = useEnum()

    const items = computed<SummaryItem[]>(() => {
        if (!contract?.value) return [] as SummaryItem[]
        return [
            {
                label: i18n.t('id'),
                value: contract.value.contract_uid,
                special: 'link',
                linkURL: `/contracts/${contract.value.contract_uid}`,
                copy: true,
            },
            {
                label: i18n.t('contract'),
                value: getEnumLabel(ContractType, contract.value.contract_type),
                special: 'link',
                linkURL: `/contracts/${contract.value.contract_uid}`,
                copy: true,
            },
            {
                label: i18n.t('interest_rate'),
                value: contract.value.interest_rate + ' %',
            },
            {
                label: i18n.t('bullet_loan'),
                value: contract.value.is_bullet_loan,
                special: 'check',
            },
            {
                label: i18n.t('period'),
                value: contract.value.contract_period_type
                    ? getEnumLabel(
                          ContractPeriodType,
                          contract.value.contract_period_type
                      )
                    : '',
            },
            {
                label: i18n.t('orders'),
                value: contract.value.orders_count,
            },
        ]
    })

    const { Summary: ContractSummary } = useSummary(items, {
        ...{
            title: 'contract',
        },
        ...options,
    })

    return {
        ContractSummary,
    }
}
