<template>
    <li
        class="mb-0.5 rounded-sm px-3 py-2 last:mb-0"
        :class="{ 'bg-primary-800': expanded }"
    >
        <a
            class="block truncate text-gray-200 transition duration-150 hover:text-white"
            :class="{ 'hover:text-gray-200': expanded }"
            href="#0"
            @click.prevent="handleClick()"
        >
            <div class="flex items-center justify-between">
                <div class="flex items-center">
                    <div class="flex h-6 w-6 items-center justify-center">
                        <slot name="icon" />
                    </div>
                    <span
                        class="lg:sidebar-active:opacity-100 ml-3 text-sm font-medium duration-200"
                        >{{ $t(label) }}</span
                    >
                </div>
                <!-- Icon -->
                <div class="ml-2 flex shrink-0">
                    <svg
                        class="ml-1 h-3 w-3 shrink-0 fill-current text-gray-400"
                        :class="expanded && 'rotate-180 transform'"
                        viewBox="0 0 12 12"
                    >
                        <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                    </svg>
                </div>
            </div>
        </a>
        <div
            class="lg:sidebar-active:block"
            :class="{
                'hidden': !sidebarOpen,
            }"
        >
            <ul
                class="mt-1 pl-9"
                :class="{ 'hidden': !expanded }"
            >
                <slot />
            </ul>
        </div>
    </li>
</template>

<script setup lang="ts">
import { onMounted, ref, toRefs } from 'vue'

const emit = defineEmits(['toggle-sidebar'])

const props = defineProps({
    label: {
        type: String,
        required: true,
    },
    sidebarOpen: {
        type: Boolean,
        required: true,
    },
    keepOpen: {
        type: Boolean,
        default: false,
    },
})

const { sidebarOpen, keepOpen } = toRefs(props)

const expanded = ref<boolean>(false)

const handleClick = () => {
    if (!sidebarOpen.value) {
        emit('toggle-sidebar')
        expanded.value = true
    } else {
        expanded.value = !expanded.value
    }
}

onMounted(() => {
    if (keepOpen.value) {
        handleClick()
    }
})
</script>
