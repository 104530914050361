<template>
    <div>
        <TextField
            v-model="modelValue.incentives_title"
            name="campaign_incentive_title"
            label="campaign_incentive_title"
            help="help_campaign_incentive_title"
            :required="true"
        />
        <NumberField
            v-model="modelValue.incentives_amount"
            name="campaign_incentive_amount"
            label="campaign_incentive_amount"
            help="help_campaign_incentive_amount"
            :required="true"
            :step="0.01"
        />
        <EditorField
            v-model="modelValue.incentives_description"
            name="campaign_incentive_description"
            label="campaign_incentive_description"
            help="help_campaign_incentive_description"
            :required="true"
        />
    </div>
</template>

<script setup lang="ts">
import { AssetsIncentive } from '@src/types'

import { EditorField, NumberField, TextField } from '@src/components'
import { Ref, toRefs, watch } from 'vue'

const props = defineProps<{
    modelValue: Ref<AssetsIncentive>
}>()

const { modelValue } = toRefs(props)

const emit = defineEmits(['update:modelValue'])

watch(modelValue, (value) => {
    emit('update:modelValue', value)
})
</script>

<style lang="scss" scoped></style>
