<template>
    <CampaignsTable>
        <template #actions>
            <DeleteBulkButton
                v-if="userStore.can('delete_campaign')"
                :selected="selected"
                @click.stop="openBulkDeleteCampaignsModal"
            />

            <CreateButton
                v-if="userStore.can('create_campaign')"
                @click.stop="openCreateCampaignModal"
            />
        </template>
        <template #filters>
            <DropdownFilter
                :pin="pinFilters"
                @apply="applyFilters"
                @clear="clearFilters"
                @toggle-pin="togglePinFilters"
            >
                <DropdownFilterItem v-if="userStore.can('filter_by_id')">
                    <label class="flex items-center">
                        <TextField
                            v-model="filtersFields.asset_var_uid"
                            name="campaign_id"
                            :placeholder="$t('campaign_id')"
                        />
                    </label>
                </DropdownFilterItem>
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <SelectField
                            v-model="filtersFields.asset_uid"
                            v-model:options="assetSelectOptions"
                            name="asset_uid"
                            class="w-full"
                            :disabled="disableFilter('asset_uid')"
                            :placeholder="$t('asset')"
                            :on-search="loadAssetSelectOptions"
                        />
                    </label>
                </DropdownFilterItem>
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <TextField
                            v-model="filtersFields.asset_title"
                            name="campaign"
                            :disabled="disableFilter('asset_title')"
                            :placeholder="$t('campaign')"
                        />
                    </label>
                </DropdownFilterItem>
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <SelectField
                            v-model="filtersFields.platform_name_exact"
                            v-model:options="platformsSelectOptions"
                            name="platform_name"
                            class="w-full"
                            :placeholder="$t('platform')"
                            :disabled="disableFilter('platform_name_exact')"
                            :on-search="loadPlatformsSelectOptions"
                        />
                    </label>
                </DropdownFilterItem>
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <SelectField
                            v-model="filtersFields.language"
                            name="language"
                            class="w-full"
                            :options="languages"
                            :placeholder="$t('language')"
                            :disabled="disableFilter('language')"
                            :reduce="reduceEnumValue"
                        />
                    </label>
                </DropdownFilterItem>
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <SelectField
                            v-model="filtersFields.country"
                            name="countries"
                            class="w-full"
                            :options="countries"
                            :disabled="disableFilter('country')"
                            :placeholder="$t('country')"
                        />
                    </label>
                </DropdownFilterItem>
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <SelectField
                            v-model="filtersFields.status"
                            name="status"
                            class="w-full"
                            :options="mapEnumToArray(CampaignStatus)"
                            :reduce="reduceEnumValue"
                            :disabled="disableFilter('status')"
                            :placeholder="$t('status')"
                        />
                    </label>
                </DropdownFilterItem>
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <Datepicker
                            v-model="filtersFields.created_on"
                            placeholder="created_on"
                            name="filter_created_on"
                        />
                    </label>
                </DropdownFilterItem>
            </DropdownFilter>
        </template>
    </CampaignsTable>
    <ModalWrapper v-if="isModalOpen" />
    <CreateCampaignModal
        v-if="createCampaignModalOpen"
        ref="createCampaignModal"
        v-model="tempCampaign"
        v-model:open="createCampaignModalOpen"
        :disabled-fields="createDisabledFields"
        @submit="createCampaign"
        @cancel="closeCreateCampaignModal"
    />

    <DeleteItemModal
        id="delete-campaign-modal"
        ref="deleteCampaignModal"
        v-model="tempCampaign"
        v-model:open="deleteCampaignModalOpen"
        title="modal_title_delete_campaign"
        name-key="asset_asset_title"
        id-key="asset_var_uid"
        @submit="deleteCampaign"
        @cancel="closeDeleteCampaignModal"
    />
    <DeleteItemsModal
        id="delete-campaigns-modal"
        ref="deleteCampaignsModal"
        v-model="campaignsToDelete"
        v-model:open="deleteCampaignsModalOpen"
        title="modal_title_delete_campaigns"
        name-key="asset_asset_title"
        id-key="asset_var_uid"
        @submit="bulkDeleteCampaigns"
        @cancel="closeDeleteCampaignsModal"
    />
</template>

<script setup lang="ts">
import { useEnum, useTable } from '@composables/common'
import DuplicateCampaignForm from '@partials/campaigns/DuplicateCampaignForm.vue'
import PlatformNameCellRenderer from '@partials/platforms/PlatformNameCellRenderer.vue'
import CountryColumnCellRenderer from '@partials/tables/CountryColumnCellRenderer.vue'
import LanguageColumnCellRenderer from '@partials/tables/LanguageColumnCellRenderer.vue'
import {
    Datepicker,
    DeleteItemModal,
    DeleteItemsModal,
    DropdownFilter,
    DropdownFilterItem,
    SelectField,
    TextField,
} from '@src/components'
import { ModalButtonStyles, useModal } from '@src/composables/common'
import { CampaignStatus } from '@src/enums'
import CreateButton from '@src/partials/actions/CreateButton.vue'
import DeleteBulkButton from '@src/partials/actions/DeleteBulkButton.vue'
import CreateCampaignModal from '@src/partials/campaigns/CreateCampaignModal.vue'
import {
    Asset,
    Campaign,
    DuplicatedCampaign,
    Platform,
    TableSorting,
} from '@src/types'
import { getAsset, getAssets } from '@src/utils/api/assets'
import {
    createCampaign as apiCreateCampaign,
    deleteCampaign as apiDeleteCampaign,
    deleteCampaigns as apiDeleteCampaigns,
    duplicateCampaign as apiDuplicateCampaign,
    getCampaigns,
} from '@src/utils/api/campaigns'
import { getCountries } from '@src/utils/api/countries'
import { getLanguages } from '@src/utils/api/languages'
import { getPlatforms } from '@src/utils/api/platforms'
import { useToastStore } from '@src/utils/stores/toast'
import { useUserStore } from '@src/utils/stores/user'
import moment from 'moment'
import { computed, h, onMounted, ref, shallowRef, toRefs } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

const props = withDefaults(
    defineProps<{
        initialFilters?: {
            asset_asset_title?: string | null
            asset_title?: string | null
            asset_uid?: string | null
            platform_name_exact?: string | null
            country?: string | null
            status?: string | null
            created_on?: string | null
        } | null
        createInitialValues?: Campaign | null
        createDisabledFields?: string[] | null
    }>(),
    {
        initialFilters: null,
        createInitialValues: null,
        createDisabledFields: null,
    }
)

const { initialFilters, createInitialValues, createDisabledFields } =
    toRefs(props)

const router = useRouter()
const toast = useToastStore()
const i18n = useI18n()
const { mapEnumToArray, reduceEnumValue } = useEnum()

const campaigns = ref<Campaign[]>([])
const campaignsCount = ref<number>(0)
const languages = ref<{ label: string; value: string | null }[]>([])
const countries = ref<{ label: string; value: string | null }[]>([])

const { ModalWrapper, openModal } = useModal()
const isModalOpen = ref(false)

const userStore = useUserStore()
const { getEnumLabel } = useEnum()

const disableFilter = (filter: string) => {
    if (initialFilters.value) {
        return (
            Object.keys(initialFilters.value).filter((item) => {
                return item === filter
            }).length > 0
        )
    }
    return false
}

const {
    TableWrapper: CampaignsTable,
    selected,
    limit,
    sorting,
    page,
    filtersFields,
    filters,
    pinFilters,
    togglePinFilters,
    setFilters,
    applyFilters,
    clearFilters,
} = useTable({
    loadData: loadCampaigns,
    filters: {
        asset_var_uid: null,
        asset_asset_title: null,
        asset_uid: null,
        asset_title: null,
        platform_name_exact: null,
        language: null,
        country: null,
        status: null,
        created_on: null,
        search: null,
    },
    disableFilters: initialFilters.value
        ? Object.keys(initialFilters.value)
        : [],
    tableProps: {
        id: 'campaigns-table',
        label: 'campaigns',
        help: 'help_campaigns_table',
        pagination: true,
        data: campaigns,
        total: campaignsCount,
        sorting: ref<TableSorting[]>([
            {
                field: 'created_on',
                direction: 'desc',
            },
        ]),
        columns: [
            {
                label: 'id',
                key: 'asset_var_uid',
                select: true,
            },
            {
                label: 'id',
                key: 'asset_var_uid',
                url: campaignsTableUrl,
                sorting: true,
                condition: userStore.can('see_id_columns'),
                copy: true,
            },
            {
                label: 'name',
                key: 'asset_title',
                url: campaignsTableUrl,
                sorting: true,
                copy: true,
            },
            {
                label: 'asset',
                key: 'asset_asset_title',
                url: assetsTableUrl,
                sorting: true,
                copy: true,
            },
            {
                label: 'platform',
                key: 'platform_name',
                cellRenderer: shallowRef(PlatformNameCellRenderer),
                sorting: true,
            },
            {
                label: 'language',
                key: 'language',
                sorting: true,
                cellRenderer: shallowRef(LanguageColumnCellRenderer),
            },
            {
                label: 'country',
                key: 'country',
                sorting: true,
                cellRenderer: shallowRef(CountryColumnCellRenderer),
            },
            {
                label: 'status',
                key: 'status',
                sorting: true,
                cellRenderer: (props: { rowData: Campaign }) => {
                    return getEnumLabel(CampaignStatus, props.rowData.status)
                },
            },
            {
                label: 'created_on',
                key: 'created_on',
                sorting: true,
                cellRenderer: (props: { rowData: Campaign }) => {
                    return moment(props.rowData.created_on).format('DD.MM.yyyy')
                },
            },
        ],
        actions: [
            {
                action: handleViewCampaign,
                icon: 'fa-eye',
                title: 'view',
                condition: userStore.can('view_campaigns'),
            },
            {
                action: handleEditCampaign,
                icon: 'fa-solid fa-pen',
                title: 'edit',
                condition: userStore.can('update_campaign'),
            },
            {
                action: handleDuplicateCampaign,
                icon: 'fa-copy',
                title: 'duplicate',
                condition: userStore.can('update_campaign'),
            },
            {
                action: handleDeleteCampaign,
                icon: 'fas fa-trash-alt',
                title: 'delete',
                color: 'text-danger-500',
                condition: userStore.can('delete_campaign'),
            },
        ],
    },
})

const tempCampaign = ref<Campaign>(new Campaign())
const createCampaignModalOpen = ref(false)
const deleteCampaignModalOpen = ref(false)
const deleteCampaignsModalOpen = ref(false)

const assetSelectOptions = ref<{ label: string; value: string | null }[]>([])
const platformsSelectOptions = ref<string[]>([])

function handleDuplicateCampaign(campaign: Campaign) {
    isModalOpen.value = true
    const duplicatedCampaign = ref<DuplicatedCampaign>(new DuplicatedCampaign())

    const disableCreate = computed(() => {
        return !(
            duplicatedCampaign.value.asset_title &&
            duplicatedCampaign.value.platform_name
        )
    })

    openModal(
        {
            id: 'duplicate_campaign',
            title: 'modal_title_duplicate_campaign',
            open: isModalOpen,
            onCancel: () => (isModalOpen.value = false),
            onSubmit: () =>
                duplicateCampaign(campaign, duplicatedCampaign.value),
        },
        () =>
            h(DuplicateCampaignForm, {
                modelValue: duplicatedCampaign,
            }),
        {
            submitButtonText: 'duplicate',
            styles: {
                submitButton: ModalButtonStyles['BLUE'],
            },
            submitButtonDisabled: disableCreate,
        }
    )
}

async function duplicateCampaign(
    campaign: Campaign,
    duplicatedCampaign: DuplicatedCampaign
) {
    const duplicateCampaignResponse = await apiDuplicateCampaign(
        campaign.asset_var_uid as string,
        duplicatedCampaign
    )

    const newCampaign = duplicateCampaignResponse.data.campaign

    toast.success(i18n.t('toast_success_campaign_duplicated'))

    await router.push({
        name: 'campaign',
        params: { id: newCampaign.asset_var_uid },
    })
    router.go(0)
}

async function loadAssetSelectOptions(search: string) {
    const assetsRequest = await getAssets({
        'filter[asset_title]': search,
    })
    assetSelectOptions.value = assetsRequest.data.assets.map((asset: Asset) => {
        return {
            label: asset.asset_title as string,
            value: asset.asset_uid as string,
        }
    })
}

async function loadAssetSelectOptionsByUID(uid: string) {
    const assetRequest = await getAsset(uid)
    const asset = assetRequest.data.asset
    assetSelectOptions.value = [
        {
            label: asset.asset_title as string,
            value: asset.asset_uid as string,
        },
    ]
}

async function loadPlatformsSelectOptions(search: string) {
    const initialPlatformsRequest = await getPlatforms({
        'filter[platform_name]': search,
    })
    platformsSelectOptions.value = initialPlatformsRequest.data.platforms.map(
        (platform: Platform) => {
            return platform.platform_name as string
        }
    )
}

async function loadCampaigns() {
    const { data } = await getCampaigns(
        filters.value,
        page.value,
        limit.value,
        sorting.value
    )
    campaigns.value = data.asset_variations
    campaignsCount.value = data.asset_variations_count
    selected.value = []
}

function openCreateCampaignModal() {
    if (createInitialValues.value) {
        tempCampaign.value = {
            ...tempCampaign.value,
            ...createInitialValues.value,
        }
    }
    createCampaignModalOpen.value = true
}

function closeCreateCampaignModal() {
    tempCampaign.value = new Campaign()
    createCampaignModalOpen.value = false
}

function campaignsTableUrl(campaign: Campaign) {
    return '/campaigns/' + (campaign.asset_var_uid as string)
}

function assetsTableUrl(campaign: Campaign) {
    return '/assets/' + (campaign.asset_uid as string)
}

function handleViewCampaign(campaign: Campaign) {
    router.push({
        name: 'campaign',
        params: { id: campaign.asset_var_uid },
    })
}

function handleEditCampaign(campaign: Campaign) {
    router.push({
        name: 'campaign.edit',
        params: { id: campaign.asset_var_uid },
    })
}

async function createCampaign(next?: string) {
    const createCampaignRequest = await apiCreateCampaign(tempCampaign.value)
    const { asset_var_uid } = createCampaignRequest.data.campaign

    createCampaignModalOpen.value = false

    if (next && next == 'edit') {
        router.push({ name: 'campaign.edit', params: { id: asset_var_uid } })
    } else if (next && next == 'view') {
        router.push({ name: 'campaign', params: { id: asset_var_uid } })
    } else {
        page.value = 1
        limit.value = 10
        await loadCampaigns()
    }

    toast.success(i18n.t('toast_success_campaign_created'))
}

const campaignsToDelete = computed(() => {
    return campaigns.value.filter(
        (campaign) =>
            campaign.asset_var_uid &&
            selected.value.includes(campaign.asset_var_uid)
    )
})

function handleDeleteCampaign(campaign: Campaign) {
    tempCampaign.value = campaign
    deleteCampaignModalOpen.value = true
}

function closeDeleteCampaignModal() {
    deleteCampaignModalOpen.value = false
}

function openBulkDeleteCampaignsModal() {
    deleteCampaignsModalOpen.value = true
}

function closeDeleteCampaignsModal() {
    deleteCampaignsModalOpen.value = false
}

async function deleteCampaign(campaign: Campaign) {
    if (!campaign.asset_var_uid) {
        toast.error(i18n.t('toast_error_cannot_delete_campaign'))
        return
    }

    await apiDeleteCampaign(campaign.asset_var_uid)

    deleteCampaignModalOpen.value = false

    tempCampaign.value = new Campaign()

    toast.success(
        i18n.t('toast_success_campaign_deleted', {
            campaign: campaign.asset_asset_title,
        })
    )

    page.value = 1
    await loadCampaigns()
}

async function bulkDeleteCampaigns() {
    await apiDeleteCampaigns(selected.value)

    toast.success(
        i18n.t('toast_success_campaigns_deleted', {
            campaigns: selected.value.join(', '),
        })
    )

    deleteCampaignsModalOpen.value = false

    selected.value = []

    await loadCampaigns()
}

onMounted(async () => {
    if (initialFilters.value) {
        filtersFields.value = {
            ...filtersFields.value,
            ...initialFilters.value,
        }
        if (filtersFields.value.asset_uid) {
            await loadAssetSelectOptionsByUID(
                filtersFields.value.asset_uid as string
            )
        }
        if (filtersFields.value.platform_name_exact) {
            await loadPlatformsSelectOptions(
                filtersFields.value.platform_name_exact
            )
        }
        setFilters()
    }

    await loadCampaigns()

    const countryRequest = await getCountries()
    countries.value = countryRequest.data.countries.map((country) => {
        return { label: country.en, value: country.alpha3 }
    })

    const languageRequest = await getLanguages()
    languages.value = languageRequest.data.languages
})
</script>

<style lang="scss" scoped>
input {
    &:disabled {
        @apply disabled:bg-gray-100;
    }
}
</style>
