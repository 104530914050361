<template>
    <PageWrapper main-class="max-w-9xl">
        <template #headline>
            <fa icon="fa-solid fa-rocket" />&nbsp;{{ campaign.asset_title }}
        </template>

        <template #actions>
            <BackButton
                v-if="$router.options.history.state.back"
                @click="router.go(-1)"
            />
            <BackButton
                v-if="
                    campaign.asset_var_uid &&
                    $router.options.history.state.back !==
                        `/campaigns${campaign.asset_var_uid}`
                "
                icon="fa-eye"
                title="campaign"
                @click="goToDetails"
            />
            <BackButton
                v-if="$router.options.history.state.back !== '/campaigns'"
                icon="fa-list"
                title="campaigns"
                @click="toCampaigns"
            />
            <SaveButton @click="saveCampaign" />
        </template>

        <AccordionBasic
            title="campaign_details"
            class="mb-2"
            :open="true"
        >
            <TextField
                v-model="campaign.asset_title"
                label="campaign"
                name="campaign"
                :required="true"
                help="help_select_campaign"
            />
            <TextField
                v-model="campaign.asset_subtitle"
                label="campaign_subtitle"
                name="campaign_subtitle"
                :required="true"
                help="help_campaign_subtitle"
            />
            <TextField
                v-model="campaign.asset_summary"
                label="campaign_summary"
                name="campaign_summary"
                :rows="4"
                help="help_campaign_summary"
            />
            <SelectField
                v-model="campaign.platform_name"
                v-model:options="platformSelectOptions"
                name="platform_name"
                label="platform"
                help="help_platform_name_campaign"
                :on-search="loadPlatformSelectOptions"
                :required="true"
            />
            <SelectField
                v-model="campaign.asset_uid"
                v-model:options="assetSelectOptions"
                name="asset_title"
                label="asset"
                help="help_asset_title_campaign"
                :on-search="loadAssetSelectOptions"
                :required="true"
            />
            <TextField
                v-model="campaign.asset_excerpt"
                label="campaign_excerpt"
                name="campaign_excerpt"
                :rows="4"
                help="help_campaign_excerpt"
            />
        </AccordionBasic>
        <AccordionBasic
            title="funding_period"
            class="mb-2"
            :open="false"
        >
            <Datepicker
                v-model="campaign.funding_start"
                label="funding_start"
                name="funding_start"
                help="help_funding_start"
                date-format="Y-m-d H:i"
                :enable-time="true"
            />
            <Datepicker
                v-model="campaign.funding_end"
                label="funding_end"
                name="funding_end"
                help="help_funding_end"
                date-format="Y-m-d H:i"
                :enable-time="true"
            />
        </AccordionBasic>
        <AccordionBasic
            title="campaign_status"
            class="mb-2"
            :open="false"
        >
            <SelectField
                v-model="campaign.status"
                name="status"
                label="status"
                help="help_status_campaign"
                :options="mapEnumToArray(CampaignStatus)"
                :reduce="reduceEnumValue"
                :required="true"
            />
            <ToggleSwitch
                v-model="campaign.funding_stats_visible"
                name="funding_stats_visible"
                label="funding_stats_visible"
                help="help_funding_stats_visible"
            />
            <TextField
                v-if="campaign.status === CampaignStatus.PRE_FUNDING"
                v-model="campaign.asset_var_password"
                label="password"
                name="password"
                :required="true"
                type="password"
                help="help_password_campaign"
            />
        </AccordionBasic>
        <AccordionBasic
            title="content"
            class="mb-2"
            :open="false"
        >
            <EditorField
                v-if="campaign.asset_var_uid"
                v-model="campaign.asset_description"
                v-model:placeholders="placeholders"
                name="asset_description"
                label="main"
                help="help_main_campaign"
                :required="false"
            />
            <EditorField
                v-if="campaign.asset_var_uid"
                v-model="campaign.investment_summary"
                v-model:placeholders="placeholders"
                name="investment_summary"
                label="summary"
                help="help_summary_campaign"
                :required="false"
            />
        </AccordionBasic>
        <AccordionBasic
            title="regulatory_requirements"
            class="mb-2"
            :open="false"
        >
            <SelectField
                v-model="campaign.regulatory_requirements"
                name="regulatory_requirements"
                label="regulatory_requirements"
                help="help_regulatory_requirements"
                :options="mapEnumToArray(RegulatoryRequirements)"
                :reduce="reduceEnumValue"
                :required="true"
            />
        </AccordionBasic>
        <AccordionBasic
            title="country_and_language"
            class="mb-2"
            :open="false"
        >
            <SelectField
                v-model="campaign.country"
                name="country"
                label="country"
                help="help_country_campaign"
                :options="countries"
            />
            <SelectField
                v-model="campaign.language"
                name="language"
                label="language"
                help="help_language_campaign"
                :options="languages"
                :required="true"
            />
        </AccordionBasic>
        <AccordionBasic
            title="broker"
            class="mb-2"
            :open="false"
        >
            <ToggleSwitch
                v-model="broker.broker_status"
                name="broker_status"
                label="broker_status"
                help="help_broker_status"
            />
            <ToggleSwitch
                v-if="broker.broker_status"
                v-model="broker.broker_field_required"
                name="broker_field_required"
                label="required_field"
                help="help_broker_field_required"
            />
            <TextField
                v-if="broker.broker_status"
                v-model="broker.broker_disclaimer"
                label="broker_disclaimer"
                name="broker_disclaimer"
                help="help_broker_disclaimer"
            />
        </AccordionBasic>
        <AccordionBasic
            title="external_project_page"
            class="mb-2"
            :open="false"
        >
            <TextField
                v-model="campaign.external_project_url"
                label="external_project_url"
                name="external_project_url"
                :required="false"
                help="help_external_project_url"
            />
        </AccordionBasic>
        <AccordionBasic
            title="badge"
            class="mb-2"
            :open="false"
        >
            <SelectField
                v-model="campaign.badge"
                name="campaign_badge"
                label="campaign_badge"
                help="help_campaign_badge"
                :options="mapEnumToArray(CampaignBadge)"
                :reduce="reduceEnumValue"
            />
        </AccordionBasic>
        <AccordionBasic
            title="checkout"
            class="mb-2"
            :open="false"
        >
            <ToggleSwitch
                v-model="campaign.show_amount_slider"
                name="show_amount_slider"
                label="show_amount_slider"
                help="help_show_amount_slider"
            />
        </AccordionBasic>
        <AccordionBasic
            title="media"
            class="mb-2"
            :open="false"
        >
            <ImageUpload
                v-model="campaign.thumb_image"
                name="campaign_thumb_image"
                label="campaign_thumb_image"
                help="help_campaign_thumb_image"
                type="campaign"
            />
            <ImageUpload
                v-model="campaign.hero_image"
                name="campaign_hero_image"
                label="campaign_hero_image"
                help="help_campaign_hero_image"
                type="campaign"
            />
            <TextField
                v-model="campaign.asset_video"
                label="asset_video"
                name="asset_video"
                :required="false"
                help="help_asset_video"
            />
        </AccordionBasic>
        <AccordionBasic
            v-if="asset.clearing_system === ClearingSystem['EMISSION']"
            title="additional_fields"
            class="mb-2"
            :open="false"
        >
            <ToggleSwitch
                v-model="campaign.additional_fields_enabled"
                name="additional_fields_enabled"
                label="additional_fields"
                help="help_additional_fields_enabled"
                @update:model-value="activateAdditionalFields"
            />
            <div
                v-if="campaign.additional_fields"
                class="mt-4"
            >
                <ToggleSwitch
                    v-model="campaign.additional_fields.depot_owner.required"
                    label="depot_owner"
                    name="depot_owner"
                />
                <ToggleSwitch
                    v-model="campaign.additional_fields.depot_number.required"
                    label="depot_number"
                    name="depot_number"
                />
                <ToggleSwitch
                    v-model="campaign.additional_fields.depot_bank.required"
                    label="depot_bank"
                    name="depot_bank"
                />
                <ToggleSwitch
                    v-model="campaign.additional_fields.depot_bic.required"
                    label="depot_bic"
                    name="depot_bic"
                />
            </div>
        </AccordionBasic>
        <AccordionBasic
            title="agio_disagio"
            class="mb-2"
            :open="false"
        >
            <SelectField
                v-model="campaign.agio_disagio"
                name="agio_disagio"
                label="agio_disagio"
                help="help_agio_disagio"
                :options="mapEnumToArray(AgioDisagio)"
                :reduce="reduceEnumValue"
                :required="true"
            />
            <div v-if="campaign.agio_disagio !== AgioDisagio['DEACTIVATED']">
                <TextField
                    v-model="campaign.agio_disagio_title"
                    label="agio_disagio_title"
                    name="agio_disagio_title"
                    :rows="4"
                    help="help_agio_disagio_title"
                />
                <NumberField
                    v-model="campaign.agio_disagio_rate"
                    label="agio_disagio_rate"
                    name="agio_disagio_rate"
                    help="help_agio_disagio_rate"
                />
                <SelectField
                    v-model="campaign.agio_disagio_type"
                    name="agio_disagio_type"
                    label="agio_disagio_type"
                    help="help_agio_disagio_type"
                    :options="mapEnumToArray(AgioDisagioType)"
                    :reduce="reduceEnumValue"
                    :required="true"
                />
                <TextField
                    v-model="campaign.agio_disagio_info_text"
                    label="agio_disagio_info_text"
                    name="agio_disagio_info_text"
                    :rows="4"
                    help="help_agio_disagio_info_text"
                />
                <TextField
                    v-model="campaign.agio_disagio_disclaimer"
                    label="agio_disagio_disclaimer"
                    name="agio_disagio_disclaimer"
                    :rows="4"
                    help="help_agio_disagio_disclaimer"
                />
                <ToggleSwitch
                    v-model="agioDisagioVoucher"
                    name="agio_disagio_voucher"
                    label="agio_disagio_voucher"
                    help="help_agio_disagio_voucher"
                />
                <div v-if="agioDisagioVoucher">
                    <TextField
                        v-model="campaign.voucher_code"
                        label="voucher_code"
                        name="voucher_code"
                        help="help_voucher_code"
                    />
                    <NumberField
                        v-model="campaign.voucher_rate"
                        label="voucher_rate"
                        name="voucher_rate"
                        help="help_voucher_rate"
                    />
                </div>
            </div>
        </AccordionBasic>
        <AccordionBasic
            title="savings_plan"
            class="mb-2"
            :open="false"
        >
            <ToggleSwitch
                v-model="campaign.savings_plan"
                name="savings_plan"
                label="savings_plan"
                help="help_savings_plan"
            />

            <TextField
                v-model="campaign.savings_plan_description"
                :disabled="!campaign.savings_plan"
                rows="3"
                label="savings_plan_description"
                name="savings_plan_description"
                help="help_savings_plan_description"
            />
        </AccordionBasic>
    </PageWrapper>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import {
    AccordionBasic,
    Datepicker,
    EditorField,
    ImageUpload,
    NumberField,
    SelectField,
    TextField,
    ToggleSwitch,
} from '@src/components'
import BackButton from '@src/partials/actions/BackButton.vue'
import SaveButton from '@src/partials/actions/SaveButton.vue'
import PageWrapper from '@src/partials/pages/PageWrapper.vue'

import { useEnum } from '@composables/common'
import {
    AgioDisagio,
    AgioDisagioType,
    CampaignBadge,
    CampaignStatus,
    ClearingSystem,
    RegulatoryRequirements,
} from '@src/enums'
import {
    Asset,
    Broker,
    Campaign,
    CampaignAdditionalFields,
    CampaignImage,
    Placeholder,
    Platform,
} from '@src/types'
import { getAsset, getAssets } from '@src/utils/api/assets'
import { getCampaign, updateCampaign } from '@src/utils/api/campaigns'
import { getPlatforms } from '@src/utils/api/platforms'
import { useCountryStore } from '@src/utils/stores/countries'
import { useLanguageStore } from '@src/utils/stores/languages'
import { useToastStore } from '@src/utils/stores/toast'
import { useI18n } from 'vue-i18n'

const router = useRouter()
const route = useRoute()
const toast = useToastStore()
const i18n = useI18n()
const { mapEnumToArray, reduceEnumValue } = useEnum()

const campaign = ref<Campaign>(new Campaign())
const asset = ref<Asset>(new Asset())
const languageStore = useLanguageStore()
const languages = ref<{ label: string; value: string | null }[]>([])
const countryStore = useCountryStore()
const countries = ref<{ label: string; value: string | null }[]>([])
const broker = ref<Broker>(new Broker())
const agioDisagioVoucher = ref<boolean>(false)

const platformSelectOptions = ref<string[]>([])
const assetSelectOptions = ref<{ label: string; value: string | null }[]>([])

const placeholders = computed((): Placeholder[] => {
    return campaign.value.content_images.map((image: CampaignImage) => {
        return {
            key: image.name,
            value: `<img src="${image.thumb_image_url}" alt="${image.name}" />`,
        } as Placeholder
    })
})

async function loadPlatformSelectOptions(search: string) {
    const initialPlatformsRequest = await getPlatforms({
        'filter[platform_name]': search,
    })
    platformSelectOptions.value = initialPlatformsRequest.data.platforms.map(
        (platform: Platform) => {
            return platform.platform_name as string
        }
    )
}

async function loadAssetSelectOptions(search: string) {
    const assetsRequest = await getAssets({
        'filter[asset_title]': search,
    })
    assetSelectOptions.value = assetsRequest.data.assets.map((asset: Asset) => {
        return {
            label: asset.asset_title as string,
            value: asset.asset_uid as string,
        }
    })
}

function activateAdditionalFields() {
    if (
        campaign.value.additional_fields_enabled &&
        !campaign.value.additional_fields
    ) {
        campaign.value.additional_fields = new CampaignAdditionalFields()
    } else {
        campaign.value.additional_fields = null
    }
}

function goToDetails() {
    router.push({
        name: 'campaign',
        params: { id: campaign.value.asset_var_uid },
    })
}

function toCampaigns() {
    router.push({ name: 'campaigns' })
}

async function saveCampaign() {
    campaign.value.broker = broker.value
    await updateCampaign(campaign.value, campaign.value.asset_var_uid as string)
    toast.success(
        i18n.t('toast_success_campaign_saved', {
            asset: campaign.value.asset_title,
        })
    )
    if (campaign.value.hero_image) {
        campaign.value.hero_image.upload_key = null
    }
    if (campaign.value.thumb_image) {
        campaign.value.thumb_image.upload_key = null
    }
}

onMounted(async () => {
    const id = route.params.id as string
    if (id) {
        const campaignRequest = await getCampaign(id)
        campaign.value = campaignRequest.data.campaign
    }

    const assetRequest = await getAsset(campaign.value.asset_uid as string)
    asset.value = assetRequest.data.asset

    loadData()

    if (campaign.value.platform_name) {
        await loadPlatformSelectOptions(campaign.value.platform_name)
    }

    if (asset.value.asset_title) {
        await loadAssetSelectOptions(asset.value.asset_title)
    }

    if (campaign.value) {
        broker.value = campaign.value.broker ?? new Broker()
    }
})

async function loadData() {
    countries.value = await countryStore.loadAndSortCountriesOptionsAsync()
    languages.value = await languageStore.loadAndSortLanguagesOptionsAsync()
}

watch(i18n.locale, async () => {
    loadData()
})
</script>
