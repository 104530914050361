<template>
    <div class="px-2">
        <div class="p-4">
            <button
                class="btn mr-2 border-gray-200 text-gray-600 hover:border-gray-300"
                @click="loadPayoutTasks"
            >
                <fa icon="fa-solid fa-arrows-rotate" />
                <span class="ml-1">{{ $t('load_payout_tasks') }}</span>
            </button>
            <button
                v-if="userStore.can('perform_interest_payments')"
                class="btn bg-primary-additional-500 hover:bg-primary-additional-600 whitespace-nowrap text-white"
                @click="handleStartPayout"
            >
                <fa icon="fa-solid fa-money-bill" />
                <span class="ml-1">{{
                    $t('start_interest_payment_payout')
                }}</span>
            </button>
        </div>
        <PayoutTasksTable v-if="tasks.length > 0" />
        <ModalWrapper v-if="isModalOpen" />
    </div>
</template>

<script setup lang="ts">
import {
    ModalButtonStyles,
    useEnum,
    useModal,
    useTable,
} from '@composables/common'
import { Badge } from '@src/components'
import { PayoutTaskFinishedType } from '@src/enums'
import { PayoutTask, PayoutTaskResultData } from '@src/types'
import {
    startPayout as apiStartPayout,
    getPayoutTasks,
} from '@src/utils/api/contracts'
import { useToastStore } from '@src/utils/stores/toast'
import { useUserStore } from '@src/utils/stores/user'
import { useVModels } from '@vueuse/core'
import moment from 'moment'
import { h, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import InterestPaymentsTableExpandPayoutTaskResult from './InterestPaymentsTableExpandTaskResult.vue'
import InterestPaymentsTableExpandPayoutTaskResultDetails from './InterestPaymentsTableExpandTaskResultDetails.vue'

const toast = useToastStore()
const { getEnumLabel } = useEnum()
const { ModalWrapper, openModal } = useModal()

const props = defineProps<{
    id: string
}>()

const { id } = useVModels(props)

const i18n = useI18n()
const userStore = useUserStore()
const tasks = ref<PayoutTask[]>([])
const isModalOpen = ref(false)

const tasksTableColumns = [
    {
        label: 'created_on',
        key: 'created_at',
        cellRenderer: (props: { rowData: PayoutTask }) => {
            if (!props.rowData.created_at) {
                return '-'
            }
            return h(
                'span',
                moment(props.rowData.created_at).format('DD.MM.yyyy')
            )
        },
    },
    {
        label: 'status',
        key: 'status',
    },
    {
        label: 'started_at',
        key: 'started_at',
        cellRenderer: (props: { rowData: PayoutTask }) => {
            if (!props.rowData.started_at) {
                return '-'
            }
            return h(
                'span',
                moment(props.rowData.started_at).format('DD.MM.yyyy')
            )
        },
    },
    {
        label: 'finished_at',
        key: 'finished_at',
        cellRenderer: (props: { rowData: PayoutTask }) => {
            if (!props.rowData.finished_at) {
                return '-'
            }
            return h(
                'span',
                moment(props.rowData.finished_at).format('DD.MM.yyyy')
            )
        },
    },
    {
        label: 'finished_type',
        key: 'finished_type',
        cellRenderer: (props: { rowData: PayoutTask }) => {
            let badgeType = props.rowData.finished_type
            if (!props.rowData.finished_type) {
                return '-'
            }
            if (badgeType === PayoutTaskFinishedType['HAS_ERRORS']) {
                badgeType = PayoutTaskFinishedType['ERROR']
            }

            return h(
                Badge,
                {
                    type: badgeType as 'info' | 'warning' | 'error' | 'success',
                },

                () =>
                    getEnumLabel(
                        PayoutTaskFinishedType,
                        props.rowData.finished_type
                    )
            )
        },
    },
    {
        label: 'result',
        key: 'result_data',
        cellRenderer: (props: { rowData: PayoutTask }) => {
            return h(InterestPaymentsTableExpandPayoutTaskResult, {
                resultData: props.rowData.result_data as PayoutTaskResultData,
            })
        },
    },
    {
        label: 'result_details',
        key: 'result_text',
        cellRenderer: (props: { rowData: PayoutTask }) => {
            return h(InterestPaymentsTableExpandPayoutTaskResultDetails, {
                task: props.rowData,
                onViewTaskResultDetails: handleViewTaskResultDetails,
            })
        },
    },
]

const { TableWrapper: PayoutTasksTable } = useTable({
    tableProps: {
        id: 'interest_payments_payout_tasks_table',
        data: tasks,
        total: tasks.value.length,
        columns: tasksTableColumns,
        hideHeaderActions: true,
    },
})

function handleStartPayout() {
    isModalOpen.value = true
    openModal(
        {
            id: 'start_interest_payment_payout_modal',
            title: 'modal_title_start_interest_payment_payout',
            open: isModalOpen,
            onCancel: () => {
                isModalOpen.value = false
            },
            onSubmit: async () => {
                await startPayout()
                isModalOpen.value = false
            },
        },
        () => h('p', i18n.t('modal_text_start_interest_payment_payout')),
        {
            submitButtonText: 'start_interest_payment_payout',
            styles: {
                submitButton: ModalButtonStyles['BLUE'],
            },
        }
    )
}

async function loadPayoutTasks() {
    const getPayoutTasksRequest = await getPayoutTasks(id.value)
    tasks.value = getPayoutTasksRequest.data.tasks
}

async function startPayout() {
    await apiStartPayout(id.value)
    toast.success(i18n.t('toast_success_interest_payment_payout_started'))
    loadPayoutTasks()
}

function handleViewTaskResultDetails(task: PayoutTask) {
    isModalOpen.value = true
    openModal(
        {
            id: 'payout_task_modal',
            title: 'modal_title_payout_task',
            open: isModalOpen,
            onCancel: () => (isModalOpen.value = false),
        },
        () =>
            h('div', [
                h(InterestPaymentsTableExpandPayoutTaskResult, {
                    resultData: task.result_data as PayoutTaskResultData,
                }),
                h(
                    'div',
                    { class: 'whitespace-pre-line mt-4' },
                    task.result_text as string
                ),
            ])
    )
}

onMounted(async () => {
    await loadPayoutTasks()
})
</script>

<style scoped></style>
