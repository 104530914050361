import { Campaign, DuplicatedCampaign, TableSorting } from '@src/types'
import { ObjectWithStringKeysAndStringValue } from '@src/types/ObjectWithStringKeysAndStringValue'
import { ApiRes, api } from '@src/utils/api/client'
import { AxiosRequestConfig } from 'axios'

export interface CampaignsResponse {
    success: string
    asset_variations: Campaign[]
    asset_variations_count: number
}

export interface CampaignResponse {
    success: string
    campaign: Campaign
    accounts_count: number
}

export const getCampaigns = (
    filters?: ObjectWithStringKeysAndStringValue,
    page?: number,
    limit?: number,
    sorting?: TableSorting[]
): ApiRes<CampaignsResponse> => {
    const config: AxiosRequestConfig = {}

    config.params = {}

    if (filters && Object.keys(filters).length) {
        config.params = filters
    }

    if (typeof page !== 'undefined' && typeof limit !== 'undefined') {
        config.params['range'] = page * limit - limit + '-' + page * limit
    }

    if (sorting && sorting.length) {
        config.params['sort'] = sorting
            .map((sort) => {
                return (sort.direction === 'desc' ? '-' : '') + sort.field
            })
            .join(',')
    }

    return api.get('v2/manage/campaigns', config)
}

export const createCampaign = (campaign: Campaign): ApiRes<CampaignResponse> =>
    api.post('v2/manage/campaigns', campaign)

export const deleteCampaign = (id: string): ApiRes<string> =>
    api.delete('manage/campaigns/' + id)

export const deleteCampaigns = (campaigns: string[]): ApiRes<string> =>
    api.delete('v2/manage/campaigns', { data: { campaigns: campaigns } })

export const getCampaign = (id: string): ApiRes<CampaignResponse> =>
    api.get('manage/campaigns/' + id)

export const updateCampaign = (
    campaign: Campaign,
    id: string
): ApiRes<CampaignResponse> => api.patch('v2/manage/campaigns/' + id, campaign)

export const duplicateCampaign = (
    id: string,
    duplicatedCampaign: DuplicatedCampaign
): ApiRes<CampaignResponse> =>
    api.post(`manage/campaigns/${id}/duplicate`, duplicatedCampaign)
