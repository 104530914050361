<template>
    <div :class="{ 'my-4': label }">
        <div
            v-if="label"
            class="flex items-center justify-between"
        >
            <label
                class="mb-1 block text-sm font-medium"
                :for="name"
                >{{ $t(label) }}
                <span
                    v-if="required === true"
                    class="text-danger-500"
                    >*
                </span>
            </label>
            <Tooltip
                v-if="help"
                class="ml-2"
                bg="dark"
                size="md"
                position="left"
            >
                <div class="text-sm text-gray-200">{{ $t(help) }}</div>
            </Tooltip>
        </div>
        <div
            class="datepicker relative"
            :class="{
                'no-year': noYearMode,
            }"
        >
            <flat-pickr
                v-model="datePickerValue"
                class="form-input w-60 pl-9 font-medium text-gray-500 hover:text-gray-600 focus:border-gray-300"
                :autocomplete="autocomplete ? 'on' : 'off'"
                :class="pickerInputClass"
                :config="config ?? undefined"
                :name="name"
                :placeholder="placeholder ? $t(placeholder) : ''"
            >
            </flat-pickr>
            <div
                class="pointer-events-none absolute inset-0 right-auto flex items-center"
            >
                <svg
                    class="ml-3 h-4 w-4 fill-current text-gray-500"
                    :class="pickerIconClass"
                    viewBox="0 0 16 16"
                >
                    <path
                        d="M15 2h-2V0h-2v2H9V0H7v2H5V0H3v2H1a1 1 0 00-1 1v12a1 1 0 001 1h14a1 1 0 001-1V3a1 1 0 00-1-1zm-1 12H2V6h12v8z"
                    />
                </svg>
            </div>
        </div>
        <div
            v-if="bottomHelpText"
            class="mt-2 text-xs"
        >
            {{ $t(bottomHelpText) }}
        </div>
    </div>
</template>

<script setup lang="ts">
import { Tooltip } from '@src/components'
import { DatepickerValue } from '@src/types'
import { useVModels } from '@vueuse/core'
import { computed } from 'vue'
import flatPickr from 'vue-flatpickr-component'

const props = withDefaults(
    defineProps<{
        modelValue: DatepickerValue | unknown
        autocomplete?: boolean
        bottomHelpText?: string | null
        dateFormat?: string
        dateFrom?: DatepickerValue | unknown | null
        dateTo?: DatepickerValue | unknown | null
        datemode?: string
        defaultDate?: DatepickerValue
        enableTime?: boolean
        help?: string | null
        label?: string | null
        name: string
        pickerIconClass?: string
        pickerInputClass?: string
        placeholder?: string
        required?: boolean
        time24hr?: boolean
    }>(),
    {
        autocomplete: false,
        bottomHelpText: null,
        dateFormat: 'Y-m-d',
        dateFrom: undefined,
        dateTo: undefined,
        datemode: 'single',
        defaultDate: undefined,
        enableTime: false,
        help: null,
        label: null,
        pickerIconClass: '',
        pickerInputClass: '',
        placeholder: '',
        time24hr: true,
    }
)

const emit = defineEmits([
    'update:modelValue',
    'update:dateFrom',
    'update:dateTo',
])

const { modelValue, dateFrom, dateTo } = useVModels(props, emit)

const datePickerValue = computed({
    get() {
        return (modelValue.value ?? undefined) as DatepickerValue
    },
    set(value) {
        if (typeof value === 'string' && !value.length) {
            modelValue.value = null
        } else {
            modelValue.value = value
        }
        dateFrom.value = null
        dateTo.value = null
        if (value && props.datemode === 'range') {
            const dates = (value as string).split(' to ')
            modelValue.value = null
            dateFrom.value = dates[0]
            dateTo.value = dates[1]
        }
    },
})

const noYearMode = computed(() => {
    return props.dateFormat === 'm-d'
})

const config: {
    readonly wrap: false
    readonly defaultDate: unknown
    allowInput: boolean
    mode: string
    static: boolean
    monthSelectorType: string
    dateFormat: string
    enableTime: boolean
    time_24hr: boolean
    prevArrow: string
    nextArrow: string
} = {
    allowInput: true,
    mode: props.datemode,
    static: true,
    monthSelectorType: 'static',
    dateFormat: props.dateFormat,
    enableTime: props.enableTime,
    time_24hr: props.time24hr,
    defaultDate: null,
    wrap: false,
    prevArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
    nextArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
}
</script>
