<template>
    <Modal
        id="set-order-status-modal"
        ref="setOrderStatusModal"
        v-model:open="open"
        :title="title"
        @submit="submit"
        @cancel="cancel"
    >
        <div class="px-5">
            <span class="font-bold">
                {{ $t('id') }}: {{ modelValue.order_uid }}</span
            ><br />
            {{ $t('platform') }}: {{ modelValue.platform_name }}<br />
            {{ $t('account') }}: {{ modelValue.account_title }}
        </div>
        <template #actions>
            <button
                v-if="toStatus === OrderStatus.PAID"
                type="submit"
                class="btn-sm bg-gray-500 text-white hover:bg-gray-600"
            >
                {{ $t('modal_text_order_status_set_order_status_paid') }}
            </button>

            <button
                v-else-if="toStatus === OrderStatus.ACCEPTED"
                type="submit"
                class="btn-sm bg-primary-accent-500 hover:bg-primary-accent-600 text-white"
            >
                {{ $t('modal_text_order_status_accept') }}.
            </button>
            <button
                v-else-if="toStatus === OrderStatus.PENDING"
                type="submit"
                class="btn-sm bg-gray-500 text-white hover:bg-gray-600"
            >
                {{ $t('modal_text_order_status_set_order_status_pending') }}
            </button>
            <button
                v-else-if="toStatus === OrderStatus.CANCELED"
                type="submit"
                class="btn-sm bg-danger-500 hover:bg-danger-600 text-white"
            >
                {{ $t('modal_text_order_status_set_order_status_canceled') }}
            </button>
        </template>
    </Modal>
</template>
<script setup lang="ts">
import { Modal } from '@src/components'
import { OrderStatus } from '@src/enums'
import { Order } from '@src/types'
import { useVModels } from '@vueuse/core'

const props = defineProps<{
    modelValue: Order
    open: boolean
    title: string
    toStatus: number
}>()

const emit = defineEmits([
    'update:modelValue',
    'update:open',
    'submit',
    'cancel',
])

const { modelValue, open } = useVModels(props, emit)

function cancel() {
    emit('cancel')
}

function submit() {
    emit('submit', modelValue.value)
}
</script>
