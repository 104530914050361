<template>
    <div class="relative inline-flex">
        <a
            class="flex h-8 w-8 items-center justify-center rounded-full bg-blue-500 transition duration-150"
            :href="documentationLink"
            target="_blank"
        >
            <span class="sr-only">Help</span>
            <fa
                icon="fa-question"
                class="text-white"
            />
        </a>
    </div>
</template>

<script setup lang="ts">
import { i18n } from '@src/utils/lang'
import { computed } from 'vue'

defineProps({
    align: {
        type: String,
        default: 'left',
    },
})

const documentationLink = computed(() => {
    if (i18n.global.locale.value === 'de') {
        return 'https://doc.clickup.com/30378345/p/h/wz2b9-10368/5153b5e70e763aa'
    }
    return 'https://doc.clickup.com/30378345/p/h/wz2b9-10908/ff453c64388f1ce'
})
</script>
