import { ModalButtonStyles, OpenModal, useTable } from '@composables/common'
import UpdateOrCreatePlatformDisclaimerForm from '@partials/platforms/UpdateOrCreatePlatformDisclaimerForm.vue'
import ViewPlatformDisclaimer from '@partials/platforms/ViewPlatformDisclaimer.vue'
import { NameAndId } from '@src/components'
import PlatformDisclaimerCountriesColumnCellRenderer from '@src/partials/platforms/PlatformDisclaimerCountriesColumnCellRenderer.vue'
import { PlatformDisclaimer } from '@src/types'
import { useUserStore } from '@src/utils/stores/user'
import { Ref, computed, h, ref, shallowRef } from 'vue'
import { useI18n } from 'vue-i18n'

export function usePlatformDisclaimersTable(
    disclaimers: Ref<PlatformDisclaimer[]>,
    update: (successMessage?: string | null) => Promise<void>,
    openModal: OpenModal,
    isModalOpen: Ref<boolean>
) {
    const userStore = useUserStore()
    const i18n = useI18n()

    const total = computed(() => {
        return disclaimers.value.length
    })

    const {
        TableWrapper: PlatformDisclaimersTable,
        selected: selectedPlatformDisclaimersIds,
    } = useTable({
        tableProps: {
            id: 'platform_disclaimers',
            label: 'platform_disclaimers',
            help: 'help_platform_disclaimers_table',
            data: disclaimers,
            total: total,
            columns: [
                {
                    label: 'id',
                    key: 'id',
                    select: true,
                },
                {
                    label: 'id',
                    key: 'id',
                    condition: userStore.can('see_id_columns'),
                    copy: true,
                },
                {
                    label: 'name',
                    key: 'risk_title',
                },
                {
                    label: 'countries',
                    key: 'countries',
                    cellRenderer: shallowRef(
                        PlatformDisclaimerCountriesColumnCellRenderer
                    ),
                },
            ],
            actions: [
                {
                    action: handleViewPlatformDisclaimer,
                    icon: 'fa-eye',
                    title: 'view',
                },
                {
                    action: handleEditOrCreatePlatformDisclaimer,
                    icon: 'fa-solid fa-pen',
                    title: 'edit',
                    condition: userStore.can('update_platform'),
                },
                {
                    action: handleDeletePlatformDisclaimer,
                    icon: 'fas fa-trash-alt',
                    title: 'delete',
                    color: 'text-danger-500',
                    condition: userStore.can('update_platform'),
                },
            ],
        },
    })

    function handleViewPlatformDisclaimer(
        platformDisclaimer: PlatformDisclaimer
    ) {
        isModalOpen.value = true
        openModal(
            {
                id: 'view_platform_disclaimer',
                title: 'platform_disclaimer',
                open: isModalOpen,
                onCancel: () => (isModalOpen.value = false),
            },
            () =>
                h(ViewPlatformDisclaimer, {
                    modelValue: platformDisclaimer,
                })
        )
    }

    function handleDeletePlatformDisclaimer(
        platformDisclaimer: PlatformDisclaimer
    ) {
        isModalOpen.value = true
        openModal(
            {
                id: 'delete_platform_disclaimer',
                title: 'modal_title_delete_platform_disclaimer',
                open: isModalOpen,
                onCancel: () => (isModalOpen.value = false),
                onSubmit: () => deletePlatformDisclaimer(platformDisclaimer),
            },
            () =>
                h(NameAndId, {
                    idKey: 'id',
                    nameKey: 'name',
                    modelValue: [platformDisclaimer],
                }),
            {
                submitButtonText: 'delete',
                styles: {
                    submitButton: ModalButtonStyles['RED'],
                },
            }
        )
    }

    function handleDeletePlatformDisclaimers() {
        isModalOpen.value = true
        openModal(
            {
                id: 'delete_platform_disclaimers',
                title: 'modal_title_delete_platform_disclaimers',
                open: isModalOpen,
                onCancel: () => (isModalOpen.value = false),
                onSubmit: () => deletePlatformDisclaimers(),
            },
            () =>
                h(NameAndId, {
                    idKey: 'id',
                    nameKey: 'name',
                    modelValue: selectedPlatformDisclaimers.value,
                }),
            {
                submitButtonText: 'delete',
                styles: {
                    submitButton: ModalButtonStyles['RED'],
                },
            }
        )
    }

    function deletePlatformDisclaimer(
        platformDisclaimerToDelete: PlatformDisclaimer,
        submit = true
    ) {
        const disclaimersTemp = [...disclaimers.value]
        disclaimers.value.splice(
            disclaimers.value.findIndex(
                (platformDisclaimer) =>
                    platformDisclaimerToDelete.id == platformDisclaimer.id
            ),
            1
        )
        if (submit) {
            update(
                i18n.t('toast_success_platform_disclaimer_deleted', {
                    disclaimer: platformDisclaimerToDelete.risk_title,
                })
            )
                .catch((error) => {
                    disclaimers.value = disclaimersTemp
                    throw error
                })
                .then(() => {
                    isModalOpen.value = false
                })
        }
    }

    function deletePlatformDisclaimers() {
        const disclaimersTemp = [...disclaimers.value]
        selectedPlatformDisclaimers.value.forEach((platformDisclaimer) => {
            deletePlatformDisclaimer(platformDisclaimer, false)
        })

        update(
            i18n.t('toast_success_platform_disclaimers_deleted', {
                disclaimers: selectedPlatformDisclaimers.value
                    .map((platformDisclaimer) => platformDisclaimer.risk_title)
                    .join(', '),
            })
        )
            .catch((error) => {
                disclaimers.value = disclaimersTemp
                throw error
            })
            .then(() => {
                selectedPlatformDisclaimersIds.value = []
                isModalOpen.value = false
            })
    }

    function handleEditOrCreatePlatformDisclaimer(
        disclaimer: PlatformDisclaimer = new PlatformDisclaimer()
    ) {
        const editedDisclaimer = ref(new PlatformDisclaimer())
        editedDisclaimer.value = { ...disclaimer }
        isModalOpen.value = true

        const disableCreate = computed(() => {
            return !(
                editedDisclaimer.value.risk_title &&
                editedDisclaimer.value.language &&
                editedDisclaimer.value.risk_content
            )
        })

        openModal(
            {
                id: 'edit_platform_disclaimer',
                title: editedDisclaimer.value.id
                    ? 'modal_title_update_platform_disclaimer'
                    : 'modal_title_create_platform_disclaimer',
                open: isModalOpen,
                onCancel: () => (isModalOpen.value = false),
                onSubmit: () => saveDisclaimer(editedDisclaimer.value),
            },
            () =>
                h(UpdateOrCreatePlatformDisclaimerForm, {
                    modelValue: editedDisclaimer,
                }),
            {
                submitButtonText: editedDisclaimer.value.id
                    ? 'update'
                    : 'create',
                styles: {
                    submitButton: ModalButtonStyles['BLUE'],
                },
                submitButtonDisabled: disableCreate,
            }
        )
    }

    function saveDisclaimer(platformDisclaimer: PlatformDisclaimer) {
        if (platformDisclaimer.id) {
            disclaimers.value[
                disclaimers.value.findIndex(
                    (disclaimer: PlatformDisclaimer) =>
                        platformDisclaimer.id == disclaimer.id
                )
            ] = platformDisclaimer
        } else {
            disclaimers.value.push(platformDisclaimer)
        }

        let toast = 'toast_success_platform_disclaimer_updated'
        if (!platformDisclaimer.id) {
            toast = 'toast_success_platform_disclaimer_created'
        }
        update(
            i18n.t(toast, {
                disclaimer: platformDisclaimer.risk_title,
            })
        )
        isModalOpen.value = false
    }

    const selectedPlatformDisclaimers = computed(() => {
        return disclaimers.value.filter(
            (disclaimer) =>
                disclaimer.id &&
                selectedPlatformDisclaimersIds.value.includes(disclaimer.id)
        )
    })

    return {
        PlatformDisclaimersTable,
        selectedPlatformDisclaimersIds,
        handleEditOrCreatePlatformDisclaimer,
        handleDeletePlatformDisclaimers,
    }
}
