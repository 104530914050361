import { GlobalSearchResults } from '@src/types/GlobalSearch'
import { getAccounts } from '@src/utils/api/accounts'
import { getAssets } from '@src/utils/api/assets'
import { getCampaigns } from '@src/utils/api/campaigns'
import { getContracts } from '@src/utils/api/contracts'
import { getEmails } from '@src/utils/api/emails'
import { getIssuers } from '@src/utils/api/issuers'
import { getOrders } from '@src/utils/api/orders'
import { getPlatforms } from '@src/utils/api/platforms'
import { useUserStore } from '@stores/user'
import debounce from 'lodash.debounce'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useSearch = defineStore('search', () => {
    const search = ref<string | null>(null)
    const results = ref<GlobalSearchResults>({
        platforms: [],
        platformsCount: 0,
        issuers: [],
        issuersCount: 0,
        assets: [],
        assetsCount: 0,
        campaigns: [],
        campaignsCount: 0,
        accounts: [],
        accountsCount: 0,
        orders: [],
        ordersCount: 0,
        emails: [],
        emailsCount: 0,
        contracts: [],
        contractsCount: 0,
    })

    const userStore = useUserStore()

    const entityResultsLimit = ref<number>(
        parseInt(import.meta.env.VITE_GLOBAL_SEARCH_ENTITY_RESULTS_LIMIT)
    )

    const setSearch = debounce((value: string) => {
        if (search.value !== value && value.length > 2) {
            doSearch(value)
        }
        search.value = value

        const url = new URL(window.location.href)
        const urlParams = new URLSearchParams(url.search)

        if (value) {
            urlParams.set('global-search', value)
        } else {
            urlParams.delete('global-search')
        }

        window.history.replaceState(
            {},
            '',
            urlParams.size > 0
                ? `${window.location.pathname}?${urlParams}`
                : window.location.pathname
        )
    }, parseInt(import.meta.env.VITE_GLOBAL_SEARCH_DEBOUNCE))

    async function doSearch(search: string) {
        if (userStore.can('view_platforms')) {
            const platformsRequest = await getPlatforms(
                {
                    'filter[search]': search,
                },
                1,
                entityResultsLimit.value
            )

            results.value.platforms = platformsRequest.data.platforms
            results.value.platformsCount = platformsRequest.data.platforms_count
        }

        if (userStore.can('view_issuers')) {
            const issuersRequest = await getIssuers(
                {
                    'filter[search]': search,
                },
                1,
                entityResultsLimit.value
            )
            results.value.issuers = issuersRequest.data.issuers
            results.value.issuersCount = issuersRequest.data.issuers_count
        }

        if (userStore.can('view_assets')) {
            const assetsRequest = await getAssets(
                {
                    'filter[search]': search,
                },
                1,
                entityResultsLimit.value
            )
            results.value.assets = assetsRequest.data.assets
            results.value.assetsCount = assetsRequest.data.assets_count
        }

        if (userStore.can('view_campaigns')) {
            const campaignsRequest = await getCampaigns(
                {
                    'filter[search]': search,
                },
                1,
                entityResultsLimit.value
            )
            results.value.campaigns = campaignsRequest.data.asset_variations
            results.value.campaignsCount =
                campaignsRequest.data.asset_variations_count
        }

        if (userStore.can('view_accounts')) {
            const accountsRequest = await getAccounts(
                {
                    'filter[search]': search,
                },
                1,
                entityResultsLimit.value
            )
            results.value.accounts = accountsRequest.data.accounts
            results.value.accountsCount = accountsRequest.data.accounts_count
        }

        if (userStore.can('view_orders')) {
            const orderRequest = await getOrders(
                { 'filter[search]': search },
                1,
                entityResultsLimit.value
            )
            results.value.orders = orderRequest.data.orders
            results.value.ordersCount = orderRequest.data.orders_count
        }

        if (userStore.can('view_email_audits')) {
            const emailsRequest = await getEmails(
                { 'filter[search]': search },
                1,
                entityResultsLimit.value
            )
            results.value.emails = emailsRequest.data.email_audits
            results.value.emailsCount = emailsRequest.data.email_audits_count
        }

        if (userStore.can('view_contracts')) {
            const contractsRequest = await getContracts(
                { 'filter[search]': search },
                1,
                entityResultsLimit.value
            )
            results.value.contracts = contractsRequest.data.contracts
            results.value.contractsCount = contractsRequest.data.contracts_count
        }
    }

    const noResults = computed(() => {
        if (
            results.value.accountsCount ||
            results.value.assetsCount ||
            results.value.campaignsCount ||
            results.value.contractsCount ||
            results.value.emailsCount ||
            results.value.issuersCount ||
            results.value.ordersCount ||
            results.value.platformsCount
        ) {
            return false
        }
        return true
    })

    function init() {
        const url = new URL(window.location.href)
        const urlParams = new URLSearchParams(url.search)
        const searchValue = urlParams.get('global-search')
        if (searchValue) {
            setSearch(searchValue)
        }
    }

    init()

    return {
        search,
        setSearch,
        results,
        noResults,
        entityResultsLimit,
    }
})
