<template>
    <editor
        v-model="modelValue"
        :api-key="tinyApiKey"
        :init="{
            menu: {
                placeholders: {
                    title: $t('placeholders'),
                    items: placeholderItems,
                },
            },
            menubar: 'edit view format placeholders',
            plugins: plugins,
            fullscreen_native: true,
            toolbar: toolbar,
            fontsize_formats: '8px 10px 12px 14px 16px 18px 24px 36px 48px',
            font_size_input_default_unit: 'px',
            setup: setupTinyMCE,
            language: $i18n.locale,
            content_style: `body { font-size: 14px; }`,
        }"
    />
</template>
<script lang="ts" setup>
import { Placeholder } from '@src/types'
import Editor from '@tinymce/tinymce-vue'
import { useVModels } from '@vueuse/core'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const props = defineProps<{
    placeholders?: Placeholder[] | null
    modelValue?: string | null
}>()

const emit = defineEmits(['update:modelValue'])

const placeholderItems: string[] = []

const { modelValue } = useVModels(props, emit)

props.placeholders?.forEach((placeholder: Placeholder) => {
    placeholderItems.push(placeholder.key)
})

// load api key from environment file
const tinyApiKey = import.meta.env.VITE_TINY_API_KEY
const tinyPaidPlugins = import.meta.env.VITE_TINY_PAID_PLUGINS

const plugins = `lists link image code fullscreen${
    tinyPaidPlugins === 'true' ? ' formatpainter advcode' : ''
}`

const toolbar = `undo redo | styleselect | bold italic | fontsizeselect fontselect | removeformat | bullist numlist | link | image | alignleft aligncenter alignright alignjustify | outdent indent |
    ${
        tinyPaidPlugins === 'true'
            ? 'formatpainter formatpainter_removeformat | '
            : ''
    } fullscreen | code`

const setupTinyMCE = function (editor: typeof Editor) {
    // render the given placeholders into the menu
    props.placeholders?.forEach((placeholder: Placeholder) => {
        editor.on('PreInit', () => {
            editor.editorManager.addI18n('de', {
                'System Font': 'Schriftart ändern',
            })
            editor.editorManager.addI18n('en', {
                'System Font': 'Change Font',
            })
        })
        editor.ui.registry.addMenuItem(placeholder.key, {
            text: t('insert') + ' ' + placeholder.key,
            onAction: () => {
                editor.insertContent(placeholder.value)
            },
        })
    })
}
</script>
