<template>
    <td
        class="w-px whitespace-nowrap bg-white px-2 py-3 first:sticky first:left-0 first:pl-5 last:sticky last:right-0 last:pr-5"
    >
        <div class="flex items-center">
            <label class="inline-flex">
                <span class="sr-only">Select</span>
                <input
                    class="form-checkbox"
                    type="checkbox"
                    :checked="modelValue.includes(id as string)"
                    @change="toggleSingle"
                />
            </label>
        </div>
    </td>
</template>
<script setup lang="ts">
import { useVModels } from '@vueuse/core'

const props = defineProps<{
    id: string | null
    modelValue: string[]
}>()

const emit = defineEmits(['update:modelValue'])

const { id, modelValue } = useVModels(props, emit)

function toggleSingle(): void {
    if (!id.value) return
    // if id is included, remove it
    if (modelValue.value.includes(id.value)) {
        modelValue.value = modelValue.value.filter((item) => item !== id.value)
    }
    // if id is not included, add it
    else {
        modelValue.value.push(id.value)

        // emit the change manually (due to push)
        emit('update:modelValue', modelValue.value)
    }
}
</script>
<style scoped>
tr:hover td {
    @apply bg-gray-100;
}
</style>
