<template>
    <div class="my-4">
        <div class="flex items-center justify-between">
            <label
                v-if="label"
                class="mb-1 block text-sm font-medium"
                :for="name"
                >{{ $t(label) }}
                <span
                    v-if="required === true"
                    class="text-danger-500"
                    >*</span
                ></label
            >
            <Tooltip
                v-if="help"
                class="ml-2"
                bg="dark"
                size="md"
                position="left"
            >
                <div class="text-sm text-gray-200">{{ $t(help) }}</div>
            </Tooltip>
        </div>
        <div class="flex">
            <input
                :id="name"
                :key="refreshKey"
                v-model="textfieldValue"
                :name="name"
                class="form-input w-full"
                type="text"
                :disabled="disabled"
                @keydown="onKeyDown"
                @blur="onBlur"
            />
            <span
                v-if="unit"
                class="ml-2 pt-2"
                >{{ unit }}</span
            >
        </div>
        <div
            v-if="bottomHelpText"
            class="mt-2 text-xs"
        >
            {{ $t(bottomHelpText) }}
        </div>
    </div>
</template>
<script setup lang="ts">
import { Tooltip } from '@src/components'
import { numberFiledValueParsing } from '@src/const'
import { i18n } from '@src/utils/lang'
import { useVModels } from '@vueuse/core'
import { computed, ref } from 'vue'

const { locale } = i18n.global

const props = defineProps<{
    name: string
    label?: string
    help?: string
    bottomHelpText?: string | null
    required?: boolean
    modelValue: number | null
    unit?: string
    min?: number
    max?: number
    disabled?: boolean
}>()

const emit = defineEmits(['update:modelValue'])

const { modelValue, min, max } = useVModels(props, emit)

const refreshKey = ref(0)

function onKeyDown(event: KeyboardEvent) {
    if (
        !(
            /[0-9,.]$/.test(event.key) ||
            event.key === 'Backspace' ||
            event.key === 'Enter' ||
            (event.ctrlKey && event.key === 'a')
        )
    ) {
        event.preventDefault()
    }
}

function onBlur(event: FocusEvent) {
    const target = event.target as HTMLInputElement
    const value = target.value
    if (!value) {
        modelValue.value = 0
        refreshKey.value++
    }
}

const textfieldValue = computed({
    get() {
        return modelValue.value?.toLocaleString(locale.value)
    },
    set(value) {
        if (value && /[0-9,.]$/.test(value)) {
            const numValue = parseFloat(value)
            if (max && max.value && numValue > max.value) {
                value = max.value.toString()
                refreshKey.value++
            }
            if (min && min.value && numValue < min.value) {
                value = min.value.toString()
                refreshKey.value++
            }
            modelValue.value = numberFiledValueParsing[locale.value](value)
        }
    },
})
</script>
