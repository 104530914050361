<template>
    <p class="mb-4 font-bold">{{ getEnumLabel(TaskType, task.type) }}</p>
    <div
        v-if="typeof task.result_data?.totalCount === 'number'"
        class="mb-2"
    >
        {{ $t('total_count') }}
        <Badge type="info">{{ task.result_data.totalCount }}</Badge>
    </div>
    <div
        v-if="typeof task.result_data?.errorCount === 'number'"
        class="mb-4"
    >
        {{ $t('error_count') }}
        <Badge type="error">{{ task.result_data.errorCount }}</Badge>
    </div>
    <pre
        v-if="task.result_text"
        class="mb-4"
        v-text="
            task.result_text.replaceAll('<br>', '\n').replaceAll('<br/>', '\n')
        "
    />
    <button
        v-if="task.result_data?.filename && task.result_data?.download_url"
        class="btn-sm bg-primary-additional-500 text-xs text-white"
        @click="downloadFile(task.result_data?.download_url)"
    >
        {{ task.result_data?.filename }}
    </button>
</template>

<script setup lang="ts">
import { Badge } from '@src/components'
import { useEnum } from '@src/composables/common'
import { TaskType } from '@src/enums'
import { Task } from '@src/types'
import { downloadFile } from '@utils/helpers'

defineProps<{ task: Task }>()

const { getEnumLabel } = useEnum()
</script>

<style scoped></style>
