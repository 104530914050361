<template>
    <div>
        <FileUploadButton
            title="button_bank_kyc_document_upload"
            accept=".png,.jpg,.jpeg,.pdf"
            mode="KYC"
            :deletable="false"
            @submit="onUpload"
        />
        <SelectField
            v-model="modelValue.type"
            name="kyc_document_type"
            label="type"
            help="help_kyc_document_type"
            :options="mapEnumToArray(KYCBankDocumentType)"
            :reduce="reduceEnumValue"
            :required="true"
        />
        <TextField
            v-model="modelValue.bankAccount.iban"
            :mask="ibanMask"
            name="kyc_document_iban"
            label="iban"
            help="help_kyc_document_iban"
            :required="true"
        />
        <TextField
            v-model="modelValue.bankAccount.holder"
            name="kyc_document_account_holder"
            label="account_holder"
            help="help_kyc_document_account_holder"
        />
    </div>
</template>

<script setup lang="ts">
import { useEnum, useMask } from '@composables/common'
import FileUploadButton from '@partials/actions/FileUploadButton.vue'
import { SelectField, TextField } from '@src/components'
import { KYCBankDocumentType } from '@src/enums'
import { BankKYCDocument } from '@src/types'
import { UploadFileResponse } from '@src/utils/api/s3'
import { Ref, toRefs, watch } from 'vue'

const props = defineProps<{
    modelValue: Ref<BankKYCDocument>
}>()

const { modelValue } = toRefs(props)

const emit = defineEmits(['update:modelValue'])

const { mapEnumToArray, reduceEnumValue } = useEnum()
const { ibanMask } = useMask()

function onUpload(response: UploadFileResponse) {
    modelValue.value.url = response.temp_storage_url
    modelValue.value.storage_key = response.key
}

watch(
    modelValue,
    (value) => {
        emit('update:modelValue', value)
    },
    { deep: true }
)
</script>

<style lang="scss" scoped></style>
