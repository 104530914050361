<template>
    <Modal
        id="modal_create-order"
        ref="modal_create-order"
        v-model:open="open"
        title="modal_title_create_order"
        @submit="submit"
        @cancel="cancel"
    >
        <SelectField
            v-if="!preselected"
            v-model="modelValue.account_uid"
            v-model:options="accountSelectOptions"
            name="account_name"
            label="account_name"
            help="help_account_name"
            :required="true"
            :on-search="loadSelectAccountOptions"
        />
        <div v-if="modelValue.account_uid">
            <div>
                {{ selectedAccount.account_title }}
            </div>

            <NumberField
                v-model="modelValue.amount"
                label="invested_amount"
                name="order_invested_amount"
                help="help_order_invested_amount"
                :required="true"
                :disabled="disabledFields?.includes('amount')"
            />

            <ToggleSwitch
                v-model="modelValue.send_mails"
                name="order_send_mails"
                label="order_send_mails"
                help="help_order_send_mails"
                :disabled="disabledFields?.includes('send_mails')"
            />

            <HiddenField
                v-model="modelValue.account_uid"
                label="account_uid"
                name="account_uid"
            />

            <Datepicker
                v-model="modelValue.created_on"
                label="date_of_order"
                name="date_of_order"
                placeholder="date_of_order"
                help="help_date_of_order"
                :required="true"
                :disabled="disabledFields?.includes('created_on')"
            />
            <SelectField
                v-model="modelValue.asset_var_uid"
                v-model:options="campaignSelectOptions"
                name="campaign_title"
                label="campaign_title"
                help="help_select_campaign"
                :on-search="loadCampaignSelectOptions"
                :required="true"
                :disabled="disabledFields?.includes('asset_var_uid')"
                @update:model-value="handleCampaignOnSelect"
            />
            <SelectField
                v-if="incentiveSelectOptions.length > 0"
                v-model="modelValue.incentive_uid"
                name="incentive"
                label="incentive"
                help="help_select_incentive"
                :options="incentiveSelectOptions"
                :disabled="disabledFields?.includes('incentive_uid')"
            />

            <SelectField
                v-if="campaign.asset_needs_contract_selection"
                v-model="modelValue.contract_type"
                name="contract_type"
                label="contract_type"
                help="help_contract_type"
                :options="mapEnumToArray(ContractType)"
                :reduce="reduceEnumValue"
                :required="true"
                :disabled="disabledFields?.includes('contract_type')"
            />
        </div>

        <template #actions>
            <MultiActionButton />
        </template>
    </Modal>
</template>
<script setup lang="ts">
import { useEnum, useMultiActionButton } from '@composables/common'
import {
    Datepicker,
    HiddenField,
    Modal,
    NumberField,
    SelectField,
    ToggleSwitch,
} from '@src/components'
import { ContractType } from '@src/enums'
import {
    Account,
    Asset,
    AssetsIncentive,
    Campaign,
    CreateOrderPayload,
} from '@src/types'
import { getAccount, searchAccounts } from '@src/utils/api/accounts'
import { getAsset } from '@src/utils/api/assets'
import { getCampaign, getCampaigns } from '@src/utils/api/campaigns'
import { formatNumericValues } from '@src/utils/helpers'
import { useVModels } from '@vueuse/core'
import { computed, onMounted, ref } from 'vue'

const campaign = ref<Campaign>(new Campaign())
const selectedAccount = ref<Account>(new Account())

const props = defineProps<{
    open: boolean
    modelValue: CreateOrderPayload
    preselected?: string | null
    disabledFields?: string[] | null
    assetUid?: string | null
}>()

const emit = defineEmits([
    'update:modelValue',
    'update:open',
    'cancel',
    'submit',
])

const { modelValue, open } = useVModels(props, emit)
const { mapEnumToArray, reduceEnumValue } = useEnum()

const accountSelectOptions = ref<{ label: string; value: string | null }[]>([])
const campaignSelectOptions = ref<{ label: string; value: string | null }[]>([])
const asset = ref<Asset>(new Asset())
const assetCurrency = ref<string | null>(null)

const incentiveSelectOptions = computed(() => {
    return campaign.value.assets_incentives.map(
        (incentive: AssetsIncentive) => {
            return {
                label: `${incentive.incentives_title} ${formatNumericValues(
                    incentive.incentives_amount as number,
                    assetCurrency.value || ''
                )}`,
                value: incentive.asset_inc_uid as string,
            }
        }
    )
})

const { MultiActionButton } = useMultiActionButton({
    id: 'create-order-button',
    actions: [
        {
            label: 'create',
            action: submit,
            disabled: () => disableCreate.value,
        },
        {
            label: 'create_and_edit',
            action: submitAndEdit,
            disabled: () => disableCreate.value,
        },
    ],
})

onMounted(async () => {
    if (props.preselected) {
        await loadAccount(props.preselected)
    }
})

async function loadAccount(id: string) {
    const accountRequest = await getAccount(id)
    selectedAccount.value = accountRequest.data.account
    modelValue.value.account_uid = selectedAccount.value.account_uid
}

async function loadSelectAccountOptions(search: string) {
    const accountsSearchRequest = await searchAccounts(
        '?range=0-5&sort=-created_on&filter[account_title]=' + search
    )
    accountSelectOptions.value = accountsSearchRequest.data.accounts.map(
        (account: Account) => {
            return {
                label:
                    account.account_title +
                    ' (' +
                    account.email +
                    ' on ' +
                    account.platform_name +
                    ')',
                value: account.account_uid as string,
            }
        }
    )
}

async function loadCampaignSelectOptions(search: string) {
    let filtersToApply: { [key: string]: string } = {
        'filter[asset_title]': search,
    }
    if (props.assetUid) {
        filtersToApply = {
            ...filtersToApply,
            'filter[asset_uid]': props.assetUid,
        }
    }
    const campaignRequest = await getCampaigns(filtersToApply)
    campaignSelectOptions.value = campaignRequest.data.asset_variations.map(
        (campaign: Campaign) => {
            return {
                label:
                    campaign.asset_title + ' (' + campaign.platform_name + ')',
                value: campaign.asset_var_uid as string,
            }
        }
    )
}

async function handleCampaignOnSelect(id: string) {
    if (id) {
        const campaignRequest = await getCampaign(id)
        campaign.value = campaignRequest.data.campaign

        const assetRequest = await getAsset(campaign.value.asset_uid as string)
        asset.value = assetRequest.data.asset
        assetCurrency.value = asset.value.currency
    }
}

function cancel() {
    emit('cancel')
}

function submit() {
    emit('submit', 'view')
}

function submitAndEdit() {
    emit('submit', 'edit')
}

const disableCreate = computed(() => {
    return (
        !modelValue.value.amount ||
        !modelValue.value.created_on ||
        !modelValue.value.asset_var_uid ||
        (campaign.value.asset_needs_contract_selection &&
            !modelValue.value.contract_type)
    )
})

onMounted(async () => {
    if (modelValue.value.asset_var_uid) {
        const campaignRequest = await getCampaign(
            modelValue.value.asset_var_uid
        )
        campaign.value = campaignRequest.data.campaign
        await loadCampaignSelectOptions(campaign.value.asset_title as string)
    }
})
</script>
