<template>
    <PageWrapper>
        <template #headline>
            <fa icon="fa-building" />&nbsp;{{ issuer.name }}
        </template>

        <template #actions>
            <BackButton
                v-if="$router.options.history.state.back"
                @click="router.go(-1)"
            />
            <BackButton
                v-if="$router.options.history.state.back !== '/issuers'"
                icon="fa-list"
                title="issuers"
                @click="toIssuers"
            />
            <EditButton
                v-if="userStore.can('update_issuer')"
                @click="editIssuer"
            />
            <DeleteButton
                v-if="userStore.can('delete_issuer')"
                @click="openDeleteModal"
            />
        </template>

        <template #modals>
            <DeleteItemModal
                id="delete-issuer-modal"
                v-model="issuer"
                v-model:open="deleteModalOpen"
                title="modal_title_delete_issuer"
                name-key="name"
                id-key="id"
                @submit="deleteIssuer"
            />
        </template>

        <SummaryWrapper>
            <issuerSummary />
            <template
                v-if="userStore.is('developer')"
                #sidebar
            >
                <PlatformSummary v-if="userStore.is('developer')" />
            </template>
        </SummaryWrapper>

        <AssetsTable
            v-if="issuer.id"
            :initial-filters="{
                issuer_id: issuer.id,
            }"
            :create-initial-values="assetsTableCreateInitialValues"
            :create-disabled-fields="['issuer_id', 'platform_name']"
        />

        <ModalWrapper v-if="isModalOpen" />
    </PageWrapper>
</template>

<script setup lang="ts">
import { useEnum, useModal } from '@composables/common'
import {
    IssuerIndustry,
    IssuerInternalStatus,
    IssuerStatus,
} from '@enums/Issuer'
import AssetsTable from '@partials/assets/AssetsTable.vue'
import { DeleteItemModal, SummaryWrapper } from '@src/components'
import { useSummary } from '@src/composables/common'
import { usePlatformSummary } from '@src/composables/summaries/usePlatformSummary'
import BackButton from '@src/partials/actions/BackButton.vue'
import DeleteButton from '@src/partials/actions/DeleteButton.vue'
import EditButton from '@src/partials/actions/EditButton.vue'
import PageWrapper from '@src/partials/pages/PageWrapper.vue'
import { Asset, Issuer, Platform } from '@src/types'
import { SummaryItem } from '@src/types/Summary'
import {
    deleteIssuer as apiDeleteIssuer,
    getIssuer,
} from '@src/utils/api/issuers'
import { getPlatform } from '@src/utils/api/platforms'
import { useToastStore } from '@src/utils/stores/toast'
import { useUserStore } from '@src/utils/stores/user'
import moment from 'moment'
import { computed, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
const { getEnumLabel } = useEnum()

const route = useRoute()
const router = useRouter()
const toast = useToastStore()
const i18n = useI18n()
const { ModalWrapper } = useModal()
const isModalOpen = ref(false)
const userStore = useUserStore()

const issuer = ref<Issuer>(new Issuer())
const platform = ref<Platform>(new Platform())

const deleteModalOpen = ref<boolean>(false)

const assetsTableCreateInitialValues = computed(() => {
    return {
        ...new Asset(),
        ...{
            issuer_id: issuer.value.id,
            issuer: issuer.value,
            platform_name: issuer.value.platform_name,
            platform: platform.value,
        },
    }
})

const { PlatformSummary } = usePlatformSummary(platform)

const issuerDetailsItems = computed<SummaryItem[]>(() => {
    return [
        {
            label: i18n.t('id'),
            value: issuer.value.id,
            copy: true,
        },
        {
            label: i18n.t('name'),
            value: issuer.value.name,
            copy: true,
        },
        {
            label: i18n.t('status'),
            value: getEnumLabel(IssuerStatus, issuer.value.status),
        },
        {
            label: i18n.t('internal_status'),
            value: getEnumLabel(
                IssuerInternalStatus,
                issuer.value.internal_status
            ),
        },
        {
            label: i18n.t('platform_name'),
            value: issuer.value.platform_name,
            copy: true,
        },
        {
            label: i18n.t('issuer_logo'),
            value: issuer.value.company_logo?.thumb_image_url ?? null,
            special: 'image',
        },
        {
            label: i18n.t('issuer_industry'),
            value: getEnumLabel(IssuerIndustry, issuer.value.industry),
        },
        {
            label: i18n.t('wallet_id'),
            value: issuer.value.wallet_id,
            copy: true,
        },
        {
            label: i18n.t('address'),
            value: issuer.value.address,
            copy: true,
        },
        {
            label: i18n.t('city'),
            value: issuer.value.city,
            copy: true,
        },
        {
            label: i18n.t('postal_code'),
            value: issuer.value.postal_code,
            copy: true,
        },
        {
            label: i18n.t('state'),
            value: issuer.value.state,
            copy: true,
        },
        {
            label: i18n.t('country'),
            value: issuer.value.country,
            copy: true,
        },
        {
            label: i18n.t('phone_number'),
            value: issuer.value.phone_number,
            copy: true,
        },
        {
            label: i18n.t('email'),
            value: issuer.value.email,
            copy: true,
        },
        {
            label: i18n.t('issuer_website'),
            value: issuer.value.website,
            special: 'link',
            icon: 'fa-chain',
            copy: true,
        },
        {
            label: i18n.t('created_at'),
            value: moment(issuer.value.created_at).format('DD.MM.yyyy HH:mm'),
        },
        {
            label: i18n.t('updated_at'),
            value: moment(issuer.value.updated_at).format('DD.MM.yyyy HH:mm'),
        },
    ]
})

const { Summary: issuerSummary } = useSummary(issuerDetailsItems, {
    title: 'summary',
})

function toIssuers(): void {
    router.push({ name: 'issuers' })
}

function editIssuer(): void {
    router.push({ name: 'issuer.edit', params: { id: issuer.value.id } })
}

function openDeleteModal(): void {
    deleteModalOpen.value = true
}

async function deleteIssuer() {
    if (!issuer.value?.id) return
    await apiDeleteIssuer(issuer.value?.id)
    toast.success(
        i18n.t('toast_success_issuer_deleted', { issuer: issuer.value?.id })
    )
    router.push({ name: 'issuers' })
}

async function load() {
    if (route.params.id) {
        const request = await getIssuer(route.params.id as string)
        issuer.value = request.data

        const platformRequest = await getPlatform(
            issuer.value.platform_name as string
        )
        platform.value = platformRequest.data.platform
    }
}

watch(
    () => route.params.id,
    async () => {
        await load()
    }
)

onMounted(async () => {
    await load()
})
</script>
