import { ModalButtonStyles, OpenModal, useTable } from '@composables/common'
import UpdateOrCreatePlatformAttachmentForm from '@partials/platforms/UpdateOrCreatePlatformAttachmentForm.vue'
import { NameAndId } from '@src/components'
import { PlatformAttachment } from '@src/types'
import { uploadPrivate } from '@src/utils/api/s3'
import { useUserStore } from '@src/utils/stores/user'
import { Ref, computed, h, ref } from 'vue'
import { useI18n } from 'vue-i18n'

export function usePlatformAttachmentsTable(
    attachments: Ref<PlatformAttachment[]>,
    update: (successMessage?: string | null) => Promise<void>,
    openModal: OpenModal,
    isModalOpen: Ref<boolean>
) {
    const userStore = useUserStore()
    const i18n = useI18n()

    const total = computed(() => {
        return attachments.value.length
    })

    const {
        TableWrapper: PlatformAttachmentsTable,
        selected: selectedPlatformAttachmentsIds,
    } = useTable({
        tableProps: {
            id: 'platform_attachments',
            label: 'platform_attachments',
            help: 'help_platform_attachments_table',
            data: attachments,
            total: total,
            columns: [
                {
                    label: 'id',
                    key: 'id',
                    select: true,
                },
                {
                    label: 'id',
                    key: 'id',
                    condition: userStore.can('see_id_columns'),
                    copy: true,
                },
                {
                    label: 'name',
                    key: 'name',
                },
                {
                    label: 'type',
                    key: 'type',
                    cellRenderer: (props: { rowData: PlatformAttachment }) => {
                        return i18n.t(props.rowData.type as string)
                    },
                },
            ],
            actions: [
                {
                    action: handleViewPlatformAttachment,
                    icon: 'fa-eye',
                    title: 'view',
                },
                {
                    action: handleUpdateOrCreatePlatformAttachment,
                    icon: 'fa-solid fa-pen',
                    title: 'edit',
                    condition: userStore.can('update_platform'),
                },
                {
                    action: handleDeletePlatformAttachment,
                    icon: 'fas fa-trash-alt',
                    title: 'delete',
                    color: 'text-danger-500',
                    condition: userStore.can('update_platform'),
                },
            ],
        },
    })

    function handleViewPlatformAttachment(
        platformAttachment: PlatformAttachment
    ) {
        const url = platformAttachment.url
        if (url) {
            window.open(url)
        }
    }

    function handleUpdateOrCreatePlatformAttachment(
        platformAttachment: PlatformAttachment = new PlatformAttachment()
    ) {
        const editedPlatformAttachment = ref(new PlatformAttachment())
        editedPlatformAttachment.value = { ...platformAttachment }
        const file = ref<File | null>(null)
        isModalOpen.value = true

        const disableCreate = computed(() => {
            if (editedPlatformAttachment.value.id) {
                return !(
                    editedPlatformAttachment.value.name &&
                    editedPlatformAttachment.value.type
                )
            }
            return !(
                file.value &&
                editedPlatformAttachment.value.name &&
                editedPlatformAttachment.value.type
            )
        })
        openModal(
            {
                id: 'edit_platform_attachment',
                title: editedPlatformAttachment.value.id
                    ? 'modal_title_update_platform_attachment'
                    : 'modal_title_create_platform_attachment',
                open: isModalOpen,
                onCancel: () => (isModalOpen.value = false),
                onSubmit: () =>
                    savePlatformAttachment(
                        editedPlatformAttachment.value,
                        file.value as File
                    ),
            },
            () =>
                h(UpdateOrCreatePlatformAttachmentForm, {
                    // eslint-disable-next-line quote-props
                    modelValue: editedPlatformAttachment,
                    'onUpdate:modelValue': (
                        value: unknown,
                        files: Ref<FileList>
                    ) => {
                        file.value = files.value?.item(0)
                    },
                }),
            {
                submitButtonText: editedPlatformAttachment.value.id
                    ? 'update'
                    : 'create',
                styles: {
                    submitButton: ModalButtonStyles['BLUE'],
                },
                submitButtonDisabled: disableCreate,
            }
        )
    }

    function handleDeletePlatformAttachment(
        platformAttachment: PlatformAttachment
    ) {
        isModalOpen.value = true
        openModal(
            {
                id: 'delete_platform_attachment',
                title: 'modal_title_delete_platform_attachment',
                open: isModalOpen,
                onCancel: () => (isModalOpen.value = false),
                onSubmit: () => deletePlatformAttachment(platformAttachment),
            },
            () =>
                h(NameAndId, {
                    idKey: 'id',
                    nameKey: 'name',
                    modelValue: [platformAttachment],
                }),
            {
                submitButtonText: 'delete',
                styles: {
                    submitButton: ModalButtonStyles['RED'],
                },
            }
        )
    }

    function handleDeletePlatformAttachments() {
        isModalOpen.value = true
        openModal(
            {
                id: 'delete_platform_attachments',
                title: 'modal_title_delete_platform_attachments',
                open: isModalOpen,
                onCancel: () => (isModalOpen.value = false),
                onSubmit: () => deletePlatformAttachments(),
            },
            () =>
                h(NameAndId, {
                    idKey: 'id',
                    nameKey: 'name',
                    modelValue: selectedPlatformAttachments.value,
                }),
            {
                submitButtonText: 'delete',
                styles: {
                    submitButton: ModalButtonStyles['RED'],
                },
            }
        )
    }

    function deletePlatformAttachment(
        platformAttachmentToDelete: PlatformAttachment,
        submit = true
    ) {
        const attachmentsTemp = [...attachments.value]
        attachments.value.splice(
            attachments.value.findIndex(
                (platformAttachment) =>
                    platformAttachmentToDelete.id == platformAttachment.id
            ),
            1
        )
        if (submit) {
            update(
                i18n.t('toast_success_platform_attachment_deleted', {
                    platformAttachment: platformAttachmentToDelete.name,
                })
            )
                .catch((error) => {
                    attachments.value = attachmentsTemp
                    throw error
                })
                .then(() => {
                    selectedPlatformAttachmentsIds.value = []
                    isModalOpen.value = false
                })
        }
    }

    function deletePlatformAttachments() {
        const attachmentsTemp = [...attachments.value]
        selectedPlatformAttachments.value.forEach((platformAttachment) => {
            deletePlatformAttachment(platformAttachment, false)
        })

        update(
            i18n.t('toast_success_platform_attachments_deleted', {
                attachments: selectedPlatformAttachments.value
                    .map((platformAttachment) => platformAttachment.name)
                    .join(', '),
            })
        )
            .catch((error) => {
                attachments.value = attachmentsTemp
                throw error
            })
            .then(() => {
                selectedPlatformAttachmentsIds.value = []
                isModalOpen.value = false
            })
    }

    async function savePlatformAttachment(
        platformAttachment: PlatformAttachment,
        file: File
    ) {
        const attachmentsTemp = [...attachments.value]
        if (file) {
            const uploadPrivateResponse = await uploadPrivate(file)
            platformAttachment.upload_key = uploadPrivateResponse.data.key
            platformAttachment.url = uploadPrivateResponse.data.temp_storage_url
        }

        if (platformAttachment.id) {
            attachments.value[
                attachments.value.findIndex(
                    (attachment: PlatformAttachment) =>
                        platformAttachment.id == attachment.id
                )
            ] = platformAttachment
        } else {
            attachments.value.push(platformAttachment)
        }

        let toast = 'toast_success_platform_attachment_updated'
        if (!platformAttachment.id) {
            toast = 'toast_success_platform_attachment_created'
        }
        update(
            i18n.t(toast, {
                attachment: platformAttachment.name,
            })
        )
            .catch((error) => {
                attachments.value = attachmentsTemp
                throw error
            })
            .then(() => {
                isModalOpen.value = false
            })
    }

    const selectedPlatformAttachments = computed(() => {
        return attachments.value.filter(
            (item: PlatformAttachment) =>
                item.id &&
                selectedPlatformAttachmentsIds.value.includes(item.id)
        )
    })

    return {
        PlatformAttachmentsTable,
        selectedPlatformAttachmentsIds,
        handleUpdateOrCreatePlatformAttachment,
        handleDeletePlatformAttachments,
    }
}
