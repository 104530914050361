<template>
    <PageWrapper>
        <template #headline>
            <fa icon="fa-solid fa-file-signature" />&nbsp;
            {{ $t('edit') }}&nbsp; {{ $t('contract') }}&nbsp;
            {{ contract.contract_uid }}
        </template>

        <template #actions>
            <BackButton
                v-if="$router.options.history.state.back"
                @click="router.go(-1)"
            />
            <BackButton
                v-if="
                    (contract.contract_uid &&
                        $router.options.history.state.back
                            ?.toString()
                            .includes('/contracts') &&
                        $router.options.history.state.back !==
                            `/contracts/${contract.contract_uid}`) ||
                    (contract.asset_uid &&
                        $router.options.history.state.back
                            ?.toString()
                            .includes('/assets') &&
                        $router.options.history.state.back !==
                            `/assets/${contract.asset_uid}`)
                "
                icon="fa-eye"
                title="contract"
                @click="goToDetails"
            />
            <BackButton
                v-if="$router.options.history.state.back !== '/contracts'"
                icon="fa-list"
                title="contracts"
                @click="toContracts"
            />

            <SaveButton @click="saveContract" />
        </template>

        <AccordionBasic
            v-if="contract.allow_edit"
            title="contract_details"
            class="mb-2"
            :open="true"
        >
            <SelectField
                v-model="contract.asset_uid"
                v-model:options="assetSelectOptions"
                name="asset_uid"
                label="asset"
                help="help_asset_title_campaign"
                :on-search="loadAssetSelectOptions"
                :required="true"
            />
            <SelectField
                v-model="contract.contract_type"
                name="contract_type"
                label="contract_type"
                help="help_contract_type"
                :options="mapEnumToArray(ContractType)"
                :reduce="reduceEnumValue"
                :required="true"
            />
            <NumberField
                v-model="contract.interest_rate"
                name="interest_rate"
                label="interest_rate"
                unit="%"
                :required="true"
                help="help_interest_rate"
                :min="0"
                :max="100"
            />
        </AccordionBasic>
        <AccordionBasic
            v-if="contract.allow_edit"
            title="payouts"
            class="mb-2"
            :open="false"
        >
            <SelectField
                v-model="contract.contract_period_type"
                name="contract_period_type"
                label="contract_duration"
                help="help_contract_duration"
                :options="mapEnumToArray(ContractPeriodType)"
                :reduce="reduceEnumValue"
                :required="true"
            />
            <Datepicker
                v-model="contract.payment_start_date"
                name="payment_start_date"
                placeholder="first_payout"
                label="first_payout"
                help="help_first_payout"
                :required="true"
            />
            <Datepicker
                v-if="
                    contract.contract_period_type ===
                    ContractPeriodType['FIXED']
                "
                v-model="contract.payment_end_date"
                name="payment_end_date"
                placeholder="last_payout"
                label="last_payout"
                help="help_last_payout"
                :required="true"
            />
            <NumberField
                v-if="
                    contract.contract_period_type ===
                    ContractPeriodType['CONTINUOUS']
                "
                v-model="contract.contract_payments_count"
                name="contract_payments_count"
                label="total_payments_count"
                :required="true"
                help="help_total_payments_count"
            />
            <SelectField
                v-if="
                    contract.contract_period_type ===
                    ContractPeriodType['CONTINUOUS']
                "
                v-model="contract.contract_period_start"
                name="contract_period_start"
                label="start_of_contract"
                help="help_start_of_contract"
                :options="mapEnumToArray(ContractPeriodStart)"
                :reduce="reduceEnumValue"
            />
            <hr class="mb-2" />
            <Banner
                v-if="!contract.payment_dates.length"
                type="warning"
            >
                <div class="mb-1 font-medium text-gray-800">
                    {{ $t('help_contract_payment_dates_title') }}
                </div>
                <p>{{ $t('help_contract_payment_dates_text') }}</p>
            </Banner>
            <div
                v-for="index in contract.payment_dates.keys()"
                :key="`payment_date${index}`"
                class="flex gap-2 p-2"
            >
                <Datepicker
                    v-model="contract.payment_dates[index]"
                    date-format="m-d"
                    placeholder="payment_date"
                    :name="`payment_date${index}`"
                />
                <button @click.stop="() => handelRemovePaymentDate(index)">
                    <fa icon="fa-solid fa-xmark" />
                </button>
            </div>
            <div class="mt-2 p-2">
                <Datepicker
                    v-model="newPaymentDate"
                    name="new_payment_date"
                    date-format="m-d"
                    placeholder="add_date"
                    picker-input-class="bg-primary-additional-500 text-white placeholder:text-white hover:bg-primary-additional-600"
                    picker-icon-class="text-white"
                    @input="handelAddPaymentDate"
                />
            </div>
        </AccordionBasic>
        <AccordionBasic
            v-if="contract.allow_edit"
            title="early_bird_promotion"
            class="mb-2"
            :open="false"
        >
            <ToggleSwitch
                v-model="contract.has_early_bird_promotion"
                name="early_bird_promotion"
                label="early_bird_promotion"
                help="help_early_bird_promotion"
            />
            <template v-if="contract.has_early_bird_promotion">
                <NumberField
                    v-model="contract.early_bird_interest_rate"
                    label="early_bird_interest_rate"
                    name="early_bird_interest_rate"
                    unit="%"
                    :required="true"
                    help="help_early_bird_interest_rate"
                    :min="0"
                    :max="100"
                />
                <Datepicker
                    v-model="contract.early_bird_deadline"
                    enable-time
                    date-format="Y-m-d H:i"
                    placeholder="early_bird_deadline"
                    name="early_bird_deadline"
                    label="early_bird_deadline"
                    help="help_early_bird_deadline"
                />
            </template>
        </AccordionBasic>
        <AccordionBasic
            v-if="contract.allow_edit"
            title="interest_rate_calculation"
            class="mb-2"
            :open="false"
        >
            <SelectField
                v-model="contract.interest_rate_calculation_method"
                name="calculation_method"
                label="calculation_method"
                help="help_calculation_method"
                :options="mapEnumToArray(InterestRateCalculationMethod)"
                :reduce="reduceEnumValue"
                :required="true"
            />
            <SelectField
                v-model="contract.calculation_start_date"
                name="calculation_start_date"
                label="calculation_start_date"
                help="help_calculation_start_date"
                :options="mapEnumToArray(CalculationStartDate)"
                :reduce="reduceEnumValue"
                :required="true"
            />
        </AccordionBasic>
        <AccordionBasic
            v-if="contract.allow_edit"
            title="repayment"
            class="mb-2"
            :open="false"
        >
            <ToggleSwitch
                v-model="contract.is_bullet_loan"
                name="bullet_loan"
                label="bullet_loan"
                help="help_bullet_loan"
            />
            <NumberField
                v-if="!contract.is_bullet_loan"
                v-model="contract.deferred_clearance"
                label="deferred_clearance"
                name="deferred_clearance"
                help="help_deferred_clearance"
            />
        </AccordionBasic>
        <AccordionBasic
            v-if="contract.allow_edit"
            title="tax_calculation"
            class="mb-2"
            :open="false"
        >
            <ToggleSwitch
                v-model="contract.tax_calculation_enabled"
                name="tax_calculation_enabled"
                label="tax_calculation_enabled"
            />
        </AccordionBasic>
        <AccordionBasic
            title="external_wallet"
            class="mb-2"
            :open="false"
        >
            <TextField
                v-model="contract.external_wallet_id"
                label="external_wallet_id"
                name="external_wallet_id"
                help="help_external_wallet_id"
            />
        </AccordionBasic>
        <ModalWrapper v-if="isModalOpen" />
    </PageWrapper>
</template>

<script setup lang="ts">
import { h, nextTick, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import BackButton from '@partials/actions/BackButton.vue'
import {
    AccordionBasic,
    Banner,
    Datepicker,
    NumberField,
    SelectField,
    TextField,
    ToggleSwitch,
} from '@src/components'
import {
    CalculationStartDate,
    ContractPeriodStart,
    ContractPeriodType,
    ContractType,
    InterestRateCalculationMethod,
} from '@src/enums'
import SaveButton from '@src/partials/actions/SaveButton.vue'
import PageWrapper from '@src/partials/pages/PageWrapper.vue'

import { ModalButtonStyles, useEnum, useModal } from '@composables/common'
import { Asset, ContractDetails } from '@src/types'
import { getAsset, getAssets } from '@src/utils/api/assets'
import {
    createContract,
    getContract,
    updateContract,
    updateContractAfterInterestPayment,
} from '@src/utils/api/contracts'
import { useToastStore } from '@src/utils/stores/toast'
import { useI18n } from 'vue-i18n'

const router = useRouter()
const route = useRoute()
const toast = useToastStore()
const i18n = useI18n()
const { mapEnumToArray, reduceEnumValue } = useEnum()
const { ModalWrapper, openModal } = useModal()

const contract = ref<ContractDetails>(new ContractDetails())
const newPaymentDate = ref<string | null>(null)
const isModalOpen = ref(false)

const assetSelectOptions = ref<{ label: string; value: string | null }[]>([])

async function loadAssetSelectOptions(search: string) {
    const assetsRequest = await getAssets({
        'filter[asset_title]': search,
    })
    assetSelectOptions.value = assetsRequest.data.assets.map((asset: Asset) => {
        return {
            label: asset.asset_title as string,
            value: asset.asset_uid,
        }
    })
}

async function loadAssetSelectOptionsById(id: string) {
    const assetRequest = await getAsset(id)
    await loadAssetSelectOptions(assetRequest.data.asset.asset_title as string)
}

async function handelAddPaymentDate() {
    if (newPaymentDate.value) {
        contract.value.payment_dates?.push(newPaymentDate.value)
        await nextTick()
        newPaymentDate.value = null
    }
}

function handelRemovePaymentDate(index: number) {
    contract.value.payment_dates?.splice(index, 1)
}

function goToDetails() {
    if (contract.value.contract_uid) {
        router.push({
            name: 'contract',
            params: { id: contract.value.contract_uid },
        })
    } else {
        router.push({
            name: 'asset',
            params: { id: contract.value.asset_uid },
        })
    }
}

function toContracts() {
    router.push({
        name: 'contracts',
    })
}

async function saveContract() {
    if (contract.value.contract_uid) {
        try {
            if (contract.value.allow_edit === false) {
                await updateContractAfterInterestPayment(contract.value)
            } else {
                await updateContract(contract.value)
            }

            toast.success(
                i18n.t('toast_success_contract_saved', {
                    contract: contract.value.contract_uid,
                })
            )
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            if (error.response.data.needs_confirm) {
                openConfirmUpdateContractModal()
            }
        }
    } else {
        await createContract(contract.value)

        toast.success(
            i18n.t('toast_success_contract_saved', {
                contract: contract.value.contract_uid,
            })
        )
    }
}

function openConfirmUpdateContractModal() {
    isModalOpen.value = true
    openModal(
        {
            id: 'confirm_update_contract',
            title: 'modal_title_confirm_update_contract',
            open: isModalOpen,
            onCancel: () => (isModalOpen.value = false),
            onSubmit: () => {
                contract.value.confirmed = true
                saveContract()
                isModalOpen.value = false
            },
        },
        () =>
            h(
                'span',
                i18n.t('modal_text_confirm_update_contract', {
                    contract: contract.value.contract_uid,
                })
            ),
        {
            submitButtonText: 'update',
            styles: {
                submitButton: ModalButtonStyles['BLUE'],
            },
        }
    )
}

async function loadContract() {
    if (route.params.id) {
        const contractRequest = await getContract(route.params.id as string)
        contract.value = contractRequest.data
    }

    if (route.params.copyUid) {
        const contractRequest = await getContract(
            route.params.copyUid as string
        )
        contract.value = contractRequest.data
        contract.value.contract_type = ContractType.VOUCHER
        contract.value.contract_uid = null
    }

    if (route.params.assetUid && contract.value) {
        contract.value.asset_uid = route.params.assetUid as string
    }

    if (contract.value.asset_uid) {
        await loadAssetSelectOptionsById(contract.value.asset_uid)
    }
}

onMounted(async () => {
    await loadContract()
})
</script>

<style scoped></style>
