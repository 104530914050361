<template>
    <PageWrapper>
        <template #headline>
            <fa icon="fa-building" />&nbsp;{{ $t('issuers') }}
        </template>

        <IssuersTable>
            <template #actions>
                <DeleteBulkButton
                    v-if="userStore.can('delete_issuer')"
                    :selected="selected"
                    @click.stop="openBulkDeleteModal"
                />
                <CreateButton
                    v-if="userStore.can('create_issuer')"
                    @click.stop="openCreateModal"
                />
            </template>
            <template #filters>
                <DropdownFilter
                    :pin="pinFilters"
                    @apply="applyFilters"
                    @clear="clearFilters"
                    @toggle-pin="togglePinFilters"
                >
                    <DropdownFilterItem v-if="userStore.can('filter_by_id')">
                        <label class="flex items-center">
                            <TextField
                                v-model="filtersFields.id"
                                name="issuer_id"
                                :placeholder="$t('issuer_id')"
                            />
                        </label>
                    </DropdownFilterItem>
                    <DropdownFilterItem>
                        <label class="flex items-center">
                            <SelectField
                                v-model="filtersFields.platform_name"
                                v-model:options="filterPlatformOptions"
                                name="platform_name"
                                class="w-full"
                                :placeholder="$t('platform')"
                                :on-search="loadPlatformNameOptions"
                            />
                        </label>
                    </DropdownFilterItem>
                    <DropdownFilterItem>
                        <label class="flex items-center">
                            <TextField
                                v-model="filtersFields.name"
                                name="issuer_name"
                                :placeholder="$t('issuer_name')"
                            />
                        </label>
                    </DropdownFilterItem>
                    <DropdownFilterItem>
                        <label class="flex items-center">
                            <SelectField
                                v-model="filtersFields.status"
                                name="status"
                                class="w-full"
                                :options="mapEnumToArray(IssuerStatus)"
                                :placeholder="$t('issuer_status')"
                            />
                        </label>
                    </DropdownFilterItem>
                    <DropdownFilterItem>
                        <label class="flex items-center">
                            <SelectField
                                v-model="filtersFields.internal_status"
                                name="internal_status"
                                class="w-full"
                                :options="mapEnumToArray(IssuerInternalStatus)"
                                :placeholder="$t('issuer_internal_status')"
                            />
                        </label>
                    </DropdownFilterItem>
                </DropdownFilter>
            </template>
        </IssuersTable>

        <template #modals>
            <CreateIssuerModal
                v-if="createModalOpen"
                ref="createIssuerModal"
                v-model="tmpIssuer"
                v-model:open="createModalOpen"
                @submit="createIssuer"
                @cancel="closeCreateModal"
            />
            <DeleteItemModal
                id="delete-issuer-modal"
                ref="deleteIssuerModal"
                v-model="tmpIssuer"
                v-model:open="deleteModalOpen"
                title="modal_title_delete_issuer"
                name-key="name"
                id-key="id"
                @submit="deleteIssuer"
                @cancel="closeDeleteModal"
            />
            <DeleteItemsModal
                id="delete-assets-modal"
                ref="deleteIssuersModal"
                v-model="issuersToDelete"
                v-model:open="bulkDeleteModalOpen"
                title="modal_title_delete_assets"
                id-key="asset_uid"
                name-key="asset_title"
                @submit="bulkDeleteAssets"
                @cancel="closeBulkDeleteModal"
            />
        </template>
    </PageWrapper>
</template>
<script setup lang="ts">
import { useEnum, useTable } from '@composables/common'
import { IssuerInternalStatus, IssuerStatus } from '@enums/Issuer'
import PlatformNameCellRenderer from '@partials/platforms/PlatformNameCellRenderer.vue'
import {
    DeleteItemModal,
    DeleteItemsModal,
    DropdownFilter,
    DropdownFilterItem,
    SelectField,
    TextField,
} from '@src/components'
import CreateButton from '@src/partials/actions/CreateButton.vue'
import DeleteBulkButton from '@src/partials/actions/DeleteBulkButton.vue'
import CreateIssuerModal from '@src/partials/issuers/CreateIssuerModal.vue'
import PageWrapper from '@src/partials/pages/PageWrapper.vue'
import { Issuer, Platform, TableSorting } from '@src/types'
import { getPlatforms } from '@src/utils/api/platforms'
import { useToastStore } from '@src/utils/stores/toast'
import { useUserStore } from '@src/utils/stores/user'
import {
    createIssuer as apiCreateIssuer,
    deleteIssuer as apiDeleteIssuer,
    deleteIssuers,
    getIssuers,
} from '@utils/api/issuers'
import moment from 'moment'
import { computed, onMounted, ref, shallowRef } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
const { mapEnumToArray } = useEnum()

const router = useRouter()
const toast = useToastStore()
const i18n = useI18n()

const issuers = ref<Issuer[]>([])
const tmpIssuer = ref<Issuer>(new Issuer())
const count = ref<number>(0)

const filterPlatformOptions = ref<string[]>([])
const createModalOpen = ref<boolean>(false)
const bulkDeleteModalOpen = ref<boolean>(false)
const deleteModalOpen = ref<boolean>(false)
const deleteIssuerModal = ref(null)
const deleteIssuersModal = ref(null)

const userStore = useUserStore()
const { getEnumLabel } = useEnum()

const {
    TableWrapper: IssuersTable,
    selected,
    limit,
    sorting,
    page,
    filtersFields,
    filters,
    pinFilters,
    togglePinFilters,
    applyFilters,
    clearFilters,
} = useTable({
    loadData: loadData,
    filters: {
        name: null,
        platform_name: null,
        id: null,
        search: null,
    },
    tableProps: {
        id: 'issuers-table',
        label: 'issuers',
        help: 'help_issuers_table',
        pagination: true,
        data: issuers,
        total: count,
        sorting: ref<TableSorting[]>([
            {
                field: 'created_at',
                direction: 'desc',
            },
        ]),
        columns: [
            {
                label: 'id',
                key: 'id',
                select: true,
            },
            {
                label: 'name',
                key: 'name',
                url: tableUrl,
                sorting: true,
                copy: true,
            },
            {
                label: 'status',
                key: 'status',
                sorting: true,
                cellRenderer: (props: { rowData: Issuer }) => {
                    return getEnumLabel(IssuerStatus, props.rowData.status)
                },
            },
            {
                label: 'internal_status',
                key: 'internal_status',
                sorting: true,
                cellRenderer: (props: { rowData: Issuer }) => {
                    return getEnumLabel(
                        IssuerInternalStatus,
                        props.rowData.internal_status
                    )
                },
            },
            {
                label: 'platform',
                key: 'platform_name',
                cellRenderer: shallowRef(PlatformNameCellRenderer),
                sorting: true,
            },
            {
                label: 'created_at',
                key: 'created_at',
                sorting: true,
                cellRenderer: (props: { rowData: Issuer }) => {
                    if (props.rowData.created_at === null) {
                        return '-'
                    }
                    return moment(props.rowData.created_at).format(
                        'DD.MM.yyyy HH:mm'
                    )
                },
            },
        ],
        actions: [
            {
                action: handleView,
                icon: 'fa-eye',
                title: 'view',
                condition: userStore.can('view_issuers'),
            },
            {
                action: handleEdit,
                icon: 'fa-solid fa-pen',
                title: 'edit',
                condition: userStore.can('update_issuer'),
            },
            {
                action: handleDelete,
                icon: 'fas fa-trash-alt',
                title: 'delete',
                color: 'text-danger-500',
                condition: (issuer: Issuer) => {
                    return (
                        userStore.can('delete_issuer') &&
                        issuer.assets_count === 0
                    )
                },
            },
        ],
    },
})

function tableUrl(issuer: Issuer) {
    return '/issuers/' + issuer.id
}

function openBulkDeleteModal(): void {
    bulkDeleteModalOpen.value = true
}

function openCreateModal(): void {
    createModalOpen.value = true
}

async function loadData() {
    const request = await getIssuers(
        filters.value,
        page.value,
        limit.value,
        sorting.value
    )
    issuers.value = request.data.issuers
    count.value = request.data.issuers_count
}

async function loadPlatformNameOptions(search: string) {
    const initialPlatformsRequest = await getPlatforms({
        'filter[platform_name]': search,
    })
    filterPlatformOptions.value = initialPlatformsRequest.data.platforms.map(
        (platform: Platform) => {
            return platform.platform_name as string
        }
    )
}

async function createIssuer(next?: string) {
    const createIssuerRequest = await apiCreateIssuer(tmpIssuer.value)
    const id = createIssuerRequest.data.id

    createModalOpen.value = false

    if (next && next == 'edit') {
        router.push({
            name: 'issuer.edit',
            params: { id: id },
        })
    } else if (next && next == 'view') {
        router.push({ name: 'issuer', params: { id: id } })
    } else {
        page.value = 1
        limit.value = parseInt(import.meta.env.VITE_DEFAULT_TABLE_LIMIT)
        await loadData()
    }

    tmpIssuer.value = new Issuer()

    toast.success(i18n.t('toast_success_issuer_created'))
}

function closeCreateModal() {
    tmpIssuer.value = new Issuer()
    createModalOpen.value = false
}

function handleView(issuer: Issuer) {
    router.push({ name: 'issuer', params: { id: issuer.id } })
}

function handleEdit(issuer: Issuer) {
    router.push({ name: 'issuer.edit', params: { id: issuer.id } })
}

function handleDelete(issuer: Issuer) {
    tmpIssuer.value = issuer
    deleteModalOpen.value = true
}

async function deleteIssuer(issuer: Issuer) {
    if (!issuer.id) {
        toast.error(i18n.t('toast_error_cannot_delete_issuer'))
        return
    }

    await apiDeleteIssuer(issuer.id)

    deleteModalOpen.value = false

    tmpIssuer.value = new Issuer()

    toast.success(
        i18n.t('toast_success_issuer_deleted', { issuer: issuer.name })
    )

    page.value = 1
    await loadData()
}

function closeDeleteModal() {
    tmpIssuer.value = new Issuer()
}

async function bulkDeleteAssets() {
    await deleteIssuers(selected.value)

    toast.success(
        i18n.t('toast_success_issuers_deleted', {
            assets: selected.value.join(', '),
        })
    )

    bulkDeleteModalOpen.value = false

    selected.value = []

    await loadData()
}

function closeBulkDeleteModal() {
    deleteModalOpen.value = false
}

const issuersToDelete = computed(() => {
    return issuers.value.filter(
        (issuer) => issuer.id && selected.value.includes(issuer.id)
    )
})

onMounted(async () => {
    await loadData()
})
</script>
