<template>
    <div>
        <SelectField
            v-if="createForProfile === ProfileType.LEGAL"
            v-model="modelValue.natural_profile_id"
            :options="naturalProfileOptions"
            name="natural_profile_id"
            label="account"
            :required="true"
        />
        <SelectField
            v-model="modelValue.bank_account_id"
            name="account_bank_accounts"
            label="account_bank_accounts"
            help="help_bank_account"
            :options="bankAccountOptions"
            :required="true"
        />
        <TextField
            v-model="modelValue.comment"
            name="comment"
            label="comment"
            help="help_money_withdrawal_comment"
            :required="true"
            :error-text="errors?.comment"
        />
        <NumberField
            v-model="modelValue.amount"
            name="money_withdrawal_value"
            label="withdrawal_amount"
            help="help_money_withdrawal_amount"
            :min="0"
            :required="true"
        />
        <HiddenField
            v-if="createForProfile !== ProfileType.NATURAL"
            v-model="modelValue.natural_profile_id"
            name="natural_profile_id"
        />
    </div>
</template>

<script setup lang="ts">
import {
    HiddenField,
    NumberField,
    SelectField,
    TextField,
} from '@src/components'
import { ProfileType } from '@src/enums'
import {
    CreateMoneyWithdrawal,
    LegalProfile,
    NaturalProfile,
    ObjectWithStringKeysAndStringValue,
} from '@src/types'
import { getNaturalProfilesOfLegalProfile } from '@src/utils/api/accounts'
import { Ref, computed, defineEmits, onMounted, ref, toRefs, watch } from 'vue'

const props = defineProps<{
    modelValue: Ref<CreateMoneyWithdrawal>
    naturalProfile: NaturalProfile
    legalProfile: LegalProfile
    createForProfile: ProfileType
}>()

const bankAccountOptions = computed(() => {
    switch (props.createForProfile) {
        case ProfileType.LEGAL:
            return props.legalProfile.bankAccounts.map((account) => ({
                label: account.iban,
                value: account.id,
            }))
        case ProfileType.NATURAL:
            return props.naturalProfile.bankAccounts.map((account) => ({
                label: account.iban,
                value: account.id,
            }))
        default:
            return []
    }
})

const { modelValue, createForProfile } = toRefs(props)
const emit = defineEmits(['validate'])

const naturalProfileOptions = ref<{ label: string; value: string | null }[]>([])

const errors = ref<ObjectWithStringKeysAndStringValue | null>(null)
const isFormValid = ref(false)

const validate = () => {
    errors.value = {}
    isFormValid.value = true

    if (
        createForProfile.value === ProfileType.LEGAL &&
        !modelValue.value.natural_profile_id
    ) {
        isFormValid.value = false
    }

    if (
        !(
            modelValue.value.amount &&
            modelValue.value.bank_account_id &&
            modelValue.value.comment
        )
    ) {
        isFormValid.value = false
    }

    if (
        modelValue.value.comment &&
        !/^[A-Za-z0-9 -_]*$/.test(modelValue.value.comment)
    ) {
        errors.value['comment'] = 'the_field_contains_unallowed_characters'
        isFormValid.value = false
    }
}

watch(modelValue.value, () => {
    validate()
    emit('validate', isFormValid.value)
})

onMounted(async () => {
    if (modelValue.value.natural_profile_id === null) {
        modelValue.value.natural_profile_id =
            props.naturalProfile.natural_profile_uid
    }

    if (props.createForProfile === ProfileType.LEGAL) {
        const naturalProfilesRequest = await getNaturalProfilesOfLegalProfile(
            props.legalProfile.legal_profile_uid as string
        )
        naturalProfileOptions.value =
            naturalProfilesRequest.data.natural_profiles.map(
                (profile: NaturalProfile) => {
                    return {
                        label: `${profile.first_name} ${profile.last_name}`,
                        value: profile.natural_profile_uid as string,
                    }
                }
            )
    }
})
</script>
