import { ModalButtonStyles, OpenModal, useTable } from '@composables/common'
import UpdateOrCreatePlatformWebhookForm from '@partials/platforms/UpdateOrCreatePlatformWebhookForm.vue'
import { NameAndId } from '@src/components'
import { PlatformWebhookAction } from '@src/enums/Platform'
import { PlatformWebhook } from '@src/types'
import { useUserStore } from '@src/utils/stores/user'
import { Ref, computed, h, ref } from 'vue'
import { useI18n } from 'vue-i18n'

export function usePlatformWebhooksTable(
    webhooks: Ref<PlatformWebhook[]>,
    update: (successMessage?: string | null) => Promise<void>,
    openModal: OpenModal,
    isModalOpen: Ref<boolean>
) {
    const userStore = useUserStore()
    const i18n = useI18n()

    const total = computed(() => {
        return webhooks.value.length
    })

    const {
        TableWrapper: PlatformWebhooksTable,
        selected: selectedPlatformWebhooksIds,
    } = useTable({
        tableProps: {
            id: 'platform_webhooks',
            label: 'platform_webhooks',
            help: 'help_platform_webhooks_table',
            data: webhooks,
            total: total,
            columns: [
                {
                    label: 'id',
                    key: 'id',
                    select: true,
                },
                {
                    label: 'id',
                    key: 'id',
                    condition: userStore.can('see_id_columns'),
                    copy: true,
                },
                {
                    label: 'event',
                    key: 'action',
                },
                {
                    label: 'url',
                    key: 'url',
                    copy: true,
                },
            ],
            actions: [
                {
                    action: handleViewPlatformWebhook,
                    icon: 'fa-eye',
                    title: 'view',
                },
                {
                    action: handleEditOrCreatePlatformWebhook,
                    icon: 'fa-solid fa-pen',
                    title: 'edit',
                    condition: userStore.can('update_platform'),
                },
                {
                    action: handleDeletePlatformWebhook,
                    icon: 'fas fa-trash-alt',
                    title: 'delete',
                    color: 'text-danger-500',
                    condition: userStore.can('update_platform'),
                },
            ],
        },
    })

    function randomSecret() {
        const pwdChars =
            '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
        const pwdLen = 30
        return Array(pwdLen)
            .fill(pwdChars)
            .map(function (x) {
                return x[Math.floor(Math.random() * x.length)]
            })
            .join('')
    }

    function handleViewPlatformWebhook(platformWebhook: PlatformWebhook) {
        isModalOpen.value = true
        openModal(
            {
                id: 'view_platform_webhook',
                title: 'platform_webhook',
                open: isModalOpen,
                onCancel: () => (isModalOpen.value = false),
            },
            () =>
                h('div', [
                    h('p', { class: 'font-bold' }, `${i18n.t('event')}:`),
                    h('p', platformWebhook.action as string),
                    h('p', { class: 'mt-2 font-bold' }, `${i18n.t('url')}:`),
                    h('p', platformWebhook.url as string),
                ])
        )
    }

    function handleDeletePlatformWebhook(platformWebhook: PlatformWebhook) {
        isModalOpen.value = true
        openModal(
            {
                id: 'delete_platform_webhook',
                title: 'modal_title_delete_platform_webhook',
                open: isModalOpen,
                onCancel: () => (isModalOpen.value = false),
                onSubmit: () => deletePlatformWebhook(platformWebhook),
            },
            () =>
                h(NameAndId, {
                    idKey: 'id',
                    nameKey: 'name',
                    modelValue: [platformWebhook],
                }),
            {
                submitButtonText: 'delete',
                styles: {
                    submitButton: ModalButtonStyles['RED'],
                },
            }
        )
    }

    function handleDeletePlatformWebhooks() {
        isModalOpen.value = true
        openModal(
            {
                id: 'delete_platform_webhooks',
                title: 'modal_title_delete_platform_webhooks',
                open: isModalOpen,
                onCancel: () => (isModalOpen.value = false),
                onSubmit: () =>
                    deletePlatformWebhooks(selectedPlatformWebhooks.value),
            },
            () =>
                h(NameAndId, {
                    idKey: 'id',
                    nameKey: 'name',
                    modelValue: selectedPlatformWebhooks.value,
                }),
            {
                submitButtonText: 'delete',
                styles: {
                    submitButton: ModalButtonStyles['RED'],
                },
            }
        )
    }

    function deletePlatformWebhook(
        platformWebhookToDelete: PlatformWebhook,
        submit = true
    ) {
        const webhooksTemp = [...webhooks.value]
        webhooks.value.splice(
            webhooks.value.findIndex(
                (platformWebhook) =>
                    platformWebhookToDelete.id == platformWebhook.id
            ),
            1
        )
        if (submit) {
            update(
                i18n.t('toast_success_platform_webhook_deleted', {
                    action: platformWebhookToDelete.action,
                })
            )
                .catch((error) => {
                    webhooks.value = webhooksTemp
                    throw error
                })
                .then(() => {
                    isModalOpen.value = false
                })
        }
    }

    function deletePlatformWebhooks(webhooksToDelete: PlatformWebhook[]) {
        const webhooksTemp = [...webhooks.value]
        webhooksToDelete.forEach((platformWebhook) => {
            deletePlatformWebhook(platformWebhook, false)
        })

        update(
            i18n.t('toast_success_platform_webhooks_deleted', {
                actions: webhooksToDelete
                    .map((platformWebhook) => platformWebhook.action)
                    .join(', '),
            })
        )
            .catch((error) => {
                webhooks.value = webhooksTemp
                throw error
            })
            .then(() => {
                selectedPlatformWebhooksIds.value = []
                isModalOpen.value = false
            })
    }

    function handleEditOrCreatePlatformWebhook(
        webhook: PlatformWebhook = new PlatformWebhook()
    ) {
        const editedWebhook = ref(new PlatformWebhook())
        editedWebhook.value = { ...webhook }
        editedWebhook.value.secret = randomSecret()
        isModalOpen.value = true

        const disableCreate = computed(() => {
            return !(
                typeof editedWebhook.value.action === 'string' &&
                Object.values(PlatformWebhookAction)
                    .map((value) => value)
                    .includes(editedWebhook.value.action) &&
                editedWebhook.value.url &&
                editedWebhook.value.url.length > 10 &&
                (editedWebhook.value.id ||
                    (editedWebhook.value.secret?.length
                        ? editedWebhook.value.secret.length > 24
                        : false))
            )
        })

        openModal(
            {
                id: 'edit_platform_webhook',
                title: editedWebhook.value.id
                    ? 'modal_title_update_platform_webhook'
                    : 'modal_title_create_platform_webhook',
                open: isModalOpen,
                onCancel: () => (isModalOpen.value = false),
                onSubmit: () => saveWebhook(editedWebhook.value),
            },
            () =>
                h(UpdateOrCreatePlatformWebhookForm, {
                    modelValue: editedWebhook,
                }),
            {
                submitButtonText: editedWebhook.value.id ? 'update' : 'create',
                styles: {
                    submitButton: ModalButtonStyles['BLUE'],
                },
                submitButtonDisabled: disableCreate,
            }
        )
    }

    function saveWebhook(platformWebhook: PlatformWebhook) {
        const webhooksTemp = [...webhooks.value]
        if (platformWebhook.id) {
            webhooks.value[
                webhooks.value.findIndex(
                    (webhook: PlatformWebhook) =>
                        platformWebhook.id == webhook.id
                )
            ] = platformWebhook
        } else {
            webhooks.value.push(platformWebhook)
        }

        let toast = 'toast_success_platform_webhook_updated'
        if (!platformWebhook.id) {
            toast = 'toast_success_platform_webhook_created'
        }
        update(
            i18n.t(toast, {
                action: platformWebhook.action,
            })
        )
            .catch((error) => {
                webhooks.value = webhooksTemp
                throw error
            })
            .then(() => {
                isModalOpen.value = false
            })
    }

    const selectedPlatformWebhooks = computed(() => {
        return webhooks.value.filter(
            (webhook) =>
                webhook.id &&
                selectedPlatformWebhooksIds.value.includes(webhook.id)
        )
    })

    return {
        PlatformWebhooksTable,
        selectedPlatformWebhooksIds,
        handleEditOrCreatePlatformWebhook,
        handleDeletePlatformWebhooks,
    }
}
