<template>
    <div>
        <FileUploadButton
            title="button_tax_kyc_document_upload"
            accept=".png,.jpg,.jpeg,.pdf"
            mode="KYC"
            :deletable="false"
            @submit="onUpload"
        />
        <div v-if="modelValue.storage_key">
            <SelectField
                v-model="modelValue.type"
                name="kyc_document_type"
                label="type"
                help="help_kyc_document_type"
                :options="mapEnumToArray(KYCTaxDocumentType)"
                :reduce="reduceEnumValue"
                :required="true"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import { useEnum } from '@composables/common'
import FileUploadButton from '@partials/actions/FileUploadButton.vue'
import { SelectField } from '@src/components'
import { KYCTaxDocumentType } from '@src/enums'
import { KYCDocument } from '@src/types'
import { UploadFileResponse } from '@src/utils/api/s3'
import { Ref, toRefs, watch } from 'vue'

const props = defineProps<{
    modelValue: Ref<KYCDocument>
}>()

const { modelValue } = toRefs(props)

const emit = defineEmits(['update:modelValue'])

const { mapEnumToArray, reduceEnumValue } = useEnum()

function onUpload(response: UploadFileResponse) {
    modelValue.value.url = response.temp_storage_url
    modelValue.value.storage_key = response.key
}

watch(
    modelValue,
    (value) => {
        emit('update:modelValue', value)
    },
    { deep: true }
)
</script>

<style lang="scss" scoped></style>
