<template>
    <Modal
        :id="id"
        v-model:open="open"
        :title="title"
        @cancel="cancel"
        @submit="submit"
    >
        <div>
            <span class="font-bold">{{ modelValue[nameKey] }}</span> ({{
                modelValue[idKey]
            }})
        </div>

        <!-- Modal footer -->
        <template #actions>
            <button
                type="submit"
                class="btn-sm bg-danger-500 hover:bg-danger-600 text-white"
            >
                {{ $t('delete') }}
            </button>
        </template>
    </Modal>
</template>
<script setup lang="ts">
import { Modal } from '@src/components'
import { ObjectWithStringKeys } from '@src/types/ObjectWithStringKeys'
import { useVModels } from '@vueuse/core'

const props = withDefaults(
    defineProps<{
        id: string
        title: string
        idKey?: string
        nameKey?: string
        modelValue: ObjectWithStringKeys
        open: boolean
    }>(),
    {
        idKey: 'id',
        nameKey: 'name',
    }
)

const emit = defineEmits(['update:open', 'cancel', 'submit'])

const { modelValue, open } = useVModels(props, emit)

function submit(): void {
    emit('submit', modelValue.value)
}

function cancel() {
    emit('cancel')
}
</script>
