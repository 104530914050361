/* eslint-disable quotes */
/* eslint-disable prettier/prettier */
import { createI18n, Path } from 'vue-i18n'
import { en } from '@src/utils/lang/en'
import { de } from '@src/utils/lang/de'

const messages = {
    en: en,
    de: de,
}

export const i18n = createI18n({
    legacy: false,
    locale: import.meta.env.VITE_DEFAULT_LANG,
    fallbackLocale: import.meta.env.VITE_FALLBACK_LANG,
    globalInjection: true,
    messages: messages,
    missing: (locale: string, key: Path) => {
        if (key.length === 0) {
            return '-'
        }
        return key
    },
})
