<template>
    <button
        class="h-8 w-8 rounded-full align-top text-gray-400 hover:text-gray-500"
        :title="$t('copy_value')"
        @click.stop="copy"
    >
        <fa icon="fa-copy" />
    </button>
</template>

<script setup lang="ts">
import { useToastStore } from '@src/utils/stores/toast'
import { useVModels } from '@vueuse/core'
import useClipboard from 'vue-clipboard3'
import { useI18n } from 'vue-i18n'

const props = defineProps<{
    value: unknown
}>()

const { value } = useVModels(props)
const { toClipboard } = useClipboard()
const toast = useToastStore()
const i18n = useI18n()

async function copy() {
    try {
        await toClipboard(value.value as string)
        toast.success(i18n.t('toast_success_copy_to_clipboard'))
    } catch (e) {
        toast.success(i18n.t('toast_error_copy_to_clipboard'))
    }
}
</script>

<style scoped></style>
