<template>
    <div
        class="min-w-60"
        :class="{ 'my-4': label }"
    >
        <div
            v-if="label"
            class="flex items-center justify-between"
        >
            <label
                class="mb-1 block text-sm font-medium"
                :for="name"
                >{{ $t(label) }}
                <span
                    v-if="required === true"
                    class="text-danger-500"
                    >*</span
                ></label
            >
            <Tooltip
                v-if="help"
                class="ml-2"
                bg="dark"
                size="md"
                position="left"
            >
                <div
                    v-if="help"
                    class="text-sm text-gray-200"
                >
                    {{ $t(help) }}
                </div>
            </Tooltip>
        </div>
        <div
            v-if="modelValue && options.length === 0 && isPristine"
            class="form-input w-full"
        >
            {{ $t('loading') }}
        </div>
        <VueSelect
            v-else
            :id="name"
            v-model="modelValue"
            :name="name"
            :options="options"
            :reduce="reduceSelection"
            label="label"
            :required="required"
            :multiple="multiple"
            :disabled="disabled"
            :placeholder="placeholder"
            :debounce="250"
            :clearable="!required"
            :filterable="isFilterable"
            @search="handleOnSearch"
            @keydown.enter.prevent="handleEnterPress"
        >
            <template #search="{ attributes, events }">
                <input
                    v-bind="attributes"
                    class="vs__search"
                    :required="!modelValue && required"
                    v-on="events"
                    @focus="handleOnFocus"
                    @blur="handleOnBlur"
                />
            </template>
            <template #no-options>
                <span v-if="shouldSearch">
                    {{ $t('nothing_found') }}
                </span>
                <span v-else>
                    {{ $t('help_search_field') }}
                </span>
            </template>
            <template #option="option">
                {{ option.label ?? option }}
            </template>
        </VueSelect>
        <div
            v-if="bottomHelpText"
            class="mt-2 text-xs"
        >
            {{ $t(bottomHelpText) }}
        </div>
    </div>
</template>
<script setup lang="ts">
import { Tooltip } from '@src/components'
import { handleEnterPress } from '@src/utils/helpers'
import { useVModels } from '@vueuse/core'
import { computed, ref } from 'vue'

const props = defineProps<{
    name: string
    label?: string
    help?: string
    bottomHelpText?: string | null
    placeholder?: string
    required?: boolean
    options:
        | string[]
        | { label: string | null; value: number | string | null }[]
    modelValue:
        | number
        | number[]
        | string
        | string[]
        | null
        | undefined
        | unknown
    onSearch?: (search: string) => Promise<void>
    multiple?: boolean
    disabled?: boolean
}>()

const emit = defineEmits(['update:modelValue', 'update:options'])

const { modelValue, onSearch, options, multiple } = useVModels(props, emit)

const shouldSearch = ref(false)
const optionsBackup = ref<
    string[] | { label: string | null; value: number | string | null }[]
>([])

const modelValueBackup = ref<
    number | number[] | string | string[] | null | undefined | unknown
>(null)
const isPristine = ref(true)
const isFilterable = computed(() => {
    if (onSearch?.value) {
        return false
    }
    return true
})

async function handleOnSearch(search: string, loading: (val: boolean) => void) {
    if (onSearch?.value && search.length > 2) {
        shouldSearch.value = true
        loading(true)
        await onSearch.value(search)
        loading(false)
    } else {
        shouldSearch.value = false
    }
}

async function handleOnFocus() {
    isPristine.value = false
    if (!multiple.value) {
        optionsBackup.value = options.value
        modelValueBackup.value = modelValue.value
        modelValue.value = null
        options.value = []
    }
}

async function handleOnBlur() {
    if (!multiple.value) {
        setTimeout(() => {
            if (modelValue.value === null) {
                modelValue.value = modelValueBackup.value
                options.value = optionsBackup.value
            }
        })
    }
}

function reduceSelection(option: { value: string } | string) {
    if (typeof option === 'object' && typeof option !== 'undefined') {
        return option.value
    }

    return option
}
</script>
