import { ApiRes, api } from './client'
import { AxiosRequestConfig } from 'axios'
import { TableSorting, VoucherifyVoucher } from '@src/types'

export interface VoucherifyVouchersResponse {
    success: boolean
    vouchers: VoucherifyVoucher[]
}

export const getVoucherifyVouchers = (
    filters?: Record<string, unknown>,
    page?: number,
    limit?: number,
    sorting?: TableSorting[]
): ApiRes<VoucherifyVouchersResponse> => {
    const config: AxiosRequestConfig = {}

    config.params = {}

    if (filters && Object.keys(filters).length) {
        config.params = filters
    }

    if (typeof page === 'number' && typeof limit === 'number') {
        config.params['range'] = page * limit - limit + '-' + page * limit
    }

    if (sorting && sorting.length) {
        config.params['sort'] = sorting
            .map((sort) => {
                return (sort.direction === 'desc' ? '-' : '') + sort.field
            })
            .join(',')
    }

    return api.get('v2/manage/voucherify-vouchers', config)
}
